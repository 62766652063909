import { ComponentProps } from 'react';
import Modal from 'antd/es/modal';
import clsx from 'clsx';

interface IOpModalProps extends ComponentProps<typeof Modal> {}

export const OpModal = ({
  children,
  className,
  centered = true,
  ...modalProps
}: IOpModalProps) => (
  <Modal
    className={clsx('op-modal', className)}
    centered={centered}
    {...modalProps}
  >
    {children}
  </Modal>
);
