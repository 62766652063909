import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notification, Spin } from 'antd';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { RootState, AppDispatch } from 'store/store';
import { clearSelectedRedFlag } from 'store/slices/redFlagSlice';
import CombinedFormContent from './tabs/CombinedFormContent';
import DataContent from './tabs/DataContent';
import LogContent from './tabs/LogContent';
import { hasPermission, formatFullName } from 'utils/utils';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { OpDrawer } from 'components/customAntd/DLS/OpDrawer/OpDrawer';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { MultiFormButtons } from 'components/customAntd/MultiFormButtons';
import { LoadingOutlined } from '@ant-design/icons';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { DRAWER_WIDTH } from 'constants/ui';

dayjs.extend(isSameOrBefore);

interface RedFlagsDrawerProps {
    open: boolean;
    onClose: () => void;
}

const RedFlagsDrawer: React.FC<RedFlagsDrawerProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedRedFlag, describeRedFlagLoading } = useSelector((state: RootState) => state.redFlags);

    const [redFlagForm] = OpForm.useForm();
    const [dataForm] = OpForm.useForm();
    const [logForm] = OpForm.useForm();

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');
    const confirmModal = useConfirmModal();
    const [activeKey, setActiveKey] = useState<string>('redFlag');

    useEffect(() => {
        if (open) {
            redFlagForm.resetFields();
            dataForm.resetFields();
            logForm.resetFields();
            setActiveKey('redFlag');
        }
    }, [open, redFlagForm, dataForm, logForm]);

    const handleSubmitAllForms = async () => {
        const redFlagValues = redFlagForm.getFieldsValue();

        const effectiveDate = redFlagValues.effectiveDate ? dayjs(redFlagValues.effectiveDate) : null;
        const expireDate = redFlagValues.expireDate ? dayjs(redFlagValues.expireDate) : null;

        if (expireDate && effectiveDate && expireDate.isSameOrBefore(effectiveDate)) {
            notification.error({
                message: 'Error',
                description: 'Expire date must be after the effective date.',
                placement: 'bottomRight',
            });
            return;
        }

        try {
            if (redFlagForm.isFieldsTouched()) {
                await redFlagForm.submit();
            }

            if (dataForm.isFieldsTouched()) {
                await dataForm.submit();
            }
            onClose();
        } catch (error) {
            notification.error({
                message: 'Save Error',
                description: 'Failed to save red flag.',
                placement: 'bottomRight',
            });
        }
    };

    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (redFlagForm.isFieldsTouched() || dataForm.isFieldsTouched() || logForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    redFlagForm.resetFields();
                    dataForm.resetFields();
                    logForm.resetFields();
                    dispatch(clearSelectedRedFlag());
                    onClose();
                },
            });
        } else {
            dispatch(clearSelectedRedFlag());
            onClose();
        }
    };

    const items = useMemo(() => [
        {
            key: 'redFlag',
            label: 'Red Flag',
            children: <CombinedFormContent form={redFlagForm} tab='redFlag' />,
        },
        {
            key: 'visitor',
            label: 'Visitor',
            children: <CombinedFormContent form={redFlagForm} tab='visitor' />,
        },
        {
            key: 'data',
            label: 'Additional Information',
            children: <DataContent form={dataForm} />,
        },
        {
            key: 'log',
            label: 'Log',
            children: <LogContent form={logForm} />,
        }
    ], [redFlagForm, dataForm, logForm]);

    const renderTabContent = useCallback(() => {
        return items.map((item) => (
            <div
                key={item.key}
                style={{ display: item.key === activeKey ? 'block' : 'none' }}
            >
                {item.children}
            </div>
        ));
    }, [activeKey, items]);

    return (
        <OpDrawer
            title={describeRedFlagLoading ? '' : (selectedRedFlag ? `Edit Red Flag: ${formatFullName(selectedRedFlag.firstName, selectedRedFlag.middleName, selectedRedFlag.lastName)}` : 'Add New Red Flag')}
            width={DRAWER_WIDTH}
            open={open}
            onClose={finalOnClose}
            footer={
                !describeRedFlagLoading && hasRedflagWrite && (
                    <MultiFormButtons
                        forms={[redFlagForm, dataForm]}
                        submitButtonLabel="Save"
                        onSubmitClick={handleSubmitAllForms}
                        isSubmitButtonLoading={describeRedFlagLoading}
                    />
                )
            }
        >
            {describeRedFlagLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                >
                    {items.map(item => (
                        <OpTabs.TabPane tab={item.label} key={item.key} />
                    ))}
                </OpTabs>
            )}
            {renderTabContent()}
        </OpDrawer>
    );
};

export default RedFlagsDrawer;
