import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpSlider } from 'components/customAntd/DLS/OpSlider/OpSlider';
import { OpSkeleton } from 'components/customAntd/DLS/OpSkeleton/OpSkeleton';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormSlider.scss';

interface IOpFormSlider
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      'name' | 'label' | 'tooltip'
    >,
    Omit<ComponentProps<typeof OpSlider>, 'name' | 'tooltip'> {
  formItemClassName?: string;
  isLoading?: boolean;
  sliderTooltip?: ComponentProps<typeof OpSlider>['tooltip'];
}

export const OpFormSlider = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,

  // For skeleton loading state
  isLoading,

  disabled,
  sliderTooltip,
  ...elementProps
}: IOpFormSlider) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx('op-form-slider', formItemClassName)}
      name={name}
      tooltip={tooltip}
      label={label}
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Avatar active size="small" shape="square" />
      ) : (
        <OpSlider
          disabled={isReadOnly || disabled}
          tooltip={sliderTooltip}
          {...elementProps}
        />
      )}
    </OpForm.Item>
  );
};
