import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { AppDispatch, RootState } from 'store/store';
import { TABLE_HEIGHT } from 'constants/ui';
import { describeRedFlag, fetchRedFlagData, fetchRedFlagLogs, fetchRedFlags } from 'store/slices/redFlagSlice';
import { formatFullName, hasPermission } from 'utils/utils';
import { StopOutlined, EyeOutlined } from "@ant-design/icons";
import { RedFlag } from 'types/redFlagTypes';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/dates';

interface RedFlagsTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const RedFlagsTable: React.FC<RedFlagsTableProps> = ({ setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { redFlags, fetchRedFlagsLoading } = useSelector((state: RootState) => state.redFlags);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    // Get red flags from database
    useEffect(() => {
        if (orgId) {
            dispatch(fetchRedFlags({ orgId }));
        }
    }, [dispatch, orgId]);

    const transformedData = redFlags.data.map((item: RedFlag) => ({
        ...item,
        dateOfBirth: dayjs(item.dateOfBirth).isValid() ? dayjs(item.dateOfBirth).format(DATE_FORMAT) : null,
        fullName: formatFullName(item.firstName, item.middleName, item.lastName),
    }));

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'levelName',
            label: "Level Name",
            width: 300,
            render: (text: string) => {
                if (text === 'Blocklist') {
                    return <span><StopOutlined style={{ color: 'rgba(242,103,87,1.0)' }} /> {text}</span>;
                } else if (text === 'Watchlist') {
                    return <span><EyeOutlined style={{ color: '#FFD54F' }} /> {text}</span>;
                }
                return text;
            },
            filter: {
                type: 'multiSelect',
                options: [
                    { label: 'Blocklist', value: 'Blocklist' },
                    { label: 'Watchlist', value: 'Watchlist' }
                ],
            },
            sorter: (a, b) => (a.levelName || '').localeCompare(b.levelName || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'fullName',
            label: "Full Name",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.fullName || '').localeCompare(b.fullName || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'dateOfBirth',
            label: "Date of Birth",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.dateOfBirth || '').localeCompare(b.dateOfBirth || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'identification',
            label: "Identification",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.identification || '').localeCompare(b.identification || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'address',
            label: "Address",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.address || '').localeCompare(b.address || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'city',
            label: "City",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.city || '').localeCompare(b.city || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'postalCode',
            label: "Postal Code",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.postalCode || '').localeCompare(b.postalCode || ''), // Alphabetical sorting with null check
        }
    ];

    const opTableProps: IOpTableProps = {
        dataSource: transformedData,
        columns: columns,
        rowActions: {
            onEditClick: (redFlag: RedFlag) => {
                dispatch(describeRedFlag({ orgId: orgId, redFlagId: redFlag.id }));
                dispatch(fetchRedFlagData({ orgId: orgId, redFlagId: redFlag.id }));
                dispatch(fetchRedFlagLogs({ orgId: orgId, redFlagId: redFlag.id }));
                setDrawerVisible(true);
            }
        },
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        loading: fetchRedFlagsLoading,
        writeAccess: hasRedflagWrite,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
    };

    return <OpTable {...opTableProps} />;
};

export default RedFlagsTable;
