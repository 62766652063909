import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, List, notification, FormInstance } from 'antd';
import { MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { AppDispatch, RootState } from 'store/store';
import { createVisitorInvitation, resetState } from 'store/slices/visitorInvitationSlice';
import { fetchVisitorTypes } from 'store/slices/visitorTypesSlice';
import { describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { fetchRedFlags } from 'store/slices/redFlagSlice';
import { fetchUsers } from 'store/slices/usersSlice';
import VisitorModal from './VisitorModal';
import warningIcon from './warning.svg';
import STATUS from 'constants/status';
import { profileIcon } from 'utils/visitorsHelper';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import dayjs from 'dayjs';
import { formatFullName } from 'utils/utils';
import { describeVisitorWorkflowFields } from 'store/slices/visitorWorkflowsSlice';
import { updateVisit } from 'store/slices/visitsSlice';
import { updateVisitor } from 'store/slices/visitorsSlice';
import { Visitor } from 'types/visitTypes';
import { CreateVisitorInvitationResponse, VisitorInvitationPayload } from 'types/visitorInvitationTypes';
import { ApiResponse } from 'types/apiTypes';

interface ManualFormProps {
    form: FormInstance;
    onClose: () => void;
    setFormIsLoading: (isLoading: boolean) => void;
}

const ManualForm: React.FC<ManualFormProps> = ({ form, onClose, setFormIsLoading }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedLocationWorkflow = useSelector((state: RootState) => state.locations.selectedLocationWorkflow);
    const { visitorTypes, fetchVisitorTypesLoading } = useSelector((state: RootState) => state.visitorTypes);
    const { locations, globalLocation, fetchLocationsLoading } = useSelector((state: RootState) => state.locations);
    const { redFlags } = useSelector((state: RootState) => state.redFlags);
    const { users, fetchUsersLoading } = useSelector((state: RootState) => state.users);
    const { visitorWorkflowFields } = useSelector((state: RootState) => state.visitorWorkflows);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentVisitor, setCurrentVisitor] = useState<Visitor | null>(null);

    const initialValues = visitorTypes.data.length > 0 ? {
        siteId: globalLocation?.id,
        visitorTypeId: visitorTypes.data[0].id,
        visitors: []
    } : {};

    useEffect(() => {
        dispatch(fetchVisitorTypes({ orgId }));
        dispatch(fetchLocations({ orgId, status: 1 }));
        dispatch(fetchRedFlags({ orgId }));
        dispatch(fetchUsers({ orgId }));
        if (globalLocation?.id) {
            dispatch(describeLocationWorkflow({ orgId, locationId: globalLocation.id }));
        }
    }, [dispatch, globalLocation?.id, orgId]);

    useEffect(() => {
        if (selectedLocationWorkflow && selectedLocationWorkflow.SignInWorkflowId) {
            dispatch(describeVisitorWorkflowFields({ orgId, visitorWorkflowId: selectedLocationWorkflow.SignInWorkflowId }));
        }
    }, [dispatch, orgId, globalLocation?.id, selectedLocationWorkflow])

    const handleOpenModal = (visitor?: Visitor) => {
        setCurrentVisitor(visitor || null);
        setIsModalVisible(true);
    };

    const handleCreateEditVisitor = (visitor: Visitor) => {
        const currentVisitors = form.getFieldValue('visitors') || [];
        if (currentVisitor) {
            const updatedVisitors = currentVisitors.map((v: Visitor) => (v === currentVisitor ? visitor : v));
            form.setFieldsValue({ visitors: updatedVisitors });
        } else {
            form.setFieldsValue({ visitors: [...currentVisitors, visitor] });
        }
        setIsModalVisible(false);
    };

    const handleCancelModal = () => {
        setIsModalVisible(false);
    };

    const isVisitorRedFlagged = (visitor: Visitor) => {
        return redFlags.data.some(redFlag =>
            redFlag?.firstName?.toLowerCase() === visitor.firstName?.toLowerCase() &&
            redFlag?.middleName?.toLowerCase() === visitor.middleName?.toLowerCase() &&
            redFlag?.lastName?.toLowerCase() === visitor.lastName?.toLowerCase()
        );
    };

    const handleSubmit = useCallback(async ({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        setFormIsLoading(true);
        try {
            const payload: VisitorInvitationPayload = {
                ...values,
                visitors: values.visitors.map((visitor: Visitor) => ({
                    id: visitor.id || '',
                    firstName: visitor.firstName,
                    middleName: visitor.middleName || null,
                    lastName: visitor.lastName,
                    email: visitor.email || null,
                    mobilePhone: visitor.mobilePhone || null,
                    userId: visitor.userId || null,
                    status: STATUS.PENDING.id,
                    signIn: visitor.signIn || null,
                    signOut: visitor.signOut || null,
                }))
            };

            if (!payload.visitors?.length) {
                notification.error({
                    message: 'Error',
                    description: 'Please add at least one visitor.',
                    placement: 'bottomRight',
                });
                setFormIsLoading(false);
                return;
            }

            const response = await dispatch(createVisitorInvitation({ orgId, payload }));
            const visit = response.payload as ApiResponse<CreateVisitorInvitationResponse>;
            if (response.meta.requestStatus === 'fulfilled' && visit.data[0].id) {
                const visitId = visit.data[0].id;
                await dispatch(updateVisit({
                    orgId,
                    visitId,
                    visitPayload: {
                        signIn: dayjs().utc(true).local().toISOString(),
                        status: Number(STATUS.SIGNED_IN.id)
                    }
                }));

                for (const visitor of visit.data[0].visitors) {
                    if (visitor.id) {
                        await dispatch(updateVisitor({
                            orgId,
                            visitId: visitId,
                            visitorId: visitor.id,
                            visitorPayload: {
                                signIn: dayjs().utc(true).local().toISOString(),
                                status: STATUS.SIGNED_IN.id
                            }
                        }));
                    }
                }
            }

            notification.success({
                message: 'Success',
                description: 'Visitor(s) signed in successfully',
                placement: 'bottomRight',
            });
            form.resetFields();
            dispatch(resetState());
            onClose();
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred during the submission.',
                placement: 'bottomRight',
            });
        } finally {
            setFormIsLoading(false);
        }
    }, [dispatch, orgId, form, onClose, setFormIsLoading]);

    const renderFormItems = () => {
        if (!visitorWorkflowFields) return null;

        const fieldsConfig = {
            company: visitorWorkflowFields.visitCompany,
            hostId: visitorWorkflowFields.host,
            purpose: visitorWorkflowFields.visitPurpose,
            note: visitorWorkflowFields.visitNotes,
        };

        return Object.entries(fieldsConfig).map(([key, config]) => {
            if (config?.included) {
                const label = key === 'hostId' ? 'Host' : key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1');
                const rules = config.required ? [{ required: true, message: `Please enter ${label.toLowerCase()}` }] : [];
                return (
                    <React.Fragment key={key}>
                        {key === 'hostId' ? (
                            <OpForm.Select
                                name={key} label={label} rules={rules}
                                showSearch
                                loading={fetchUsersLoading}
                                placeholder="Select Host"
                                filterOption={(input, option) => {
                                    const label = option?.label;
                                    return typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase());
                                }}
                                options={users.data.filter(user => Number(user.status) === 1).map(user => ({
                                    label: user.identity?.firstName || user.identity?.lastName
                                        ? formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName)
                                        : user.identity?.email,
                                    value: user.id
                                }))}
                            />
                        ) : key === 'purpose' || key === 'note' ? (
                            <OpForm.TextAreaInput
                                name={key} label={label} rules={rules}
                                rows={4} />
                        ) : (
                            <OpForm.Input name={key} label={label} rules={rules} />
                        )}
                    </React.Fragment>
                );
            }
            return null;
        });
    };


    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
        >
            <Form.List name="visitors">
                {(fields, { add, remove }) => (
                    <List
                        locale={{ emptyText: ' ' }}
                        dataSource={fields}
                        renderItem={(field, index) => (
                            <List.Item
                                key={field.key}
                                actions={[
                                    <EditOutlined onClick={() => handleOpenModal(form.getFieldValue(['visitors', field.name]))} />,
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        isVisitorRedFlagged(form.getFieldValue(['visitors', field.name]))
                                            ? <img src={warningIcon} alt="Warning" style={{ width: '24px', height: '24px' }} />
                                            : profileIcon({
                                                firstName: form.getFieldValue(['visitors', field.name, 'firstName']),
                                                lastName: form.getFieldValue(['visitors', field.name, 'lastName']),
                                                size: 24,
                                                color: "#6D988A",
                                                marginRight: 0,
                                            })
                                    }
                                    title={formatFullName(
                                        form.getFieldValue(['visitors', field.name, 'firstName']),
                                        form.getFieldValue(['visitors', field.name, 'middleName']),
                                        form.getFieldValue(['visitors', field.name, 'lastName'])
                                    )}
                                    description={form.getFieldValue(['visitors', field.name, 'email'])}
                                />
                            </List.Item>
                        )}
                    />
                )}
            </Form.List>
            <OpForm.Button type="dashed" onClick={() => handleOpenModal()} block icon={<PlusOutlined />}>
                Add Visitor
            </OpForm.Button>

            <OpForm.Select
                label="Visitor Type" name="visitorTypeId" rules={[{ required: true, message: 'Please enter Visitor Type' }]}
                loading={fetchVisitorTypesLoading}
                placeholder="Select Visitor Type"
                options={visitorTypes.data.map(type => ({
                    label: type.name,
                    value: type.id
                }))}
            />
            <OpForm.Select
                label="Locations" name="siteId" rules={[{ required: true, message: 'Please enter Location.' }]}
                loading={fetchLocationsLoading}
                placeholder="Select Locations"
                options={locations.data.map(location => ({
                    label: location.name,
                    value: location.id
                }))}
            />
            {renderFormItems()}
            <VisitorModal
                open={isModalVisible}
                onCreate={handleCreateEditVisitor}
                onCancel={handleCancelModal}
                initialData={currentVisitor}
            />
        </OpForm>
    );
};

export default ManualForm;
