import React, { useCallback, useEffect } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";
import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchVisitorWorkflows } from 'store/slices/visitorWorkflowsSlice';
import { updateLocationWorkflow } from 'store/slices/locationsSlice';
import { notification } from 'antd';
import { hasPermission } from 'utils/utils';

interface WorkflowContentProps {
    form: any;
}

const WorkflowContent: React.FC<WorkflowContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedLocation = useSelector((state: RootState) => state.locations.selectedLocation);
    const visitorWorkflows = useSelector((state: RootState) => state.visitorWorkflows.visitorWorkflows);
    const selectedLocationWorkflow = useSelector((state: RootState) => state.locations.selectedLocationWorkflow);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasLocationRead = hasPermission(tokenScopeList, orgId, 'o', 'location:r');
    const hasLocationWrite = hasPermission(tokenScopeList, orgId, 'o', 'location:w');

    // Create initial values based on the selectedLocationWorkflow or set to the first available option
    const initialValues = selectedLocationWorkflow ? {
        SignInWorkflowId: selectedLocationWorkflow.SignInWorkflowId,
        SignOutWorkflowId: selectedLocationWorkflow.SignOutWorkflowId,
        KioskInWorkflowId: selectedLocationWorkflow.KioskInWorkflowId,
        KioskOutWorkflowId: selectedLocationWorkflow.KioskOutWorkflowId,
    } : {
        SignInWorkflowId: visitorWorkflows.data.find(workflow => workflow.workflowTypeId)?.id,
        SignOutWorkflowId: visitorWorkflows.data.find(workflow => workflow.workflowTypeId)?.id,
        KioskInWorkflowId: visitorWorkflows.data.find(workflow => workflow.workflowTypeId)?.id,
        KioskOutWorkflowId: visitorWorkflows.data.find(workflow => workflow.workflowTypeId)?.id,
    };

    useEffect(() => {
        if (orgId) {
            dispatch(fetchVisitorWorkflows({ orgId }))
        }
    }, [dispatch, orgId]);

    const getOptions = (workflowTypeId: number) => {
        return visitorWorkflows.data
            .filter(workflow => workflow.workflowTypeId === workflowTypeId)
            .map(workflow => ({ label: workflow.name, value: workflow.id }));
    };

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        if (selectedLocation) {
            dispatch(updateLocationWorkflow({ orgId, locationId: selectedLocation.id, workflow: values }));
            notification.success({
                message: 'Success',
                description: 'Location workflows updated successfully',
                placement: 'bottomRight',
            });

        }
    }, [dispatch, selectedLocation, orgId]);

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasLocationWrite && hasLocationRead}
        >
            <OpRow gutter={16}>
                <OpCol span={24}>
                    <OpForm.Select
                        label="Attendant Sign In" name="SignInWorkflowId"
                        options={getOptions(1)} />
                </OpCol>
            </OpRow>
            <OpRow gutter={16}>
                <OpCol span={24}>
                    <OpForm.Select
                        label="Attendant Sign Out" name="SignOutWorkflowId"
                        options={getOptions(2)} />
                </OpCol>
            </OpRow>
            <OpRow gutter={16}>
                <OpCol span={24}>
                    <OpForm.Select
                        label="Kiosk Sign In" name="KioskInWorkflowId"
                        options={getOptions(3)} />
                </OpCol>
            </OpRow>
            <OpRow gutter={16}>
                <OpCol span={24}>
                    <OpForm.Select
                        label="Kiosk Sign Out" name="KioskOutWorkflowId"
                        options={getOptions(4)} />
                </OpCol>
            </OpRow>
        </OpForm>
    );
};

export default WorkflowContent;
