import React from "react";
import { List, Typography, Card } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import qrCodeKioskDownload from 'images/qr-code-kiosk-download.svg';

const { Text } = Typography;

const ConnectionContent: React.FC = () => {
    const selectedKiosk = useSelector((state: RootState) => state.kiosks.selectedKiosk);

    const data = [
        { label: 'Device Type', value: selectedKiosk?.deviceType || 'N/A' },
        { label: 'OS Version', value: selectedKiosk?.osVersion || 'N/A' },
        { label: 'Kiosk Version', value: selectedKiosk?.kioskVersion || 'N/A' },
    ];

    return (
        <>
            {selectedKiosk?.status.id === 1 ? (
                <List
                    header={<div>DEVICE INFORMATION</div>}
                    itemLayout="horizontal"
                    dataSource={data}
                    bordered
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.label}
                                description={item.value}
                            />
                        </List.Item>
                    )}
                />
            ) : (
                <Card title="CONNECTING INSTRUCTIONS" style={{ margin: '20px' }}>
                    <ol>
                        <li>
                            Download InVisit Kiosk app on your tablet to be used as the self-serve kiosk.
                            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                                <div>Scan the QR code with your tablet to download</div>
                                <img
                                    src={qrCodeKioskDownload}
                                    alt="QR Code to Download InVisit Kiosk App"
                                    style={{ width: '200px', height: '200px' }}
                                />
                            </div>
                        </li>
                        <li>
                            Enter code in the app on your tablet to connect your account.
                            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                                <Text strong style={{ fontSize: '24px', color: '#89CFF0' }}>{selectedKiosk?.code || 'N/A'}</Text>
                            </div>
                        </li>
                    </ol>
                </Card>
            )}
        </>
    );
};

export default ConnectionContent;
