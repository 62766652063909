import { ComponentProps } from 'react';
import Tabs from 'antd/es/tabs';

export const OpTabs = ({
  size = 'small',
  className = '',
  ...tabProps
}: ComponentProps<typeof Tabs>) => (
  <Tabs className={`op-tabs ${className}`.trim()} size={size} {...tabProps} />
);

OpTabs.TabPane = Tabs.TabPane; // Add this line to ensure TabPane is accessible
