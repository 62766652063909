import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notification, Spin } from 'antd';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { RootState, AppDispatch } from 'store/store';
import { clearSelectedLocation } from 'store/slices/locationsSlice';
import CombinedFormContent from './tabs/CombinedFormContent';
import WorkflowContent from './tabs/WorkflowContent';
import { hasPermission } from 'utils/utils';
import { OpDrawer } from 'components/customAntd/DLS/OpDrawer/OpDrawer';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { MultiFormButtons } from 'components/customAntd/MultiFormButtons';
import { LoadingOutlined } from '@ant-design/icons';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { DRAWER_WIDTH } from 'constants/ui';

interface LocationsDrawerProps {
    open: boolean;
    onClose: () => void;
}

const LocationsDrawer: React.FC<LocationsDrawerProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { selectedLocation, describeLocationLoading } = useSelector((state: RootState) => state.locations);

    const [locationForm] = OpForm.useForm();
    const [workflowForm] = OpForm.useForm();

    const hasLocationWrite = hasPermission(tokenScopeList, orgId, 'o', 'location:w');
    const confirmModal = useConfirmModal();
    const [activeKey, setActiveKey] = useState<string>('location');

    useEffect(() => {
        if (open) {
            locationForm.resetFields();
            workflowForm.resetFields();
            setActiveKey('location');
        }
    }, [open, locationForm, workflowForm]);

    const handleSubmitAllForms = async () => {
        try {
            const locationValues = await locationForm.validateFields();
            const workflowValues = await workflowForm.validateFields();

            if (locationValues && workflowValues) {
                if (locationForm.isFieldsTouched()) {
                    await locationForm.submit();
                }
                if (workflowForm.isFieldsTouched()) {
                    await workflowForm.submit();
                }
                onClose(); // Close the drawer after successful submit
            }
        } catch (error) {
            notification.error({
                message: 'Save Error',
                description: 'Failed to save location.',
                placement: 'bottomRight',
            });
        }
    };

    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (locationForm.isFieldsTouched() || workflowForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    locationForm.resetFields();
                    workflowForm.resetFields();
                    dispatch(clearSelectedLocation());
                    onClose();
                },
            });
        } else {
            dispatch(clearSelectedLocation());
            onClose();
        }
    };

    const items = useMemo(() => [
        {
            key: 'location',
            label: 'Location',
            children: <CombinedFormContent form={locationForm} tab='location' />,
        },
        {
            key: 'details',
            label: 'Details',
            children: <CombinedFormContent form={locationForm} tab='details' />,
        },
        ...(selectedLocation ? [{
            key: 'workflow',
            label: 'Workflow',
            children: <WorkflowContent form={workflowForm} />,
        }] : [])
    ], [selectedLocation, locationForm, workflowForm]);

    const renderTabContent = useCallback(() => {
        return items.map((item) => (
            <div
                key={item.key}
                style={{ display: item.key === activeKey ? 'block' : 'none' }}
            >
                {item.children}
            </div>
        ));
    }, [activeKey, items]);

    return (
        <OpDrawer
            title={describeLocationLoading ? '' : (selectedLocation ? `Edit Location: ${selectedLocation.name}` : 'Add New Location')}
            width={DRAWER_WIDTH}
            open={open}
            onClose={finalOnClose}
            footer={
                !describeLocationLoading && hasLocationWrite && (
                    <MultiFormButtons
                        forms={[locationForm, workflowForm]}
                        submitButtonLabel="Save"
                        onSubmitClick={handleSubmitAllForms}
                        isSubmitButtonLoading={describeLocationLoading}
                    />
                )
            }
        >
            {describeLocationLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                >
                    {items.map(item => (
                        <OpTabs.TabPane tab={item.label} key={item.key} />
                    ))}
                </OpTabs>
            )}
            {renderTabContent()}
        </OpDrawer>
    );
};

export default LocationsDrawer;
