import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import LocationsDrawer from './LocationsDrawer';
import LocationsTable from './LocationsTable';
import { LOCATION_TOOLTIP } from 'constants/tooltip';

const Locations: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <OpPage title="Locations" tooltip={LOCATION_TOOLTIP} subtitle="">
            <LocationsTable setDrawerVisible={setDrawerVisible} />
            <LocationsDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </OpPage>
    );
}

export default Locations;
