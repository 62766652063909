import { ComponentProps } from 'react';
import DatePicker from 'antd/es/date-picker';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_TIME_FORMAT } from 'constants/dates';

import './OpDatePicker.scss';

interface IOpDatePickerProps
  extends Omit<
    ComponentProps<typeof DatePicker>,
    'value' | 'onChange' | 'picker'
  > {
  testId?: string;
  gtm?: string;
  showTime?: boolean | { format: string };
  value?: Dayjs | string;
  onChange?: (date: Dayjs | null, dateString: string | string[]) => void; // Adjust the type here
}

export const OpDatePicker = ({
  className = '',
  testId = 'op-date-picker',
  gtm,
  value,
  format = DATE_TIME_FORMAT,
  onChange,
  ...datePickerProps
}: IOpDatePickerProps) => {
  // We want to store a string in the form values rather than a dayjs object
  const handleChange = (date: any, dateString: string | string[]): void => {
    const dayjsDate = date ? dayjs(date) : null;
    onChange?.(dayjsDate, dateString); // Pass the Dayjs object directly
  };

  // We need to convert the now stored string to a dayjs object
  const pickerValue = typeof value === 'string' ? dayjs(value) : value;

  return (
    <DatePicker
      className={clsx('op-date-picker', className)}
      data-testid={testId}
      data-gtm={gtm}
      format={format}
      value={pickerValue}
      onChange={handleChange}
      {...datePickerProps}
    />
  );
};
