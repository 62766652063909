import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import RolesTable from './RolesTable';
import RolesDrawer from './RolesDrawer';
import { ROLES_TOOLTIP } from 'constants/tooltip';

const Roles: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <OpPage title="Roles" tooltip={ROLES_TOOLTIP} subtitle="">
            <RolesTable setDrawerVisible={setDrawerVisible} />
            <RolesDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </OpPage>
    );
}

export default Roles;
