import { ComponentProps } from 'react';
import clsx from 'clsx';
import { OpSelect } from '../OpSelect/OpSelect';
import timezones from './timezones.json';

export const OpTimezonePicker = ({
  testId = 'op-timezone-picker',
  gtm,
  children,
  className,
  ...opSelectProps
}: ComponentProps<typeof OpSelect>) => {
  const timezoneOptions = Object.entries(timezones).map(([label, value]) => ({
    value,
    label,
  }));

  return (
    <OpSelect
      className={clsx('op-timezone-picker', className)}
      testId={testId}
      gtm={gtm}
      {...opSelectProps}
      options={timezoneOptions}
    >
      {children}
    </OpSelect>
  );
};
