import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ITableState } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpCheckbox } from 'components/customAntd/DLS/OpCheckbox/OpCheckbox';
import { OpMenu } from 'components/customAntd/DLS/OpMenu/OpMenu';
import { OpTooltip } from 'components/customAntd/DLS/OpTooltip/OpTooltip';
import { OpDropdown } from 'components/customAntd/DLS/OpDropdown/OpDropdown';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpTableRecordType } from '../OpTableCore';
import { FilterOutlined } from '@ant-design/icons';
import { DropdownProps } from 'antd';

interface IShowHideColumnsButtonProps {
  tableState: ITableState<OpTableRecordType>;
  setTableState: React.Dispatch<React.SetStateAction<ITableState<OpTableRecordType>>>;
  onVisibleColumnsChange?: (visibleColumns: Set<string>) => void;
}

export const ShowHideColumnsButton = ({
  tableState,
  setTableState,
  onVisibleColumnsChange
}: IShowHideColumnsButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const visibleColumns = useMemo(() => {
    return new Set(
      tableState.columns
        .filter(col => !col.hidden && col.label !== undefined)
        .map(col => col.label as string)
    );
  }, [tableState.columns]);

  const onVisibleColumnsChangeRef = useRef(onVisibleColumnsChange);
  const visibleColumnsRef = useRef(visibleColumns);

  useEffect(() => {
    onVisibleColumnsChangeRef.current = onVisibleColumnsChange;
    visibleColumnsRef.current = visibleColumns;
  }, [onVisibleColumnsChange, visibleColumns]);

  useEffect(() => {
    if (onVisibleColumnsChangeRef.current) {
      onVisibleColumnsChangeRef.current(visibleColumnsRef.current);
    }
  }, [open]);

  const onCheckboxClick = useCallback((key: string, disabled?: boolean) => {
    if (disabled) return;

    setTableState((currentTableState: ITableState<OpTableRecordType>) => {
      const newColumns = [...currentTableState.columns];
      const foundColumnIndex = newColumns.findIndex((column) => column.key === key);

      if (foundColumnIndex === -1) return currentTableState; // If the column is not found, return the current state

      // Toggle the hidden state
      newColumns[foundColumnIndex].hidden = !newColumns[foundColumnIndex].hidden;

      return {
        ...currentTableState,
        columns: newColumns,
      };
    });
    // Keep the dropdown open
    setOpen(true);
  }, [setTableState]);

  const handleMenuItemClick = useCallback((event: React.MouseEvent, key: string, disabled: boolean) => {
    event.stopPropagation();
    if (!disabled) {
      onCheckboxClick(key, disabled);
    }
  }, [onCheckboxClick]);

  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    console.log("nextOpne", nextOpen);
    console.log("info", info);
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const items = useMemo(() => tableState.columns.map(({ key, label, hidden, disabled }) => {
    return {
      key: key as string,
      label: (
        <div onClick={(event) => handleMenuItemClick(event, key as string, disabled!)} style={{ display: 'flex', alignItems: 'center' }}>
          <OpCheckbox
            checked={!hidden}
            disabled={disabled}
            onClick={(event) => {
              event.stopPropagation();
              if (!disabled) {
                onCheckboxClick(key as string, disabled);
              }
            }}
          />
          <span style={{ marginLeft: 8 }}>{label}</span>
        </div>
      ),
      disabled: disabled
    };
  }), [tableState.columns, handleMenuItemClick, onCheckboxClick]);

  const menu = useCallback(() => <OpMenu items={items} />, [items]);

  return (
    <OpTooltip placement="topRight" title={t('Show/hide columns')}>
      <OpDropdown
        dropdownRender={menu}
        trigger={['click']}
        open={open}
        onOpenChange={handleOpenChange}
      >
        <OpButton
          testId="show-hide-columns-button"
          size="large"
          shape="circle"
          icon={<FilterOutlined />}
        />
      </OpDropdown>
    </OpTooltip>
  );
};
