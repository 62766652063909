import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiErrorResponse } from 'types/apiTypes';
import { describeVisit, fetchVisits } from './visitsSlice';
import { UpdateVisitorPayload } from 'types/visitorTypes';
import { Visitor } from 'types/visitTypes';

interface VisitorsState {
    loading: boolean;
    error: string | null;
    visitorPhotoUrl: string | null;
}

const initialState: VisitorsState = {
    loading: false,
    error: null,
    visitorPhotoUrl: null
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const updateVisitor = createAsyncThunk<void, { orgId: number; visitId: number; visitorId: number; visitorPayload: Partial<UpdateVisitorPayload> }, { rejectValue: string; dispatch: any }>(
    'visitors/updateVisitor',
    async ({ orgId, visitId, visitorId, visitorPayload }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitors/${visitorId}`, visitorPayload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchVisits({ orgId }));
            dispatch(describeVisit({ orgId, visitId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update visitor');
        }
    }
);

export const addVisitorsToVisit = createAsyncThunk<void, { orgId: number; visitId: number; visitors: Visitor[] }, { rejectValue: string; dispatch: any }>(
    'visitors/addVisitorsToVisit',
    async ({ orgId, visitId, visitors }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitors`, { visitors }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeVisit({ orgId, visitId }));
            dispatch(fetchVisits({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to add visitors to visit');
        }
    }
);

export const removeVisitorFromVisit = createAsyncThunk<void, { orgId: number; visitId: number; visitorId: number }, { rejectValue: string; dispatch: any }>(
    'visitors/removeVisitorFromVisit',
    async ({ orgId, visitId, visitorId }, { dispatch, rejectWithValue }) => {
        try {
            await axios.delete(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitors/${visitorId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchVisits({ orgId }));
            dispatch(describeVisit({ orgId, visitId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to remove visitor from visit');
        }
    }
);


export const viewVisitorPhoto = createAsyncThunk<string, { orgId: number; visitId: number; visitorId: number }, { rejectValue: string }>(
    'visitors/viewVisitorPhoto',
    async ({ orgId, visitId, visitorId }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitors/${visitorId}/photo`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                responseType: 'blob',
            });
            const imageBlob = response.data;
            const imageUrl = URL.createObjectURL(imageBlob);
            return imageUrl;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visitor photo');
        }
    }
);

export const fetchVisitorAgreement = createAsyncThunk<string, { orgId: number; visitId: number; visitorId: number }, { rejectValue: string }>(
    'visitors/fetchVisitorAgreement',
    async ({ orgId, visitId, visitorId }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitors/${visitorId}/agreement`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/pdf',
                },
                responseType: 'blob',
            });
            const pdfBlob = response.data;
            const pdfUrl = URL.createObjectURL(pdfBlob);
            return pdfUrl;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visitor agreement');
        }
    }
);

const visitorsSlice = createSlice({
    name: 'visitors',
    initialState,
    reducers: {
        clearVisitorsState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateVisitor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateVisitor.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateVisitor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to update visitor';
            })
            .addCase(addVisitorsToVisit.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addVisitorsToVisit.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(addVisitorsToVisit.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to add visitors to visit';
            })
            .addCase(removeVisitorFromVisit.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(removeVisitorFromVisit.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(removeVisitorFromVisit.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to remove visitor from visit';
            })
            .addCase(viewVisitorPhoto.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.visitorPhotoUrl = null; // Reset photo URL
            })
            .addCase(viewVisitorPhoto.fulfilled, (state, action) => {
                state.loading = false;
                state.visitorPhotoUrl = action.payload;
            })
            .addCase(viewVisitorPhoto.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch visitor photo';
            })
            .addCase(fetchVisitorAgreement.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchVisitorAgreement.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(fetchVisitorAgreement.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch visitor agreement';
            });
    },
});

export const { clearVisitorsState } = visitorsSlice.actions;
export default visitorsSlice.reducer;
