import { ComponentProps } from 'react';
import Button from 'antd/es/button';

import './OpButton.scss';

interface IOpButtonProps extends ComponentProps<typeof Button> {
  testId?: string;
  gtm?: string;
}

export const OpButton = ({
  testId = 'op-button',
  children,
  className = '',
  gtm,
  ...buttonProps
}: IOpButtonProps) => {
  return (
    <Button
      data-testid={testId}
      data-gtm={gtm}
      className={`op-button ${className}`.trim()}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
