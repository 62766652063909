export const DASHBOARD_TOOLTIP = "The InVisit dashboard provides a real-time, comprehensive view of current visitors including today’s expected visitors. ";
export const VISITORS_TOOLTIP = "The Visitors List view will list visitors to your organization. Each visit will highlight their current status and display its associated visitors.";
export const LOCATION_TOOLTIP = "Any area where a visitor will seek to enter your organization, by way of signing in and out, is considered a Location.  Locations can have dedicated workflows to manage the visit process.";
export const USERS_TOOLTIP = "Users refer to those individuals that can be given access to InVisit solutions.  Access is defined by the Role that is assigned to the user.";
export const ROLES_TOOLTIP = "Roles refers to a set of permissions that can be assigned to a user.  These permissions define what a user has access to within InVisit.  A user can can be assigned to multiple roles.";
export const KIOSK_TOOLTIP = "A Kiosk is a self-serve tablet that visitors can use to sign in and out of an organization.  Tablets can be registered with InVisit and then assigned a desired workflow to allow or deny access to the Organization.";
export const INVITATIONOPTIONS_TOOLTIP = "Invitation options allow a user to set invitation preferences (how invitations are create and sent to the visitor) and manage invitation form fields (what’s included on the invitation form).";
export const WORKFLOW_TOOLTIP = "Workflow configurations allow a user to establish desired preferences for visitor signs ins, and sign outs.  Multiple workflows can be set up to support multiple scenarios.";
export const REDFLAG_TOOLTIP = "A Red Flag is an association to being on a Watchlist and/or Blocklist.  InVisit supports Blocklist and Watchlist integrations, along with the management of internal watchlists and blocklists.  Organizations will have the ability to access their watchlists, blocklists, and reports to quickly identify any red flags.";
export const AGREEMENTS_TOOLTIP = "Agreements refers to any document that a visitor needs to acknowledge as part of the sign in, sign out, or pre-registration process. Within InVisit, users can create, modify, and delete agreements.";
export const VISITORREPORTRS_TOOLTIP = "The Visitors Report shows the total number of completed visits per day for the selected date range.";
export const REPEATVISITORS_TOOLTIP = "The Repeat Visitors Report shows any visitors that has visited multiple times within in a selected date range.";
export const REDFLAGREPORTS_TOOLTIP = "The Red Flag Report provides red flag totals, with the ability to drill down into the list of visitors that have associated red flags, for the selected date range.";
export const HOSTREPORTS_TOOLTIP = "The Host Report provide visit totals per host, for the selected date range.";
export const ACTIVITYLOG_TOOLTIP = "The Activity Log is an internal report that shows admin related activities that have occurred within the Organization, for the selected date range.";