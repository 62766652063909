import { ButtonProps } from 'antd/es/button';
import { OpTooltip } from 'components/customAntd/DLS/OpTooltip/OpTooltip';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';

import { PlusOutlined } from '@ant-design/icons';

interface AddButtonProps {
  onClick: ButtonProps['onClick'];
  itemName?: string;
}

export const AddButton = ({ onClick, itemName = 'item' }: AddButtonProps) => (
  <OpTooltip placement="topRight" title={`Add ${itemName}`}>
    <OpButton
      data-testid={`add-${itemName.toLowerCase().split(' ').join('-')}`}
      size="large"
      shape="circle"
      icon={<PlusOutlined />}
      onClick={onClick}
    />
  </OpTooltip>
);
