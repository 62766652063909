import React, { useState, useCallback, useRef } from 'react';
import { Modal, Button, Select, notification } from 'antd';
import { useEvent } from 'react-use';
import idBarcode from 'images/idBack.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { describeVisit, fetchVisits } from 'store/slices/visitsSlice';
import { formatFullName, hasPermission } from 'utils/utils';
import STATUS from 'constants/status';
import dayjs from 'dayjs';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import VisitorsDrawer from 'components/pages/visitors/VisitorsDrawer';

interface ScanIdComponentProps {
    open: boolean;
    onClose: () => void;
}

interface OptionType {
    value: string;
    label: string;
    key: string;
}

const ScanIdComponent: React.FC<ScanIdComponentProps> = ({ open, onClose }) => {
    const visits = useSelector((state: RootState) => state.visits.visits);
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { globalLocation } = useSelector((state: RootState) => state.locations);

    const [scannedData, setScannedData] = useState('');
    const [scanning, setScanning] = useState(false);
    // eslint-disable-next-line
    const [newLineCount, setNewLineCount] = useState(0);

    const [options, setOptions] = useState<OptionType[]>([]);
    // eslint-disable-next-line
    const [parsedNames, setParsedNames] = useState<{ firstName: string, middleName: string, lastName: string } | null>(null);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    const [selectedVisitorName, setSelectedVisitorName] = useState<string>('');
    const [selectedVisitId, setSelectedVisitId] = useState<string>('');

    const [isVisitorOptionsModalOpen, setIsVisitorOptionsModalOpen] = useState<boolean>(false);
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);

    const timeoutRef = useRef<number | null>(null); // Reference to store the timeout ID

    useEvent('keydown', scanning ? (event: Event) => handleKeyDown(event as KeyboardEvent) : null, document);

    // console.log("scannedData", scannedData);
    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current); // Clear any existing timeout
        }

        timeoutRef.current = window.setTimeout(() => {
            // Reset state if timeout is reached
            setScannedData('');
            setScanning(false);
            setNewLineCount(0);
            notification.error({
                message: 'Scan Failed',
                description: 'Could not scan the driver license. Please try again.',
                placement: 'bottomRight'
            });
        }, 5000); // Set timeout for 5 seconds

        if (event.key === 'Enter') {
            setNewLineCount(prev => {
                const newCount = prev + 1;
                if (newCount >= 2) {
                    handleScannedData(scannedData);
                    setScannedData('');
                    setScanning(false);
                    setNewLineCount(0);
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                } else {
                    setScannedData(prev => prev + '\n');
                }
                return newCount;
            });
        } else if (event.key !== 'Shift') {
            setScannedData(prev => prev + event.key);
        }
        // eslint-disable-next-line
    }, [scannedData]);

    const handleScannedData = (data: string) => {
        console.log("Scanned Data:", data); // Moved the console log here
        const cleanedData = data.replace(/Shift/g, '');
        const extractedData = parseDriverLicense(cleanedData);
        if (extractedData) {
            console.log(`Last Name: ${extractedData.lastName}`);
            console.log(`First Name: ${extractedData.firstName}`);
            console.log(`Middle Name: ${extractedData.middleName}`);
            setParsedNames(extractedData);
            onClose();
            handleVisitorOptions(extractedData); // Run visitor options logic after scanning
        } else {
            setScannedData('');
            setScanning(false);
            setNewLineCount(0);
            console.log("Could not scan the driver license.");
        }
    };

    const parseDriverLicense = (data: string) => {
        const lastNameRegex = /DCS([A-Z\s]+?)(?=DAC)/;
        const firstNameRegex = /DAC([A-Z\s]+?)(?=DAD)/;
        const middleNameRegex = /DAD([A-Z\s]+?)(?=DBD)/;

        const lastNameMatch = data.match(lastNameRegex);
        const firstNameMatch = data.match(firstNameRegex);
        const middleNameMatch = data.match(middleNameRegex);

        if (lastNameMatch && firstNameMatch && middleNameMatch) {
            return {
                lastName: lastNameMatch[1]?.trim() || "NONE",
                firstName: firstNameMatch[1]?.trim() || "NONE",
                middleName: middleNameMatch[1]?.trim() || "NONE",
            };
        }
        return null;
    };

    const handleVisitorOptions = (parsedNames: { firstName: string, middleName: string, lastName: string }) => {
        if (visits.data.length === 0) {
            dispatch(fetchVisits({ orgId }));
        }

        const filteredVisits = visits.data.filter(visit =>
            visit.site?.id === globalLocation?.id &&
            (hasAllvisitorsWrite || globalUserId === visit.host.userId) &&
            dayjs(visit.scheduleStart).isSame(dayjs(), 'day') &&
            visit.visitors.some(visitor =>
                visitor.status === STATUS.PENDING.id
            )
        );

        const newOptions: OptionType[] = filteredVisits.flatMap(visit =>
            visit.visitors
                .filter(visitor => {
                    const firstNameMatch = parsedNames.firstName === "NONE" || visitor.firstName?.toLowerCase() === parsedNames.firstName.toLowerCase();
                    const middleNameMatch = parsedNames.middleName === "NONE" || visitor.middleName?.toLowerCase() === parsedNames.middleName.toLowerCase();
                    const lastNameMatch = parsedNames.lastName === "NONE" || visitor.lastName?.toLowerCase() === parsedNames.lastName.toLowerCase();
                    return firstNameMatch && middleNameMatch && lastNameMatch;
                })
                .map(visitor => ({
                    value: `${visit.id}-${visitor.id}`,
                    label: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
                    key: `${visit.id}-${visitor.id}`
                }))
        );

        setOptions(newOptions);

        // Handle single or multiple options only if scanning is completed
        if (newOptions.length === 0) {
            notification.warning({
                message: 'No Matched Visitor',
                description: 'No matched visitor is found',
                placement: 'bottomRight'
            });
        } else if (newOptions.length === 1) {
            const [visitId] = newOptions[0].value.split('-');
            dispatch(describeVisit({ orgId, visitId: Number(visitId) }));
            setIsVisitorsDrawerOpen(true);
        } else if (newOptions.length > 1) {
            setIsVisitorOptionsModalOpen(true);
        }
    };

    const resetSelection = () => {
        setSelectedVisitId('');
        setSelectedVisitorName('');
    };

    const handleVisitorChange = (value: string, option: any) => {
        setSelectedVisitId(value);
        setSelectedVisitorName(option.label);

        const visitId = Number(value.split('-')[0]);
        dispatch(describeVisit({ orgId, visitId }));
        setIsVisitorsDrawerOpen(true);
        resetSelection();
        setIsVisitorOptionsModalOpen(false);
    };

    return (
        <>
            <Modal
                title="Scan ID to Sign In"
                open={open}
                onOk={onClose}
                onCancel={() => { setScanning(false); onClose(); }}
                okText="Confirm"
                cancelText="Cancel"
                centered
                style={{ textAlign: 'center' }}
                footer={null}
            >
                <p>
                    <img
                        src={idBarcode}
                        alt="Barcode ID"
                        style={{ width: '300px' }}
                    />
                </p>
                <Button
                    type="primary"
                    onClick={() => setScanning(true)}
                    disabled={scanning}
                >
                    {scanning ? 'Scanning...' : 'Start Scanning'}
                </Button>
            </Modal>
            <Modal
                title="Visitor to Sign In"
                open={isVisitorOptionsModalOpen}
                onCancel={() => {
                    setIsVisitorOptionsModalOpen(false);
                    resetSelection();
                }}
                footer={null}
                centered
            >
                <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '10px' }}>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select visitor to sign in"
                        onChange={handleVisitorChange}
                        value={selectedVisitorName || selectedVisitId}
                        filterOption={(input, option) =>
                            option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={options}
                    />
                </OpSpace>
            </Modal>
            <VisitorsDrawer open={isVisitorsDrawerOpen} onClose={() => setIsVisitorsDrawerOpen(false)} />
        </>
    );
};

export default ScanIdComponent;
