import React from 'react';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { DRAWER_WIDTH } from 'constants/ui';
import CreateInvitationForm from './CreateInvitationForm';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';

interface InvitationDrawerProps {
    open: boolean;
    onClose: () => void;
    loading: boolean;
}

const InvitationDrawer: React.FC<InvitationDrawerProps> = ({ open, onClose, loading }) => {
    const [form] = OpForm.useForm();

    return (
        <OpFormDrawer
            title={'New Invitation'}
            width={DRAWER_WIDTH}
            open={open}
            formIsLoading={loading}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            form={form}
            formComponent={
                <CreateInvitationForm
                    form={form}
                    onClose={onClose}
                />
            }
        />
    );
};

export default InvitationDrawer;
