import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, notification } from 'antd';
import { DRAWER_WIDTH, TABLE_HEIGHT } from 'constants/ui';

// Custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import UserForm from './UserForm';

// Store and actions
import { RootState, AppDispatch } from 'store/store';
import { clearUsers, clearSelectedUser, fetchUsers, describeUser, deleteUser } from 'store/slices/usersSlice';

// Types
import { clearRoles, fetchRoles, fetchUsersInRole } from 'store/slices/rolesSlice';
import { User } from 'types/userTypes';
import { OpDrawer } from 'components/customAntd/DLS/OpDrawer/OpDrawer';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { MultiFormButtons } from 'components/customAntd/MultiFormButtons';

import { LoadingOutlined } from '@ant-design/icons';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { formatFullName, hasPermission } from 'utils/utils';
import { USERS_TOOLTIP } from 'constants/tooltip';

const Users: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [activeUsers, setActiveUsers] = useState<User[]>([]);

    const [activeKey, setActiveKey] = useState<string>('user');

    const [userForm] = OpForm.useForm();

    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { users, selectedUser, fetchUsersLoading, describeUserLoading } = useSelector((state: RootState) => state.users);
    const { roles } = useSelector((state: RootState) => state.roles);

    const hasUserWrite = hasPermission(tokenScopeList, orgId, 'o', 'user:w');

    useEffect(() => {
        if (drawerVisible) {
            setActiveKey('user');
            // Reset forms whenever the drawer opens to ensure they start fresh
            userForm.resetFields();
        }
    }, [userForm, drawerVisible]);

    const tabItems = [
        {
            key: 'user',
            label: 'User',
            children: <UserForm form={userForm} />
        }
    ];

    // Fetch users on mount and when organizationId changes, clear users on unmount
    useEffect(() => {
        if (orgId) {
            dispatch(fetchUsers({ orgId }));
            dispatch(fetchRoles({ orgId }));
        }
        return () => {
            dispatch(clearUsers());
            dispatch(clearRoles());
        };
    }, [dispatch, orgId]);

    // Filter active users from the fetched users
    useEffect(() => {
        if (users && users.data) {
            const filteredUsers = users.data.filter(user => user.status === 1);
            setActiveUsers(filteredUsers);
        }
    }, [users]);

    // Called when the save button is clicked
    const handleSubmitAllForms = async () => {
        try {
            const formValues = await userForm.validateFields();

            if (formValues) {
                if (userForm.isFieldsTouched()) {
                    await userForm.submit();
                }
                setDrawerVisible(false); // Close the drawer after successful submit
            }
        } catch (error) {
            notification.error({
                message: 'Save Error',
                description: 'Failed to save user.',
                placement: 'bottomRight',
            });
        }
    };

    const confirmModal = useConfirmModal();
    // Called when we close the drawer by clicking cancel button or touching the main page
    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (userForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    userForm.resetFields();
                    setDrawerVisible(false);
                    dispatch(clearSelectedUser());
                },
            });
        } else {
            setDrawerVisible(false);
            dispatch(clearSelectedUser());
        }
    };

    // Handle deleting a user
    const handleDeleteUser = async (record: User) => {
        // Find "Super Admin" role ID
        const superAdminRole = roles.data.find(role => role.name === 'Super Admin');
        if (!superAdminRole) {
            notification.error({
                message: 'Deletion Error',
                description: 'Super Admin role not found.',
                placement: 'bottomRight',
            });
            return;
        }

        // Fetch users in "Super Admin" role
        const superAdminUsersResponse = await dispatch(fetchUsersInRole({ orgId, roleId: superAdminRole.id }));
        console.log(superAdminUsersResponse);
        if (fetchUsersInRole.fulfilled.match(superAdminUsersResponse)) {
            const superAdminUsers = superAdminUsersResponse.payload.data.filter(user => user.status === 1);

            // Check if the only remaining "Super Admin" user is being deleted
            if (superAdminUsers.length === 1 && superAdminUsers[0].id === record.id) {
                notification.error({
                    message: 'Deletion Error',
                    description: 'There must be at least one user with the "Super Admin" role in your organization.',
                    placement: 'bottomRight',
                });
                return;
            }
        }
        try {
            await dispatch(deleteUser({ orgId, userId: record.id }));
            notification.success({
                message: 'Success',
                description: 'User deleted successfully',
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: 'Deletion Error',
                description: 'Failed to delete user',
                placement: 'bottomRight',
            });
        }
    };

    // Column configuration for the table
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: ['identity', 'firstName'],
            label: 'FIRST NAME',
            filter: { type: 'input' },
            sorter: (a, b) => (a.identity.firstName || '').localeCompare(b.identity.firstName || ''),
        },
        {
            dataIndex: ['identity', 'lastName'],
            label: 'LAST NAME',
            filter: { type: 'input' },
            sorter: (a, b) => (a.identity.lastName || '').localeCompare(b.identity.lastName || ''),
        },
        {
            dataIndex: ['identity', 'email'],
            label: 'EMAIL',
            filter: { type: 'input' },
            sorter: (a, b) => (a.identity.email || '').localeCompare(b.identity.email || ''),
        }
    ];

    // Batch action menu items
    // const batchActionMenuItems = [
    //     { key: 'active', label: 'Activate users', onClick: () => console.log('active') },
    //     { key: 'suspend', label: 'Suspend users', onClick: () => console.log('suspend') },
    //     { key: 'delete', label: 'Delete users', onClick: () => console.log('delete') },
    // ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: activeUsers,
        columns: columns,
        rowActions: {
            onEditClick: (user: User) => {
                dispatch(describeUser({ orgId, userId: user.id }));
                setDrawerVisible(true);
            },
            onDeleteClick: hasUserWrite ? handleDeleteUser : undefined,
            deleteModalTitle: () => 'Delete User',
            deleteModalContent: ({ record }) => {
                const fullName = `${record.identity.firstName}${record.identity.lastName ? ' ' + record.identity.lastName : ''}`;
                return `Are you sure you want to delete this user (${fullName})?`;
            },
        },
        // batchActionMenuItems,
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasUserWrite,
        allowAddition: hasUserWrite ? {
            itemName: 'User',
            onClick: () => {
                userForm.resetFields();
                setDrawerVisible(true);
            },
        } : false,
        loading: fetchUsersLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
    };

    return (
        <OpPage title="Users" tooltip={USERS_TOOLTIP} subtitle="">
            <div>
                <OpTable {...opTableProps} />
                <OpDrawer
                    title={describeUserLoading ? undefined : (selectedUser ? `Edit User: ${formatFullName(selectedUser.identity.firstName, selectedUser.identity.middleName, selectedUser.identity.lastName)
                        }` : 'Add New User')}
                    width={DRAWER_WIDTH}
                    open={drawerVisible}
                    onClose={finalOnClose}
                    footer={
                        !describeUserLoading && hasUserWrite && (
                            <MultiFormButtons
                                forms={[userForm]}
                                submitButtonLabel="Save"
                                onSubmitClick={handleSubmitAllForms}
                                isSubmitButtonLoading={describeUserLoading}
                            />
                        )
                    }
                >
                    {describeUserLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        </div>
                    ) : (
                        <OpTabs
                            activeKey={activeKey}
                            onChange={(newActiveKey: string) => {
                                setActiveKey(newActiveKey);
                            }}
                            items={tabItems}
                        />
                    )}
                </OpDrawer>
            </div>
        </OpPage>
    );
};

export default Users;
