import { RangePickerProps } from 'antd/es/date-picker';
import { t } from 'i18next';
import { opTime } from 'utils/dates';

export const generateRangePickerPresets = () =>
  [
    {
      label: t('Today'),
      value: [opTime().startOf('day'), opTime()],
    },
    {
      label: t('Last hour'),
      value: [opTime().subtract(1, 'hour'), opTime()],
    },
    {
      label: t('Last {{count}} hours', { count: 4 }),
      value: [opTime().subtract(4, 'hours'), opTime()],
    },
    {
      label: t('Last {{count}} hours', { count: 12 }),
      value: [opTime().subtract(12, 'hours'), opTime()],
    },
    {
      label: t('Last {{count}} hours', { count: 24 }),
      value: [opTime().subtract(24, 'hours'), opTime()],
    },
    {
      label: t('Last {{count}} days', { count: 2 }),
      value: [opTime().subtract(2, 'days'), opTime()],
    },
    {
      label: t('Last week'),
      value: [opTime().subtract(1, 'week'), opTime()],
    },
  ] satisfies RangePickerProps['presets'];
