import { ComponentProps } from 'react';
import Input from 'antd/es/input';
import clsx from 'clsx';

import './OpInput.scss';

interface IOpSearchInputProps extends ComponentProps<typeof Input.Search> {
  testId?: string;
}

const OpSearchInput = ({
  className = '',
  testId = 'op-search-input',
  ...inputProps
}: IOpSearchInputProps) => {
  return (
    <Input.Search
      className={clsx('op-search-input', className)}
      data-testid={testId}
      data-lpignore // Prevents LastPass autofill
      {...inputProps}
    />
  );
};

interface IOpPasswordInputProps extends ComponentProps<typeof Input.Password> {
  testId?: string;
}

const OpPasswordInput = ({
  className = '',
  testId = 'op-password-input',
  ...inputProps
}: IOpPasswordInputProps) => {
  return (
    <Input.Password
      className={clsx('op-password-input', className)}
      data-testid={testId}
      {...inputProps}
    />
  );
};

interface IOpTextAreaInputProps extends ComponentProps<typeof Input.TextArea> {
  testId?: string;
}

const OpTextAreaInput = ({
  className,
  testId = 'op-text-area-input',
  ...inputProps
}: IOpTextAreaInputProps) => {
  return (
    <Input.TextArea
      className={clsx('op-text-area-input', className)}
      data-testid={testId}
      data-lpignore // Prevents LastPass autofill
      {...inputProps}
    />
  );
};

interface IOpInputProps extends ComponentProps<typeof Input> {
  gtm?: string;
  testId?: string;
  allowLastPass?: boolean;
}

export const OpInput = ({
  gtm,
  testId = 'op-input',
  className,
  allowLastPass = false,
  ...inputProps
}: IOpInputProps) => {
  return (
    <Input
      className={clsx('op-input', className)}
      data-gtm={gtm}
      data-testid={testId}
      data-lpignore={allowLastPass} // Prevents LastPass autofill
      {...inputProps}
    />
  );
};

// Get all Components defined within Input
OpInput.Password = OpPasswordInput;
OpInput.Search = OpSearchInput;
OpInput.TextArea = OpTextAreaInput;
