import React from "react";
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateAppearanceConfig } from "store/slices/kiosksSlice";
import { KioskAppearance } from "types/kioskTypes";
import { hasPermission } from "utils/utils";

interface AppearanceContentProps {
    appearanceForm: any;
}

const AppearanceContent: React.FC<AppearanceContentProps> = ({ appearanceForm }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedKiosk = useSelector((state: RootState) => state.kiosks.selectedKiosk);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasKioskRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasKioskWrite = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:w');

    const handleSubmit = async (args: IOnSubmitArgs<KioskAppearance>) => {
        if (!orgId) {
            return;
        }
        const { values } = args;

        const payload: Partial<KioskAppearance> = {
            welcomeText: values.welcomeText,
            completedText: values.completedText
        };

        if (selectedKiosk) {
            dispatch(updateAppearanceConfig({ orgId, kioskId: selectedKiosk.id, appearanceConfig: payload }));
        }
    };

    return (
        <OpForm
            form={appearanceForm}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            initialValues={{
                welcomeText: selectedKiosk?.appearance[0]?.welcomeText,
                completedText: selectedKiosk?.appearance[0]?.completedText,
            }}
            isReadOnly={!hasKioskWrite && hasKioskRead}
        >
            <OpForm.TextAreaInput rows={4} label="Welcome Message" name="welcomeText" />
            <OpForm.TextAreaInput rows={4} label="Completed Message" name="completedText" />
        </OpForm>
    );
};

export default AppearanceContent;
