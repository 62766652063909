import { ChangeEvent } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { OpInput } from 'components/customAntd/DLS/OpInput/OpInput';

import './GlobalSearch.scss';

interface IGlobalSearch {
  onGlobalSearchChange(event: ChangeEvent<HTMLInputElement>): void;
}

export const GlobalSearch = ({ onGlobalSearchChange }: IGlobalSearch) => {
  const { t } = useTranslation();

  return (
    <div className="global-search">
      <OpInput
        className="global-search__input"
        allowClear
        size="large"
        placeholder={t('Search')}
        suffix={<SearchOutlined />}
        onChange={onGlobalSearchChange}
      />
    </div>
  );
};
