import React, { useState, useEffect } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { REDFLAGREPORTS_TOOLTIP } from 'constants/tooltip';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import RedFlagReportSummary from './RedFlagReportSummary';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';
import { fetchRedFlags } from 'store/slices/redFlagSlice';
import { RedFlag } from 'types/redFlagTypes';
import { fetchVisits } from 'store/slices/visitsSlice';
import { Visitor } from 'types/visitTypes';
import { getStatusNameById, getVisitorDateTime } from 'utils/visitorsHelper';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { hasPermission } from 'utils/utils';
import STATUS from 'constants/status';
import DeniedVistiorsTable from './DeniedVistiorsTable';
import VisitorsDrawer from 'components/pages/visitors/VisitorsDrawer';
import RedFlagsDrawer from 'components/pages/redFlag/RedFlagsDrawer';
import RedFlagsTable from './RedFlagsTable';
import { notification } from 'antd';

interface ExtendedVisitor extends Visitor {
    visitId: number;
};

const RedFlagReport: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const { visits, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);
    const { redFlags, fetchRedFlagsLoading } = useSelector((state: RootState) => state.redFlags);

    const [deniedVisitors, setDeniedVisitors] = useState<ExtendedVisitor[]>([]);
    const [filteredBlocklist, setFilteredBlocklist] = useState<RedFlag[]>([]);
    const [filteredWatchlist, setFilteredWatchlist] = useState<RedFlag[]>([]);

    const [selectedLocationId, setSelectedLocationId] = useState<number>(globalLocationId!);
    const [startDate, setStartDate] = useState(dayjs().subtract(29, 'day').startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().endOf('day'));

    const [visitorsDrawerOpen, setVisitorsDrawerOpen] = useState(false);
    const [redFlagsDrawerOpen, setRedFlagsDrawerOpen] = useState(false);

    const [activeTable, setActiveTable] = useState<'denied' | 'blockList' | 'watchList'>('denied');
    const [isTableLoading, setIsTableLoading] = useState(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    useEffect(() => {
        if (orgId) {
            dispatch(fetchVisits({ orgId }));
            dispatch(fetchRedFlags({ orgId }));
        }
    }, [dispatch, orgId]);

    useEffect(() => {
        if (!fetchVisitsLoading && !fetchRedFlagsLoading) {
            if (visits && visits.data) {
                const filteredVisits = visits.data.filter(visit => visit?.site?.id === selectedLocationId && (hasAllvisitorsRead || globalUserId === visit.host.userId) && visit.visitStatus.status === 1);
                const filteredVisitors = filteredVisits.reduce<ExtendedVisitor[]>((acc, visit) => {
                    const extendedVisitor = visit.visitors
                        .filter(visitor => visitor.status === STATUS.DENIED_ENTRY.id && visitor.signIn)
                        .map(visitor => {
                            const statusName = getStatusNameById(visitor.status!, visit.scheduleStart);
                            const dateKey = getVisitorDateTime(visitor, statusName, visit?.scheduleStart, DATE_TIME_FORMAT);
                            return {
                                ...visitor,
                                visitId: visit.id,
                                dateKey
                            };
                        }).filter(visitor => visitor && dayjs(visitor.dateKey).isBetween(startDate.startOf('day'), endDate.endOf('day'), null, '[]'));
                    return [...acc, ...extendedVisitor];
                }, []);
                setDeniedVisitors(filteredVisitors);
            }

            if (redFlags && redFlags.data) {
                const filteredRedFlags = redFlags.data.filter(flag =>
                    dayjs(flag.effectiveDate).utc(true).local().isBetween(startDate, endDate, null, '[]')
                );

                const blockList = filteredRedFlags.filter(flag => flag.level === 1);
                const watchList = filteredRedFlags.filter(flag => flag.level === 2);

                setFilteredBlocklist(blockList);
                setFilteredWatchlist(watchList);
            }

            setIsTableLoading(false);
        }
    }, [visits, redFlags, selectedLocationId, startDate, endDate, fetchVisitsLoading, fetchRedFlagsLoading, globalUserId, hasAllvisitorsRead]);

    const handleDateRangeLocationFilter = (locationId: number, start: dayjs.Dayjs, end: dayjs.Dayjs) => {
        setSelectedLocationId(locationId);
        setStartDate(start);
        setEndDate(end);
        setIsTableLoading(true);
    };

    const showNotification = (message: string) => {
        notification.info({
            message,
            placement: 'bottomRight',
        });
    };

    const handleDeniedClick = () => {
        showNotification('Displaying Denied Visitors');
        setActiveTable('denied');
    };
    const handleBlockListClick = () => {
        showNotification('Displaying Block List');
        setActiveTable('blockList');
    };
    const handleWatchListClick = () => {
        showNotification('Displaying Watch List');
        setActiveTable('watchList');
    };

    return (
        <OpPage title="Red Flag Report" tooltip={REDFLAGREPORTS_TOOLTIP} subtitle="">
            <OpSpace
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <DateRangeLocationFilter2
                    onDateRangeLocationFilter={handleDateRangeLocationFilter}
                    initialStartDate={startDate}
                    initialEndDate={endDate}
                />
                <RedFlagReportSummary
                    deniedVisitors={deniedVisitors}
                    blockList={filteredBlocklist}
                    watchList={filteredWatchlist}
                    onDeniedClick={handleDeniedClick}
                    onBlockListClick={handleBlockListClick}
                    onWatchListClick={handleWatchListClick}
                />
                {activeTable === 'denied' && !isTableLoading && <DeniedVistiorsTable deniedVisitors={deniedVisitors} setDrawerVisible={setVisitorsDrawerOpen} />}
                {activeTable === 'blockList' && !isTableLoading && <RedFlagsTable redFlags={filteredBlocklist} setDrawerVisible={setRedFlagsDrawerOpen} isBlockList={true} />}
                {activeTable === 'watchList' && !isTableLoading && <RedFlagsTable redFlags={filteredWatchlist} setDrawerVisible={setRedFlagsDrawerOpen} isBlockList={false} />}
            </OpSpace>

            <VisitorsDrawer
                open={visitorsDrawerOpen}
                onClose={() => {
                    setVisitorsDrawerOpen(false)
                }}
            />
            <RedFlagsDrawer
                open={redFlagsDrawerOpen}
                onClose={() => {
                    setRedFlagsDrawerOpen(false)
                }}
            />
        </OpPage>
    );
}

export default RedFlagReport;
