import { QuestionCircleOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { OpTooltip } from '../OpTooltip/OpTooltip';

import './OpInfoTooltip.scss';

interface IOpInfoTooltipProps {
  title: ReactNode;
  className?: string;
}

export const OpInfoTooltip = ({ title, className }: IOpInfoTooltipProps) => {
  return (
    <OpTooltip className={clsx('op-info-tooltip', className)} title={title}>
      <QuestionCircleOutlined className="op-info-tooltip__icon" />
    </OpTooltip>
  );
};
