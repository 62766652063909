import { MouseEvent } from 'react';

export const createPolygonPointCoordinates = (
  e: MouseEvent<HTMLDivElement>,
  containerWidth: number,
  containerHeight: number,
) => {
  const { left, top } = (e.target as HTMLDivElement).getBoundingClientRect();

  const x = Math.floor(
    Math.min(9999, Math.max(0, ((e.clientX - left) / containerWidth) * 10000)),
  ); // x position within the element (valid values 0 - 9999)
  const y = Math.floor(
    Math.min(9999, Math.max(0, ((e.clientY - top) / containerHeight) * 10000)),
  ); // y position within the element (valid values 0 - 9999)

  return [x, y];
};
