import { ComponentProps } from 'react';
import FloatButton from 'antd/es/float-button';
import clsx from 'clsx';

interface OpFloatButtonProps extends ComponentProps<typeof FloatButton> {
  testId?: string;
}

export const OpFloatButton = ({
  className,
  testId = 'op-float-button',
  ...floatButtonProps
}: OpFloatButtonProps) => {
  return (
    <FloatButton
      data-testid={testId}
      className={clsx('op-float-button', className)}
      {...floatButtonProps}
    />
  );
};
