import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { HOSTREPORTS_TOOLTIP } from 'constants/tooltip';
import HostReportTable from './HostReportTable';

const HostReport: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    console.log("drawerVisible", drawerVisible);
    return (
        <OpPage title="Host Report" tooltip={HOSTREPORTS_TOOLTIP} subtitle="">
            <HostReportTable setDrawerVisible={setDrawerVisible} />
            {/* <VisitorsDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false)
                }}
            /> */}
        </OpPage>
    );
}

export default HostReport;
