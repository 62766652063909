import React from 'react';
import { Space, Statistic } from "antd";
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';

interface RedFlagReportCardProps {
    title: string;
    value: number;
    icon: React.ReactNode;
    color: string;
    onClick?: () => void;
}

const RedFlagReportCard: React.FC<RedFlagReportCardProps> = ({ title, value, icon, color, onClick }) => {
    return (
        <OpCard
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: color,
                cursor: value > 0 ? 'pointer' : 'default', // Change cursor based on value
                transition: 'transform 0.2s', // Add transition for hover effect
            }}
            onClick={value > 0 ? onClick : undefined} // Only allow click if value > 0
            onMouseEnter={e => {
                if (value > 0) {
                    e.currentTarget.style.transform = 'scale(1.05)'; // Scale up on hover
                }
            }}
            onMouseLeave={e => {
                if (value > 0) {
                    e.currentTarget.style.transform = 'scale(1)'; // Scale back to normal
                }
            }}
        >
            <Space align="center" style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    {icon}
                    <Statistic title={title} value={value} valueStyle={{ fontSize: '40px' }} />
                </div>
            </Space>
        </OpCard>
    );
}

export default RedFlagReportCard;
