import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Location, LocationWorkflow, CreateLocationPayload, UpdateLocationPayload, UpdateLocationWorkflowPayload } from 'types/locationTypes';

interface LocationsState {
    locations: ApiResponse<Location>;
    globalLocation: Location | null;
    selectedLocation: Location | null;
    globalLocationWorkflow: LocationWorkflow | null;
    selectedLocationWorkflow: LocationWorkflow | null;
    fetchLocationsLoading: boolean;
    describeLocationLoading: boolean;
    describeLocationWorkflowLoading: boolean;
    createLocationLoading: boolean;
    updateLocationLoading: boolean;
    deleteLocationLoading: boolean;
    updateLocationWorkflowLoading: boolean;
    error: string | null;
}

const initialState: LocationsState = {
    locations: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    globalLocation: null,
    selectedLocation: null,
    globalLocationWorkflow: null,
    selectedLocationWorkflow: null,
    fetchLocationsLoading: false,
    describeLocationLoading: false,
    describeLocationWorkflowLoading: false,
    createLocationLoading: false,
    updateLocationLoading: false,
    deleteLocationLoading: false,
    updateLocationWorkflowLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchLocations = createAsyncThunk<ApiResponse<Location>, { orgId: number; status?: number; }, { rejectValue: string }>(
    'locations/fetchLocations',
    async ({ orgId, status }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Location>>(`${apiUrl}/orgs/${orgId}/sites`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    status
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch locations');
        }
    }
);

export const describeLocation = createAsyncThunk<ApiResponse<Location>, { orgId: number; locationId: number; global?: boolean }, { rejectValue: string }>(
    'locations/describeLocation',
    async ({ orgId, locationId, global }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Location>>(`${apiUrl}/orgs/${orgId}/sites/${locationId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return { ...response.data, global };
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch location details');
        }
    }
);

export const createLocation = createAsyncThunk<void, { orgId: number; location: Partial<CreateLocationPayload> }, { rejectValue: string; dispatch: any }>(
    'locations/createLocation',
    async ({ orgId, location }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/sites`, location, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchLocations({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create location');
        }
    }
);

export const updateLocation = createAsyncThunk<void, { orgId: number; locationId: number; location: Partial<UpdateLocationPayload> }, { rejectValue: string; dispatch: any }>(
    'locations/updateLocation',
    async ({ orgId, locationId, location }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/sites/${locationId}`, location, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchLocations({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update location');
        }
    }
);

export const deleteLocation = createAsyncThunk<void, { orgId: number; locationId: number }, { rejectValue: string; dispatch: any }>(
    'locations/deleteLocation',
    async ({ orgId, locationId }, { dispatch, rejectWithValue }) => {
        try {
            await axios.delete(`${apiUrl}/orgs/${orgId}/sites/${locationId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchLocations({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to delete location');
        }
    }
);

export const describeLocationWorkflow = createAsyncThunk<ApiResponse<LocationWorkflow>, { orgId: number; locationId: number; global?: boolean }, { rejectValue: string }>(
    'locations/describeLocationWorkflow',
    async ({ orgId, locationId, global }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<LocationWorkflow>>(`${apiUrl}/orgs/${orgId}/sites/${locationId}/workflows`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return { ...response.data, global };
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch location workflow');
        }
    }
);

export const updateLocationWorkflow = createAsyncThunk<void, { orgId: number; locationId: number; workflow: Partial<UpdateLocationWorkflowPayload> }, { rejectValue: string; dispatch: any }>(
    'locations/updateLocationWorkflow',
    async ({ orgId, locationId, workflow }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/sites/${locationId}/workflows`, workflow, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeLocation({ orgId, locationId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update location workflow');
        }
    }
);

const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        clearLocations(state) {
            state.locations = initialState.locations;
        },
        clearSelectedLocation(state) {
            state.selectedLocation = initialState.selectedLocation;
            state.selectedLocationWorkflow = initialState.selectedLocationWorkflow;
        },
        clearLocationsState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocations.pending, (state) => {
                state.fetchLocationsLoading = true;
                state.error = null;
            })
            .addCase(fetchLocations.fulfilled, (state, action) => {
                state.fetchLocationsLoading = false;
                state.locations = action.payload;
            })
            .addCase(fetchLocations.rejected, (state, action) => {
                state.fetchLocationsLoading = false;
                state.error = action.payload || 'Failed to fetch locations';
            })
            .addCase(describeLocation.pending, (state) => {
                state.describeLocationLoading = true;
                state.error = null;
            })
            .addCase(describeLocation.fulfilled, (state, action) => {
                if (action.payload.global) {
                    state.globalLocation = action.payload.data[0];
                } else {
                    state.selectedLocation = action.payload.data[0];
                }
                state.describeLocationLoading = false;
            })
            .addCase(describeLocation.rejected, (state, action) => {
                state.describeLocationLoading = false;
                state.error = action.payload || 'Failed to fetch location details';
            })
            .addCase(createLocation.pending, (state) => {
                state.createLocationLoading = true;
                state.error = null;
            })
            .addCase(createLocation.fulfilled, (state) => {
                state.createLocationLoading = false;
            })
            .addCase(createLocation.rejected, (state, action) => {
                state.createLocationLoading = false;
                state.error = action.payload || 'Failed to create location';
            })
            .addCase(updateLocation.pending, (state) => {
                state.updateLocationLoading = true;
                state.error = null;
            })
            .addCase(updateLocation.fulfilled, (state) => {
                state.updateLocationLoading = false;
            })
            .addCase(updateLocation.rejected, (state, action) => {
                state.updateLocationLoading = false;
                state.error = action.payload || 'Failed to update location';
            })
            .addCase(deleteLocation.pending, (state) => {
                state.deleteLocationLoading = true;
                state.error = null;
            })
            .addCase(deleteLocation.fulfilled, (state) => {
                state.deleteLocationLoading = false;
            })
            .addCase(deleteLocation.rejected, (state, action) => {
                state.deleteLocationLoading = false;
                state.error = action.payload || 'Failed to delete location';
            })
            .addCase(describeLocationWorkflow.pending, (state) => {
                state.describeLocationWorkflowLoading = true;
                state.error = null;
            })
            .addCase(describeLocationWorkflow.fulfilled, (state, action) => {
                if (action.payload.global) {
                    state.globalLocationWorkflow = action.payload.data[0];
                } else {
                    state.selectedLocationWorkflow = action.payload.data[0];
                }
                state.describeLocationWorkflowLoading = false;
            })
            .addCase(describeLocationWorkflow.rejected, (state, action) => {
                state.describeLocationWorkflowLoading = false;
                state.error = action.payload || 'Failed to fetch location workflow';
            })
            .addCase(updateLocationWorkflow.pending, (state) => {
                state.updateLocationWorkflowLoading = true;
                state.error = null;
            })
            .addCase(updateLocationWorkflow.fulfilled, (state) => {
                state.updateLocationWorkflowLoading = false;
            })
            .addCase(updateLocationWorkflow.rejected, (state, action) => {
                state.updateLocationWorkflowLoading = false;
                state.error = action.payload || 'Failed to update location workflow';
            });
    },
});

export const { clearLocations, clearSelectedLocation, clearLocationsState } = locationsSlice.actions;
export default locationsSlice.reducer;
