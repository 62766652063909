import React, { useCallback } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { notification } from 'antd';
import { changePassword } from 'store/slices/authSlice';

interface InfoContentProps {
    form: any;
}

const CombinedFormContent: React.FC<InfoContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { globalUser } = useSelector((state: RootState) => state.users);

    const handleSubmit = useCallback(
        async ({ values }: IOnSubmitArgs<any>) => {
            if (globalUser?.id) {
                const { currentPassword, newPassword } = values;
                try {
                    await dispatch(changePassword({
                        orgId: orgId,
                        userId: globalUser.id,
                        payload: { currentPassword, newPassword }
                    })).unwrap();
                    notification.success({
                        message: 'Password Changed',
                        description: 'Your password has been successfully changed.',
                        placement: 'bottomRight',
                    });
                } catch (error: any) {
                    notification.error({
                        message: 'Change Password Failed',
                        description: error.message || 'Failed to change password.',
                        placement: 'bottomRight',
                    });
                }
            }
        },
        [dispatch, orgId, globalUser]
    );

    return (
        <OpForm
            form={form}
            onSubmit={handleSubmit}
            hasError={false}
        >
            <OpForm.Input label="Current Password" name='currentPassword' rules={[{ required: true, message: 'Current password is required' }]} />
            <OpForm.Input label="New Password" name='newPassword' rules={[{ required: true, message: 'New password is required' }]} />
        </OpForm >
    );
};

export default CombinedFormContent;
