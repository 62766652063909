import { configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import { parseFetchKioskMiddleware, parseDescribeKioskMiddleware } from './middleware/parseKioskMiddleware';
import { parseFetchLocationsMiddleware, parseDescribeLocationMiddleware, parseDescribeLocationWorkflowMiddleware } from './middleware/parseLocationMiddleware';
import { parseFetchDenyReasonsMiddleware, parseDescribeDenyReasonMiddleware, parseDescribeDeniedEntryMiddleware } from './middleware/parseDenyMiddleware';
import { parseFetchRedFlagsMiddleware, parseDescribeRedFlagMiddleware, parseFetchRedFlagDataMiddleware, parseFetchRedFlagLogsMiddleware } from './middleware/parseRedFlagMiddleware';
import { parseFetchRolesMiddleware, parseDescribeRoleMiddleware, parseFetchUsersInRoleMiddleware } from './middleware/parseRoleMiddleware';
import { parseFetchScopeResourcesMiddleware } from './middleware/parseScopeResourcesMiddleware';
import { parseDescribeAgreementMiddleware, parseFetchAgreementsMiddleware, parseDescribeAgreementContentMiddleware } from './middleware/parseAgreementMiddleware';
import { parseLoginMiddleware } from './middleware/parseAuthMiddleware';
import { parseFetchUserActivitiesMiddleware, parseFetchUserActivityTypesMiddleware } from './middleware/parseUserActivityMiddleware';
import { parseDescribeUserMiddleware, parseFetchSelectedUserRolesMiddleware, parseFetchUsersMiddleware } from './middleware/parseUserMiddleware';
import { parseDescribeVisitMiddleware, parseFetchVisitNotesMiddleware, parseFetchVisitorAgreementsMiddleware, parseFetchVisitsMiddleware } from './middleware/parseVisitMiddleware';
import { parseCreateVisitorInvitationMiddleware, parseFetchInvitationConfigMiddleware, parseFetchInvitationDocumentsMiddleware } from './middleware/parseVIsitorInvitationMiddleware';
import { parseFetchVisitorTypesMiddleware } from './middleware/parseVisitorTypeMiddleware';
import { parseDescribeVisitorWorkflowConfigMiddleware, parseDescribeVisitorWorkflowFieldsMiddleware, parseDescribeVisitorWorkflowLocationsMiddleware, parseDescribeVisitorWorkflowMiddleware, parseFetchVisitorWorkflowAgreementsMiddleware, parseFetchVisitorWorkflowTypesMiddleware, parseFetchVisitorWorkflowsMiddleware } from './middleware/parseVisitorWorkflowsMiddleware';
import { parseSearchVisitorsMiddleware } from './middleware/parseVisitorSearchMiddleware';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'theme', 'globalOrg'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(
            parseFetchAgreementsMiddleware,
            parseDescribeAgreementMiddleware,
            parseDescribeAgreementContentMiddleware,

            parseLoginMiddleware,

            parseFetchDenyReasonsMiddleware,
            parseDescribeDenyReasonMiddleware,
            parseDescribeDeniedEntryMiddleware,

            parseFetchKioskMiddleware,
            parseDescribeKioskMiddleware,

            parseFetchLocationsMiddleware,
            parseDescribeLocationMiddleware,
            parseDescribeLocationWorkflowMiddleware,

            parseFetchRedFlagsMiddleware,
            parseDescribeRedFlagMiddleware,
            parseFetchRedFlagDataMiddleware,
            parseFetchRedFlagLogsMiddleware,

            parseFetchRolesMiddleware,
            parseDescribeRoleMiddleware,
            parseFetchUsersInRoleMiddleware,

            parseFetchScopeResourcesMiddleware,

            parseFetchUserActivitiesMiddleware,
            parseFetchUserActivityTypesMiddleware,

            parseFetchUsersMiddleware,
            parseDescribeUserMiddleware,
            parseFetchSelectedUserRolesMiddleware,

            parseFetchVisitsMiddleware,
            parseDescribeVisitMiddleware,
            parseFetchVisitNotesMiddleware,
            parseFetchVisitorAgreementsMiddleware,

            parseCreateVisitorInvitationMiddleware,
            parseFetchInvitationConfigMiddleware,
            parseFetchInvitationDocumentsMiddleware,

            parseFetchVisitorTypesMiddleware,

            parseFetchVisitorWorkflowTypesMiddleware,
            parseFetchVisitorWorkflowsMiddleware,
            parseDescribeVisitorWorkflowMiddleware,
            parseDescribeVisitorWorkflowLocationsMiddleware,
            parseDescribeVisitorWorkflowConfigMiddleware,
            parseDescribeVisitorWorkflowFieldsMiddleware,
            parseFetchVisitorWorkflowAgreementsMiddleware,

            parseSearchVisitorsMiddleware
        ),
    devTools: process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
