import { ComponentProps } from 'react';
import TimePicker from 'antd/es/time-picker';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { TIME_FORMAT } from 'constants/dates';

import './OpTimePicker.scss';

interface IOpTimePickerProps
  extends Omit<
    ComponentProps<typeof TimePicker>,
    'value' | 'onChange' | 'picker'
  > {
  testId?: string;
  gtm?: string;
  value?: Dayjs | string;
  onChange?: (value: string | null, timeString: string) => void;
}

export const OpTimePicker = ({
  className = '',
  testId = 'op-time-picker',
  gtm,
  value,
  format = TIME_FORMAT,
  onChange,
  ...timePickerProps
}: IOpTimePickerProps) => {
  // We want to store a string in the form values rather than a dayjs object
  const handleChange = (
    changedValue: Dayjs | null,
    timeString: string | string[],
  ): void => {
    // If timeString is an array, you might want to take the first element or handle it differently
    const timeOutput = Array.isArray(timeString) ? timeString[0] : timeString;
    onChange?.(changedValue ? changedValue.toISOString() : null, timeOutput);
  };
  

  // We need to convert the now stored string to a dayjs object
  const pickerValue = typeof value === 'string' ? dayjs(value) : value;

  return (
    <TimePicker
      className={clsx('op-time-picker', className)}
      data-testid={testId}
      data-gtm={gtm}
      format={format}
      changeOnBlur
      value={pickerValue}
      onChange={handleChange}
      {...timePickerProps}
    />
  );
};
