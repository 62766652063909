/**
 * Ensures that the necessary query and payload are correctly configured for API requests.
 * @param apiEndpointName The name of the API endpoint.
 * @param parameters The existing parameters for the API call.
 * @returns An object containing the updated parameters array and a flag indicating the presence of query parameters.
 */
export function ensurePayloadAndQuery(
    apiEndpointName: string,
    parameters: Record<string, any>
): { parametersArray: any[], withQuery: boolean } {
    // Convert parameters object to array for easier manipulation
    let parametersArray = Object.entries(parameters).map(([key, value]) => ({ [key]: value }));

    // Check if there's a query object already
    const queriesIndex = parametersArray.findIndex(param => typeof param === 'object');
    let withQuery = queriesIndex !== -1;

    // If no query object found, add an empty one
    if (!withQuery) {
        parametersArray.push({});
        withQuery = true;
    }

    // Additional logic could be added here to modify the query based on the component's state or props
    return { parametersArray, withQuery };
}
