import React, { useState } from 'react';
import KioskSignInTable from './KioskSignInTable';
import KioskSignInDrawer from './KioskSignInDrawer';

const KioskSignIn: React.FC = () => {

    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <>
            <KioskSignInTable setDrawerVisible={setDrawerVisible} />
            <KioskSignInDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </>

    );
}

export default KioskSignIn;
