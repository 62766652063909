import Slider, { SliderSingleProps } from 'antd/es/slider';
import clsx from 'clsx';

interface IOpSliderProps extends SliderSingleProps {
  testId?: string;
}

export const OpSlider = ({
  className,
  testId = 'op-slider',
  ...rowProps
}: IOpSliderProps) => {
  const trackStyle = {
    backgroundColor: 'var(--colorPrimary)',
  };

  return (
    <Slider
      className={clsx('op-slider', className)}
      data-testid={testId}
      trackStyle={trackStyle}
      {...rowProps}
    />
  );
};
