interface Status {
    id: number;
    name: string;
}

const STATUS: { [key: string]: Status } = {
    PENDING: {
        id: 1,
        name: "Pending"
    },
    SIGNED_IN: {
        id: 2,
        name: "Signed In"
    },
    SIGNED_OUT: {
        id: 3,
        name: "Signed Out"
    },
    DENIED_ENTRY: {
        id: 99,
        name: "Denied Entry"
    },
};

export default STATUS;