import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
// Import actions and types from Redux slices
import { RootState, AppDispatch } from 'store/store';
import { describeVisit } from 'store/slices/visitsSlice';

// Import constants
import { DATE_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';
import { formatFullName } from 'utils/utils';
import { Visitor } from 'types/visitTypes';

interface DeniedVistiorsTableData {
    visitId: number;
    visitorId: number;
    fullName: string;
    date: string;
}

interface ExtendedVisitor extends Visitor {
    visitId: number;
};

interface DeniedVistiorsTableProps {
    deniedVisitors: ExtendedVisitor[];
    setDrawerVisible: (visible: boolean) => void;
}

const DeniedVistiorsTable: React.FC<DeniedVistiorsTableProps> = ({ deniedVisitors, setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    // Format visitors for table display
    const formatVisitsForTable = (deniedVisitors: ExtendedVisitor[]): DeniedVistiorsTableData[] => {
        return deniedVisitors
            .map((deniedVisitors) => {
                const date = dayjs(deniedVisitors.signIn).format(DATE_FORMAT);
                return {
                    visitId: deniedVisitors.visitId!,
                    visitorId: deniedVisitors.id!,
                    fullName: formatFullName(deniedVisitors.firstName, deniedVisitors.middleName, deniedVisitors.lastName),
                    date: date
                };
            });
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.fullName.localeCompare(b.fullName), // Alphabetical sorting
        },
        {
            dataIndex: 'date',
            label: "Date & Time",
            sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formatVisitsForTable(deniedVisitors),
        label: "Denied",
        columns: columns,
        rowActions: {
            onEditClick: (visitor: DeniedVistiorsTableData) => {
                dispatch(describeVisit({ orgId, visitId: visitor.visitId }));
                setDrawerVisible(true);
            },
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: (record) => `${record.visitId}-${record.visitorId}`,
    };

    return <OpTable {...opTableProps} />;
}

export default DeniedVistiorsTable;
