import React from 'react';
import { Modal } from 'antd';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';

interface SignInMethodModalProps {
    open: boolean;
    onClose: () => void;
    handleSignInMethodChange: (method: string) => void;
    visitorWorkflowConfig: any;
}

const SignInMethodModal: React.FC<SignInMethodModalProps> = ({ open, onClose, handleSignInMethodChange, visitorWorkflowConfig }) => {
    return (
        <Modal
            title="Choose a Method to Sign In"
            open={open}
            onOk={onClose}
            onCancel={onClose}
            centered
            footer={null}
        >
            <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '16px' }}>
                {visitorWorkflowConfig?.scan && (
                    <OpButton
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() => handleSignInMethodChange('scanId')}
                    >
                        SCAN ID
                    </OpButton>
                )}
                <OpButton
                    type="primary"
                    style={{ width: '100%' }}
                    onClick={() => handleSignInMethodChange('selectVisitor')}
                >
                    SELECT VISITOR
                </OpButton>
            </OpSpace>
        </Modal>
    );
};

export default SignInMethodModal;
