import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
// Import actions and types from Redux slices
import { RootState, AppDispatch } from 'store/store';

// Import constants
import { DATE_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';
import { formatFullName } from 'utils/utils';
import { RedFlag } from 'types/redFlagTypes';
import { describeRedFlag } from 'store/slices/redFlagSlice';

interface RedFlagsTableData {
    redFlagId: number;
    fullName: string;
    date: string;
}

interface RedFlagsTableProps {
    redFlags: RedFlag[];
    setDrawerVisible: (visible: boolean) => void;
    isBlockList: boolean;
}

const RedFlagsTable: React.FC<RedFlagsTableProps> = ({ redFlags, setDrawerVisible, isBlockList }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    // Format red flags for table display
    const formatRedFlagsForTable = (redFlags: RedFlag[]): RedFlagsTableData[] => {
        return redFlags
            .map((redFlag) => {
                const date = dayjs(redFlag.effectiveDate).utc(true).local().format(DATE_FORMAT);
                return {
                    redFlagId: redFlag.id,
                    fullName: formatFullName(redFlag.firstName, redFlag.middleName, redFlag.lastName),
                    date: date
                };
            });
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.fullName.localeCompare(b.fullName), // Alphabetical sorting
        },
        {
            dataIndex: 'date',
            label: "Date & Time",
            sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formatRedFlagsForTable(redFlags),
        label: isBlockList ? "Added to Blocklist" : "Added to Watchlist",
        columns: columns,
        rowActions: {
            onEditClick: (redFlag: RedFlagsTableData) => {
                dispatch(describeRedFlag({ orgId, redFlagId: redFlag.redFlagId }));
                setDrawerVisible(true);
            },
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: 'visitId',
    };

    return <OpTable {...opTableProps} />;
}

export default RedFlagsTable;
