import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { REPEATVISITORS_TOOLTIP } from 'constants/tooltip';
import RepeatVisitorsTable from './RepeatVisitorsTable';

const RepeatVisitors: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    console.log("drawerVisible", drawerVisible);
    return (
        <OpPage title="Repeat Visitors" tooltip={REPEATVISITORS_TOOLTIP} subtitle="">
            <RepeatVisitorsTable setDrawerVisible={setDrawerVisible} />
            {/* <VisitorsDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false)
                }}
            /> */}
        </OpPage>
    );
}

export default RepeatVisitors;
