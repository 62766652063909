import React, { useState } from 'react';
import AttendantSignOutTable from './AttendantSignOutTable';
import AttendantSignOutDrawer from './AttendantSignOutDrawer';

const AttendantSignOut: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <>
            <AttendantSignOutTable setDrawerVisible={setDrawerVisible} />
            <AttendantSignOutDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </>

    );
}

export default AttendantSignOut;
