import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { AppDispatch, RootState } from 'store/store';
import { TABLE_HEIGHT } from 'constants/ui';
import { fetchAgreements, describeAgreement, describeAgreementContent, deleteAgreement, clearSelectedAgreement } from 'store/slices/agreementsSlice';
import { Agreement } from 'types/agreementTypes';
import { hasPermission } from 'utils/utils';

interface AgreementsTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const AgreementsTable: React.FC<AgreementsTableProps> = ({ setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { agreements, fetchAgreementsLoading } = useSelector((state: RootState) => state.agreements);

    const [activeAgreements, setActiveAgreements] = useState<Agreement[]>([]);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAgreementsWrite = hasPermission(tokenScopeList, orgId, 'o', 'agreements:w');

    // Get agreements from database
    useEffect(() => {
        if (orgId) {
            dispatch(fetchAgreements({ orgId }));
        }
    }, [dispatch, orgId]);

    // Filter and get list of active agreements
    useEffect(() => {
        if (agreements && agreements.data) {
            const filteredAgreements = agreements.data.filter(agreement => agreement.status === 1);
            setActiveAgreements(filteredAgreements);
        }
    }, [agreements]);

    // Handle deleting a location
    const handleDeleteAgreement = async (record: Agreement) => {
        try {
            if (record.id) {
                await dispatch(deleteAgreement({ orgId, agreementId: record.id }));
            }
            notification.success({
                message: 'Success',
                description: 'Agreement deleted successfully',
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: 'Deletion Error',
                description: 'Failed to delete agreement',
                placement: 'bottomRight',
            });
        }
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: "NAME",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''), // Alphabetical sorting with null check
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: activeAgreements,
        columns: columns,
        rowActions: {
            onEditClick: (termsAgreement: Agreement) => {
                if (termsAgreement.id) {
                    dispatch(describeAgreement({ orgId, agreementId: termsAgreement.id }));
                    dispatch(describeAgreementContent({ orgId, agreementId: termsAgreement.id }));
                    setDrawerVisible(true);
                }
            },
            onDeleteClick: hasAgreementsWrite ? handleDeleteAgreement : undefined,
            deleteModalTitle: ({ record }) => `Confirm Deletion of ${record.name}`,
            deleteModalContent: ({ record }) => `You are about to delete ${record.name}.`,
        },
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasAgreementsWrite,
        allowAddition: hasAgreementsWrite ? {
            itemName: 'Terms Agreement',
            onClick: () => {
                dispatch(clearSelectedAgreement());
                setDrawerVisible(true);
            },
        } : false,
        loading: fetchAgreementsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
    };

    return <OpTable {...opTableProps} />;
};

export default AgreementsTable;
