import React from 'react';
import { Modal, Button, Card } from 'antd';
import holdingPhone from '../../../images/holding_phone.png';
import './MobileAppModal.scss'; 

interface MobileAppModalProps {
    open: boolean;
    onClose: () => void;
}

const MobileAppModal: React.FC<MobileAppModalProps> = ({ open, onClose }) => {
    return (
        <Modal
            open={open}
            onCancel={onClose}
            centered
            width={500}
            className="custom-modal"
            style={{ padding: 0 }}
            closeIcon={null}
            footer={null}
        >
            <Card
                style={{ width: '100%', margin: 0 }}
                cover={<img alt="Holding Phone" src={holdingPhone} style={{ width: '100%', height: 'auto' }} />}
            >
                <p>Manage your visitors through the powerful InVisit mobile app.</p>
                <div className="button-container">
                    <Button key="close" type='primary' onClick={onClose}>
                        Close
                    </Button>
                </div>
            </Card>
        </Modal>
    );
};

export default MobileAppModal;
