import { ComponentProps, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { OpDrawer } from '../OpDrawer/OpDrawer';
import { FormButtons } from '../OpForm/FormButtons';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { FormInstance } from 'antd';

interface IOpFormDrawerProps extends ComponentProps<typeof OpDrawer> {
    form: FormInstance;
    formIsLoading?: boolean;
    formIsReadOnly?: boolean;
    formComponent: ReactElement;
}

export const OpFormDrawer = ({
    children,
    form,
    formIsLoading,
    formIsReadOnly,
    onClose,
    formComponent,
    ...opDrawerProps
}: IOpFormDrawerProps) => {
    const { t } = useTranslation();
    const confirmModal = useConfirmModal();

    const finalOnClose: IOpFormDrawerProps['onClose'] = (e) => {
        if (form.isFieldsTouched()) {
            confirmModal({
                title: t('Confirm discard?'),
                content: t('You have unsaved changes. Are you sure you wish to discard them?'),
                okText: t('Yes'),
                cancelText: t('No'),
                onOk: () => {
                    form.resetFields();
                    onClose?.(e);
                },
            });
        } else {
            onClose?.(e);
        }
    };

    return (
        <OpDrawer
            data-testid="op-form-drawer"
            footer={<FormButtons form={form} isFormLoading={formIsLoading} />}
            onClose={finalOnClose}
            {...opDrawerProps}
        >
            {formComponent}
        </OpDrawer>
    );
};
