import React, { useEffect, useState } from 'react';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRedFlagLogs, fetchRedFlags } from 'store/slices/redFlagSlice';
import { Visitor } from 'types/visitTypes';
import { RedFlag } from 'types/redFlagTypes';
import { formatFullName } from 'utils/utils';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { StopOutlined, EyeOutlined } from "@ant-design/icons";
import { IOpTableProps, OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { TABLE_HEIGHT } from 'constants/ui';
import dayjs from 'dayjs';
import { DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import RedFlagDetailsModal from './RedFlagDetailsModal';

interface RedFlagMatchModalProps {
    visible: boolean;
    onClose: () => void;
    selectedVisitor: Visitor;
}

const RedFlagMatchModal: React.FC<RedFlagMatchModalProps> = ({ visible, onClose, selectedVisitor }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { matchingRedFlags, fetchRedFlagsLoading } = useSelector((state: RootState) => state.redFlags);
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
    const [selectedRedFlag, setSelectedRedFlag] = useState<RedFlag | null>(null);

    // Get red flags from database
    useEffect(() => {
        if (orgId) {
            dispatch(fetchRedFlags({
                orgId,
                firstName: selectedVisitor.firstName || undefined,
                middleName: selectedVisitor.middleName || undefined,
                lastName: selectedVisitor.lastName || undefined,
                isMatchingRedFlags: true
            }));
        }
    }, [dispatch, orgId, selectedVisitor.firstName, selectedVisitor.middleName, selectedVisitor.lastName]);

    const transformedData = matchingRedFlags.data.map((item: RedFlag) => ({
        ...item,
        effectiveDate: dayjs(item.effectiveDate).utc(true).local().format(DATE_TIME_AM_PM_FORMAT),
        fullName: formatFullName(item.firstName, item.middleName, item.lastName),
    }));

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'levelName',
            label: "Level Name",
            render: (text: string) => {
                if (text === 'Blocklist') {
                    return <span><StopOutlined style={{ color: 'rgba(242,103,87,1.0)' }} /> {text}</span>;
                } else if (text === 'Watchlist') {
                    return <span><EyeOutlined style={{ color: '#FFD54F' }} /> {text}</span>;
                }
                return text;
            },
            // filter: {
            //     type: 'multiSelect',
            //     options: [
            //         { label: 'Blocklist', value: 'Blocklist' },
            //         { label: 'Watchlist', value: 'Watchlist' }
            //     ],
            // },
            // sorter: (a, b) => (a.levelName || '').localeCompare(b.levelName || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'fullName',
            label: "Full Name",
            // filter: {
            //     type: 'input',
            // },
            // sorter: (a, b) => (a.fullName || '').localeCompare(b.fullName || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'effectiveDate',
            label: "Effective Date",
            // filter: {
            //     type: 'input',
            // },
            // sorter: (a, b) => (a.effectiveDate || '').localeCompare(b.effectiveDate || ''), // Alphabetical sorting with null check
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: transformedData,
        columns: columns,
        rowActions: {
            onEditClick: (redFlag: RedFlag) => {
                setSelectedRedFlag(redFlag);
                dispatch(fetchRedFlagLogs({ orgId: orgId, redFlagId: redFlag.id }));
                setIsDetailsModalVisible(true);
            }
        },
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: false,
        loading: fetchRedFlagsLoading,
        // writeAccess: hasRedflagWrite,
        allowExport: false,
        allowShowHideColumns: false,
        gtm: 'dashboard-table-gtm',
    };

    return (
        <OpModal
            title="Red Flag Matches"
            open={visible}
            onOk={onClose}
            onCancel={onClose}
            width={700}
            okText="Close"
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <OpTable {...opTableProps} />
            {selectedRedFlag && (
                <RedFlagDetailsModal
                    open={isDetailsModalVisible}
                    onClose={() => setIsDetailsModalVisible(false)}
                    redFlagData={selectedRedFlag}
                />
            )}
        </OpModal>
    );
};

export default RedFlagMatchModal;
