// Designed to handle and display action buttons (like edit and delete) for rows in a table.
// import { useSelectorJs } from 'utils/customHooks';
import { useTranslation } from 'react-i18next';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  IOpRowActionFuncParams,
  IOpRowActions,
} from 'components/customAntd/DLS/OpTable/OpTable';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { MouseEvent } from 'react';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { OpTableRecordType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';

// interface State {
//   user?: {
//     permissions?: string[];
//   };
// }

export const RowActions = ({
  rowActions,
  record,
  numRecords,
  writeAccess,
}: {
  rowActions: IOpRowActions;
  record: OpTableRecordType;
  numRecords?: number;
  writeAccess?: boolean;
}) => {
  const { t } = useTranslation();
  // const hasWriteAccess = useSelectorJs((state: State) => selectHasValidScopeWriteAccess(state));
  const hasWriteAccess = writeAccess;
  const confirmModal = useConfirmModal();

  const onEditClick = () => {
    rowActions.onEditClick?.(record);
  };

  const onDeleteClick = () => {
    rowActions.onDeleteClick?.(record);
  };

  function getFinalText(
    property: string | ((params: IOpRowActionFuncParams) => string) | undefined,
    defaultText: string,
  ): string {
    return typeof property === 'function'
      ? property({ record, numRecords })
      : typeof property === 'string'
        ? property
        : defaultText;
  }

  const handleDeleteClick = (e: MouseEvent) => {
    // Prevents edit callback from firing
    e.stopPropagation();
    const title = getFinalText(
      rowActions.deleteModalTitle,
      t('Confirm delete'),
    );

    const content = getFinalText(
      rowActions.deleteModalContent,
      t('Are you sure you want to delete this?'),
    );

    confirmModal({
      title,
      content,
      okText: t('Yes'),
      cancelText: t('No'),
      onOk() {
        onDeleteClick();
      },
    });
  };

  return (
    <OpSpace size={0}>
      {rowActions.onEditClick && (
        <OpButton
          testId="edit-table-item-button"
          onClick={onEditClick}
          size="small"
          shape="circle"
          type="text"
          icon={hasWriteAccess ? <EditOutlined /> : <EyeOutlined />}
        />
      )}
      {rowActions.onDeleteClick && (
        <OpButton
          testId="delete-table-item-button"
          size="small"
          shape="circle"
          type="text"
          icon={<DeleteOutlined />}
          onClick={handleDeleteClick}
        />
      )}
    </OpSpace>
  );
};
