import React, { useState } from 'react';
import AttendantSignInTable from './AttendantSignInTable';
import AttendantSignInDrawer from './AttendantSignInDrawer';

const AttendantSignIn: React.FC = () => {

    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <>
            <AttendantSignInTable setDrawerVisible={setDrawerVisible} />
            <AttendantSignInDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </>

    );
}

export default AttendantSignIn;
