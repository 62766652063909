import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import VisitorsDrawer from './VisitorsDrawer';
import VisitorsTable from './VisitorsTable';
import { VISITORS_TOOLTIP } from 'constants/tooltip';


const Visitors: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <OpPage title="Visitors" tooltip={VISITORS_TOOLTIP}>
            <VisitorsTable setDrawerVisible={setDrawerVisible} />
            <VisitorsDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false)
                }}
            />
        </OpPage>
    );
}

export default Visitors;
