import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Visit, Note, UpdateVisitPayload, ProcessVisitorExitPayload, CreateVisitNotePayload, VisitorAgreement, DocumentCheck } from 'types/visitTypes';

interface VisitsState {
    visits: ApiResponse<Visit>;
    selectedVisit: Visit | null;
    error: string | null;
    notes: ApiResponse<Note>;
    agreements: ApiResponse<VisitorAgreement>;
    documentsCheck: ApiResponse<DocumentCheck>;
    fetchVisitsLoading: boolean;
    describeVisitLoading: boolean;
    updateVisitLoading: boolean;
    updateVisitorLoading: boolean;
    fetchVisitNotesLoading: boolean;
    fetchAgreementsLoading: boolean;
    addVisitNoteLoading: boolean;
    processVisitorExitLoading: boolean;
    checkDocumentsLoading: boolean;
}

const initialState: VisitsState = {
    visits: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    selectedVisit: null,
    error: null,
    notes: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    agreements: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    documentsCheck: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchVisitsLoading: false,
    describeVisitLoading: false,
    updateVisitLoading: false,
    updateVisitorLoading: false,
    fetchVisitNotesLoading: false,
    fetchAgreementsLoading: false,
    addVisitNoteLoading: false,
    processVisitorExitLoading: false,
    checkDocumentsLoading: false
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchVisits = createAsyncThunk<ApiResponse<Visit>, { orgId: number; siteId?: number; startDate?: string; endDate?: string; firstName?: string; lastName?: string; }, { rejectValue: string }>(
    'visitors/fetchVisits',
    async ({ orgId, siteId, startDate, endDate, firstName, lastName }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Visit>>(`${apiUrl}/orgs/${orgId}/visitor`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    siteId,
                    startDate,
                    endDate,
                    firstName,
                    lastName
                }
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visits');
        }
    }
);

export const describeVisit = createAsyncThunk<ApiResponse<Visit>, { orgId: number; visitId: number }, { rejectValue: string }>(
    'visitors/describeVisit',
    async ({ orgId, visitId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Visit>>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visit details');
        }
    }
);


export const updateVisit = createAsyncThunk<void, { orgId: number; visitId: number; visitPayload: Partial<UpdateVisitPayload> }, { rejectValue: string; dispatch: any }>(
    'visitors/updateVisit',
    async ({ orgId, visitId, visitPayload }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitor/${visitId}`, visitPayload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeVisit({ orgId, visitId }));
            dispatch(fetchVisits({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update visit');
        }
    }
);

export const fetchVisitNotes = createAsyncThunk<ApiResponse<Note>, { orgId: number; visitId: number }, { rejectValue: string }>(
    'visitors/fetchVisitNotes',
    async ({ orgId, visitId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Note>>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitorLog`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visit notes');
        }
    }
);

export const createVisitNote = createAsyncThunk<void, { orgId: number; visitId: number; visitNote: CreateVisitNotePayload }, { rejectValue: string; dispatch: any }>(
    'visits/createVisitNote',
    async ({ orgId, visitId, visitNote }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitorLog`, visitNote, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            dispatch(fetchVisitNotes({ orgId, visitId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to add visit note');
        }
    }
);


export const fetchVisitorAgreements = createAsyncThunk<ApiResponse<VisitorAgreement>, { orgId: number; visitId: number, documentId?: number, visitorId?: number }, { rejectValue: string }>(
    'visitors/fetchVisitorAgreements',
    async ({ orgId, visitId, documentId, visitorId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorAgreement>>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitorAgreement`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    documentId,
                    visitorId
                }
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visitor agreements');
        }
    }
);

export const processVisitorExit = createAsyncThunk<void, { orgId: number; visitId: number; exitPayload: Partial<ProcessVisitorExitPayload> }, { rejectValue: string; dispatch: any }>(
    'visits/processVisitorExit',
    async ({ orgId, visitId, exitPayload }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitorExit`, exitPayload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeVisit({ orgId, visitId }));
            dispatch(fetchVisits({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to process visitor exit');
        }
    }
);

export const checkDocuments = createAsyncThunk<ApiResponse<DocumentCheck>, { orgId: number; visitId: number }, { rejectValue: string }>(
    'visitors/fetchVisitorDocuments',
    async ({ orgId, visitId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<DocumentCheck>>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/documentCheck`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to check visitor documents');
        }
    }
);

const visitsSlice = createSlice({
    name: 'visitors',
    initialState,
    reducers: {
        clearVisits(state) {
            state.visits = initialState.visits;
        },
        clearSelectedVisit(state) {
            state.selectedVisit = initialState.selectedVisit;
        },
        clearNotes(state) {
            state.notes = initialState.notes;
        },
        clearVisitsState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVisits.pending, (state) => {
                // state.fetchVisitsLoading = true;
                state.error = null;
            })
            .addCase(fetchVisits.fulfilled, (state, action) => {
                state.fetchVisitsLoading = false;
                state.visits = action.payload;
            })
            .addCase(fetchVisits.rejected, (state, action) => {
                state.fetchVisitsLoading = false;
                state.error = action.payload || 'Failed to fetch visits';
            })
            .addCase(describeVisit.pending, (state) => {
                state.describeVisitLoading = true;
                state.error = null;
            })
            .addCase(describeVisit.fulfilled, (state, action) => {
                state.describeVisitLoading = false;
                state.selectedVisit = action.payload.data[0];
            })
            .addCase(describeVisit.rejected, (state, action) => {
                state.describeVisitLoading = false;
                state.error = action.payload || 'Failed to fetch visit details';
            })
            .addCase(updateVisit.pending, (state) => {
                state.updateVisitLoading = true;
                state.error = null;
            })
            .addCase(updateVisit.fulfilled, (state) => {
                state.updateVisitLoading = false;
            })
            .addCase(updateVisit.rejected, (state, action) => {
                state.updateVisitLoading = false;
                state.error = action.payload || 'Failed to update visit';
            })
            .addCase(fetchVisitNotes.pending, (state) => {
                state.fetchVisitNotesLoading = true;
                state.error = null;
            })
            .addCase(fetchVisitNotes.fulfilled, (state, action) => {
                state.fetchVisitNotesLoading = false;
                state.notes = action.payload;
            })
            .addCase(fetchVisitNotes.rejected, (state, action) => {
                state.fetchVisitNotesLoading = false;
                state.error = action.payload || 'Failed to fetch visit notes';
            })
            .addCase(createVisitNote.pending, (state) => {
                state.addVisitNoteLoading = true;
                state.error = null;
            })
            .addCase(createVisitNote.fulfilled, (state) => {
                state.addVisitNoteLoading = false;
            })
            .addCase(createVisitNote.rejected, (state, action) => {
                state.addVisitNoteLoading = false;
                state.error = action.payload || 'Failed to add visit note';
            })
            .addCase(fetchVisitorAgreements.pending, (state) => {
                state.fetchAgreementsLoading = true;
                state.error = null;
            })
            .addCase(fetchVisitorAgreements.fulfilled, (state, action) => {
                state.fetchAgreementsLoading = false;
                state.agreements = action.payload;
            })
            .addCase(fetchVisitorAgreements.rejected, (state, action) => {
                state.fetchAgreementsLoading = false;
                state.error = action.payload || 'Failed to fetch visitor agreements';
            })
            .addCase(processVisitorExit.pending, (state) => {
                state.processVisitorExitLoading = true;
                state.error = null;
            })
            .addCase(processVisitorExit.fulfilled, (state) => {
                state.processVisitorExitLoading = false;
            })
            .addCase(processVisitorExit.rejected, (state, action) => {
                state.processVisitorExitLoading = false;
                state.error = action.payload || 'Failed to process visitor exit';
            })
            .addCase(checkDocuments.pending, (state) => {
                state.checkDocumentsLoading = true;
                state.error = null;
            })
            .addCase(checkDocuments.fulfilled, (state, action) => {
                state.checkDocumentsLoading = false;
                state.documentsCheck = action.payload;
            })
            .addCase(checkDocuments.rejected, (state, action) => {
                state.checkDocumentsLoading = false;
                state.error = action.payload || 'Failed to fetch visitor documents';
            });
    },
});

export const { clearVisits, clearSelectedVisit, clearNotes, clearVisitsState } = visitsSlice.actions;
export default visitsSlice.reducer;
