import { useEffect, FunctionComponent, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, ConfigProvider, notification } from 'antd';

import './scss/main.scss';
import { THEME } from './op-themes';
import AppHeader from './components/layout/appHeader/AppHeader';
import SideMenu from './components/layout/sideMenu/SideMenu';
import PageContent from './components/layout/pageContent/PageContent';
import LogIn from './components/auth/LogIn';
import { RootState, AppDispatch } from './store/store';
import { clearAuthState } from './store/slices/authSlice';

const App: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const auth = useSelector((state: RootState) => state.auth.auth);
    const authData = auth?.data && auth.data.length > 0 ? auth.data[0] : null;

    const isTokenExpired = useCallback(() => {
        const expiresAt = authData?.expiresAt;
        if (!expiresAt) return true;

        const expiryDate = new Date(expiresAt);
        const now = new Date();

        return now >= expiryDate;
    }, [authData?.expiresAt]);

    const clearAuthDataFromState = useCallback(() => {
        dispatch(clearAuthState());
    }, [dispatch]);

    // Apply the theme class on the body element
    useEffect(() => {
        const handleThemeChange = () => {
            document.documentElement.classList.toggle('dark', isDarkMode);
            window.dispatchEvent(new Event('themechange')); // Dispatch theme change event
        };

        handleThemeChange();
    }, [isDarkMode]);

    // Validate token on app load
    useEffect(() => {
        const validateToken = async () => {
            const token = authData?.token;
            if (token && !isTokenExpired()) {
                try {
                    await axios.post(`/auth/accessTokens/${token}/validate`);
                } catch (error) {
                    console.error('Token validation error:', error);
                    clearAuthDataFromState();
                    notification.error({
                        message: 'Authentication Error',
                        description: 'Your session has expired or is invalid. Please log in again.',
                    });
                    navigate('/login', { replace: true });
                }
            } else {
                clearAuthDataFromState();
                if (window.location.pathname !== '/login') {
                    navigate('/login', { replace: true });
                }
            }
        };
        validateToken();
    }, [authData?.token, clearAuthDataFromState, isTokenExpired, navigate]);

    return (
        <ConfigProvider theme={isDarkMode ? THEME.dark : THEME.light}>
            <Routes>
                <Route path="/login" element={<LogIn />} />
                <Route path="/*" element={
                    authData ? (
                        <Layout className='op-app'>
                            <AppHeader />
                            <Layout>
                                <SideMenu />
                                <PageContent />
                            </Layout>
                        </Layout>
                    ) : null
                } />
            </Routes>
        </ConfigProvider>
    );
};

export default App;
