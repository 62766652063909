
export const capitalizeName = (name: string | null) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const formatFullName = (firstName: string | null, middleName: string | null, lastName: string | null) => {
    const first = capitalizeName(firstName);
    const middle = capitalizeName(middleName);
    const last = capitalizeName(lastName);

    return [first, middle, last].filter(Boolean).join(' ');
};

export const hasPermission = (tokenScopeList: any[], orgId: number, prefix: 'o' | 's', permission: string) => {
    const orgPermissionPrefix = `${prefix}${orgId}-`;
    const requiredPermission = orgPermissionPrefix + permission;

    // Filter the scopes that belong to the given orgId
    const orgScopes = tokenScopeList.find(scope => Number(scope.org.id) === Number(orgId));
    if (orgScopes) {
        return orgScopes.scope.includes(requiredPermission);
    }

    return false;
};
