import React, { useEffect, useState } from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import dayjs from 'dayjs';
import { getStatusNameById, getVisitDateTime, getStatusColor } from 'utils/visitorsHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { formatFullName } from 'utils/utils';
import { Visit } from 'types/visitTypes';

interface DetailsContentProps {
    form: any;
}

const DetailsContent: React.FC<DetailsContentProps> = ({ form }) => {
    const selectedVisit = useSelector((state: RootState) => state.visits.selectedVisit);
    const [statusColor, setStatusColor] = useState('transparent');
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (selectedVisit) {
            const status = getStatusNameById(selectedVisit.visitStatus.id, selectedVisit.scheduleStart!);
            setStatusColor(getStatusColor(status));

            const dateTime = (status === 'Pending' || status === 'No Show') ? '' : getVisitDateTime(selectedVisit, status, DATE_TIME_AM_PM_FORMAT);

            const newFormValues = {
                company: selectedVisit.company,
                visitorType: selectedVisit.visitorType.name,
                visitStatus: getStatusNameById(selectedVisit.visitStatus.id, selectedVisit.scheduleStart!),
                dateTime: dateTime,
                scheduleStart: selectedVisit.scheduleStart ? dayjs(selectedVisit.scheduleStart).format(DATE_TIME_AM_PM_FORMAT) : undefined,
                scheduleEnd: selectedVisit.scheduleEnd ? dayjs(selectedVisit.scheduleEnd).format(DATE_TIME_AM_PM_FORMAT) : undefined,
                site: selectedVisit.site?.name,
                host: getHostDisplayName(selectedVisit.host),
                purpose: selectedVisit.purpose,
            };
            setFormValues(newFormValues);
        }
    }, [selectedVisit]);

    const getHostDisplayName = (host?: Visit['host']) => {
        const { firstName, lastName, email } = host || {};
        const displayName = `${firstName || ''} ${lastName || ''}`.trim();
        return displayName ? formatFullName(firstName!, null, lastName!) : email;
    };

    return (
        <OpForm
            form={form}
            onSubmit={() => console.log()}
            hasError={false}
            defaultButtons={false}
            initialValues={formValues}
        >
            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Company" name="company" readOnly />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Visitor Type" name="visitorType" readOnly />
                </OpCol>
            </OpRow>

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Status" name="visitStatus" readOnly style={{ backgroundColor: statusColor, color: statusColor === 'transparent' ? 'inherit' : 'white' }} />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Date / Time" name="dateTime" readOnly />
                </OpCol>
            </OpRow>

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Schedule Start Date / Time" name="scheduleStart" readOnly />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Schedule End Date / Time" name="scheduleEnd" readOnly />
                </OpCol>
            </OpRow>

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Location" name="site" readOnly />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Host" name="host" readOnly />
                </OpCol>
            </OpRow>

            <OpForm.TextAreaInput label="Purpose" name="purpose" readOnly rows={4} />
        </OpForm>
    );
};

export default DetailsContent;
