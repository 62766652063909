import { MouseEvent, ComponentProps, ReactNode } from 'react';
import Select from 'antd/es/select';
import Tag from 'antd/es/tag';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
// import { ChevronDownSvg } from 'components/svgs/ChevronDownSvg';
import { DownOutlined } from '@ant-design/icons';
import { sortOptionsAlphabetically } from 'utils/sortOptionsAlphabetically';
import clsx from 'clsx';

import './OpSelect.scss';

interface IOpSelectProps
  extends ComponentProps<typeof Select<string | string[]>> {
  gtm?: string;
  testId?: string;
  tagTestId?: string;
  labelValidator?: (value: ReactNode) => boolean;
}

export const OpSelect = ({
  className,
  gtm,
  testId = 'op-select',
  tagTestId = 'op-select-tag',
  filterSort = sortOptionsAlphabetically,
  labelValidator,
  suffixIcon = <DownOutlined />,
  ...selectProps
}: IOpSelectProps) => {
  const tagRender = ({ label, closable, onClose }: CustomTagProps) => {
    const validationError =
      labelValidator && label && !labelValidator(String(label));

    const onPreventMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        bordered={false}
        className={clsx('op-select__tag', {
          'op-select__tag--error': validationError,
        })}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        data-testid={tagTestId}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      className={clsx('op-select', className)}
      suffixIcon={suffixIcon}
      tagRender={tagRender}
      filterSort={filterSort}
      {...selectProps}
      data-testid={testId}
      data-gtm={gtm}
    />
  );
};

OpSelect.Option = Select.Option;
