import { ComponentProps } from 'react';
import AceEditor from 'react-ace';
import clsx from 'clsx';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-clouds';
import 'ace-builds/src-noconflict/theme-cloud9_night';

import './OpCodeEditor.scss';

interface IOpCodeEditorProps extends ComponentProps<typeof AceEditor> {
  testId?: string;
  showAnnotation?: boolean;
  disabled?: boolean;
  id?: string;
}

export const OpCodeEditor = ({
  testId = 'op-code-editor',
  className,
  showAnnotation,
  disabled,
  ...elementProps
}: IOpCodeEditorProps) => {
  const theme = document.documentElement.classList.contains('dark')
    ? 'cloud9_night'
    : 'clouds';

  return (
    <AceEditor
      data-testid={testId}
      className={clsx('op-code-editor', 'op-code-editor--error', className, {
        'op-code-editor--disabled': disabled,
      })}
      setOptions={{ useWorker: showAnnotation }}
      mode="json"
      readOnly={disabled}
      editorProps={{ $blockScrolling: true }}
      tabSize={2}
      highlightActiveLine={!disabled}
      {...elementProps}
      theme={theme}
    />
  );
};
