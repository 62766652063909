import { VisitorWorkflow, VisitorWorkflowConfig, VisitorWorkflowField, VisitorWorkflowFields, VisitorWorkflowType } from 'types/visitorWorkflowTypes';

export const parseVisitorWorkflowTypeResponse = (response: any): VisitorWorkflowType => ({
    id: Number(response.id),
    name: response.name,
    purpose: response.purpose,
    method: response.method,
    languageCode: response.languageCode,
    workflowCount: response.workflowCount !== null ? Number(response.workflowCount) : null,
});

export const parseVisitorWorkflowResponse = (response: any): VisitorWorkflow => ({
    id: Number(response.id),
    name: response.name,
    workflowTypeId: response.workflowTypeId !== null ? Number(response.workflowTypeId) : null,
    status: Number(response.status),
});

export const parseVisitorWorkflowConfigResponse = (response: any): VisitorWorkflowConfig => {
    return ({
        id: Number(response.id),
        scan: response.scan === '1',
        scanCamera: response.scanCamera === '1',
        scanScanner: response.scanScanner === '1',
        notifyHost: response.notifyHost === '1',
        additionalVisitors: response.additionalVisitors === '1',
        visitorPhoto: response.visitorPhoto === '1',
        visitorPhotoEach: response.visitorPhotoEach === '1',
        agreementsDisplay: response.agreementsDisplay === '1',
        agreementsAccept: response.agreementsAccept === '1',
        agreementsSignature: response.agreementsSignature === '1',
        agreementsEach: response.agreementsEach === '1',
        printLabel: response.printLabel === '1',
        confirmReturnItems: response.confirmReturnItems === '1',
    })
};

export const parseVisitorWorkflowFieldsResponse = (response: any): VisitorWorkflowFields => ({
    middleName: parseVisitorWorkflowFieldResponse(response.middleName),
    email: parseVisitorWorkflowFieldResponse(response.email),
    visitPhone: parseVisitorWorkflowFieldResponse(response.visitPhone),
    visitCompany: parseVisitorWorkflowFieldResponse(response.visitCompany),
    host: parseVisitorWorkflowFieldResponse(response.host),
    visitPurpose: parseVisitorWorkflowFieldResponse(response.visitPurpose),
    visitNotes: response.visitNotes ? parseVisitorWorkflowFieldResponse(response.visitNotes) : undefined,
});

export const parseVisitorWorkflowFieldResponse = (response: any): VisitorWorkflowField => {
    return ({
        included: response.included === '1',
        required: response.required === '1',
        configurable: response.configurable === '1',
    })
};
