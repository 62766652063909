import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormInstance, notification } from 'antd';

// Custom components
import { OpForm, IOnSubmitArgs } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';

// Store and actions
import { RootState, AppDispatch } from 'store/store';
import { clearRoles, fetchRoles, fetchUsersInRole } from 'store/slices/rolesSlice';
import { clearSelectedUser, createUser, updateUser, fetchSelectedUserRoles, clearSelectedUserRoles } from 'store/slices/usersSlice';

// Types
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Role } from 'types/roleTypes';
import { hasPermission } from 'utils/utils';
import { fetchTokenIdentity } from 'store/slices/authSlice';
dayjs.extend(utc);

interface UserFormProps {
	form: FormInstance;
}

const UserForm: React.FC<UserFormProps> = ({ form }) => {
	const dispatch: AppDispatch = useDispatch();
	const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
	const token = useSelector((state: RootState) => state.auth.auth.data[0].token);
	const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
	const { roles, fetchRolesLoading } = useSelector((state: RootState) => state.roles);
	const { selectedUser, selectedUserRoles, fetchSelectedUserRolesLoading } = useSelector((state: RootState) => state.users);

	const hasUserRead = hasPermission(tokenScopeList, orgId, 'o', 'user:r');
	const hasUserWrite = hasPermission(tokenScopeList, orgId, 'o', 'user:w');

	const initialValues = useMemo(() => {
		return {
			identity: {
				email: selectedUser?.identity?.email,
				firstName: selectedUser?.identity?.firstName,
				lastName: selectedUser?.identity?.lastName,
				middleName: selectedUser?.identity?.middleName,
			},
			startDate: selectedUser?.startDate ? dayjs(selectedUser.startDate).format(DATE_FORMAT) : undefined,
			endDate: selectedUser?.endDate ? dayjs(selectedUser.endDate).format(DATE_FORMAT) : undefined,
			// startDate: selectedUser?.startDate ? dayjs.utc(selectedUser.startDate).local().format(DATE_FORMAT) : undefined,
			// endDate: selectedUser?.endDate ? dayjs.utc(selectedUser.endDate).local().format(DATE_FORMAT) : undefined,
			department: selectedUser?.department,
			title: selectedUser?.title,
			personId: selectedUser?.personId,
			externalId: selectedUser?.externalId,
			roleId: selectedUser ? selectedUserRoles.data.map(role => role.id) : [],
		};
	}, [selectedUser, selectedUserRoles.data]);

	useEffect(() => {
		if (orgId) {
			dispatch(fetchRoles({ orgId }));
		}
		if (selectedUser && orgId) {
			dispatch(fetchSelectedUserRoles({ orgId, userId: selectedUser.id }));
		}
		return () => {
			dispatch(clearRoles());
			dispatch(clearSelectedUserRoles());
		};
	}, [dispatch, orgId, selectedUser]);

	const handleSubmit = async (args: IOnSubmitArgs<any>) => {
		const { values, touchedValues } = args;

		const startDate = values.startDate ? dayjs(values.startDate, DATE_FORMAT).format(DATE_TIME_FORMAT) : null;
		const endDate = values.endDate ? dayjs(values.endDate, DATE_FORMAT).format(DATE_TIME_FORMAT) : null;

		// Convert dates from local to UTC for submission
		// const startDate = values.startDate ? dayjs(values.startDate, DATE_FORMAT).utc().format(DATE_TIME_FORMAT) : null;
		// const endDate = values.endDate ? dayjs(values.endDate, DATE_FORMAT).utc().format(DATE_TIME_FORMAT) : null;

		// Validation for date order
		if (endDate && startDate && dayjs(endDate).isBefore(dayjs(startDate))) {
			notification.error({
				message: 'Validation Error',
				description: 'End date cannot be before start date.',
				placement: 'bottomRight',
			});
			return;
		}

		// Check if "Super Admin" role is being removed
		const superAdminRole = roles.data.find(role => role.admin);
		if (superAdminRole) {
			const currentUserRoles = selectedUserRoles.data.map(role => role.id);
			const newUserRoles = values.roleId?.map((roleId: number) => Number(roleId));
			const isRemovingSuperAdmin = currentUserRoles.includes(superAdminRole.id) && !newUserRoles?.includes(Number(superAdminRole.id));

			if (isRemovingSuperAdmin) {
				// Fetch users in "Super Admin" role
				const superAdminUsersResponse = await dispatch(fetchUsersInRole({ orgId, roleId: superAdminRole.id }));
				if (fetchUsersInRole.fulfilled.match(superAdminUsersResponse)) {
					const superAdminUsers = superAdminUsersResponse.payload.data.filter(user => user.status === 1);

					// Check if the only remaining "Super Admin" user is being deleted
					if (superAdminUsers.length === 1 && superAdminUsers[0].id === selectedUser?.id) {
						notification.error({
							message: 'Save Error',
							description: 'There must be at least one user with the "Super Admin" role in your organization.',
							placement: 'bottomRight',
						});
						return;
					}
				}
			}
		}
		// Construct the payload using UTC dates
		const payload = {
			identity: values.identity,
			startDate: startDate ? startDate : undefined,
			endDate: endDate ? endDate : undefined,
			department: values.department,
			title: values.title,
			externalId: values.externalId,
			personId: values.personId,
			roleId: values.roleId?.map((roleId: number) => Number(roleId)),
		};

		try {
			if (selectedUser) {
				await dispatch(updateUser({ orgId, userId: selectedUser.id, user: payload }));
				notification.success({
					message: 'Success',
					description: 'User updated successfully',
					placement: 'bottomRight',
				});
				if (touchedValues.roleId) {
					await dispatch(fetchTokenIdentity({ token }));
				}
				dispatch(clearSelectedUser());
			} else {
				await dispatch(createUser({ orgId, user: payload }));
				notification.success({
					message: 'Success',
					description: 'User created successfully',
					placement: 'bottomRight',
				});
			}
		} catch (error) {
			notification.error({
				message: 'Save Error',
				description: 'Failed to save user.',
				placement: 'bottomRight',
			});
		}
	};

	return (
		<OpForm
			form={form}
			onSubmit={handleSubmit}
			hasError={false}
			defaultButtons={false}
			initialValues={initialValues}
			isReadOnly={!hasUserWrite && hasUserRead}
		>
			<OpForm.Input
				label="Email"
				name={['identity', 'email']}
				rules={[{ required: true, message: 'Email is required', type: 'email' }]}
			/>

			<OpForm.Input
				label="First Name"
				name={['identity', 'firstName']}
				rules={[{ required: true, message: 'First Name is required', type: 'string' }]}
			/>

			<OpForm.Input label="Last Name" name={['identity', 'lastName']} />

			<OpForm.Input label="Middle Name" name={['identity', 'middleName']} />

			<OpRow gutter={16}>
				<OpCol span={12}>
					<OpForm.DatePicker format={DATE_FORMAT} label="Start Date" name="startDate" />
				</OpCol>
				<OpCol span={12}>
					<OpForm.DatePicker format={DATE_FORMAT} label="End Date" name="endDate" />
				</OpCol>
			</OpRow>

			<OpRow gutter={16}>
				<OpCol span={12}>
					<OpForm.Input label="Department" name="department" />
				</OpCol>
				<OpCol span={12}>
					<OpForm.Input label="Title" name="title" />
				</OpCol>
			</OpRow>

			<OpRow gutter={16}>
				<OpCol span={12}>
					<OpForm.Input label="Person ID" name="personId" />
				</OpCol>
				<OpCol span={12}>
					<OpForm.Input label="External ID" name="externalId" />
				</OpCol>
			</OpRow>

			<OpForm.Select
				label="Roles" name="roleId" rules={[{ required: true, message: 'Role is required' }]}
				mode="multiple"
				placeholder="Select role(s)"
				options={roles.data.map((role: Role) => ({ label: role.name, value: role.id }))}
				loading={fetchRolesLoading || fetchSelectedUserRolesLoading}
			/>
		</OpForm>
	);
};

export default UserForm;
