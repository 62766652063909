import React, { useCallback, useMemo, useState } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { Button, Modal, Input, notification } from 'antd';
import { createRedFlagData, updateRedFlagData } from 'store/slices/redFlagSlice';
import { hasPermission } from 'utils/utils';

interface DataContentProps {
    form: any;
}

const DataContent: React.FC<DataContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedRedFlag, redFlagData } = useSelector((state: RootState) => state.redFlags);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [customName, setCustomName] = useState('');
    const [customFields, setCustomFields] = useState<string[]>([]);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedflagRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    const initialValues = useMemo(() => {
        const values: any = {};
        // Populate values from redFlagData
        if (redFlagData) {
            redFlagData.forEach((item) => {
                if (item.data) {
                    values[item.data] = item.value;
                }
            });
        }

        // Initialize customFields if not already set by redFlagData
        customFields.forEach(field => {
            if (!values[field]) {
                values[field] = '';
            }
        });

        return values;
    }, [redFlagData, customFields]);

    const handleSubmit = useCallback(
        async ({ values, touchedValues }: IOnSubmitArgs<any>) => {
            const keys = Object.keys(touchedValues);
            if (selectedRedFlag) {
                for (const key of keys) {
                    const existingData = redFlagData.find(d => d.data === key);
                    if (existingData) {
                        // Update existing data
                        await dispatch(updateRedFlagData({
                            orgId: orgId,
                            redFlagId: selectedRedFlag.id,
                            dataId: existingData.id,
                            redFlagData: {
                                name: key,
                                value: values[key]
                            }
                        }));
                    } else {
                        // Create new data
                        await dispatch(createRedFlagData({
                            orgId: orgId,
                            redFlagId: selectedRedFlag?.id,
                            redFlagData: {
                                name: key,
                                value: values[key]
                            }
                        }));
                    }
                }
            }
            notification.success({
                message: 'Success',
                description: 'Red flag data updated successfully',
                placement: 'bottomRight',
            });
            // Clear custom fields after submission to prevent duplication
            setCustomFields([]);
        },
        [dispatch, redFlagData, orgId, selectedRedFlag]
    );

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setCustomFields(prevFields => [...prevFields, customName]);
        setCustomName('');
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setCustomName('');
        setIsModalVisible(false);
    };

    return (
        <>
            <OpForm
                form={form}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                hasError={false}
                defaultButtons={false}
                isReadOnly={!hasRedflagWrite && hasRedflagRead}
            >
                {redFlagData && redFlagData.map((item) => (
                    <OpForm.Input
                        key={item.id}
                        label={item.data}
                        name={item.data}
                    />
                ))}
                {customFields.map((field, index) => (
                    <OpForm.Input
                        key={field}
                        label={field}
                        name={field}
                    />
                ))}
                {hasRedflagWrite && (
                    <Button type="dashed" onClick={showModal}>
                        Add Custom Field
                    </Button>
                )}
            </OpForm>

            <Modal title="Add Custom Field" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Input
                    placeholder="Enter custom field name"
                    value={customName}
                    onChange={(e) => setCustomName(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default DataContent;
