import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpDataFetchSelect } from 'components/customAntd/DLS/OpDataFetchSelect/OpDataFetchSelect';
import { OpSkeleton } from 'components/customAntd/DLS/OpSkeleton/OpSkeleton';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';
// import { Api } from '../../../../../types/apiTypes';

interface IOpFormDataFetchSelectProps<T extends string | number | symbol>
  extends Pick<
    ComponentProps<typeof OpForm.Item>,
    | 'name'
    | 'label'
    | 'tooltip'
    | 'rules'
    | 'validateTrigger'
    | 'validateStatus' // Temp: For SUIR wrapped version
    | 'help' // Temp: For SUIR wrapped version
    | 'required' // Temp: For SUIR wrapped version
  >,
  ComponentProps<any> {
  formItemClassName?: string;
  isLoading?: boolean;
  placeHolder?: T;
}

export const OpFormDataFetchSelect = ({
  // Form Item props
  name,
  label,
  tooltip,
  rules,
  validateTrigger,
  formItemClassName,
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  disabled,

  ...selectProps
}: IOpFormDataFetchSelectProps<any>) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx('op-form-data-fetch-select', formItemClassName)}
      name={name}
      label={label}
      tooltip={tooltip}
      rules={rules}
      validateTrigger={validateTrigger}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Input active block />
      ) : (
        <OpDataFetchSelect
          disabled={isReadOnly || disabled}
          {...selectProps}
        />
      )}
    </OpForm.Item>
  );
};
