import dayjs from 'dayjs';
import { DenyReason, DenyDetails, DenyEntry, DeniedBy } from 'types/denyTypes';
import { parseVisitResponse, parseVisitorResponse } from './visitParser';

// response
export const parseDenyReasonResponse = (response: any): DenyReason => ({
    id: Number(response.id),
    name: response.name,
    languageCode: response.languageCode,
});

export const parseDenyEntryResponse = (response: any): DenyEntry => ({
    denyDetails: Array.isArray(response.DenyDetails) ? response.DenyDetails.map(parseDenyDetailsResponse) : [],
    visit: Array.isArray(response.Visit) ? response.Visit.map(parseVisitResponse) : [],
});

export const parseDenyDetailsResponse = (response: any): DenyDetails => ({
    id: Number(response.id),
    visitId: Number(response.visitId),
    visitor: Array.isArray(response.visitor) ? response.visitor.map(parseVisitorResponse) : [],
    deniedAt: response.deniedAt ? dayjs(response.deniedAt).toISOString() : null,
    deniedBy: parseDeniedByResponse(response.deniedBy),
    reason: parseDenyReasonResponse(response.reason),
});

export const parseDeniedByResponse = (response: any): DeniedBy => ({
    userId: Number(response.deniedBy.userId),
    identityID: response.deniedBy.identityID !== null ? Number(response.deniedBy.identityID) : null,
    firstName: response.deniedBy.firstName,
    lastName: response.deniedBy.lastName,
    email: response.deniedBy.email,
    mobilePhone: response.deniedBy.mobilePhone,
    department: response.deniedBy.department,
    title: response.deniedBy.title,
});