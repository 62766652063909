import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { AppDispatch } from 'store/store';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Role } from 'types/roleTypes';
import { User, CreateUserPayload, UpdateUserPayload } from 'types/userTypes';

interface UsersState {
    users: ApiResponse<User>;
    globalUser: User | null;
    selectedUser: User | null;
    selectedUserRoles: ApiResponse<Role>;
    fetchUsersLoading: boolean;
    describeUserLoading: boolean;
    createUserLoading: boolean;
    updateUserLoading: boolean;
    deleteUserLoading: boolean;
    fetchSelectedUserRolesLoading: boolean;
    error: string | null;
}

const initialState: UsersState = {
    users: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    globalUser: null,
    selectedUser: null,
    selectedUserRoles: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchUsersLoading: false,
    describeUserLoading: false,
    createUserLoading: false,
    updateUserLoading: false,
    deleteUserLoading: false,
    fetchSelectedUserRolesLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchUsers = createAsyncThunk<ApiResponse<User>, { orgId: number }, { rejectValue: string }>(
    'users/fetchUsers',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<User>>(`${apiUrl}/orgs/${orgId}/users`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch users');
        }
    }
);

export const describeUser = createAsyncThunk<ApiResponse<User>, { orgId: number; userId: number; global?: boolean }, { rejectValue: string; dispatch: AppDispatch }>(
    'users/describeUser',
    async ({ orgId, userId, global }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<User>>(`${apiUrl}/orgs/${orgId}/users/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return { ...response.data, global };
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch user details');
        }
    }
);

export const createUser = createAsyncThunk<void, { orgId: number; user: Partial<CreateUserPayload> }, { rejectValue: string; dispatch: AppDispatch }>(
    'users/createUser',
    async ({ orgId, user }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/users`, user, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchUsers({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create user');
        }
    }
);

export const updateUser = createAsyncThunk<void, { orgId: number; userId: number; user: Partial<UpdateUserPayload>; global?: boolean }, { rejectValue: string; dispatch: AppDispatch }>(
    'users/updateUser',
    async ({ orgId, userId, user, global }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/users/${userId}`, user, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchUsers({ orgId }));
            if (global) {
                dispatch(describeUser({ orgId, userId, global: true }));
            }
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update user');
        }
    }
);

export const deleteUser = createAsyncThunk<void, { orgId: number; userId: number }, { rejectValue: string; dispatch: AppDispatch }>(
    'users/deleteUser',
    async ({ orgId, userId }, { dispatch, rejectWithValue }) => {
        try {
            await axios.delete(`${apiUrl}/orgs/${orgId}/users/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchUsers({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to delete user');
        }
    }
);

export const fetchSelectedUserRoles = createAsyncThunk<ApiResponse<Role>, { orgId: number; userId: number }, { rejectValue: string }>(
    'users/fetchSelectedUserRoles',
    async ({ orgId, userId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Role>>(`${apiUrl}/orgs/${orgId}/users/${userId}/roles`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch user roles');
        }
    }
);

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clearUsers(state) {
            state.users = initialState.users;
        },
        clearSelectedUser(state) {
            state.selectedUser = initialState.selectedUser;
        },
        clearSelectedUserRoles(state) {
            state.selectedUserRoles = initialState.selectedUserRoles;
        },
        clearUsersState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.fetchUsersLoading = true;
                state.error = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.fetchUsersLoading = false;
                state.users = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.fetchUsersLoading = false;
                state.error = action.payload || 'Failed to fetch users';
            })
            .addCase(describeUser.pending, (state) => {
                state.describeUserLoading = true;
                state.error = null;
            })
            .addCase(describeUser.fulfilled, (state, action) => {
                if (action.payload.global) {
                    state.globalUser = action.payload.data[0];
                } else {
                    state.selectedUser = action.payload.data[0];
                }
                state.describeUserLoading = false;
            })
            .addCase(describeUser.rejected, (state, action) => {
                state.describeUserLoading = false;
                state.error = action.payload || 'Failed to fetch user details';
            })
            .addCase(createUser.pending, (state) => {
                state.createUserLoading = true;
                state.error = null;
            })
            .addCase(createUser.fulfilled, (state) => {
                state.createUserLoading = false;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.createUserLoading = false;
                state.error = action.payload || 'Failed to create user';
            })
            .addCase(updateUser.pending, (state) => {
                state.updateUserLoading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.updateUserLoading = false;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.updateUserLoading = false;
                state.error = action.payload || 'Failed to update user';
            })
            .addCase(deleteUser.pending, (state) => {
                state.deleteUserLoading = true;
                state.error = null;
            })
            .addCase(deleteUser.fulfilled, (state) => {
                state.deleteUserLoading = false;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.deleteUserLoading = false;
                state.error = action.payload || 'Failed to delete user';
            })
            .addCase(fetchSelectedUserRoles.pending, (state) => {
                state.fetchSelectedUserRolesLoading = true;
                state.error = null;
            })
            .addCase(fetchSelectedUserRoles.fulfilled, (state, action) => {
                state.fetchSelectedUserRolesLoading = false;
                state.selectedUserRoles = action.payload;
            })
            .addCase(fetchSelectedUserRoles.rejected, (state, action) => {
                state.fetchSelectedUserRolesLoading = false;
                state.error = action.payload || 'Failed to fetch user roles';
            });
    },
});

export const { clearUsers, clearSelectedUser, clearSelectedUserRoles, clearUsersState } = userSlice.actions;
export default userSlice.reducer;
