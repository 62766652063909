import dayjs from 'dayjs';
import { VisitorSearch } from 'types/visitorSearchTypes';

export const parseVisitorSearchResponse = (response: any): VisitorSearch => ({
    id: Number(response.id),
    firstName: response.firstName,
    middleName: response.middleName,
    lastName: response.lastName,
    email: response.email,
    mobilePhone: response.mobilePhone,
    company: response.company,
    status: Number(response.status),
    timestamp: response.timestamp ? dayjs(response.timestamp).toISOString() : null,
    visitId: Number(response.visitId),
    visitStatus: Number(response.visitStatus),
    hostUserId: Number(response.hostUserId),
});