import React, { useState, useEffect } from "react";
import { Spin, notification } from "antd";
import { OpDrawer } from "components/customAntd/DLS/OpDrawer/OpDrawer";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpTabs } from "components/customAntd/DLS/OpTabs/OpTabs";
import KioskContent from "./tabs/KiosksContent";
import OptionsContent from "./tabs/OptionsContent";
import AppearanceContent from "./tabs/AppearanceContent";
import ConnectionContent from "./tabs/ConnectionContent";
import { useConfirmModal } from "utils/customHooks/useConfirmModal";
import { MultiFormButtons } from "components/customAntd/MultiFormButtons";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { LoadingOutlined } from '@ant-design/icons';
import { hasPermission } from "utils/utils";

interface KiosksDrawerProps {
    open: boolean;
    onClose: () => void;
}

const KiosksDrawer: React.FC<KiosksDrawerProps> = ({ open, onClose }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const { selectedKiosk, describeKioskLoading } = useSelector((state: RootState) => state.kiosks);

    const [activeKey, setActiveKey] = useState<string>('kiosk');

    const [kioskForm] = OpForm.useForm();
    const [optionsForm] = OpForm.useForm();
    const [appearanceForm] = OpForm.useForm();

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasKioskWrite = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:w');

    useEffect(() => {
        if (open) {
            setActiveKey('kiosk');
        }
    }, [open]);

    const handleSubmitAllForms = async () => {
        const isKioskFormTouched = kioskForm.isFieldsTouched();
        const isOptionsFormTouched = optionsForm.isFieldsTouched();
        const isAppearanceFormTouched = appearanceForm.isFieldsTouched();

        try {
            if (isKioskFormTouched) {
                await kioskForm.validateFields();
                await kioskForm.submit();
                notification.success({
                    message: 'Success',
                    description: 'Kiosk form updated successfully',
                    placement: 'bottomRight',
                });
            }
            if (isOptionsFormTouched) {
                await optionsForm.validateFields();
                await optionsForm.submit();
                notification.success({
                    message: 'Success',
                    description: 'Options form updated successfully',
                    placement: 'bottomRight',
                });
            }
            if (isAppearanceFormTouched) {
                await appearanceForm.validateFields();
                await appearanceForm.submit();
                notification.success({
                    message: 'Success',
                    description: 'Appearance form updated successfully',
                    placement: 'bottomRight',
                });
            }
            onClose(); // Close the drawer after successful update
        } catch (error) {
            notification.error({
                message: 'Save Error',
                description: 'Failed to save the forms.',
                placement: 'bottomRight',
            });
        }
    };

    const items = [
        {
            key: 'kiosk',
            label: 'Kiosk',
            children: <KioskContent kioskForm={kioskForm} />,
        },
        {
            key: 'options',
            label: 'Options',
            children: <OptionsContent optionsForm={optionsForm} />,
        },
        {
            key: 'appearance',
            label: 'Appearance',
            children: <AppearanceContent appearanceForm={appearanceForm} />,
        },
        {
            key: 'connection',
            label: 'Connection',
            children: <ConnectionContent />,
        }
    ];

    const confirmModal = useConfirmModal();

    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (kioskForm.isFieldsTouched() || optionsForm.isFieldsTouched() || appearanceForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    kioskForm.resetFields();
                    optionsForm.resetFields();
                    appearanceForm.resetFields();
                    onClose?.();
                },
            });
        } else {
            onClose?.();
        }
    };

    return (
        <OpDrawer
            title={describeKioskLoading ? undefined : (`Edit Kiosk: ${selectedKiosk?.label}`)}
            width='900'
            open={open}
            onClose={finalOnClose}
            footer={
                !describeKioskLoading && hasKioskWrite && (
                    <MultiFormButtons
                        forms={[kioskForm, optionsForm, appearanceForm]}
                        submitButtonLabel="Save"
                        onSubmitClick={handleSubmitAllForms}
                    />
                )
            }
        >
            {describeKioskLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                    items={items}
                />
            )}
        </OpDrawer>
    );
};

export default KiosksDrawer;
