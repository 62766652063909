import React, { useState } from 'react';
import KioskSignOutTable from './KioskSignOutTable';
import KioskSignOutDrawer from './KioskSignOutDrawer';

const KioskSignOut: React.FC = () => {

    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <>
            <KioskSignOutTable setDrawerVisible={setDrawerVisible} />
            <KioskSignOutDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </>

    );
}

export default KioskSignOut;
