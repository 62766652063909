import { ComponentProps } from 'react';
import ColorPicker from 'antd/es/color-picker';
import clsx from 'clsx';

interface IOpColorPickerProps extends ComponentProps<typeof ColorPicker> {
  testId?: string;
}

export const OpColorPicker = ({
  className,
  testId = 'op-color-picker',
  ...colorPickerProps
}: IOpColorPickerProps) => {
  return (
    <ColorPicker
      className={clsx('op-color-picker', className)}
      data-testid={testId}
      {...colorPickerProps}
    />
  );
};
