import React, { useEffect, useState } from "react";
import { List, FormInstance } from "antd";
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateAppearanceConfig } from "store/slices/kiosksSlice";
import { KioskAppearance } from "types/kioskTypes";
import { hasPermission } from "utils/utils";
import { OpSwitch } from "components/customAntd/DLS/OpSwitch/OpSwitch";

interface OptionsContentProps {
    optionsForm: FormInstance;
}

const OptionsContent: React.FC<OptionsContentProps> = ({ optionsForm }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedKiosk = useSelector((state: RootState) => state.kiosks.selectedKiosk);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasKioskRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasKioskWrite = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:w');

    const [isScanIdCameraDisabled, setScanIdCameraDisabled] = useState(true);

    useEffect(() => {
        const initialScanIdState = selectedKiosk?.appearance[0].scanID;
        setScanIdCameraDisabled(!initialScanIdState);
        if (!initialScanIdState) {
            optionsForm.setFieldsValue({ scanIdCamera: false });
        }
    }, [selectedKiosk, optionsForm]);

    const handleSubmit = async (args: IOnSubmitArgs<KioskAppearance>) => {
        if (!orgId) { return; }

        const { values } = args;
        const payload: Partial<KioskAppearance> = {
            allowSignOut: values.allowSignOut,
            scanQR: values.scanQR,
            scanID: values.scanID,
            scanIdCamera: values.scanIdCamera,
            badgePrinter: values.badgePrinter
        };
        console.log(payload);
        if (selectedKiosk) {
            dispatch(updateAppearanceConfig({ orgId, kioskId: selectedKiosk.id, appearanceConfig: payload }));
        }
    };

    const handleScanIdChange = (checked: boolean) => {
        setScanIdCameraDisabled(!checked);
        if (!checked) {
            optionsForm.setFieldsValue({ scanIdCamera: false });
        }
    };

    const data = [
        { name: 'allowSignOut', label: 'Allow Sign Out', valuePropName: 'checked' },
        { name: 'scanQR', label: 'Pre-Registration QR code', valuePropName: 'checked' },
        { name: 'scanID', label: 'Scan ID To Identify', valuePropName: 'checked' },
        { name: 'scanIdCamera', label: 'Use Camera', valuePropName: 'checked', dependent: true },
        { name: 'badgePrinter', label: 'Badge printer', valuePropName: 'checked' },
    ];

    return (
        <OpForm
            form={optionsForm}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            initialValues={{
                allowSignOut: selectedKiosk?.appearance[0].allowSignOut,
                scanQR: selectedKiosk?.appearance[0].scanQR,
                scanID: selectedKiosk?.appearance[0].scanID,
                scanIdCamera: selectedKiosk?.appearance[0].scanIdCamera,
                badgePrinter: selectedKiosk?.appearance[0].badgePrinter
            }}
            isReadOnly={!hasKioskWrite && hasKioskRead}
        >
            <List
                header={<div>KIOSK OPTIONS</div>}
                dataSource={data}
                bordered
                renderItem={item => (
                    <List.Item>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <OpForm.Item name={item.name} valuePropName={item.valuePropName} style={{ marginBottom: 0 }}>
                                <OpSwitch
                                    disabled={item.dependent && (isScanIdCameraDisabled || (!hasKioskWrite && hasKioskRead))}
                                    onChange={item.name === 'scanID' ? handleScanIdChange : undefined}
                                />
                            </OpForm.Item>
                            <span style={{ marginLeft: 8 }}>{item.label}</span>
                        </div>
                    </List.Item>
                )}
            />
        </OpForm>
    );
};

export default OptionsContent;
