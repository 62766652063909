import { ScopeResource, Scope } from 'types/scopeResourcesTypes';

export const parseScopeResponse = (response: any): Scope => ({
    id: Number(response.id),
    name: response.name,
    permission: response.permission,
    code: response.code,
    description: response.description,
    languageCode: response.languageCode,
    parentScopeId: response.parentScopeId !== null ? Number(response.parentScopeId) : null,
    order: response.order !== null ? Number(response.order) : null,
});

export const parseScopeResourceResponse = (response: any): ScopeResource => ({
    id: Number(response.id),
    name: response.name,
    languageCode: response.languageCode,
    scopes: Array.isArray(response.scopes) ? response.scopes.map(parseScopeResponse) : [],
});
