import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, List, Form, Input } from 'antd';
import { AppDispatch, RootState } from "store/store";
import { createRedFlagLog, fetchRedFlagLogs } from 'store/slices/redFlagSlice';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { hasPermission } from 'utils/utils';

const { TextArea } = Input;

interface LogContentProps {
    form: any;
}

const LogContent: React.FC<LogContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const redFlagId = useSelector((state: RootState) => state.redFlags.selectedRedFlag?.id);
    const { redFlagLogs, fetchRedFlagLogsLoading } = useSelector((state: RootState) => state.redFlags);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedflagRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    useEffect(() => {
        if (orgId && redFlagId) {
            dispatch(fetchRedFlagLogs({ orgId: orgId, redFlagId }));
        }
    }, [orgId, redFlagId, dispatch]);

    const handleFinish = async ({ values }: IOnSubmitArgs<{ log: string }>) => {
        if (orgId && redFlagId) {
            const newLog = { log: values.log, timestamp: dayjs().format(DATE_TIME_FORMAT) };
            await dispatch(createRedFlagLog({
                orgId: orgId,
                redFlagId,
                redFlagLog: {
                    logUserId: Number(globalUserId),
                    log: newLog.log
                }
            }));
            form.resetFields();
        }
    };

    return (
        <OpForm
            form={form}
            onSubmit={handleFinish}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasRedflagWrite && hasRedflagRead}
        >
            {hasRedflagWrite && (
                <>
                    <Form.Item name="log" rules={[{ required: true, message: 'Please enter a log' }]}>
                        <TextArea rows={4} placeholder="Add new log" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Save New Log</Button>
                    </Form.Item>
                </>
            )}
            <List
                loading={fetchRedFlagLogsLoading}
                dataSource={[...redFlagLogs].reverse()}  // Reverse the logs array to display new logs at the top
                renderItem={(log) => (
                    <List.Item key={log.timestamp} style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '12px',
                        padding: '16px',
                        marginBottom: '16px',
                    }}>
                        <List.Item.Meta
                            description={dayjs(log.timestamp).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)}
                        />
                        {log.log}
                    </List.Item>
                )}
            />
        </OpForm>
    );
};

export default LogContent;
