import React, { useState, useEffect, useCallback } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvitationConfig, fetchInvitationDocuments, updateInvitationConfig, updateInvitationDocuments } from 'store/slices/visitorInvitationSlice';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { List, notification } from 'antd';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { INVITATIONOPTIONS_TOOLTIP } from 'constants/tooltip';
import { hasPermission } from 'utils/utils';
import { OpSwitch } from 'components/customAntd/DLS/OpSwitch/OpSwitch';

interface InvitationFieldConfig {
    included: boolean;
    required: boolean;
    configurable: boolean;
}

interface InitialValuesType {
    allowInvitations: boolean;
    allowPreRegistration: boolean;
    sendEmail: boolean;
    requireApproval: boolean;
    firstName: InvitationFieldConfig;
    lastName: InvitationFieldConfig;
    email: InvitationFieldConfig;
    visitSchedule: InvitationFieldConfig;
    visitCompany: InvitationFieldConfig;
    visitPhone: InvitationFieldConfig;
    visitPurpose: InvitationFieldConfig;
    visitNotes: InvitationFieldConfig;
    host: InvitationFieldConfig;
    invitationDocuments: number[];
}

const InvitationOptions: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { invitationConfig, invitationDocuments, loadingInvitationConfig, loadingInvitationDocuments } = useSelector((state: RootState) => state.visitorInvitation);
    const [configForm] = OpForm.useForm();

    const [allowInvitations, setAllowInvitations] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [allowPreRegistration, setAllowPreRegistration] = useState(false);
    const [initialValues, setInitialValues] = useState<Partial<InitialValuesType>>({});

    const hasInvitationRead = hasPermission(tokenScopeList, orgId, 'o', 'invitation:r');
    const hasInvitationWrite = hasPermission(tokenScopeList, orgId, 'o', 'invitation:w');

    useEffect(() => {
        if (orgId) {
            dispatch(fetchInvitationConfig({ orgId }));
            dispatch(fetchInvitationDocuments({ orgId }));
        }
    }, [dispatch, orgId]);

    useEffect(() => {
        if (invitationConfig.data && invitationConfig.data.length > 0) {
            const initialData = invitationConfig.data[0];
            setAllowInvitations(initialData.allowInvitations);
            setSendEmail(initialData.sendEmail);
            setAllowPreRegistration(initialData.allowPreRegistration);
            const values: InitialValuesType = {
                allowInvitations: initialData.allowInvitations,
                allowPreRegistration: initialData.allowPreRegistration,
                sendEmail: initialData.sendEmail,
                requireApproval: initialData.requireApproval,
                firstName: {
                    included: initialData.firstName.included,
                    required: initialData.firstName.required,
                    configurable: initialData.firstName.configurable
                },
                lastName: {
                    included: initialData.lastName.included,
                    required: initialData.lastName.required,
                    configurable: initialData.lastName.configurable
                },
                email: {
                    included: initialData.email.included,
                    required: initialData.email.required,
                    configurable: initialData.email.configurable
                },
                visitSchedule: {
                    included: initialData.visitSchedule.included,
                    required: initialData.visitSchedule.required,
                    configurable: initialData.visitSchedule.configurable
                },
                visitCompany: {
                    included: initialData.visitCompany.included,
                    required: initialData.visitCompany.required,
                    configurable: initialData.visitCompany.configurable
                },
                visitPhone: {
                    included: initialData.visitPhone.included,
                    required: initialData.visitPhone.required,
                    configurable: initialData.visitPhone.configurable
                },
                visitPurpose: {
                    included: initialData.visitPurpose.included,
                    required: initialData.visitPurpose.required,
                    configurable: initialData.visitPurpose.configurable
                },
                visitNotes: {
                    included: initialData.visitNotes.included,
                    required: initialData.visitNotes.required,
                    configurable: initialData.visitNotes.configurable
                },
                host: {
                    included: initialData.host.included,
                    required: initialData.host.required,
                    configurable: initialData.host.configurable
                },
                invitationDocuments: invitationDocuments.data.filter(document => document.selected).map(document => document.id)
            };
            setInitialValues(values);
            configForm.setFieldsValue(values);
        }
    }, [invitationConfig, invitationDocuments, configForm]);

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        dispatch(updateInvitationConfig({ orgId, config: touchedValues }));
        if (values.invitationDocuments && values.invitationDocuments.length > 0) {
            dispatch(updateInvitationDocuments({ orgId, documentIds: values.invitationDocuments }));
        } else {
            dispatch(updateInvitationDocuments({ orgId }));
        }
        notification.success({
            message: 'Saved',
            description: 'Your changes have been saved.',
            placement: 'bottomRight',
        });
    }, [dispatch, orgId]);

    const handleAllowInvitationsChange = (checked: boolean) => {
        setAllowInvitations(checked);
        if (!checked) {
            setSendEmail(false);
            setAllowPreRegistration(false);
            configForm.setFieldsValue({ sendEmail: false, allowPreRegistration: false });
        }
    };

    const handleSendEmailChange = (checked: boolean) => {
        setSendEmail(checked);
        if (!checked) {
            setAllowPreRegistration(false);
            configForm.setFieldsValue({ allowPreRegistration: false });
        }
    };

    const handleAllowPreRegistrationChange = (checked: boolean) => {
        setAllowPreRegistration(checked);
        configForm.setFieldsValue({ allowPreRegistration: checked });
    };

    const handleSwitchChange = (checked: boolean, field: keyof InitialValuesType) => {
        if (!checked) {
            configForm.setFieldsValue({ [field]: { included: false, required: false } });
        }
    };

    const invitationOptions = [
        { label: 'Allow Invitations', name: 'allowInvitations', valuePropName: 'checked' },
        { label: 'Email Invitations', name: 'sendEmail', valuePropName: 'checked' },
        { label: 'Allow Pre-Registration', name: 'allowPreRegistration', valuePropName: 'checked' }
    ];

    const invitationFields = [
        { label: 'First Name', field: 'firstName' },
        { label: 'Last Name', field: 'lastName' },
        { label: 'Email', field: 'email' },
        { label: 'Visit Schedule', field: 'visitSchedule' },
        { label: 'Visit Company', field: 'visitCompany' },
        { label: 'Visit Phone', field: 'visitPhone' },
        { label: 'Host', field: 'host' },
        { label: 'Visit Purpose', field: 'visitPurpose' },
        { label: 'Visit Notes', field: 'visitNotes' },
    ];

    const isFieldConfigurable = (field: keyof InitialValuesType): boolean => {
        const fieldValue = initialValues[field];
        return typeof fieldValue === 'object' && fieldValue !== null && 'configurable' in fieldValue && (fieldValue as InvitationFieldConfig).configurable;
    };

    return (
        <OpPage title="Invitation Options" tooltip={INVITATIONOPTIONS_TOOLTIP} subtitle="">
            <OpForm
                form={configForm}
                onSubmit={handleSubmit}
                initialValues={initialValues}
                hasError={false}
                isReadOnly={!hasInvitationWrite && hasInvitationRead}
            >
                <OpSpace
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <List
                        header={<div>INVITATION OPTIONS</div>}
                        dataSource={[...invitationOptions, { label: 'Include Agreements', name: 'invitationDocuments', valuePropName: 'value' }]}
                        bordered
                        renderItem={item => (
                            <List.Item>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    {item.name === 'invitationDocuments' ? (
                                        <OpForm.Item name={item.name} label={item.label} style={{ flex: 1, marginBottom: 0 }}>
                                            <OpForm.Select
                                                loading={loadingInvitationDocuments}
                                                mode="multiple"
                                                placeholder="Select Invitation Documents"
                                                options={invitationDocuments.data.map(document => ({
                                                    label: document.name,
                                                    value: document.id
                                                }))}
                                                disabled={!allowPreRegistration}
                                            />
                                        </OpForm.Item>
                                    ) : (
                                        <>
                                            <OpForm.Item name={item.name} valuePropName={item.valuePropName} style={{ marginBottom: 0 }}>
                                                <OpSwitch
                                                    onChange={item.name === 'allowInvitations' ? handleAllowInvitationsChange : item.name === 'sendEmail' ? handleSendEmailChange : item.name === 'allowPreRegistration' ? handleAllowPreRegistrationChange : undefined}
                                                    disabled={
                                                        ((item.name === 'sendEmail' || item.name === 'allowPreRegistration') && !allowInvitations) ||
                                                        (item.name === 'allowPreRegistration' && !sendEmail) ||
                                                        (!hasInvitationWrite && hasInvitationRead)
                                                    }
                                                    loading={loadingInvitationConfig}
                                                />
                                            </OpForm.Item>
                                            <span style={{ marginLeft: 8 }}>{item.label}</span>
                                        </>
                                    )}
                                </div>
                            </List.Item>
                        )}
                    />
                    <OpTable
                        label={<div>INVITATION FIELDS</div>}
                        dataSource={invitationFields}
                        pagination={false}
                        allowGlobalSearch={false}
                        allowExport={false}
                        allowShowHideColumns={false}
                        columns={[
                            {
                                label: 'Field',
                                dataIndex: 'label',
                            },
                            {
                                label: 'ENABLED',
                                dataIndex: 'enabledSwitch',
                                render: (text, record) => (
                                    <OpForm.Item name={[record.field, 'included']} valuePropName="checked" style={{ marginBottom: 0 }}>
                                        <OpSwitch
                                            loading={loadingInvitationConfig}
                                            disabled={!isFieldConfigurable(record.field) || (!hasInvitationWrite && hasInvitationRead)}
                                            onChange={(checked) => handleSwitchChange(checked, record.field)}
                                        />
                                    </OpForm.Item>
                                )
                            },
                            {
                                label: 'REQUIRED',
                                dataIndex: 'requiredSwitch',
                                render: (text, record) => (
                                    <OpForm.Item
                                        shouldUpdate={(prevValues, currentValues) => {
                                            const prev = prevValues as InitialValuesType;
                                            const current = currentValues as InitialValuesType;
                                            const field = record.field as keyof InitialValuesType;
                                            // Check if the field is of type InvitationFieldConfig
                                            return typeof prev[field] === 'object' && prev[field] !== null && 'included' in (prev[field] as InvitationFieldConfig) &&
                                                typeof current[field] === 'object' && current[field] !== null && 'included' in (current[field] as InvitationFieldConfig) &&
                                                (prev[field] as InvitationFieldConfig).included !== (current[field] as InvitationFieldConfig).included;
                                        }}
                                        noStyle
                                    >
                                        {({ getFieldValue }) => (
                                            <OpForm.Item
                                                name={[record.field, 'required']}
                                                valuePropName="checked"
                                                style={{ marginBottom: 0 }}
                                            >
                                                <OpSwitch
                                                    loading={loadingInvitationConfig}
                                                    disabled={!getFieldValue([record.field, 'included']) || !isFieldConfigurable(record.field) || (!hasInvitationWrite && hasInvitationRead)}
                                                />
                                            </OpForm.Item>
                                        )}
                                    </OpForm.Item>
                                )
                            }
                        ]}
                    />
                </OpSpace>
            </OpForm>
        </OpPage>
    );
}

export default InvitationOptions;