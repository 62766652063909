import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { OpDrawer } from "components/customAntd/DLS/OpDrawer/OpDrawer";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpTabs } from "components/customAntd/DLS/OpTabs/OpTabs";
import { RootState } from "store/store";
import { DRAWER_WIDTH } from "constants/ui";
import { LoadingOutlined } from '@ant-design/icons';
import ActivityLogContent from "./tabs/ActivityLogFormContent";

interface ActivityLogDrawerProps {
    open: boolean;
    onClose: () => void;
}

const ActivityLogDrawer: React.FC<ActivityLogDrawerProps> = ({ open, onClose }) => {
    const [activeKey, setActiveKey] = useState<string>('activityLog');
    const { fetchUserActivitiesLoading } = useSelector((state: RootState) => state.userActivity);

    const [activityLogForm] = OpForm.useForm();

    useEffect(() => {
        if (open) {
            setActiveKey('activityLog');
            // Reset forms whenever the drawer opens to ensure they start fresh
            activityLogForm.resetFields();
        }
    }, [open, activityLogForm]);

    const items = useMemo(() => [
        {
            key: 'activityLog',
            label: 'Activity Log',
            children: <ActivityLogContent form={activityLogForm} tab='activityLog' />,
        },
    ], [activityLogForm]);

    const renderTabContent = useCallback(() => {
        return items.map((item) => (
            <div
                key={item.key}
                style={{ display: item.key === activeKey ? 'block' : 'none' }}
            >
                {item.children}
            </div>
        ));
    }, [activeKey, items]);

    return (
        <OpDrawer
            title={'Activity Log'}
            width={DRAWER_WIDTH}
            open={open}
            onClose={() => onClose()}
        >
            {fetchUserActivitiesLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                >
                    {items.map(item => (
                        <OpTabs.TabPane tab={item.label} key={item.key} />
                    ))}
                </OpTabs>
            )}
            {renderTabContent()}
        </OpDrawer>
    );
};

export default ActivityLogDrawer;
