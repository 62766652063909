import dayjs from 'dayjs';
import { User, Organization, Identity } from 'types/userTypes';

export const parseUserResponse = (response: any): User => ({
    id: Number(response.id),
    startDate: response.startDate ? dayjs(response.startDate).toISOString() : null,
    endDate: response.endDate ? dayjs(response.endDate).toISOString() : null,
    externalId: response.externalId,
    personId: response.personId,
    title: response.title,
    department: response.department,
    status: Number(response.status),
    createdAt: response.createdAt ? dayjs(response.createdAt).toISOString() : null,
    updatedAt: response.updatedAt ? dayjs(response.updatedAt).toISOString() : null,
    org: parseOrganizationResponse(response.org),
    identity: parseIdentityResponse(response.identity),
    roleCount: response.roleCount !== undefined && response.roleCount !== null ? Number(response.roleCount) : null,
});

export const parseOrganizationResponse = (response: any): Organization => ({
    id: Number(response.id),
    name: response.name,
    status: Number(response.status),
});

export const parseIdentityResponse = (response: any): Identity => ({
    id: Number(response.id),
    email: response.email,
    firstName: response.firstName,
    middleName: response.middleName,
    lastName: response.lastName,
    mobilePhone: response.mobilePhone,
    createdAt: response.createdAt ? dayjs(response.createdAt).toISOString() : null,
});
