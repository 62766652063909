import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, List, notification, FormInstance, DatePicker, TimePicker, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { AppDispatch, RootState } from 'store/store';
import { createVisitorInvitation, resetState, fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';
import { fetchVisitorTypes } from 'store/slices/visitorTypesSlice';
import { fetchLocations } from 'store/slices/locationsSlice';
import { fetchRedFlags } from 'store/slices/redFlagSlice';
import { fetchUsers } from 'store/slices/usersSlice';
import AddVisitorModal from './AddVisitorModal';
import warningIcon from './warning.svg';
import STATUS from 'constants/status';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import dayjs from 'dayjs';
import { formatFullName } from 'utils/utils';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Visitor } from 'types/visitTypes';
import { VisitorInvitationPayload } from 'types/visitorInvitationTypes';
import { profileIcon } from 'utils/visitorsHelper';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface CreateInvitationFormProps {
    form: FormInstance;
    onClose: () => void;
}


const now = dayjs();
const defaultStartTime = now.minute(0).add(1, 'hour');
const defaultEndTime = defaultStartTime.add(1, 'hour');

const CreateInvitationForm: React.FC<CreateInvitationFormProps> = ({ form, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { visitorTypes, fetchVisitorTypesLoading } = useSelector((state: RootState) => state.visitorTypes);
    const { locations, globalLocation, fetchLocationsLoading } = useSelector((state: RootState) => state.locations);
    const { redFlags } = useSelector((state: RootState) => state.redFlags);
    const { users, fetchUsersLoading } = useSelector((state: RootState) => state.users);
    const invitationConfig = useSelector((state: RootState) => state.visitorInvitation.invitationConfig);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentVisitor, setCurrentVisitor] = useState<Visitor | null>(null);

    const initialValues = useMemo(() => {
        if (visitorTypes.data.length > 0) {
            return {
                siteId: globalLocation?.id,
                visitorTypeId: visitorTypes.data[0].id,
                date: now,
                startTime: defaultStartTime,
                endTime: defaultEndTime,
                visitors: [],
            };
        }
        return {};
    }, [globalLocation?.id, visitorTypes.data]);

    useEffect(() => {
        if (visitorTypes.data.length === 0) {
            dispatch(fetchVisitorTypes({ orgId }));
        }
        if (locations.data.length === 0) {
            dispatch(fetchLocations({ orgId, status: 1 }));
        }
        if (redFlags.data.length === 0) {
            dispatch(fetchRedFlags({ orgId }));
        }
        if (users.data.length === 0) {
            dispatch(fetchUsers({ orgId }));
        }
        if (invitationConfig.data.length === 0) {
            dispatch(fetchInvitationConfig({ orgId }));
        }
    }, [dispatch, orgId, visitorTypes.data.length, locations.data.length, redFlags.data.length, users.data.length, invitationConfig.data.length]);


    const handleOpenModal = (visitor?: Visitor) => {
        setCurrentVisitor(visitor || null);
        setIsModalVisible(true);
    };

    const handleCreateEditVisitor = (visitor: Visitor) => {
        const currentVisitors = form.getFieldValue('visitors') || [];
        if (currentVisitor) {
            const updatedVisitors = currentVisitors.map((v: Visitor) => (v === currentVisitor ? visitor : v));
            form.setFieldsValue({ visitors: updatedVisitors });
        } else {
            form.setFieldsValue({ visitors: [...currentVisitors, visitor] });
        }
        setIsModalVisible(false);
        setCurrentVisitor(null);
    };

    const isVisitorRedFlagged = (visitor: Visitor) => {
        return redFlags.data.some(redFlag =>
            redFlag?.firstName?.toLowerCase() === visitor.firstName?.toLowerCase() &&
            redFlag?.lastName?.toLowerCase() === visitor.lastName?.toLowerCase()
        );
    };

    const handleFieldsChange = (changedFields: any, allFields: any) => {
        const changedField = changedFields[0];
        if (changedField) {
            const { name, value } = changedField;
            const [field] = name;

            if (field === 'startTime') {
                const startTime = value;
                // const endTime = form.getFieldValue('endTime');

                if (startTime) {
                    const startMoment = dayjs(startTime);
                    const newEndTime = startMoment.add(1, 'hour');
                    form.setFieldsValue({ endTime: newEndTime });
                }
            } else if (field === 'endTime') {
                const endTime = value;
                const startTime = form.getFieldValue('startTime');

                if (startTime && endTime) {
                    const startMoment = dayjs(startTime);
                    const endMoment = dayjs(endTime);

                    if (startMoment.isSameOrAfter(endMoment)) {
                        form.setFieldsValue({ startTime: endMoment.subtract(1, 'hour') });
                    }
                }
            }
        }
    };

    const handleSubmit = useCallback(({ values }: IOnSubmitArgs<any>) => {
        const { date, startTime, endTime, ...restValues } = values;
        let startDate = date ? dayjs(date).hour(dayjs(startTime).hour()).minute(dayjs(startTime).minute()) : null;
        let endDate = date ? dayjs(date).hour(dayjs(endTime).hour()).minute(dayjs(endTime).minute()) : null;
        // Adjust endDate to the next day if it's before startDate
        if (endDate && startDate && endDate.isSameOrBefore(startDate)) {
            endDate = endDate.add(1, 'day');
        }

        if (!startDate || !endDate || endDate.isSameOrBefore(startDate)) {
            notification.error({
                message: 'Error',
                description: 'End time must be after the start time.',
                placement: 'bottomRight',
            });
            return;
        }

        const payload: VisitorInvitationPayload = {
            ...restValues,
            scheduleIn: startDate.format('YYYY-MM-DD HH:mm:ss'),
            scheduleOut: endDate.format('YYYY-MM-DD HH:mm:ss'),
            visitors: restValues.visitors.map((visitor: Visitor) => ({
                id: visitor.id || '',
                firstName: visitor.firstName,
                middleName: visitor.middleName || null,
                lastName: visitor.lastName,
                email: visitor.email || null,
                mobilePhone: visitor.mobilePhone || null,
                userId: visitor.userId || null,
                status: STATUS.PENDING.id,
                signIn: visitor.signIn || null,
                signOut: visitor.signOut || null,
            }))
        };

        if (!payload.visitors?.length) {
            notification.error({
                message: 'Error',
                description: 'Please add at least one visitor.',
                placement: 'bottomRight',
            });
            return;
        }
        dispatch(createVisitorInvitation({ orgId, payload }));
        notification.success({
            message: 'Success',
            description: 'Visitor invitation created successfully',
            placement: 'bottomRight',
        });
        form.resetFields();
        onClose();
        dispatch(resetState());
    }, [dispatch, orgId, form, onClose]);

    const renderFormItems = () => {
        if (!invitationConfig.data || !invitationConfig.data[0]) return null;

        const fieldsConfig = {
            schedule: invitationConfig.data[0].visitSchedule,
            company: invitationConfig.data[0].visitCompany,
            hostId: invitationConfig.data[0].host,
            purpose: invitationConfig.data[0].visitPurpose,
            note: invitationConfig.data[0].visitNotes,
        };

        return Object.entries(fieldsConfig).map(([key, config]) => {
            if (config?.included) {
                const label = key === 'hostId' ? 'Host' : key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1');
                const rules = config.required ? [{ required: true, message: `Please enter ${label.toLowerCase()}` }] : [];
                if (key === 'schedule') {
                    return (
                        <Row gutter={8} key={key}>
                            <Col span={8} key={`${key}-date`}>
                                <OpForm.Item name="date" label="Schedule" rules={[{ required: true, message: 'Please select a date' }]}>
                                    <DatePicker style={{ width: '100%' }} />
                                </OpForm.Item>
                            </Col>
                            <Col span={8} key={`${key}-startTime`}>
                                <OpForm.Item name="startTime" label="Start Time" rules={[{ required: true, message: 'Please select a start time' }]}>
                                    <TimePicker
                                        format="h:mm A"
                                        use12Hours
                                        minuteStep={5}
                                        needConfirm={false}
                                        style={{ width: '100%' }}
                                    />
                                </OpForm.Item>
                            </Col>
                            <Col span={8} key={`${key}-endTime`}>
                                <OpForm.Item name="endTime" label="End Time" rules={[{ required: true, message: 'Please select an end time' }]}>
                                    <TimePicker
                                        format="h:mm A"
                                        use12Hours
                                        minuteStep={5}
                                        needConfirm={false}
                                        style={{ width: '100%' }}
                                    />
                                </OpForm.Item>
                            </Col>
                        </Row>
                    );
                }
                if (key === 'hostId') {
                    return (
                        <OpForm.Select
                            key={key}
                            name={key}
                            label={label}
                            rules={rules}
                            showSearch
                            loading={fetchUsersLoading}
                            placeholder="Select Host"
                            filterOption={(input, option) => {
                                const label = option?.label;
                                return typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase());
                            }}
                            options={users.data.filter(user => user.status === 1).map(user => ({
                                label: user.identity?.firstName || user.identity?.lastName
                                    ? formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName)
                                    : user.identity?.email,
                                value: user.id,
                            }))}
                        />
                    );
                }

                if (key === 'purpose' || key === 'note') {
                    return <OpForm.TextAreaInput rows={4} key={key} name={key} label={label} rules={rules} />;
                }

                return <OpForm.Input key={key} name={key} label={label} rules={rules} />;
            }
            return null;
        });
    };

    return (
        <>
            <OpForm
                form={form}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onFieldsChange={handleFieldsChange}
                hasError={false}
                defaultButtons={false}
            >
                <Form.List name="visitors">
                    {(fields, { add, remove }) => (
                        <List
                            locale={{ emptyText: ' ' }}
                            dataSource={fields}
                            renderItem={(field, index) => (
                                <List.Item
                                    key={field.key}
                                    actions={[
                                        <EditOutlined onClick={() => handleOpenModal(form.getFieldValue(['visitors', field.name]))} />,
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />,
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            isVisitorRedFlagged(form.getFieldValue(['visitors', field.name]))
                                                ? <img src={warningIcon} alt="Warning" style={{ width: '24px', height: '24px' }} />
                                                : profileIcon({
                                                    firstName: form.getFieldValue(['visitors', field.name, 'firstName']),
                                                    lastName: form.getFieldValue(['visitors', field.name, 'lastName']),
                                                    size: 24,
                                                    color: "#0898D8",
                                                    marginRight: 0,
                                                })
                                        }
                                        title={
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                {formatFullName(
                                                    form.getFieldValue(['visitors', field.name, 'firstName']),
                                                    form.getFieldValue(['visitors', field.name, 'middleName']),
                                                    form.getFieldValue(['visitors', field.name, 'lastName'])
                                                )}
                                            </span>
                                        }
                                        description={form.getFieldValue(['visitors', field.name, 'email'])}
                                    />
                                </List.Item>
                            )}
                        />
                    )}
                </Form.List>
                <OpForm.Button type="dashed" onClick={() => handleOpenModal()} block icon={<PlusOutlined />}>
                    Add Visitor
                </OpForm.Button>
                <OpForm.Select
                    label="Visitor Type" name="visitorTypeId" rules={[{ required: true, message: 'Please enter Visitor Type' }]}
                    loading={fetchVisitorTypesLoading}
                    placeholder="Select Visitor Type"
                    options={visitorTypes.data.map(type => ({
                        label: type.name,
                        value: type.id
                    }))}
                />
                <OpForm.Select
                    label="Locations" name="siteId" rules={[{ required: true, message: 'Please enter Location.' }]}
                    loading={fetchLocationsLoading}
                    placeholder="Select Locations"
                    options={locations.data.map(location => ({
                        label: location.name,
                        value: location.id
                    }))}
                />
                {renderFormItems()}
            </OpForm>

            <AddVisitorModal
                open={isModalVisible}
                onCreate={handleCreateEditVisitor}
                onCancel={() => {
                    setIsModalVisible(false);
                    setCurrentVisitor(null);
                }}
                initialData={currentVisitor}
            />
        </>
    );
};

export default CreateInvitationForm;
