import React, { useMemo, useState } from 'react';
import { Button, Dropdown, Input } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthState } from 'store/slices/authSlice';
import { clearGlobalOrgState } from 'store/slices/globalOrgSlice';
import { AppDispatch, RootState } from 'store/store';
import { toggleCollapsed } from 'store/slices/uiSlice';
import { clearUsersState, describeUser } from 'store/slices/usersSlice';
import { formatFullName, hasPermission } from 'utils/utils';
import ProfileDrawer from './profile/ProfileDrawer';
import { clearVisitorSearchState, searchVisitors } from 'store/slices/visitorSearchSlice';
import { clearLocationsState } from 'store/slices/locationsSlice';
import { clearAgreementsState } from 'store/slices/agreementsSlice';
import { clearDenyState } from 'store/slices/denySlice';
import { clearKiosksState } from 'store/slices/kiosksSlice';
import { clearScopeResourcesState } from 'store/slices/scopeResourcesSlice';
import { clearRedFlagState } from 'store/slices/redFlagSlice';
import { clearRolesState } from 'store/slices/rolesSlice';
import { clearUserActivityState } from 'store/slices/userActivitySlice';
import { clearVisitorTypesState } from 'store/slices/visitorTypesSlice';
import { clearVisitorsState } from 'store/slices/visitorsSlice';
import { clearVisitorWorkflowsState } from 'store/slices/visitorWorkflowsSlice';
import { clearVisitsState } from 'store/slices/visitsSlice';
import { clearVisitorInvitationState } from 'store/slices/visitorInvitationSlice';
import { profileIcon } from 'utils/visitorsHelper';
import { OpTooltip } from 'components/customAntd/DLS/OpTooltip/OpTooltip';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { ReactComponent as VoltIcon } from 'images/icons/volt.svg';
import QuickActionsModal from './quickActions/QuickActionsModal'; // Import the new component

const AppHeader: React.FC = () => {

    const { Search } = Input;

    // State hooks
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [quickActionsVisible, setQuickActionsVisible] = useState(false); // State to control QuickActionsModal visibility

    // Navigation hook
    const navigate = useNavigate();

    // Dispatch hook
    const dispatch: AppDispatch = useDispatch();

    // Selector hooks
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { auth } = useSelector((state: RootState) => state.auth);
    const { globalUser } = useSelector((state: RootState) => state.users);
    const { globalLocation } = useSelector((state: RootState) => state.locations);

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');

    const selectedOrgName = useMemo(() => {
        const selectedOrg = auth.data[0].tokenScopeList.find(scope => Number(scope.org.id) === Number(orgId));
        if (!selectedOrg || !selectedOrg.user) {
            navigate('/login');
            return '';
        }
        const userId = selectedOrg!.user.id;
        dispatch(describeUser({ orgId, userId, global: true }));
        return selectedOrg!.org.name;
        // eslint-disable-next-line
    }, [orgId]);

    const userFullName = useMemo(() => {
        if (globalUser && globalUser.identity) {
            return formatFullName(globalUser!.identity.firstName, globalUser!.identity.middleName, globalUser!.identity.lastName);
        }
    }, [globalUser]);

    const createUserAvatarIcon = (size: number, marginRight: number) => {
        const nameOrEmail = userFullName || globalUser?.identity.email || "";
        return profileIcon({
            fullName: nameOrEmail,
            size: size,
            color: isDarkMode ? "#31394b" : "#D3D3D3",
            textColor: isDarkMode ? "#FFFFFF" : "#000000",
            shape: "square",
            marginRight: marginRight,
        });
    };

    // eslint-disable-next-line
    const userAvatarIcon40 = useMemo(() => createUserAvatarIcon(40, 0), [userFullName, globalUser, isDarkMode]);
    // eslint-disable-next-line
    const userAvatarIcon70 = useMemo(() => createUserAvatarIcon(70, 15), [userFullName, globalUser, isDarkMode]);

    const onLogout = () => {
        // Clear state
        dispatch(clearAgreementsState());
        dispatch(clearAuthState());
        dispatch(clearDenyState());
        dispatch(clearGlobalOrgState());
        dispatch(clearKiosksState());
        dispatch(clearLocationsState());
        dispatch(clearRedFlagState());
        dispatch(clearRolesState());
        dispatch(clearScopeResourcesState());
        dispatch(clearUserActivityState());
        dispatch(clearVisitorInvitationState());
        dispatch(clearVisitorSearchState());
        dispatch(clearVisitorsState());
        dispatch(clearVisitorTypesState());
        dispatch(clearVisitorWorkflowsState());
        dispatch(clearVisitsState());
        dispatch(clearUsersState());

        // Remove token from localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('identityId');
        localStorage.removeItem('createdAt');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('tokenScopeList');

        // Navigate to login page
        navigate('/login');
    };

    const menu = {
        items: [
            {
                key: 'profileHeader',
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px 10px 10px 0', borderBottom: '1px solid #f0f0f0', width: '250px' }}>
                        {userAvatarIcon70}
                        <div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '16px', color: 'var(--colorHeaderButtonText)' }}>{userFullName}</div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '12px', color: 'var(--colorHeaderButtonText)' }}>{globalUser!.identity.email}</div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '12px', color: 'var(--colorHeaderButtonText)' }}>org:{selectedOrgName}</div>
                        </div>
                    </div>
                ),
                disabled: true,
            },
            {
                key: '1',
                label: (
                    <Button type="primary" block onClick={() => setDrawerVisible(true)}>
                        Profile
                    </Button>
                ),
            },
            {
                key: '2',
                label: (
                    <Button block danger onClick={onLogout}>
                        Sign out
                    </Button>
                ),
            },
        ]
    };

    const onSearch = (value: string) => {
        if (value && globalLocation?.id) {
            dispatch(searchVisitors({ orgId, siteId: globalLocation.id, searchString: value })).then(() => {
                navigate('/visitorSearchResults');
            });
        }
    };

    return (
        <div style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: "60px"
        }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: '50' }}>
                <Button
                    type="text"
                    icon={<MenuOutlined />}
                    onClick={() => dispatch(toggleCollapsed())}
                    style={{
                        marginLeft: '16px'
                    }}
                />

                <a href="/dashboard" style={{ display: 'flex', alignItems: 'center', height: '60px' }}>
                    <img src="/images/logo_main.png" alt="Logo" style={{ maxHeight: '100%', maxWidth: '100%' }} />
                </a>
            </div>

            <div>
                <Search placeholder="Search Visitor" allowClear style={{ width: 400 }} onSearch={onSearch} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {(hasSigninWrite || hasSignoutWrite || hasInviteWrite) && (
                    <div style={{ marginRight: '16px' }}>
                        <OpTooltip placement="topRight" title="Quick Actions">
                            <OpButton
                                icon={<VoltIcon style={{ width: 18, height: 18 }} />}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundColor: isDarkMode ? "#31394b" : "#D3D3D3",
                                    color: isDarkMode ? "#D3D3D3" : "#31394b"
                                }}
                                onClick={() => setQuickActionsVisible(true)} // Open the modal when the button is clicked
                            />
                        </OpTooltip>
                    </div>
                )}
                <div style={{ marginRight: '16px' }}>
                    {selectedOrgName}
                </div>
                <Dropdown menu={menu} trigger={['click']} placement="bottomRight">
                    <Button type="text" onClick={e => e.preventDefault()} style={{ padding: 0, marginRight: '16px', height: '40px' }}>
                        {userAvatarIcon40}
                    </Button>
                </Dropdown>
            </div>

            <ProfileDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />

            <QuickActionsModal
                visible={quickActionsVisible}
                onClose={() => setQuickActionsVisible(false)}
            />
        </div>
    );
}

export default AppHeader;
