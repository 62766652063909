import React, { useEffect, useMemo } from 'react';
import { Modal, Form, Row, Col, DatePicker, TimePicker } from 'antd';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchVisitorTypes } from 'store/slices/visitorTypesSlice';
import { fetchLocations } from 'store/slices/locationsSlice';
import { fetchUsers } from 'store/slices/usersSlice';
import { formatFullName } from 'utils/utils';
import { Visit } from 'types/visitTypes';
import dayjs from 'dayjs';

interface ModalFormProps {
    open: boolean;
    onOk: (data: Visit) => void;
    onCancel: () => void;
    initialData: Visit | null;
}

const EditVisitModal: React.FC<ModalFormProps> = ({ open, onOk, onCancel, initialData }) => {
    const [form] = Form.useForm();
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { visitorTypes, fetchVisitorTypesLoading } = useSelector((state: RootState) => state.visitorTypes);
    const { users, fetchUsersLoading } = useSelector((state: RootState) => state.users);

    const initialValues = useMemo(() => {
        if (initialData) {
            return {
                company: initialData.company,
                siteId: initialData.site?.id,
                visitorTypeId: initialData.visitorType?.id,
                hostId: initialData.host?.userId,
                purpose: initialData.purpose,
                signIn: dayjs(initialData.signIn),
                signInDate: dayjs(initialData.signIn), // just to display date
                signOut: dayjs(initialData.signOut),
                signOutDate: dayjs(initialData.signOut),// just to display date
                date: dayjs(initialData.scheduleStart).startOf('day'),
                startTime: dayjs(initialData.scheduleStart),
                endTime: dayjs(initialData.scheduleEnd),
            };
        }
        return {};
    }, [initialData]);

    useEffect(() => {
        if (open) {
            dispatch(fetchVisitorTypes({ orgId }));
            dispatch(fetchLocations({ orgId, status: 1 }));
            dispatch(fetchUsers({ orgId }));
        }
    }, [dispatch, orgId, open]);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues, form]);

    const handleFieldsChange = (changedFields: any, allFields: any) => {
        const changedField = changedFields[0];
        if (changedField) {
            const { name, value } = changedField;
            const [field] = name;

            if (field === 'startTime') {
                const startTime = value;
                // const endTime = form.getFieldValue('endTime');

                if (startTime) {
                    const startMoment = dayjs(startTime);
                    const newEndTime = startMoment.add(1, 'hour');
                    form.setFieldsValue({ endTime: newEndTime });
                }
            } else if (field === 'endTime') {
                const endTime = value;
                const startTime = form.getFieldValue('startTime');

                if (startTime && endTime) {
                    const startMoment = dayjs(startTime);
                    const endMoment = dayjs(endTime);

                    if (startMoment.isSameOrAfter(endMoment)) {
                        form.setFieldsValue({ startTime: endMoment.subtract(1, 'hour') });
                    }
                }
            }
        }
    };

    return (
        <Modal
            open={open}
            title={`Edit Visit`}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onOk(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            centered={true}
        >
            <Form form={form} initialValues={initialValues} onFieldsChange={handleFieldsChange} layout="vertical">
                {initialData?.visitStatus.id === 1 && (
                    <>
                        {/* <OpForm.Select
                            label="Locations" name="siteId" rules={[{ required: true, message: 'Please enter Location.' }]}
                            loading={fetchLocationsLoading}
                            placeholder="Select Locations"
                            options={locations.data.map(location => ({
                                label: location.name,
                                value: location.id
                            }))}
                        /> */}
                        <OpForm.Select
                            label="Visitor Type" name="visitorTypeId" rules={[{ required: true, message: 'Please enter Visitor Type' }]}
                            loading={fetchVisitorTypesLoading}
                            placeholder="Select Visitor Type"
                            options={visitorTypes.data.map(type => ({
                                label: type.name,
                                value: type.id
                            }))}
                        />
                        <Row gutter={8}>
                            <Col span={8}>
                                <OpForm.Item name="date" label="Schedule" rules={[{ required: true, message: 'Please select a date' }]}>
                                    <DatePicker style={{ width: '100%' }} />
                                </OpForm.Item>
                            </Col>
                            <Col span={8}>
                                <OpForm.Item name="startTime" label="Start Time" rules={[{ required: true, message: 'Please select a start time' }]}>
                                    <TimePicker
                                        format="h:mm A"
                                        use12Hours
                                        minuteStep={5}
                                        needConfirm={false}
                                        style={{ width: '100%' }}
                                    />
                                </OpForm.Item>
                            </Col>
                            <Col span={8}>
                                <OpForm.Item name="endTime" label="End Time" rules={[{ required: true, message: 'Please select an end time' }]}>
                                    <TimePicker
                                        format="h:mm A"
                                        use12Hours
                                        minuteStep={5}
                                        needConfirm={false}
                                        style={{ width: '100%' }}
                                    />
                                </OpForm.Item>
                            </Col>
                        </Row>
                    </>
                )}
                {(initialData?.visitStatus.id === 2 || initialData?.visitStatus.id === 3) && (
                    <Row gutter={8}>
                        <Col span={12}>
                            <OpForm.Item name="signInDate" label="Sign In Date">
                                <DatePicker style={{ width: '100%' }} disabled={true} />
                            </OpForm.Item>
                        </Col>
                        <Col span={12}>
                            <OpForm.Item
                                name="signIn"
                                label="Start Time"
                                rules={[{ required: true, message: 'Please select a start time' }]}
                            >
                                <TimePicker
                                    format="h:mm A"
                                    use12Hours
                                    minuteStep={5}
                                    style={{ width: '100%' }}
                                />
                            </OpForm.Item>
                        </Col>
                    </Row>
                )}
                {initialData?.visitStatus.id === 3 && (
                    <Row gutter={8}>
                        <Col span={12}>
                            <OpForm.Item name="signOutDate" label="Sign Out Date">
                                <DatePicker style={{ width: '100%' }} disabled={true} />
                            </OpForm.Item>
                        </Col>
                        <Col span={12}>
                            <OpForm.Item
                                name="signOut"
                                label="End Time"
                                rules={[{ required: true, message: 'Please select a end time' }]}
                            >
                                <TimePicker
                                    format="h:mm A"
                                    use12Hours
                                    minuteStep={5}
                                    style={{ width: '100%' }}
                                />
                            </OpForm.Item>
                        </Col>
                    </Row>
                )}
                <OpForm.Input label="Company" name="company" />
                <OpForm.Select
                    label="Host" name="hostId"
                    showSearch
                    loading={fetchUsersLoading}
                    placeholder="Select Host"
                    filterOption={(input, option) => {
                        const label = option?.label;
                        return typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase());
                    }}
                    options={users.data.filter(user => user.status === 1).map(user => ({
                        label: user.identity.firstName || user.identity.lastName
                            ? formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName)
                            : user.identity.email,
                        value: user.id
                    }))}
                />
                <OpForm.TextAreaInput rows={3} label="Purpose" name="purpose" />
            </Form>
        </Modal>
    );
};

export default EditVisitModal;
