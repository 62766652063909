import { ComponentProps } from 'react';
import clsx from 'clsx';
import Collapse from 'antd/es/collapse';

import './OpCollapse.scss';

interface IOpCollapseProps
  extends Omit<ComponentProps<typeof Collapse>, 'children'> {
  testId?: string;
}

export const OpCollapse = ({
  className,
  testId = 'op-collapse',
  ...collapseProps
}: IOpCollapseProps) => {
  return (
    <div className="op-collapse-container">
      <Collapse
        className={clsx('op-collapse', className)}
        data-testid={testId}
        {...collapseProps}
      />
    </div>
  );
};
