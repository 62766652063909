import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
import dayjs from 'dayjs';
import { Visitor } from 'types/visitTypes';
import { DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';

interface ModalFormProps {
    open: boolean;
    onOk: (data: Visitor) => void;
    onCancel: () => void;
    initialData: Visitor | null;
}

const EditVisitorModal: React.FC<ModalFormProps> = ({ open, onOk, onCancel, initialData }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialData) {
            const dataWithDayjsDates = {
                ...initialData,
                signIn: initialData.signIn ? dayjs(initialData.signIn) : null,
                signOut: initialData.signOut ? dayjs(initialData.signOut) : null,
            };
            form.setFieldsValue(dataWithDayjsDates);
        }
    }, [initialData, form, open]);

    const renderFormItems = () => {
        if (initialData?.status !== 1) return null;

        const fields: Array<keyof Visitor> = [
            'firstName', 'middleName', 'lastName', 'email', 'mobilePhone',
        ];

        return fields.map((field) => (
            <Form.Item
                key={field}
                name={field}
                label={field === 'mobilePhone' ? 'Mobile Phone' : field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                rules={
                    field === 'firstName' || field === 'lastName'
                        ? [{ required: true, message: `Please input the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}!` }]
                        : undefined
                }
            >
                <Input />
            </Form.Item>
        ));
    };

    const renderAdditionalFields = () => {
        if (!initialData) return null;

        if (initialData.status === 2) {
            return (
                <OpForm.DatePicker showTime format={DATE_TIME_AM_PM_FORMAT}
                    name="signIn"
                    label="Sign In"
                    rules={[{ required: true, message: 'Please select the sign in time' }]}
                />
            );
        }
        if (initialData.status === 3) {
            return (
                <>
                    <OpForm.DatePicker showTime format={DATE_TIME_AM_PM_FORMAT}
                        name="signIn"
                        label="Sign In"
                        rules={[{ required: true, message: 'Please select the sign in time' }]}
                    />
                    <OpForm.DatePicker showTime format={DATE_TIME_AM_PM_FORMAT}
                        name="signOut"
                        label="Sign Out"
                        rules={[{ required: true, message: 'Please select the sign-out time!' }]}
                    />
                </>

            );
        }

        return null;
    };

    return (
        <Modal
            open={open}
            title={initialData ? `Edit Visitor` : 'Add Visitor'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onOk(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        // centered={true}
        >
            <Form form={form} layout="vertical">
                {renderFormItems()}
                {renderAdditionalFields()}
            </Form>
        </Modal>
    );
};

export default EditVisitorModal;
