import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import DateRangeFilter from 'components/customAntd/DateRangeFilter';

// Import actions and types from Redux slices
import { RootState, AppDispatch } from 'store/store';
import { fetchUserActivities, fetchUserActivityTypes, describeUserActivity } from 'store/slices/userActivitySlice';

// Import constants
import { DATE_FORMAT, DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';
import { UserActivity } from 'types/userActivityTypes';
import { formatFullName } from 'utils/utils';

dayjs.extend(isBetween);

interface ActivityLogTableData {
    id: number;
    user: string | null;
    activityType: string | null;
    updatedAt: string;
    activityDetails: string | null;
}

interface ActivityLogTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const ActivityLogTable: React.FC<ActivityLogTableProps> = ({ setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { activities, fetchUserActivitiesLoading } = useSelector((state: RootState) => state.userActivity);

    const [filteredActivities, setFilteredActivities] = useState<UserActivity[]>([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().endOf('day'));

    // Fetch user activities and activity types on organizationId change
    useEffect(() => {
        if (orgId) {
            dispatch(fetchUserActivities({ orgId }));
            dispatch(fetchUserActivityTypes({ orgId }));
        }
    }, [dispatch, orgId]);

    // Filter activities based on date range
    useEffect(() => {
        if (activities && activities.data) {
            const filteredActivities = activities.data.filter((activity) => {
                const updatedAtUtc = dayjs(activity.updatedAt).utc(true); // Treat this as UTC time
                const localUpdatedAt = dayjs(updatedAtUtc).local();
                return localUpdatedAt.isBetween(startDate, endDate, null, '[]');
            });
            setFilteredActivities(filteredActivities);
        }
    }, [activities, startDate, endDate]);

    const handleDateRangeFilter = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
        setStartDate(start);
        setEndDate(end);
        // Fetch user activities with the new filters
        // dispatch(fetchUserActivities({ orgId: orgId, startDate: start.toISOString(), endDate: end.toISOString() }));
    };

    // Format activities for table display
    const formatActivitiesForTable = (activities: UserActivity[]): ActivityLogTableData[] => {
        return activities.map((activity) => {
            const fullName = formatFullName(activity.user.firstName, null, activity.user.lastName);
            const updatedAtUtc = dayjs(activity.updatedAt).utc(true); // Treat this as UTC time
            return {
                id: activity.id,
                user: fullName && fullName.trim() !== '' ? fullName : activity.user.email,
                activityType: activity.activityType,
                updatedAt: dayjs(updatedAtUtc).local().format(DATE_TIME_AM_PM_FORMAT),
                activityDetails: activity.activityDetails
            };
        });
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'user',
            label: 'User',
            filter: { type: 'input' },
            sorter: (a, b) => a.user.length - b.user.length
        },
        {
            dataIndex: 'activityType',
            label: 'Activity Type',
            filter: { type: 'input' },
            sorter: (a, b) => a.activityType.length - b.activityType.length
        },
        {
            dataIndex: 'updatedAt',
            label: 'Date & Time',
            sorter: (a, b) => dayjs(a.updatedAt).unix() - dayjs(b.updatedAt).unix()
        },
        {
            dataIndex: 'activityDetails',
            label: 'Details',
            filter: { type: 'input' },
            sorter: (a, b) => a.activityDetails.length - b.activityDetails.length,
            hidden: true
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formatActivitiesForTable(filteredActivities),
        label: `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`,
        columns: columns,
        rowActions: {
            onEditClick: (userActivity: UserActivity) => {
                dispatch(describeUserActivity(userActivity));
                setDrawerVisible(true);
            },
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        allowExport: true,
        allowShowHideColumns: true,
        loading: fetchUserActivitiesLoading,
        gtm: 'activity-log-table-gtm',
        rowKey: 'id',
    };

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <DateRangeFilter
                onDateRangeFilter={handleDateRangeFilter}
                initialStartDate={startDate}
                initialEndDate={endDate}
            />
            <OpTable {...opTableProps} />
        </OpSpace>
    );
}

export default ActivityLogTable;
