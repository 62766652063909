import React, { useState, useEffect } from 'react';
import { Drawer, notification, List, Button, Card, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { EyeOutlined, CheckOutlined, UserOutlined } from '@ant-design/icons';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { getStatusNameById, getStatusColor } from 'utils/visitorsHelper';
import { AppDispatch, RootState } from 'store/store';
import { OpBanner } from 'components/customAntd/DLS/OpBanner/OpBanner';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { formatFullName, hasPermission } from 'utils/utils';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import EditVisitorModal from '../EditVisitorModal';
import { removeVisitorFromVisit, updateVisitor, viewVisitorPhoto, fetchVisitorAgreement } from 'store/slices/visitorsSlice';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import RedFlagMatchModal from './RedFlagMatchModal';
import warningIcon from '../../warning.svg';
import { Visitor } from 'types/visitTypes';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { checkDocuments, fetchVisitorAgreements } from 'store/slices/visitsSlice';
import { fetchVisitorWorkflowAgreements } from 'store/slices/visitorWorkflowsSlice';

// Import the custom styles
import './custom-pdf-styles.scss';

interface VisitorProfileDrawerProps {
    open: boolean;
    onClose: () => void;
    visitor: Visitor | null;
    form: any;
}

const VisitorProfileDrawer: React.FC<VisitorProfileDrawerProps> = ({ open, onClose, visitor, form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedVisit = useSelector((state: RootState) => state.visits.selectedVisit);
    const signedAgreements = useSelector((state: RootState) => state.visits.agreements);
    const documentsCheck = useSelector((state: RootState) => state.visits.documentsCheck);
    const allAgreements = useSelector((state: RootState) => state.visitorWorkflows.visitorAgreements);

    const redFlags = useSelector((state: RootState) => state.redFlags.redFlags);
    const visitorPhotoUrl = useSelector((state: RootState) => state.visitors.visitorPhotoUrl);

    const [isEditVisitorModalVisible, setIsEditModalVisible] = useState(false);
    const [isRedFlagMatchModalVisible, setIsRedFlagMatchModalVisible] = useState(false);

    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationWorkflow = useSelector((state: RootState) => state.locations.globalLocationWorkflow);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');
    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');

    const [activeKey, setActiveKey] = useState<string>('profile');

    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState<string | null>(null);

    useEffect(() => {
        if (open && globalLocationWorkflow && selectedVisit && visitor) {
            dispatch(fetchVisitorWorkflowAgreements({ orgId, visitorWorkflowId: globalLocationWorkflow?.SignInWorkflowId }));
            dispatch(fetchVisitorAgreements({ orgId, visitId: selectedVisit.id, visitorId: visitor.id! }));
            dispatch(checkDocuments({ orgId, visitId: selectedVisit.id }));
            setActiveKey('profile');
            form.resetFields();
        }
    }, [open, globalLocationWorkflow, selectedVisit, visitor, dispatch, orgId, form]);

    useEffect(() => {
        if (visitor && selectedVisit) {
            dispatch(viewVisitorPhoto({ orgId, visitId: selectedVisit.id, visitorId: visitor.id! }));
        }
    }, [visitor, orgId, selectedVisit, dispatch]);

    const confirmModal = useConfirmModal();

    const onDeleteClick = () => {
        confirmModal({
            title: 'Delete Confirmation',
            content: 'Are you sure you want to delete this visitor? This can not be undone.',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                onClose();
                if (selectedVisit?.visitors.length && selectedVisit?.visitors.length > 1) {
                    await dispatch(removeVisitorFromVisit({
                        orgId,
                        visitId: selectedVisit.id,
                        visitorId: visitor?.id!,
                    }));
                    notification.success({
                        message: 'Success',
                        description: 'Visitor deleted successfully',
                        placement: 'bottomRight',
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'There must be at least one visitor in a visit.',
                        placement: 'bottomRight',
                    });
                }
            },
        });
    };

    const editVisitor = async (updatedVisitor: Visitor) => {
        if (visitor && selectedVisit) {
            const payload = {
                firstName: updatedVisitor.firstName || undefined,
                middleName: updatedVisitor.middleName === null ? undefined : (updatedVisitor.middleName || ''),
                lastName: updatedVisitor.lastName || undefined,
                email: updatedVisitor.email === null ? undefined : (updatedVisitor.email || ''),
                mobilePhone: updatedVisitor.mobilePhone === null ? undefined : (updatedVisitor.mobilePhone || ''),
                signIn: updatedVisitor.signIn ? dayjs(updatedVisitor.signIn).format(DATE_TIME_FORMAT) : undefined,
                signOut: updatedVisitor.signOut ? dayjs(updatedVisitor.signOut).format(DATE_TIME_FORMAT) : undefined,
            };

            if (payload.signOut && payload.signIn && dayjs(payload.signOut).isBefore(dayjs(payload.signIn))) {
                notification.error({
                    message: 'Validation Error',
                    description: 'End date cannot be before start date.',
                    placement: 'bottomRight',
                });
                setIsEditModalVisible(false);
                return;
            }

            try {
                await dispatch(updateVisitor({
                    orgId: orgId,
                    visitId: selectedVisit.id,
                    visitorId: visitor.id!,
                    visitorPayload: payload
                }));
                setIsEditModalVisible(false);
                notification.success({
                    message: 'Success',
                    description: 'Profile updated successfully',
                    placement: 'bottomRight',
                });
            } catch (error) {
                console.error('Failed to update visitor:', error);
            }
        }
    };

    const isVisitorRedFlagged = (visitor: Visitor) => {
        return redFlags.data.some(redFlag =>
            redFlag?.firstName?.toLowerCase() === visitor.firstName?.toLowerCase() &&
            redFlag?.middleName?.toLowerCase() === visitor.middleName?.toLowerCase() &&
            redFlag?.lastName?.toLowerCase() === visitor.lastName?.toLowerCase()
        );
    };

    const handleAgreementClick = async () => {
        try {
            if (selectedVisit && visitor) {
                const resultAction = await dispatch(fetchVisitorAgreement({ orgId, visitId: selectedVisit.id, visitorId: visitor.id! }));
                if (fetchVisitorAgreement.fulfilled.match(resultAction)) {
                    const pdfUrl = resultAction.payload;
                    const link = document.createElement('a');
                    link.href = pdfUrl;
                    link.download = 'agreement.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    console.error('Failed to fetch agreement:', resultAction.payload);
                }
            }
        } catch (error) {
            console.error('Error fetching agreement:', error);
        }
    };

    const handleImageCardClick = () => {
        if (visitorPhotoUrl) {
            setModalImageUrl(visitorPhotoUrl);
            setIsImageModalVisible(true);
        }
    };

    const handleImageModalClose = () => {
        setIsImageModalVisible(false);
        setModalImageUrl(null);
    };

    const allAgreementsData = allAgreements.data;
    const signedAgreementsData = signedAgreements.data.map(signedAgreement => signedAgreement.document);

    const uniqueAgreements = allAgreementsData.filter(
        agreement => !signedAgreementsData.some(signedAgreement => signedAgreement.id === agreement.id)
    );

    const combinedAgreements = [...uniqueAgreements, ...signedAgreementsData];

    return (
        <>
            <Drawer
                width={700}
                title="Visitor Profile"
                placement="right"
                closable={false}
                onClose={onClose}
                open={open}
                extra={
                    <OpSpace>
                        {((visitor?.status === 1 && hasInviteWrite) ||
                            (visitor?.status === 2 && hasSigninWrite) ||
                            (visitor?.status === 3 && hasSignoutWrite)) && (hasAllvisitorsWrite || globalUserId === selectedVisit?.host.userId) && (
                                <>
                                    {visitor?.status === 1 && (
                                        <OpButton type="primary" danger ghost onClick={onDeleteClick}>
                                            Delete
                                        </OpButton>
                                    )}
                                    <OpButton type="primary" ghost onClick={() => setIsEditModalVisible(true)}>
                                        Edit
                                    </OpButton>
                                </>
                            )}
                    </OpSpace>
                }
            >
                {visitor && isVisitorRedFlagged(visitor) && (
                    <>
                        <OpBanner
                            type="error"
                            style={{ marginBottom: '20px', cursor: 'pointer' }}
                            showIcon={false}
                            message={(
                                <div onClick={() => setIsRedFlagMatchModalVisible(true)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={warningIcon} alt="Warning" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                        VISITOR ON RED FLAG LIST
                                    </span>
                                    <EyeOutlined />
                                </div>
                            )}
                        />
                        <RedFlagMatchModal visible={isRedFlagMatchModalVisible} onClose={() => setIsRedFlagMatchModalVisible(false)} selectedVisitor={visitor} />
                    </>
                )}
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                >
                    <OpTabs.TabPane tab="Profile" key="profile">
                        <OpForm
                            form={form}
                            onSubmit={() => console.log()}
                            hasError={false}
                            defaultButtons={false}
                            initialValues={{
                                fullName: formatFullName(visitor?.firstName!, visitor?.middleName!, visitor?.lastName!),
                                status: getStatusNameById(visitor?.status!, selectedVisit?.scheduleStart!),
                                email: visitor?.email,
                                mobilePhone: visitor?.mobilePhone,
                                signIn: visitor?.signIn ? dayjs(visitor.signIn).format(DATE_TIME_AM_PM_FORMAT) : undefined,
                                signOut: visitor?.signOut ? dayjs(visitor.signOut).format(DATE_TIME_AM_PM_FORMAT) : undefined,
                            }}
                        >
                            <OpRow gutter={24}>
                                <OpCol span={12}>
                                    <OpForm.Input readOnly label="Full Name" name="fullName" />
                                    <OpForm.Input
                                        label="Status"
                                        name="status"
                                        readOnly
                                        style={{
                                            backgroundColor: getStatusColor(getStatusNameById(visitor?.status!, selectedVisit?.scheduleStart!)),
                                            color: getStatusColor(getStatusNameById(visitor?.status!, selectedVisit?.scheduleStart!)) === 'transparent' ? 'inherit' : 'white'
                                        }}
                                    />
                                    <OpForm.Input readOnly label="Mobile Phone" name="mobilePhone" />
                                </OpCol>
                                <OpCol span={12}>
                                    <Card
                                        hoverable={!!visitorPhotoUrl}
                                        style={{
                                            width: '100%',
                                            height: '280px',
                                            borderRadius: '30px',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={handleImageCardClick}
                                    >
                                        {visitorPhotoUrl ? (
                                            <img src={visitorPhotoUrl}
                                                alt='Visitor'
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    borderRadius: '30px',
                                                }}
                                                onError={(e) => {
                                                }}
                                            />
                                        ) : (
                                            <UserOutlined style={{ fontSize: '64px', color: '#ccc' }} />
                                        )}
                                    </Card>
                                </OpCol>
                            </OpRow>
                            <OpForm.Input readOnly label="Email" name="email" />
                            <OpRow gutter={16}>
                                <OpCol span={12}>
                                    <OpForm.Input readOnly label="Sign In Time" name="signIn" />
                                </OpCol>
                                <OpCol span={12}>
                                    <OpForm.Input readOnly label="Sign Out Time" name="signOut" />
                                </OpCol>
                            </OpRow>
                        </OpForm>
                    </OpTabs.TabPane >
                    <OpTabs.TabPane tab="Agreements" key="agreements">
                        <List
                            itemLayout="horizontal"
                            dataSource={combinedAgreements}
                            bordered
                            renderItem={(agreement) => (
                                <List.Item
                                    extra={
                                        signedAgreements.data.some(signedAgreement =>
                                            signedAgreement.document.id === agreement.id
                                        ) && <CheckOutlined style={{ color: 'green' }} />
                                    }
                                >
                                    <List.Item.Meta
                                        title={
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                {agreement.name}
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                        {documentsCheck.data.some(doc => doc.agreement && doc.agreement.trim() !== '') && (
                            <OpButton onClick={handleAgreementClick} type="primary" ghost style={{ width: '100%', marginTop: '10px' }}>
                                View Signed Agreements
                            </OpButton>
                        )}
                    </OpTabs.TabPane >
                </OpTabs>
            </Drawer>

            <Modal
                open={isImageModalVisible}
                title="Visitor Photo"
                onOk={handleImageModalClose}
                onCancel={handleImageModalClose}
                centered
                footer={[
                    <Button key="close" type="primary" onClick={handleImageModalClose}>
                        Close
                    </Button>
                ]}
            >
                {modalImageUrl && <img src={modalImageUrl} alt="Visitor" style={{ width: '100%' }} />}
            </Modal>

            <EditVisitorModal
                open={isEditVisitorModalVisible}
                onOk={editVisitor}
                onCancel={() => setIsEditModalVisible(false)}
                initialData={visitor}
            />
        </>
    );
};

export default VisitorProfileDrawer;
