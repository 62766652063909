import React, { useCallback } from 'react';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";
import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { createLocation, updateLocation } from 'store/slices/locationsSlice';
import { notification } from 'antd';
import { hasPermission } from 'utils/utils';

interface CombinedFormContentProps {
    form: any;
    tab: string;
}

const CombinedFormContent: React.FC<CombinedFormContentProps> = ({ form, tab }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedLocation = useSelector((state: RootState) => state.locations.selectedLocation);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasLocationRead = hasPermission(tokenScopeList, orgId, 'o', 'location:r');
    const hasLocationWrite = hasPermission(tokenScopeList, orgId, 'o', 'location:w');

    // Create initial values based on the selectedLocation
    const initialValues = selectedLocation ? {
        name: selectedLocation.name,
        description: selectedLocation.description,
        address: selectedLocation.address,
        address2: selectedLocation.address2,
        city: selectedLocation.city,
        region: selectedLocation.region,
        postalcode: selectedLocation.postalcode,
        country: selectedLocation.country,
        phone: selectedLocation.phone,
    } : {};

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        if (touchedValues) {
            if (selectedLocation) {
                dispatch(updateLocation({ orgId, locationId: selectedLocation.id, location: touchedValues }));
                notification.success({
                    message: 'Success',
                    description: 'Location updated successfully',
                    placement: 'bottomRight',
                });
            } else {
                dispatch(createLocation({ orgId, location: touchedValues }));
                notification.success({
                    message: 'Success',
                    description: 'Location created successfully',
                    placement: 'bottomRight',
                });
            }
        }
    }, [dispatch, selectedLocation, orgId]);

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasLocationWrite && hasLocationRead}
        >
            {tab === 'location' && (
                <>
                    <OpForm.Input
                        label="Name" name="name" rules={[{ required: true, message: 'Enter the name of the location.' }]}
                    />
                    <OpForm.Input label="Description" name="description" />

                </>
            )}
            {tab === 'details' && (
                <>
                    <OpForm.Input label="Address" name="address" />
                    <OpForm.Input label="Address 2" name="address2" />
                    <OpRow gutter={16}>
                        <OpCol span={12}>
                            <OpForm.Input label="City" name="city" />
                        </OpCol>
                        <OpCol span={12}>
                            <OpForm.Input label="State" name="region" />
                        </OpCol>
                    </OpRow>
                    <OpRow gutter={16}>
                        <OpCol span={12}>
                            <OpForm.Input label="Zip" name="postalcode" />
                        </OpCol>
                        <OpCol span={12}>
                            <OpForm.Input label="Country" name="country" />
                        </OpCol>
                    </OpRow>
                    <OpForm.Input label="Phone" name="phone" />
                </>
            )}
        </OpForm >
    );
};

export default CombinedFormContent;
