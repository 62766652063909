import React, { useEffect } from 'react';
import { List, Input, Button, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store/store';
import { fetchVisitNotes, createVisitNote } from 'store/slices/visitsSlice';
import dayjs from 'dayjs';
import { DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { Note } from 'types/visitTypes';
import { hasPermission } from 'utils/utils';

const NotesContent: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedVisit = useSelector((state: RootState) => state.visits.selectedVisit);
    const selectedVisitId = useSelector((state: RootState) => state.visits.selectedVisit?.id);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const notes = useSelector((state: RootState) => state.visits.notes.data);
    const fetchVisitNotesLoading = useSelector((state: RootState) => state.visits.fetchVisitNotesLoading);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasVisitorlogWrite = hasPermission(tokenScopeList, orgId, 'o', 'visitorlog:w');
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    const [form] = Form.useForm();

    useEffect(() => {
        if (orgId && selectedVisitId) {
            dispatch(fetchVisitNotes({ orgId, visitId: selectedVisitId }));
        }
    }, [dispatch, orgId, selectedVisitId]);

    const handleFinish = (values: { note: string }) => {
        if (orgId && selectedVisitId && globalUserId) {
            dispatch(createVisitNote({
                orgId: orgId,
                visitId: selectedVisitId,
                visitNote: {
                    logUserId: globalUserId,
                    log: values.note
                }
            }));
            form.resetFields();
        }
    };

    return (
        <div>
            {(hasVisitorlogWrite && (hasAllvisitorsWrite || globalUserId === selectedVisit?.host.userId)) && (
                <Form form={form} onFinish={handleFinish}>
                    <Form.Item name="note" rules={[{ required: true, message: 'Please enter a note' }]}>
                        <Input.TextArea rows={4} placeholder="Add new note" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Save New Note</Button>
                    </Form.Item>
                </Form>
            )}
            <List
                loading={fetchVisitNotesLoading}
                dataSource={notes}
                renderItem={(note: Note) => (
                    <List.Item
                        key={note.id}
                        style={{
                            border: '1px solid #d9d9d9',
                            borderRadius: '12px',  // Rounded border
                            padding: '16px',
                            marginBottom: '16px',
                        }}
                    >
                        <List.Item.Meta
                            description={dayjs(note.timestamp).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)} />
                        {note.log}
                    </List.Item>
                )}
            />
        </div>
    );
};

export default NotesContent;
