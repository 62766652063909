import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';

import './MultiFormButtons.scss';

interface MultiFormButtonsProps {
    className?: string;
    forms: ComponentProps<typeof OpForm>['form'][];
    isFormLoading?: boolean;
    isSubmitButtonLoading?: boolean;
    submitButtonLabel?: string;
    onSubmitClick?: () => void;
}

export const MultiFormButtons = ({
    className,
    forms,
    isFormLoading = false,
    isSubmitButtonLoading = false,
    submitButtonLabel = 'Save',
    onSubmitClick,
}: MultiFormButtonsProps) => {
    const { t } = useTranslation();
    const confirmModal = useConfirmModal();

    const onResetClick = () => {
        const isAnyFormTouched = forms.some(form => form?.isFieldsTouched());
        if (isAnyFormTouched) {
            confirmModal({
                title: t('Confirm discard?'),
                content: t('You have unsaved changes. Are you sure you wish to discard them?'),
                okText: t('Yes'),
                cancelText: t('No'),
                onOk: () => {
                    forms.forEach(form => {
                        if (form?.isFieldsTouched()) {
                            form.resetFields();
                        }
                    });
                },
            });
        }
    };

    return (
        <OpSpace className={clsx('form-buttons', className)} size="middle">
            <OpButton
                testId="reset-form-button"
                disabled={isFormLoading}
                onClick={onResetClick}
            >
                {t('Reset')}
            </OpButton>
            <OpButton
                testId="submit-form-button"
                type="primary"
                onClick={onSubmitClick}
                disabled={isFormLoading || isSubmitButtonLoading}
                loading={isSubmitButtonLoading}
            >
                {submitButtonLabel}
            </OpButton>
        </OpSpace>
    );
};
