import { OpDrawer } from "components/customAntd/DLS/OpDrawer/OpDrawer";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpTabs } from "components/customAntd/DLS/OpTabs/OpTabs";
import { MultiFormButtons } from "components/customAntd/MultiFormButtons";
import { useState, useEffect } from "react";
import { notification } from "antd";
import { useConfirmModal } from "utils/customHooks/useConfirmModal";
import { DRAWER_WIDTH } from "constants/ui";
import InfoContent from "./tabs/InfoContent";
import PasswordContent from "./tabs/PasswordContent";
import SettingsContent from "./tabs/SettingsContent";

interface ProfileDrawerProps {
    open: boolean;
    onClose: () => void;
}

const ProfileDrawer: React.FC<ProfileDrawerProps> = ({ open, onClose }) => {
    const [activeKey, setActiveKey] = useState<string>('info');
    const [infoForm] = OpForm.useForm();
    const [passwordForm] = OpForm.useForm();
    const [settingsForm] = OpForm.useForm();

    const confirmModal = useConfirmModal();

    useEffect(() => {
        if (open) {
            setActiveKey('info');
            // Reset forms whenever the drawer opens to ensure they start fresh
            infoForm.resetFields();
            passwordForm.resetFields();
            settingsForm.resetFields();
        }
    }, [open, infoForm, passwordForm, settingsForm]);

    // Called when the save button is clicked
    const handleSubmitAllForms = async () => {
        try {
            if (infoForm.isFieldsTouched()) {
                const infoValues = await infoForm.validateFields();
                if (infoValues) {
                    await infoForm.submit();
                }
            }
            if (passwordForm.isFieldsTouched()) {
                const passwordValues = await passwordForm.validateFields();
                if (passwordValues) {
                    await passwordForm.submit();
                }
            }
            // if (settingsForm.isFieldsTouched()) {
            await settingsForm.submit();
            // }
            onClose(); // Close the drawer after successful submit
        } catch (error) {
            notification.error({
                message: 'Save Error',
                description: 'Failed to save profile.',
                placement: 'bottomRight',
            });
        }
    };

    // Called when we close the drawer by clicking cancel button or touching the main page
    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (infoForm.isFieldsTouched() || passwordForm.isFieldsTouched() || settingsForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    infoForm.resetFields();
                    passwordForm.resetFields();
                    settingsForm.resetFields();
                    onClose();
                },
            });
        } else {
            onClose();
        }
    };

    const items = [
        {
            key: 'info',
            label: 'Info',
            children: <InfoContent form={infoForm} />,
        },
        {
            key: 'password',
            label: 'Password',
            children: <PasswordContent form={passwordForm} />,
        },
        {
            key: 'settings',
            label: 'Settings',
            children: <SettingsContent form={settingsForm} />,
        },
    ];

    return (
        <OpDrawer
            title={'Profile'}
            width={DRAWER_WIDTH}
            open={open}
            onClose={finalOnClose}
            footer={
                <MultiFormButtons
                    forms={[infoForm, passwordForm, infoForm]}
                    submitButtonLabel="Save"
                    onSubmitClick={handleSubmitAllForms}
                />
            }
        >
            <OpTabs
                activeKey={activeKey}
                onChange={(newActiveKey: string) => {
                    setActiveKey(newActiveKey);
                }}
                items={items}
            />
        </OpDrawer>
    );
};

export default ProfileDrawer;