import { ComponentProps } from 'react';
import Alert from 'antd/es/alert';
import { OpInfoWithDismiss } from './components/OpInfoWithDismiss';
import { OpInfoWithoutDismiss } from './components/OpInfoWithoutDismiss';

import './OpInfo.scss';

export interface IOpInfoProps extends ComponentProps<typeof Alert> {
  className?: string;
  dismissibleId?: string;
}

export const OpInfo = ({ dismissibleId, ...alertProps }: IOpInfoProps) =>
  dismissibleId ? (
    <OpInfoWithDismiss {...alertProps} dismissibleId={dismissibleId} />
  ) : (
    <OpInfoWithoutDismiss {...alertProps} />
  );
