import dayjs from 'dayjs';
import { UserActivity, ActivityUser, UserActivityType } from 'types/userActivityTypes';

export const parseUserActivityResponse = (activity: any): UserActivity => ({
    id: Number(activity.id),
    user: parseActivityUser(activity.user),
    updatedAt: activity.updatedAt ? dayjs(activity.updatedAt).toISOString() : null,
    activityTypeId: Number(activity.activityTypeId),
    activityType: activity.activityType,
    languageCode: activity.languageCode,
    severity: activity.severity !== null ? Number(activity.severity) : null,
    activityObjId: activity.activityObjId !== null ? Number(activity.activityObjId) : null,
    activityDetails: activity.activityDetails,
});

const parseActivityUser = (user: any): ActivityUser => ({
    userId: Number(user.userId),
    identityID: user.identityID !== null ? Number(user.identityID) : null,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    mobilePhone: user.mobilePhone,
    department: user.department,
    title: user.title,
});

export const parseUserActivityTypeResponse = (type: any): UserActivityType => ({
    id: Number(type.id),
    activityType: type.activityType,
    languageCode: type.languageCode,
    severity: type.severity !== null ? Number(type.severity) : null,
});
