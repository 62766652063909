import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox, notification } from 'antd';
import { AppDispatch, RootState } from 'store/store';
import { createVisitNote, processVisitorExit, updateVisit } from 'store/slices/visitsSlice';
import { useDispatch, useSelector } from 'react-redux';
import STATUS from 'constants/status';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { getStatusNameById, getStatusColor, profileIcon } from 'utils/visitorsHelper';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { formatFullName, hasPermission } from 'utils/utils';
import { clearDenyReasons, denyEntry, fetchDenyReasons } from 'store/slices/denySlice';
import { createRedFlag } from 'store/slices/redFlagSlice';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { UpdateVisitPayload } from 'types/visitTypes';
import { UpdateVisitorPayload } from 'types/visitorTypes';
import { updateVisitor } from 'store/slices/visitorsSlice';
import { OpSwitch } from 'components/customAntd/DLS/OpSwitch/OpSwitch';

interface VisitorsModalProps {
    open: boolean;
    onClose: () => void;
    newStatus: number;
}

const VisitorsModal: React.FC<VisitorsModalProps> = ({ open, onClose, newStatus }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);
    const { denyReasons } = useSelector((state: RootState) => state.deny);

    const [form] = OpForm.useForm();
    const [addToBlockList, setAddToBlockList] = useState(false);
    const [addToWatchList, setAddToWatchList] = useState(false);

    const initialValues = { reasonForDenial: denyReasons?.data[0]?.id };

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedFlagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflag:w');

    useEffect(() => {
        if (orgId) {
            dispatch(fetchDenyReasons({ orgId }));
        }
        return () => {
            dispatch(clearDenyReasons());
        };
    }, [dispatch, orgId]);

    const getFilteredVisitors = () => {
        if (!selectedVisit) return [];
        return selectedVisit.visitors.filter(visitor => {
            if (newStatus === Number(STATUS.SIGNED_IN.id) || newStatus === Number(STATUS.DENIED_ENTRY.id)) {
                return visitor.status === STATUS.PENDING.id;
            } else if (newStatus === Number(STATUS.SIGNED_OUT.id)) {
                return visitor.status === STATUS.SIGNED_IN.id;
            }
            return true;
        });
    };

    const visitorOptions = getFilteredVisitors().map(visitor => ({
        label: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
        value: `${visitor.id}`,
        firstName: visitor.firstName,
        lastName: visitor.lastName,
        color: getStatusColor(getStatusNameById(visitor.status!, selectedVisit?.scheduleStart!))
    }));

    const updateVisitStatus = useCallback(async () => {
        const visitPayload: Partial<UpdateVisitPayload> = { status: newStatus };
        if (newStatus === Number(STATUS.SIGNED_IN.id)) {
            visitPayload.signIn = dayjs().format(DATE_TIME_FORMAT);
            notification.success({
                message: 'Success',
                description: 'Signed in successfully',
                placement: 'bottomRight',
            });
            await dispatch(updateVisit({ orgId, visitId: selectedVisit!.id, visitPayload }));
        } else if (newStatus === Number(STATUS.SIGNED_OUT.id)) {
            visitPayload.signOut = dayjs().format(DATE_TIME_FORMAT);
            notification.success({
                message: 'Success',
                description: 'Signed out successfully',
                placement: 'bottomRight',
            });
            await dispatch(processVisitorExit({ orgId, visitId: selectedVisit!.id, exitPayload: { signOut: dayjs().format(DATE_TIME_FORMAT) } }));
        } else if (newStatus === Number(STATUS.DENIED_ENTRY.id)) {
            visitPayload.signIn = dayjs().format(DATE_TIME_FORMAT);
            notification.success({
                message: 'Success',
                description: 'Denied entry.',
                placement: 'bottomRight',
            });
            await dispatch(updateVisit({ orgId, visitId: selectedVisit!.id, visitPayload }));
            setAddToBlockList(false);
            setAddToWatchList(false);
        }
        onClose();
    }, [dispatch, orgId, selectedVisit, newStatus, onClose]);

    const updateVisitorStatus = useCallback(async (visitorId: number) => {
        const visitorPayload: Partial<UpdateVisitorPayload> = {
            status: newStatus,
            signIn: newStatus === Number(STATUS.SIGNED_IN.id) || newStatus === Number(STATUS.DENIED_ENTRY.id) ? dayjs().format(DATE_TIME_FORMAT) : undefined,
            signOut: newStatus === Number(STATUS.SIGNED_OUT.id) ? dayjs().format(DATE_TIME_FORMAT) : undefined,
        };
        try {
            await dispatch(updateVisitor({ orgId, visitId: selectedVisit!.id, visitorId, visitorPayload }));
        } catch (error) {
            console.error("Error updating visitor status:", error);
        }
    }, [dispatch, orgId, selectedVisit, newStatus]);

    const handleSubmit = useCallback(
        async ({ values, touchedValues }: IOnSubmitArgs<any>) => {
            if (touchedValues && touchedValues !== undefined) {
                for (const visitorId of touchedValues.visitorsCheckbox) {
                    await updateVisitorStatus(visitorId);
                }
                await updateVisitStatus();

                // Call denyEntry and createRedFlag if newStatus is DENIED_ENTRY
                if (newStatus === Number(STATUS.DENIED_ENTRY.id)) {
                    try {
                        for (const visitorId of touchedValues.visitorsCheckbox) {
                            // Deny Entry API Call
                            await dispatch(denyEntry({
                                orgId,
                                visitId: selectedVisit!.id,
                                denyEntry: {
                                    visitorId: visitorId,
                                    deniedBy: globalUserId,
                                    reasonId: values.reasonForDenial,
                                    details: values.detail,
                                },
                            }));
                        }

                        // Create Red Flag API Call
                        if (values.addToBlockList || values.addToWatchList) {
                            await dispatch(createRedFlag({
                                orgId,
                                redFlag: {
                                    level: values.addToBlockList ? "1" : "2",
                                    effectiveDate: dayjs().format(DATE_TIME_AM_PM_FORMAT),
                                    visitors: touchedValues.visitorsCheckbox.map((visitorId: number) => {
                                        const visitor = selectedVisit?.visitors.find(v => Number(v.id) === Number(visitorId));
                                        return {
                                            firstName: visitor?.firstName,
                                            lastName: visitor?.lastName,
                                            middleName: visitor?.middleName,
                                        };
                                    })
                                },
                            }));
                        }

                        if (touchedValues.detail !== undefined && globalUserId) {
                            await dispatch(createVisitNote({
                                orgId,
                                visitId: selectedVisit?.id!,
                                visitNote: {
                                    logUserId: globalUserId,
                                    log: values.detail
                                }
                            }));
                        }
                    } catch (error) {
                        console.error("Error in denyEntry or createRedFlag:", error);
                    }
                }
            }
            form.resetFields();
        },
        [updateVisitorStatus, updateVisitStatus, form, newStatus, dispatch, orgId, selectedVisit, globalUserId]
    );

    const onOkHandler = async () => {
        const selectedVisitors = form.getFieldValue('visitorsCheckbox') || [];
        if (selectedVisitors.length === 0) {
            notification.warning({
                message: 'No Visitors Selected',
                description: 'Please select at least one visitor to proceed.',
                placement: 'bottomRight',
            });
            return;
        }
        form.submit();
        onClose();
    };

    const getTitle = () => {
        switch (newStatus) {
            case Number(STATUS.SIGNED_IN.id):
                return 'Select Visitors to Sign In';
            case Number(STATUS.SIGNED_OUT.id):
                return 'Select Visitors to Sign Out';
            case Number(STATUS.DENIED_ENTRY.id):
                return 'Deny Entry';
            default:
                return '';
        }
    };

    const handleBlockListChange = (checked: boolean) => {
        setAddToBlockList(checked);
        if (checked) {
            setAddToWatchList(false);
        }
    };

    const handleWatchListChange = (checked: boolean) => {
        setAddToWatchList(checked);
        if (checked) {
            setAddToBlockList(false);
        }
    };

    const confirmModal = useConfirmModal();

    const finalOnCancel = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (form.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    form.resetFields();
                    setAddToBlockList(false);
                    setAddToWatchList(false);
                    onClose();
                },
            });
        } else {
            setAddToBlockList(false);
            setAddToWatchList(false);
            onClose();
        }
    };

    return (
        <OpModal
            title={getTitle()}
            open={open}
            onOk={onOkHandler}
            onCancel={finalOnCancel}
            centered
        >
            <OpDivider />

            <OpForm
                form={form}
                onSubmit={handleSubmit}
                hasError={false}
                defaultButtons={false}
                initialValues={initialValues}
            >
                <OpForm.Item name="visitorsCheckbox">
                    <Checkbox.Group style={{ width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {visitorOptions.map(option => (
                                <div key={option.value} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '100%' }}>
                                    <Checkbox value={option.value} style={{ marginRight: '8px' }}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            {profileIcon({
                                                firstName: option.firstName || "",
                                                lastName: option.lastName || "",
                                                size: 28,
                                                color: option.color
                                            })}
                                            <span style={{ marginLeft: '8px' }}>{option.label}</span>
                                        </span>
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </Checkbox.Group>
                </OpForm.Item>

                {newStatus === Number(STATUS.DENIED_ENTRY.id) && (
                    <>
                        <OpForm.Select
                            label="Reason For Denial" name="reasonForDenial"
                            options={denyReasons.data.map(reason => ({
                                label: reason.name,
                                value: reason.id
                            }))}
                        />

                        <OpForm.TextAreaInput label="Details" name="detail" rows={4} />

                        {hasRedFlagWrite && (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <OpForm.Item name="addToBlockList" style={{ marginBottom: 0 }}>
                                        <OpSwitch checked={addToBlockList} onChange={handleBlockListChange} />
                                    </OpForm.Item>
                                    <span style={{ marginLeft: 8 }}>Add to Blocklist</span>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <OpForm.Item name="addToWatchList" style={{ marginBottom: 0 }}>
                                        <OpSwitch checked={addToWatchList} onChange={handleWatchListChange} />
                                    </OpForm.Item>
                                    <span style={{ marginLeft: 8 }}>Add to Watchlist</span>
                                </div>
                            </>
                        )}
                    </>
                )}
            </OpForm>
        </OpModal>
    );
};

export default VisitorsModal;
