import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiErrorResponse, ApiResponse } from 'types/apiTypes';
import { VisitorSearch } from 'types/visitorSearchTypes';

interface VisitorSearchState {
    visitors: ApiResponse<VisitorSearch>;
    searchVisitorsLoading: boolean;
    error: string | null;
}

const initialState: VisitorSearchState = {
    visitors: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    searchVisitorsLoading: false,
    error: null,
};

const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const searchVisitors = createAsyncThunk<ApiResponse<VisitorSearch>, { orgId: number; siteId: number; searchString: string }, { rejectValue: string }>(
    'visitorSearch/searchVisitors',
    async ({ orgId, siteId, searchString }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorSearch>>(
                `${apiUrl}/orgs/${orgId}/search/visitors`,
                {
                    params: { siteId, searchString },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        Accept: 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to search visitors');
        }
    }
);

const visitorSearchSlice = createSlice({
    name: 'visitorSearch',
    initialState,
    reducers: {
        clearVisitors(state) {
            state.visitors = initialState.visitors;
        },
        clearVisitorSearchState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchVisitors.pending, (state) => {
                state.searchVisitorsLoading = true;
                state.error = null;
            })
            .addCase(searchVisitors.fulfilled, (state, action) => {
                state.searchVisitorsLoading = false;
                state.visitors = action.payload;
            })
            .addCase(searchVisitors.rejected, (state, action) => {
                state.searchVisitorsLoading = false;
                state.error = action.payload || 'Failed to search visitors';
            });
    },
});

export const { clearVisitors, clearVisitorSearchState } = visitorSearchSlice.actions;
export default visitorSearchSlice.reducer;
