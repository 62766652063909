import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';

// Import actions and types from Redux slices
import { RootState, AppDispatch } from 'store/store';

// Import utility functions
import { getStatusNameById, getStatusColor } from 'utils/visitorsHelper';

// Import constants
import { DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';

import { formatFullName, hasPermission } from 'utils/utils'
import { describeVisit } from 'store/slices/visitsSlice';

interface VisitorSearchTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const VisitorSearchTable: React.FC<VisitorSearchTableProps> = ({ setDrawerVisible }) => {
    // Redux hooks
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const { visitors, searchVisitorsLoading } = useSelector((state: RootState) => state.visitorSearch);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    // Format visitors data
    const formattedVisitors = visitors.data
        .filter(visitor => hasAllvisitorsRead || globalUserId === visitor.hostUserId)
        .map(visitor => ({
            id: visitor.id,
            fullName: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
            dateTime: visitor.timestamp ? dayjs(visitor.timestamp).format(DATE_TIME_AM_PM_FORMAT) : null,
            email: visitor.email,
            mobilePhone: visitor.mobilePhone,
            company: visitor.company,
            status: getStatusNameById(visitor.status, visitor.timestamp),
            timestamp: visitor.timestamp,
            visitId: visitor.visitId,
            visitStatus: visitor.visitStatus,
            key: `${visitor.id}-${visitor.visitId}`, // Create a unique key by combining visitor.id and visitId
        }))
        .sort((a, b) => dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix()); // Sort by timestamp

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => (a.fullName || '').localeCompare(b.fullName || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'email',
            label: 'Email',
            filter: { type: 'input' },
            sorter: (a, b) => (a.email || '').localeCompare(b.email || ''), // Alphabetical sorting with null check
            hidden: true
        },
        {
            dataIndex: 'company',
            label: 'Company',
            filter: { type: 'input' },
            sorter: (a, b) => (a.company || '').localeCompare(b.company || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'status',
            label: 'Status',
            filter: {
                type: 'multiSelect',
                options: [
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Signed In', value: 'Signed In' },
                    { label: 'Signed Out', value: 'Signed Out' },
                    { label: 'Denied Entry', value: 'Denied Entry' },
                    { label: 'No Show', value: 'No Show' },
                ],
            },
            defaultFilteredValue: ['Pending', 'Signed In', 'Signed Out', 'Denied Entry', 'No Show'],
            sorter: (a, b) => (a.status || '').localeCompare(b.status || ''), // Alphabetical sorting
            render: (statusName) => {
                const color = getStatusColor(statusName);
                return <div style={{ backgroundColor: color, padding: '5px 10px', borderRadius: '4px', color: 'white' }}>{statusName}</div>;
            }
        },
        {
            dataIndex: 'dateTime',
            label: "Date & Time",
            sorter: (a, b) => dayjs(a.dateTime, DATE_TIME_AM_PM_FORMAT).unix() - dayjs(b.dateTime, DATE_TIME_AM_PM_FORMAT).unix(),
        },
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formattedVisitors,
        label: "Search Results",
        columns: columns,
        rowActions: {
            onEditClick: (visit: any) => {
                dispatch(describeVisit({ orgId, visitId: visit.visitId }));
                setDrawerVisible(true);
            }
        },
        height: TABLE_HEIGHT,
        loading: searchVisitorsLoading,
        allowGlobalSearch: false,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: 'key', // Use the unique key
    };

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <OpTable {...opTableProps} />
        </OpSpace>
    );
}

export default VisitorSearchTable;
