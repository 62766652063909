import React, { useEffect, useState } from 'react';
import { Modal, Select, Button, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import STATUS from 'constants/status';
import dayjs from 'dayjs';
import { formatFullName, hasPermission } from 'utils/utils';
import { describeVisit, fetchVisits } from 'store/slices/visitsSlice';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';

interface SelectVisitorComponentProps {
    open: boolean;
    onClose: () => void;
    openVisitorsDrawer: () => void;
    openEnterManually: () => void;
}

interface OptionType {
    value: string;
    label: string;
    key: string;
    style?: React.CSSProperties;
}

const SignIn: React.FC<SelectVisitorComponentProps> = ({ open, onClose, openVisitorsDrawer, openEnterManually }) => {
    const visits = useSelector((state: RootState) => state.visits.visits);
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const { globalLocation } = useSelector((state: RootState) => state.locations);
    const [selectedVisitorName, setSelectedVisitorName] = useState<string>('');
    const [selectedVisitId, setSelectedVisitId] = useState<string>('');
    const [options, setOptions] = useState<OptionType[]>([]);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    useEffect(() => {
        if (visits.data.length === 0) {
            dispatch(fetchVisits({ orgId }));
        }

        const filteredVisits = visits.data.filter(visit =>
            visit.site?.id === globalLocation?.id &&
            (hasAllvisitorsWrite || globalUserId === visit.host.userId) &&
            dayjs(visit.scheduleStart).isSame(dayjs(), 'day') &&
            visit.visitors.some(visitor =>
                visitor.status === STATUS.PENDING.id
            )
        );

        const newOptions: OptionType[] = filteredVisits.flatMap(visit =>
            visit.visitors
                .filter(visitor => visitor.status === STATUS.PENDING.id)
                .map(visitor => ({
                    value: `${visit.id}-${visitor.id}`,
                    label: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
                    key: `${visit.id}-${visitor.id}`
                }))
        );

        if (newOptions.length > 0) {
            newOptions.push({
                value: 'enter-manually',
                label: 'Add New Visitor',
                key: 'enter-manually',
                style: {
                    color: 'var(--colorPrimary)',
                }
            });

        }

        setOptions(newOptions);
        // eslint-disable-next-line
    }, [visits.data]);

    const resetSelection = () => {
        setSelectedVisitId('');
        setSelectedVisitorName('');
    };

    const handleVisitorChange = (value: string, option: any) => {
        setSelectedVisitId(value);
        setSelectedVisitorName(option.label);

        if (value === 'enter-manually') {
            openEnterManually();
        } else {
            const visitId = Number(value.split('-')[0]);
            dispatch(describeVisit({ orgId, visitId }));
            openVisitorsDrawer();
        }
        onClose();
        resetSelection();
    };

    return (
        <>
            <Modal
                title="Visitor to Sign In"
                open={open}
                onCancel={() => {
                    onClose();
                    resetSelection();
                }}
                footer={null}
                centered
            >
                <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '10px' }}>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select visitor to sign in"
                        onChange={handleVisitorChange}
                        value={selectedVisitorName || selectedVisitId}
                        filterOption={(input, option) =>
                            option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        notFoundContent={
                            <Empty description="No visitors found">
                                <Button
                                    type="primary"
                                    style={{ width: '100%' }}
                                    onClick={openEnterManually}
                                >
                                    Add New Visitor
                                </Button>
                            </Empty>
                        }
                        options={options}
                    />
                </OpSpace>
            </Modal>
        </>
    );
};

export default SignIn;
