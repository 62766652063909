import { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import Form from 'antd/es/form';
import { t as translate } from 'i18next';
import clsx from 'clsx';
import Joi from 'joi';
import { Store } from 'antd/es/form/interface';
import { OpFormContext } from './OpFormContext';
import { FormButtons } from './FormButtons';
import { OpFormInput as Input } from './formComponents/OpFormInput/OpFormInput';
import { OpFormInputNumber as InputNumber } from './formComponents/OpFormInputNumber/OpFormInputNumber';
import { OpFormPasswordInput as PasswordInput } from './formComponents/OpFormPasswordInput/OpFormPasswordInput';
import { OpFormSearchInput as SearchInput } from './formComponents/OpFormSearchInput/OpFormSearchInput';
import { OpFormTextAreaInput as TextAreaInput } from './formComponents/OpFormTextAreaInput/OpFormTextAreaInput';
import { OpFormPhoneInput as PhoneInput } from './formComponents/OpFormPhoneInput/OpFormPhoneInput';
import { OpFormButton as Button } from './formComponents/OpFormButton/OpFormButton';
import { OpFormDatePicker as DatePicker } from './formComponents/OpFormDatePicker/OpFormDatePicker';
import { OpFormTimePicker as TimePicker } from './formComponents/OpFormTimePicker/OpFormTimePicker';
import { OpFormTagInput as TagInput } from './formComponents/OpFormTagInput/OpFormTagInput';
import { OpFormTextButton as TextButton } from './formComponents/OpFormTextButton/OpFormTextButton';
import { OpFormSelect as Select } from './formComponents/OpFormSelect/OpFormSelect';
import { OpFormSwitch as Switch } from './formComponents/OpFormSwitch/OpFormSwitch';
import { OpFormImage as Image } from './formComponents/OpFormImage/OpFormImage';
import { OpFormCheckbox as Checkbox } from './formComponents/OpFormCheckbox/OpFormCheckbox';
import { OpFormCheckboxGroup as CheckboxGroup } from './formComponents/OpFormCheckboxGroup/OpFormCheckboxGroup';
import { OpFormRadio as Radio } from './formComponents/OpFormRadio/OpFormRadio';
import { OpFormItem as Item } from './formComponents/OpFormItem/OpFormItem';
import { OpFormRangePicker as RangePicker } from './formComponents/OpFormRangePicker/OpFormRangePicker';
import { OpFormDataFetchSelect as DataFetchSelect } from './formComponents/OpFormDataFetchSelect/OpFormDataFetchSelect';
import { OpFormDataFetchTransfer as DataFetchTransfer } from './formComponents/OpFormDataFetchTransfer/OpFormDataFetchTransfer';
import { OpFormTimezonePicker as TimezonePicker } from './formComponents/OpFormTimezonePicker/OpFormTimezonePicker';
import { OpFormCodeEditor as CodeEditor } from './formComponents/OpFormCodeEditor/OpFormCodeEditor';
import { OpFormSlider as Slider } from './formComponents/OpFormSlider/OpFormSlider';
import { OpFormColorPicker as ColorPicker } from './formComponents/OpFormColorPicker/OpFormColorPicker';
import { OpFormVideoRegionSelect as VideoRegionSelect } from './formComponents/OpFormVideoRegionSelect/OpFormVideoRegionSelect';
import { replaceEmptyWithNull } from './helpers/replaceEmptyWithNull';

import './OpForm.scss';

const hasErrorPropValidator = Joi.boolean().required();

export interface IOnSubmitArgs<T extends Store = Store> {
  values: T;
  initialValues?: Store;
  touchedValues: Partial<T>;
}

export type FormOnSubmitType<T extends Store = Store> = (
  args: IOnSubmitArgs<T>,
) => void;

export interface IOpFormProps<T extends Store = Store>
  extends ComponentProps<typeof Form<T>> {
  isLoading?: boolean;
  isReadOnly?: boolean;
  onSubmit: FormOnSubmitType<T>;
  testId?: string;
  /**
   * This prop is here to force the developer to think about any dependency the form relies on that
   * if falsy should not allow you to use the form. For example if there are api calls needed
   * to properly populate the form and one fails, then the form will be in an unreliable state and thus
   * shouldn't be allowed to be use able
   */
  hasError: boolean;
  defaultButtons?:
  | false
  | {
    submitButtonLabel?: string;
    submitButtonIsLoading?: boolean;
  };
  shouldWarnChangesBeforeUnload?: boolean;
}

export const OpForm = <T extends Store = Store>({
  className,
  isLoading = false,
  isReadOnly = false,
  initialValues,
  onSubmit,
  children,
  layout = 'vertical',
  testId = 'op-form',
  form: propsFormRef,
  shouldWarnChangesBeforeUnload = true,
  hasError,

  // When false the submit and reset buttons are hidden
  defaultButtons = {
    submitButtonLabel: translate('Save'),
    submitButtonIsLoading: false,
  },
  ...formProps
}: IOpFormProps<T>) => {
  // Create Form instance to maintain data store
  const [internalFormRef] = Form.useForm<T>();

  // Use either the internal form instance or the instance passed in via props
  const form = propsFormRef || internalFormRef;

  // This is helpful for non Typescript files to keep consistent validation that this prop has been passed
  const { error: hasErrorValidationError } =
    hasErrorPropValidator.validate(hasError);

  /** This is so that if a change in the form occurs browser tabs can't be closed or
   * reloaded without a confirmation prompt. NOTE: we don't need to remove the listener
   * after form submittal as the fields will revert back to being untouched */
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (form.isFieldsTouched()) {
        /** Most modern browsers no longer accept a custom message, but a truthy value
         * is needed in general so the default prompt displays */
        const confirmationMessage =
          'You have unsaved changes. Are you sure you want to leave?';
        // eslint-disable-next-line no-param-reassign
        event.returnValue = confirmationMessage;
      }
    },
    [form],
  );

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      shouldWarnChangesBeforeUnload
    ) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload, shouldWarnChangesBeforeUnload]);

  /**  When initialValues changes (data source is API endpoint data) we reset
   * the fields so the new data is used
   * (Ref: https://github.com/ant-design/ant-design/issues/22372)
   *
   * Note that we are stringifying the initial values in the dependency array so that the
   * initial values are not required to be memoized when passed in to OpForm. Attempts to
   * ensure memoization through typing were not successful. The only issue we could potentially
   * run into is if somehow the initial values were the same but the order was different.
   * In this case nothing would break, but there would be a small glitch where the values would
   * quickly jump from old to new.
   *
   * One thing to note is that by doing this we lose form value changes when initialValues change
   * (e.g. when you close a drawer with a form in it). */
  const stringifiedInitialValues = JSON.stringify(initialValues);
  useEffect(() => {
    form.resetFields();
  }, [form, handleBeforeUnload, stringifiedInitialValues]);

  const onFinish = (values: T) => {
    /** These are only the values that have been "touched" meaning the values have been
     * interacted with. This doesn't necessarily mean changed, as the user could add a
     * letter and then remove it, and it would still be considered touched.
     * We are also transforming any values with empty strings to null as Helium only
     * accepts null at this time. */
    const rawTouchedValues = form.getFieldsValue(
      true,
      ({ touched }) => touched,
    );

    const touchedValues = replaceEmptyWithNull(rawTouchedValues);
    return onSubmit({
      values, // Current values
      touchedValues, // Values that have been "touched" (see note above)
      initialValues, // Values before any changes are made to the form
    });
  };

  const context = useMemo(
    () => ({
      isDataLoading: isLoading,
      isReadOnly: Boolean(isReadOnly || hasError || hasErrorValidationError),
    }),
    [isLoading, isReadOnly, hasError, hasErrorValidationError],
  );

  return (
    <Form<T>
      form={form}
      initialValues={initialValues}
      layout={layout}
      onFinish={onFinish}
      className={clsx('op-form', className)}
      data-testid={testId}
      scrollToFirstError
      {...formProps}
    >
      <OpFormContext.Provider value={context}>
        <>
          {children}

          {/** Default submit and reset buttons */}
          {defaultButtons && !isReadOnly && (
            <FormButtons
              form={form}
              isFormLoading={isLoading}
              isSubmitButtonLoading={defaultButtons.submitButtonIsLoading}
              submitButtonLabel={defaultButtons.submitButtonLabel}
            />
          )}
        </>
      </OpFormContext.Provider>
    </Form>
  );
};

// Allow for useForm and useWatch to be available as a property on the wrapper component
OpForm.useForm = Form.useForm;
OpForm.useWatch = Form.useWatch;

// Make the sub components available as a property on the wrapper component
OpForm.Input = Input;
OpForm.InputNumber = InputNumber;
OpForm.PhoneInput = PhoneInput;
OpForm.Button = Button;
OpForm.TagInput = TagInput;
OpForm.DatePicker = DatePicker;
OpForm.TimePicker = TimePicker;
OpForm.TextButton = TextButton;
OpForm.PasswordInput = PasswordInput;
OpForm.SearchInput = SearchInput;
OpForm.TextAreaInput = TextAreaInput;
OpForm.Select = Select;
OpForm.Switch = Switch;
OpForm.Image = Image;
OpForm.Checkbox = Checkbox;
OpForm.CheckboxGroup = CheckboxGroup;
OpForm.Radio = Radio;
OpForm.Item = Item;
OpForm.RangePicker = RangePicker;
OpForm.DataFetchSelect = DataFetchSelect;
OpForm.DataFetchTransfer = DataFetchTransfer;
OpForm.TimezonePicker = TimezonePicker;
OpForm.CodeEditor = CodeEditor;
OpForm.Slider = Slider;
OpForm.ColorPicker = ColorPicker;
OpForm.VideoRegionSelect = VideoRegionSelect;
