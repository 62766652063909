import React from 'react';
import { Space, Statistic } from "antd";
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';

interface RedFlagCardProps {
    title: string;
    value: number;
    icon: React.ReactNode;
    color: string;
}

const RedFlagCard: React.FC<RedFlagCardProps> = ({ title, value, icon, color }) => {
    return (
        <OpCard
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: color,
            }}
        >
            <Space align="center" style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    {icon}
                    <Statistic title={title} value={value} valueStyle={{ fontSize: '40px' }} />
                </div>
            </Space>
        </OpCard>
    );
}

export default RedFlagCard;
