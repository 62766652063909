import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { UserActivity, UserActivityType, logUserActivityPayload } from 'types/userActivityTypes';

interface UserActivityState {
    activities: ApiResponse<UserActivity>;
    selectedActivity: UserActivity | null;
    activityTypes: ApiResponse<UserActivityType>;
    fetchUserActivitiesLoading: boolean;
    fetchUserActivityTypesLoading: boolean;
    logUserActivityLoading: boolean;
    error: string | null;
}

const initialState: UserActivityState = {
    activities: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    selectedActivity: null,
    activityTypes: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchUserActivitiesLoading: false,
    fetchUserActivityTypesLoading: false,
    logUserActivityLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchUserActivities = createAsyncThunk<ApiResponse<UserActivity>, { orgId: number; startDate?: string; endDate?: string }, { rejectValue: string }>(
    'userActivity/fetchUserActivities',
    async ({ orgId, startDate, endDate }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<UserActivity>>(`${apiUrl}/orgs/${orgId}/userActivity`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    startDate,
                    endDate
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch user activities');
        }
    }
);

export const logUserActivity = createAsyncThunk<void, { orgId: number; userActivity: logUserActivityPayload; }, { rejectValue: string }>(
    'userActivity/logUserActivity',
    async ({ orgId, userActivity }, { rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/userActivity`, userActivity, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to log user activity');
        }
    }
);

export const fetchUserActivityTypes = createAsyncThunk<ApiResponse<UserActivityType>, { orgId: number }, { rejectValue: string }>(
    'userActivity/fetchUserActivityTypes',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<UserActivityType>>(`${apiUrl}/orgs/${orgId}/userActivityType`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch user activity types');
        }
    }
);

const userActivitySlice = createSlice({
    name: 'userActivity',
    initialState,
    reducers: {
        describeUserActivity(state, action: PayloadAction<UserActivity>) {
            state.selectedActivity = action.payload;
        },
        clearUserActivities(state) {
            state.activities = initialState.activities;
        },
        clearActivityTypes(state) {
            state.activityTypes = initialState.activityTypes;
        },
        clearUserActivityState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserActivities.pending, (state) => {
                state.fetchUserActivitiesLoading = true;
                state.error = null;
            })
            .addCase(fetchUserActivities.fulfilled, (state, action) => {
                state.fetchUserActivitiesLoading = false;
                state.activities = action.payload;
            })
            .addCase(fetchUserActivities.rejected, (state, action) => {
                state.fetchUserActivitiesLoading = false;
                state.error = action.payload || 'Failed to fetch user activities';
            })
            .addCase(logUserActivity.pending, (state) => {
                state.logUserActivityLoading = true;
                state.error = null;
            })
            .addCase(logUserActivity.fulfilled, (state) => {
                state.logUserActivityLoading = false;
            })
            .addCase(logUserActivity.rejected, (state, action) => {
                state.logUserActivityLoading = false;
                state.error = action.payload || 'Failed to log user activity';
            })
            .addCase(fetchUserActivityTypes.pending, (state) => {
                state.fetchUserActivityTypesLoading = true;
                state.error = null;
            })
            .addCase(fetchUserActivityTypes.fulfilled, (state, action) => {
                state.fetchUserActivityTypesLoading = false;
                state.activityTypes = action.payload;
            })
            .addCase(fetchUserActivityTypes.rejected, (state, action) => {
                state.fetchUserActivityTypesLoading = false;
                state.error = action.payload || 'Failed to fetch user activity types';
            });
    },
});

export const { describeUserActivity, clearUserActivities, clearActivityTypes, clearUserActivityState } = userActivitySlice.actions;
export default userActivitySlice.reducer;
