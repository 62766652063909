import React, { useMemo } from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";
import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/dates';

interface ActivityLogContentProps {
    form: any;
    tab: string;
}

const ActivityLogContent: React.FC<ActivityLogContentProps> = ({ form, tab }) => {
    const selectedActivity = useSelector((state: RootState) => state.userActivity.selectedActivity);

    const initialValues = useMemo(() => {
        return {
            activity: selectedActivity?.activityType,
            user: selectedActivity?.user,
            date: dayjs(selectedActivity?.updatedAt).startOf('day'),
            time: dayjs(selectedActivity?.updatedAt).local(),
            activityDetails: selectedActivity?.activityDetails
        };
    }, [selectedActivity]);


    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={() => console.log()}
            hasError={false}
            defaultButtons={false}
            isReadOnly={true}
        >
            <OpForm.Input label="Activity" name="activity" />

            <OpForm.Input label="User" name="user" />

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.DatePicker name="date" label="Date" format={DATE_FORMAT} style={{ width: '100%' }} disabled />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.TimePicker name="time" label="Time"
                        format="h:mm A"
                        use12Hours
                        minuteStep={5}
                        needConfirm={false}
                        style={{ width: '100%' }}
                        disabled
                    />
                </OpCol>
            </OpRow>
            <OpForm.TextAreaInput readOnly rows={4} label="Activity Details" name="activityDetails" />
        </OpForm >
    );
};

export default ActivityLogContent;
