import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import QuickActionCard from './QuickActionCard';
import { MailOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermission } from 'utils/utils';
import { describeVisitorWorkflowConfig } from 'store/slices/visitorWorkflowsSlice';
import SignInMethodModal from '../../pageContent/ActionFloatButtons/signIn/SignInMethodModal';
import InvitationDrawer from '../../pageContent/ActionFloatButtons/invite/InvitationDrawer';
import SignIn from '../../pageContent/ActionFloatButtons/signIn/SignIn';
import ScanIdComponent from '../../pageContent/ActionFloatButtons/signIn/ScanIdComponent';
import SignOut from '../../pageContent/ActionFloatButtons/signOut/SignOut';
import EnterManuallyComponent from 'components/layout/pageContent/ActionFloatButtons/signIn/EnterManuallyComponent';
import VisitorsDrawer from 'components/pages/visitors/VisitorsDrawer';

interface QuickActionsModalProps {
    visible: boolean;
    onClose: () => void;
}

type QuickAction = 'SIGN_IN' | 'SIGN_OUT' | 'NEW_INVITATION';

const QuickActionsModal: React.FC<QuickActionsModalProps> = ({ visible, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { loadingCreateInvitation } = useSelector((state: RootState) => state.visitorInvitation);
    const { globalLocationWorkflow } = useSelector((state: RootState) => state.locations);
    const { visitorWorkflowConfig } = useSelector((state: RootState) => state.visitorWorkflows);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);

    const [isSignInMethodModalOpen, setIsSignInMethodModalOpen] = useState<boolean>(false);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
    const [isScanIdModalOpen, setIsScanIdModalOpen] = useState<boolean>(false);
    const [isSignOutModalOpen, setIsSignOutModalOpen] = useState<boolean>(false);
    const [isNewInvitationDrawerOpen, setIsNewInvitationDrawerOpen] = useState<boolean>(false);
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);
    const [isEnterManuallyModalOpen, setIsEnterManuallyModalOpen] = useState<boolean>(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');

    useEffect(() => {
        if (globalLocationWorkflow && globalLocationWorkflow.SignInWorkflowId) {
            dispatch(describeVisitorWorkflowConfig({ orgId, visitorWorkflowId: globalLocationWorkflow.SignInWorkflowId }));
        }
    }, [dispatch, orgId, globalLocationWorkflow]);

    const handleSignInMethodChange = (method: string) => {
        setIsSignInMethodModalOpen(false);
        if (method === 'scanId') {
            setIsScanIdModalOpen(true);
        } else if (method === 'selectVisitor') {
            setIsSignInModalOpen(true);
        }
    };

    const handleFloatButtonClick = (type: QuickAction) => {
        if (type === 'SIGN_IN') {
            if (visitorWorkflowConfig) {
                if (visitorWorkflowConfig.scan) {
                    setIsSignInMethodModalOpen(true);
                } else {
                    setIsSignInModalOpen(true);
                }
            }
        } else if (type === 'SIGN_OUT') {
            setIsSignOutModalOpen(true);
        } else if (type === 'NEW_INVITATION') {
            setIsNewInvitationDrawerOpen(true);
        }
        onClose();
    };

    return (
        <>
            <Modal
                title="Quick Actions"
                open={visible}
                onCancel={onClose}
                footer={null}
                centered
                width={600}
            >
                <OpRow gutter={[16, 0]} wrap={false} justify="center">
                    {hasInviteWrite && (invitationConfig.data[0] && invitationConfig.data[0].allowInvitations) && (
                        <OpCol span={8}>
                            <QuickActionCard
                                icon={
                                    <MailOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(19,151,213,1.0)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="SCHEDULE VISITOR"
                                color="rgba(19,151,213,0.1)"
                                onClick={() => handleFloatButtonClick('NEW_INVITATION')}
                            />
                        </OpCol>
                    )}
                    {hasSigninWrite && (
                        <OpCol span={8}>
                            <QuickActionCard
                                icon={
                                    <LoginOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(109,152,138,1.0)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="SIGN IN VISITOR"
                                color="rgba(109,152,138,0.1)"
                                onClick={() => handleFloatButtonClick('SIGN_IN')}
                            />
                        </OpCol>
                    )}
                    {hasSignoutWrite && (
                        <OpCol span={8}>
                            <QuickActionCard
                                icon={
                                    <LogoutOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(155,154,154,1.0)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="SIGN OUT VISITOR"
                                color="rgba(155,154,154,0.1)"
                                onClick={() => handleFloatButtonClick('SIGN_OUT')}
                            />
                        </OpCol>
                    )}
                </OpRow>
            </Modal>

            <SignInMethodModal
                open={isSignInMethodModalOpen}
                onClose={() => setIsSignInMethodModalOpen(false)}
                handleSignInMethodChange={handleSignInMethodChange}
                visitorWorkflowConfig={visitorWorkflowConfig}
            />
            {isSignInModalOpen && (
                <SignIn
                    open={isSignInModalOpen}
                    onClose={() => setIsSignInModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openEnterManually={() => setIsEnterManuallyModalOpen(true)}
                />
            )}
            {isScanIdModalOpen && (
                <ScanIdComponent
                    open={isScanIdModalOpen}
                    onClose={() => setIsScanIdModalOpen(false)}
                />
            )}
            {isSignOutModalOpen && (
                <SignOut
                    isSignOutModalOpen={isSignOutModalOpen}
                    setIsSignOutModalOpen={setIsSignOutModalOpen}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                />
            )}
            <InvitationDrawer
                open={isNewInvitationDrawerOpen}
                onClose={() => setIsNewInvitationDrawerOpen(false)}
                loading={loadingCreateInvitation}
            />
            {isEnterManuallyModalOpen && (
                <EnterManuallyComponent
                    open={isEnterManuallyModalOpen}
                    onClose={() => setIsEnterManuallyModalOpen(false)}
                />
            )}
            {isVisitorsDrawerOpen && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    onClose={() => setIsVisitorsDrawerOpen(false)}
                />
            )}
        </>
    );
};

export default QuickActionsModal;
