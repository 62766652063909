import React from 'react';
import { StopOutlined, EyeOutlined } from "@ant-design/icons";
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import RedFlagReportCard from './RedFlagReportCard';
import { RedFlag } from 'types/redFlagTypes';
import { Visitor } from 'types/visitTypes';

interface RedFlagReportSummaryProps {
    deniedVisitors: Visitor[];
    blockList: RedFlag[];
    watchList: RedFlag[];
    onDeniedClick: () => void;
    onBlockListClick: () => void;
    onWatchListClick: () => void;
}

const RedFlagReportSummary: React.FC<RedFlagReportSummaryProps> = ({ deniedVisitors, blockList, watchList, onDeniedClick, onBlockListClick, onWatchListClick }) => {
    return (
        <OpSpace
            direction="vertical"
            size="middle"
            style={{ display: 'flex' }}
        >
            <OpCard type="inner" title="Summary">
                <OpRow justify="space-evenly">
                    <OpCol span={8} style={{ padding: '30px' }}>
                        <RedFlagReportCard
                            icon={
                                <StopOutlined
                                    style={{
                                        color: "white",
                                        backgroundColor: "rgba(242,103,87,1.0)",
                                        borderRadius: 20,
                                        fontSize: 24,
                                        padding: 8,
                                    }}
                                />
                            }
                            title="DENIED"
                            value={deniedVisitors.length}
                            color="rgba(242,103,87,0.1)"
                            onClick={onDeniedClick}
                        />
                    </OpCol>
                    <OpCol span={6} style={{ padding: '30px' }}>
                        <RedFlagReportCard
                            icon={
                                <StopOutlined
                                    style={{
                                        color: "white",
                                        backgroundColor: "rgba(242,103,87,1.0)",
                                        borderRadius: 20,
                                        fontSize: 24,
                                        padding: 8,
                                    }}
                                />
                            }
                            title="ADDED TO BLOCKLIST"
                            value={blockList.length}
                            color="rgba(242,103,87,0.1)"
                            onClick={onBlockListClick}
                        />
                    </OpCol>
                    <OpCol span={6} style={{ padding: '30px' }}>
                        <RedFlagReportCard
                            icon={
                                <EyeOutlined
                                    style={{
                                        color: "white",
                                        backgroundColor: "#FFD54F",
                                        borderRadius: 20,
                                        fontSize: 24,
                                        padding: 8,
                                    }}
                                />
                            }
                            title="ADDED TO WATCHLIST"
                            value={watchList.length}
                            color="rgba(255, 213, 79, 0.1)"
                            onClick={onWatchListClick}
                        />
                    </OpCol>
                </OpRow>
            </OpCard>
        </OpSpace>

    );
}

export default RedFlagReportSummary;
