import { useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { ApiResponse } from '../../types/apiTypes';

export interface IUseOpQueryOptions<T> {
    apiEndpointName: string;
    parameters: Record<string, any>;
    getAll?: boolean;
    placeholder?: T;
}

async function fetchData<T>(options: IUseOpQueryOptions<T>, cancelToken: CancelTokenSource): Promise<ApiResponse<T> | undefined> {
    const url = `${options.apiEndpointName}`;
    try {
        const response = await axios.get<ApiResponse<T>>(url, {
            headers: {
                'Authorization': options.parameters['Authorization'],
                'Accept': options.parameters['Accept']
            },
            params: options.parameters,
            cancelToken: cancelToken.token
        });
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
            return undefined;
        } else {
            console.error('Failed to fetch data', error);
            throw new Error('Failed to fetch data');
        }
    }
}

interface UseOpQueryResult<T> {
    data: ApiResponse<T> | null;
    isFetching: boolean;
    error: Error | null;
}

export function useOpQuery<T>(options: IUseOpQueryOptions<T>, deps: React.DependencyList = []): UseOpQueryResult<T> {
    const [data, setData] = useState<ApiResponse<T> | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (!options) return;

        const cancelTokenSource = axios.CancelToken.source();

        const fetchDataAsync = async () => {
            try {
                setIsFetching(true);
                const fetchedData = await fetchData<T>(options, cancelTokenSource);
                setData(fetchedData || null);
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsFetching(false);
            }
        };

        fetchDataAsync();

        return () => {
            cancelTokenSource.cancel('Operation canceled by the user.');
        };
    }, [options]);

    return { data, isFetching, error };
}
