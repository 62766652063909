import { OpInfoTooltip } from 'components/customAntd/DLS/OpInfoTooltip/OpInfoTooltip';
import {
  ColumnHeaderFilter,
  IColumnHeaderFilterProps,
} from './ColumnHeaderFilter';
import { OpTableRawColumnType } from '../OpTableCore';

import './ColumnHeader.scss';

export interface IColumnHeaderProps
  extends Pick<
      OpTableRawColumnType,
      'label' | 'tooltip' | 'defaultFilteredValue'
    >,
    IColumnHeaderFilterProps {
  hasHeaderFilter?: boolean;
}

export const ColumnHeader = ({
  label,
  tooltip,
  hasHeaderFilter,
  ...columnHeaderFilterProps
}: IColumnHeaderProps) => (
  <div
    className="column-header"
    /** Prevents click on this div (and its children) from firing
     * a click event on the header as that causes sorting to occur.
     * Need to add here as disabled elements do not fire the onClick
     * and we are using a disabled filter input at times */
    onClick={(e) => e?.stopPropagation?.()}
    aria-hidden
  >
    <div className="column-header__title">
      {label} {tooltip && <OpInfoTooltip title={tooltip} />}
    </div>
    {hasHeaderFilter && <ColumnHeaderFilter {...columnHeaderFilterProps} />}
  </div>
);
