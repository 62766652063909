import { ComponentProps, ReactNode } from 'react';
import Switch from 'antd/es/switch';
import clsx from 'clsx';
import { OpInfoTooltip } from '../OpInfoTooltip/OpInfoTooltip';

import './OpSwitch.scss';

interface IOpSwitchProps extends ComponentProps<typeof Switch> {
  testId?: string;
  label?: string;
  tooltip?: ReactNode;
}

export const OpSwitch = ({
  className,
  testId = 'op-switch',
  label,
  tooltip,
  ...switchProps
}: IOpSwitchProps) => {
  return (
    <div className="op-switch-container">
      <Switch
        className={clsx('op-switch', className)}
        data-testid={testId}
        {...switchProps}
      />
      {label && <span className="op-switch-container__label">{label}</span>}
      {tooltip && (
        <OpInfoTooltip
          className="op-switch-container__tooltip"
          title={tooltip}
        />
      )}
    </div>
  );
};
