import React, { useEffect, useState } from 'react';
import { FloatButton } from 'antd';
import { LoginOutlined, LogoutOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'store/store';
import { OpFloatButton } from 'components/customAntd/DLS/OpFloatButton/OpFloatButton';
import SignOut from './signOut/SignOut';
import ScanIdComponent from './signIn/ScanIdComponent';
import SignIn from './signIn/SignIn';
import { describeVisitorWorkflowConfig } from 'store/slices/visitorWorkflowsSlice';
import { hasPermission } from 'utils/utils';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';
import EnterManuallyComponent from './signIn/EnterManuallyComponent';
import VisitorsDrawer from 'components/pages/visitors/VisitorsDrawer';
import SignInMethodModal from './signIn/SignInMethodModal';
import InvitationDrawer from './invite/InvitationDrawer';

type FloatButtonsAction = 'SIGN_IN' | 'SIGN_OUT' | 'NEW_INVITATION';

const ActionFloatButtons: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { loadingCreateInvitation } = useSelector((state: RootState) => state.visitorInvitation);
    const { globalLocationWorkflow } = useSelector((state: RootState) => state.locations);
    const { visitorWorkflowConfig } = useSelector((state: RootState) => state.visitorWorkflows);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);

    const [isSignInMethodModalOpen, setIsSignInMethodModalOpen] = useState<boolean>(false);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
    const [isScanIdModalOpen, setIsScanIdModalOpen] = useState<boolean>(false);
    const [isSignOutModalOpen, setIsSignOutModalOpen] = useState<boolean>(false);
    const [isNewInvitationDrawerOpen, setIsNewInvitationDrawerOpen] = useState<boolean>(false);
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);
    const [isEnterManuallyModalOpen, setIsEnterManuallyModalOpen] = useState<boolean>(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');

    useEffect(() => {
        if (orgId && invitationConfig.data.length === 0) {
            dispatch(fetchInvitationConfig({ orgId }));
        }
    }, [dispatch, orgId, invitationConfig.data.length]);

    const handleFloatButtonClick = (type: FloatButtonsAction) => {
        if (type === 'SIGN_IN') {
            if (visitorWorkflowConfig) {
                if (visitorWorkflowConfig.scan) {
                    setIsSignInMethodModalOpen(true);
                } else {
                    setIsSignInModalOpen(true);
                }
            }
        } else if (type === 'SIGN_OUT') {
            setIsSignOutModalOpen(true);
        } else if (type === 'NEW_INVITATION') {
            setIsNewInvitationDrawerOpen(true);
        }
    };

    useEffect(() => {
        if (globalLocationWorkflow && globalLocationWorkflow.SignInWorkflowId) {
            dispatch(describeVisitorWorkflowConfig({ orgId, visitorWorkflowId: globalLocationWorkflow.SignInWorkflowId }));
        }
    }, [dispatch, orgId, globalLocationWorkflow]);

    const handleSignInMethodChange = (method: string) => {
        setIsSignInMethodModalOpen(false);
        if (method === 'scanId') {
            setIsScanIdModalOpen(true);
        } else if (method === 'selectVisitor') {
            setIsSignInModalOpen(true);
        }
    };

    return (
        <>
            {(hasSigninWrite || hasSignoutWrite || hasInviteWrite) && (
                <FloatButton.Group trigger="click" type="primary" style={{ left: 30, bottom: 24 }} icon={<PlusOutlined />}>
                    {hasSigninWrite && (
                        <OpFloatButton
                            tooltip={<div>SIGN IN VISITOR</div>}
                            icon={<LoginOutlined />}
                            style={{ background: "rgba(109,152,138,1.0)" }}
                            onClick={() => handleFloatButtonClick('SIGN_IN')}
                        />
                    )}
                    {hasSignoutWrite && (
                        <OpFloatButton
                            tooltip={<div>SIGN OUT VISITOR</div>}
                            icon={<LogoutOutlined />}
                            style={{ background: "rgba(155,154,154,1.0)" }}
                            onClick={() => handleFloatButtonClick('SIGN_OUT')}
                        />
                    )}
                    {hasInviteWrite && (invitationConfig.data[0] && invitationConfig.data[0].allowInvitations) && (
                        <OpFloatButton
                            tooltip={<div>SCHEDULE VISITOR</div>}
                            icon={<MailOutlined />}
                            style={{ backgroundColor: "rgba(19,151,213,1.0)" }}
                            onClick={() => handleFloatButtonClick('NEW_INVITATION')}
                        />
                    )}
                </FloatButton.Group>
            )}

            <SignInMethodModal
                open={isSignInMethodModalOpen}
                onClose={() => setIsSignInMethodModalOpen(false)}
                handleSignInMethodChange={handleSignInMethodChange}
                visitorWorkflowConfig={visitorWorkflowConfig}
            />
            {isSignInModalOpen && (
                <SignIn
                    open={isSignInModalOpen}
                    onClose={() => setIsSignInModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openEnterManually={() => setIsEnterManuallyModalOpen(true)}
                />
            )}
            {isScanIdModalOpen && (
                <ScanIdComponent
                    open={isScanIdModalOpen}
                    onClose={() => setIsScanIdModalOpen(false)}
                />
            )}
            {isSignOutModalOpen && (
                <SignOut
                    isSignOutModalOpen={isSignOutModalOpen}
                    setIsSignOutModalOpen={setIsSignOutModalOpen}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                />
            )}
            <InvitationDrawer
                open={isNewInvitationDrawerOpen}
                onClose={() => setIsNewInvitationDrawerOpen(false)}
                loading={loadingCreateInvitation}
            />
            {isEnterManuallyModalOpen && (
                <EnterManuallyComponent
                    open={isEnterManuallyModalOpen}
                    onClose={() => setIsEnterManuallyModalOpen(false)}
                />
            )}
            {isVisitorsDrawerOpen && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    onClose={() => setIsVisitorsDrawerOpen(false)}
                />
            )}
        </>
    );
};

export default ActionFloatButtons;
