import { ComponentProps, forwardRef, useContext } from 'react';
import clsx from 'clsx';
import { OpInputNumber } from 'components/customAntd/DLS/OpInputNumber/OpInputNumber';
import { OpSkeleton } from 'components/customAntd/DLS/OpSkeleton/OpSkeleton';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

interface IOpFormInputNumberProps
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      | 'name'
      | 'label'
      | 'rules'
      | 'tooltip'
      | 'validateTrigger'
      | 'validateStatus' // Temp: For SUIR wrapped version
      | 'help' // Temp: For SUIR wrapped version
      | 'required' // Temp: For SUIR wrapped version
    >,
    Omit<ComponentProps<typeof OpInputNumber>, 'name' | 'required'> {
  formItemClassName?: string;
  isLoading?: boolean;
}

export const OpFormInputNumber = forwardRef<
  HTMLInputElement,
  IOpFormInputNumberProps
>(
  (
    {
      // Form Item props
      formItemClassName,
      name,
      label,
      rules,
      tooltip,
      validateTrigger,
      required, // TEMP: For old form component validation
      validateStatus, // TEMP: For old form component validation
      help, // TEMP: For old form component validation

      // For skeleton loading state
      isLoading,

      // Wrapped element props
      disabled,

      ...elementProps
    },
    ref,
  ) => {
    /** The Form is wrapped in a provider that passes the loading and readOnly
     * contexts to the component */
    const { isDataLoading, isReadOnly } = useContext(OpFormContext);

    return (
      <OpForm.Item
        className={clsx('op-form-input', formItemClassName)}
        name={name}
        label={label}
        rules={rules}
        tooltip={tooltip}
        validateTrigger={validateTrigger}
        required={required} // TEMP: For old form component validation
        validateStatus={validateStatus} // TEMP: For old form component validation
        help={help} // TEMP: For old form component validation
      >
        {isDataLoading || isLoading ? (
          /** A placeholder in the shape of the component
           * so we can immediately render the structure of the form while
           * data is loading */
          <OpSkeleton.Input active block />
        ) : (
          /** The actual component that is rendered when we have the data */
          <OpInputNumber
            disabled={isReadOnly || disabled}
            {...elementProps}
            ref={ref}
          />
        )}
      </OpForm.Item>
    );
  },
);
