import dayjs from 'dayjs';
import { Visit, Visitor } from 'types/visitTypes';

export const getStatusNameById = (statusId: number, scheduleStart: string | null | undefined) => {
	let statusName = '';

	if (statusId === 1) {
		statusName = "Pending";
	} else if (statusId === 2) {
		statusName = "Signed In";
	} else if (statusId === 3) {
		statusName = "Signed Out";
	} else if (statusId === 99) {
		statusName = "Denied Entry";
	} else { // if statusId 
		statusName = "Pending";
	}

	// Check if the status should be changed to 'No Show'
	if (statusName === 'Pending' && scheduleStart && dayjs(scheduleStart).startOf('day').isBefore(dayjs().startOf('day'))) {
		statusName = 'No Show';
	}

	return statusName;
}

export const getVisitDateTime = (visit: Visit | null, statusName: string, format: string) => {
	let dateTime;
	switch (statusName) {
		case 'Denied Entry':
			dateTime = visit?.signIn;
			break;
		case 'Pending':
			dateTime = visit?.scheduleStart;
			break;
		case 'Signed In':
			dateTime = visit?.signIn;
			break;
		case 'Signed Out':
			dateTime = visit?.signOut;
			break;
		case 'No Show':
			dateTime = visit?.scheduleStart;
			break;
		default:
			dateTime = null;
	}
	const date = dayjs(dateTime);
	return date.isValid() ? date.format(format) : undefined;
};

export const getVisitorDateTime = (visitor: Visitor, statusName: string, scheduleStart: string | null, format: string) => {
	let dateTime;
	switch (statusName) {
		case 'Denied Entry':
			dateTime = visitor.signIn;
			break;
		case 'Pending':
			dateTime = scheduleStart;
			break;
		case 'Signed In':
			dateTime = visitor.signIn;
			break;
		case 'Signed Out':
			dateTime = visitor.signOut;
			break;
		case 'No Show':
			dateTime = scheduleStart;
			break;
		default:
			dateTime = null;
	}
	const date = dayjs(dateTime);
	return date.isValid() ? date.format(format) : undefined;
};

export const getStatusColor = (statusName: String) => {
	let color = '';
	switch (statusName) {
		case 'Pending':
			color = '#0898D8';
			break;
		case 'Signed In':
			color = '#6D988A';
			break;
		case 'Signed Out':
			color = '#898888';
			break;
		case 'No Show':
			color = '#EFB43C';
			break;
		case 'Denied Entry':
			color = '#FA6856';
			break;
		default:
			color = 'transparent';
	}
	return color;
}

export const capitalize = (str: string): string => {
	return str.replace(/\b(\w)/g, (s: string) => s.toUpperCase());
}

interface ProfileIconProps {
	visitor?: Visitor;
	fullName?: string;
	firstName?: string;
	lastName?: string;
	size: number;
	color: string;
	shape?: string;
}

interface ProfileIconProps {
	visitor?: Visitor;
	fullName?: string;
	firstName?: string;
	lastName?: string;
	size: number;
	color: string;
	shape?: string;
	textColor?: string;
	marginRight?: number;
}

export const profileIcon = ({ visitor, fullName, firstName, lastName, size, color, shape, textColor = "#ffffff", marginRight = 8 }: ProfileIconProps) => {
	let firstNameInitial = "";
	let lastNameInitial = "";

	const capitalizeInitial = (name: string | undefined | null) => {
		return name ? name[0].toUpperCase() : "";
	};

	if (fullName) {
		const nameParts = fullName.split(' ').filter(Boolean);
		firstNameInitial = capitalizeInitial(nameParts[0]);
		lastNameInitial = capitalizeInitial(nameParts.length > 1 ? nameParts[nameParts.length - 1] : "");
	} else if (visitor) {
		firstNameInitial = capitalizeInitial(visitor.firstName);
		lastNameInitial = capitalizeInitial(visitor.lastName);
	} else {
		firstNameInitial = capitalizeInitial(firstName);
		lastNameInitial = capitalizeInitial(lastName);
	}

	const profWidth = size;
	const profHeight = size;
	const profFontsize = Math.floor(size * 0.5);

	// const profType = shape === "square" ? Math.floor(size * 0.2) : Math.floor(size / 2); // Edge radius, default = circle
	const profType = shape === "circle" ? Math.floor(size * 0.5) : Math.floor(size * 0.2); // Edge radius, default = square
	const profColor = color;

	return (
		<span
			className="user-profile-image"
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: profWidth + "px",
				height: profHeight + "px",
				fontSize: profFontsize + "px",
				fontFamily: "arial",
				borderRadius: profType,
				color: textColor,
				backgroundColor: profColor,
				marginRight: `${marginRight}px`
			}}
		>
			{firstNameInitial}
			{lastNameInitial}
		</span>
	);
};
