import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { opTime } from 'utils/dates';
import { LinkProps } from 'react-csv/components/Link';
import { OpTooltip } from 'components/customAntd/DLS/OpTooltip/OpTooltip';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { IOpTableColumn } from '../OpTableCore';

import { DownloadOutlined } from '@ant-design/icons';

// Define the type for the CSVLink ref
type CSVLinkRef = CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement };

interface ICsvExportButtonProps extends LinkProps {
  isLoading?: boolean;
  onClick?(): void;
  columns: IOpTableColumn[];
}

export const CsvExportButton = ({
  isLoading = false,
  onClick = () => {},
  data,
  columns,
  filename = `Table-Data_${opTime().format('YYYY-MM-DD_HH-mm')}`,
}: ICsvExportButtonProps) => {
  const { t } = useTranslation();
  const csvLinkRef = useRef<CSVLinkRef>(null);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked && !isLoading) {
      csvLinkRef?.current?.link.click();
      setIsClicked(false);
    }
  }, [isClicked, isLoading, data]);

  const onExportButtonClick = () => {
    // Typically used to manually fire the queries
    onClick();

    /** Need to wrap in timeout so that the click event doesn't
     * fire before the isFetching flag changes on the query */
    setTimeout(() => {
      setIsClicked(true);
    }, 0);
  };

  return (
    <>
      <OpTooltip placement="topRight" title={t('Export to CSV')}>
        <OpButton
          size="large"
          shape="circle"
          loading={isLoading}
          icon={<DownloadOutlined />}
          onClick={onExportButtonClick}
        />
      </OpTooltip>
      {isClicked && (
        <CSVLink
          ref={csvLinkRef}
          data={data}
          headers={columns
            .filter(({ hidden }) => !hidden)
            .map(({ key, label }) => ({
              key: String(key),
              label: String(label),
            }))}
          filename={`${filename}.csv`}
        />
      )}
    </>
  );
};
