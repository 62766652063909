import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { IOpTableProps, OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';
import { getVisitDateTime } from 'utils/visitorsHelper';
import { Bar } from 'react-chartjs-2';
import { AppDispatch, RootState } from 'store/store';
import { describeVisit, fetchVisits } from 'store/slices/visitsSlice';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Visit } from 'types/visitTypes';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import STATUS from 'constants/status';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { formatFullName, hasPermission } from 'utils/utils';
import { TABLE_HEIGHT } from 'constants/ui';
import { Modal } from 'antd';
import { OpCollapse } from 'components/customAntd/DLS/OpCollapse/OpCollapse';
import VisitorsDrawer from 'components/pages/visitors/VisitorsDrawer';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

interface TableData {
    fullName: string;
    totalCount: number;
    chartData: { date: string, count: number }[];
    tableData: { key: string, date: string, hostName: string, visitId: number }[];
}

interface RepeatVisitorsTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

interface VisitorsTrendChartProps {
    visitor: TableData | null;
    startDate: Dayjs;
    endDate: Dayjs;
}

function VisitorsTrendChart({ visitor, startDate, endDate }: VisitorsTrendChartProps) {
    const generateDateRange = (start: Dayjs, end: Dayjs) => {
        const dates = [];
        let currentDate = start;
        while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
            dates.push(currentDate.format('M/D'));
            currentDate = currentDate.add(1, 'day');
        }
        return dates;
    };

    const labels = generateDateRange(startDate, endDate);

    const initialData = Array(labels.length).fill(0);

    const chartData = visitor ? visitor.chartData.reduce((acc, visit) => {
        const dateLabel = dayjs(visit.date).format('M/D');
        const index = labels.indexOf(dateLabel);
        if (index !== -1) {
            acc[index] = visit.count;
        }
        return acc;
    }, initialData) : initialData;

    const data = {
        labels,
        datasets: [
            {
                label: 'Visit Count',
                data: chartData,
                backgroundColor: 'rgba(54, 162, 235, 0.6)',  // Light blue
                borderColor: 'rgba(54, 162, 235, 1)',        // Darker blue
                borderWidth: 1,
            },
        ],
    };

    const [labelColor, setLabelColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--colorTextBase'));
    const [gridColor, setGridColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--colorBorderSecondary'));

    useEffect(() => {
        const handleThemeChange = () => {
            setLabelColor(getComputedStyle(document.documentElement).getPropertyValue('--colorTextBase'));
            setGridColor(getComputedStyle(document.documentElement).getPropertyValue('--colorBorderSecondary'));
        };

        // Listen for changes to the theme
        window.addEventListener('themechange', handleThemeChange);

        // Initial set
        handleThemeChange();

        return () => {
            window.removeEventListener('themechange', handleThemeChange);
        };
    }, []);

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    color: labelColor,
                },
                grid: {
                    color: gridColor,
                },
            },
            x: {
                ticks: {
                    color: labelColor,
                },
                grid: {
                    color: gridColor,
                },
            },
        },
        plugins: {
            legend: {
                display: true,
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '280px' }}>
            <Bar data={data} options={options} />
        </div>
    );
}

const RepeatVisitorsTable: React.FC<RepeatVisitorsTableProps> = ({ setDrawerVisible }) => {
    // Redux hooks
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const { visits, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);
    const [selectedLocationId, setSelectedLocationId] = useState<number>(globalLocationId!);
    const [startDate, setStartDate] = useState(dayjs().subtract(29, 'day').startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().endOf('day'));
    const [filteredVisits, setFilteredVisits] = useState<Visit[]>([]);
    const [selectedVisitor, setSelectedVisitor] = useState<TableData | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    // Fetch visits on organizationId change
    useEffect(() => {
        if (orgId) {
            dispatch(fetchVisits({ orgId }));
        }
    }, [dispatch, orgId]);

    // Filter visits based on location and date range
    useEffect(() => {
        if (visits && visits.data) {
            const filteredVisits = visits.data
                .filter((visit) => {
                    if (visit?.site?.id !== selectedLocationId) return false;
                    if (!hasAllvisitorsRead && globalUserId !== visit.host.userId) return false;
                    const visitDateTime = getVisitDateTime(visit, visit.visitStatus.name!, DATE_TIME_FORMAT);
                    if (!visitDateTime) return false;

                    return dayjs(visitDateTime).isBetween(startDate, endDate, null, '[]');
                })
                .sort((a, b) => dayjs(getVisitDateTime(b, b.visitStatus.name!, DATE_TIME_FORMAT)).unix() - dayjs(getVisitDateTime(a, a.visitStatus.name!, DATE_TIME_FORMAT)).unix());
            setFilteredVisits(filteredVisits);
        }
    }, [visits, selectedLocationId, startDate, endDate, globalUserId, hasAllvisitorsRead]);

    const handleDateRangeLocationFilter = (locationId: number, start: dayjs.Dayjs, end: dayjs.Dayjs) => {
        setSelectedLocationId(locationId);
        setStartDate(start);
        setEndDate(end);
        dispatch(fetchVisits({ orgId }));
    };

    const handleRowClick = (record: TableData) => {
        setSelectedVisitor(record);
        setIsModalVisible(true);
    };

    const getRepeatVisitorsData = (visits: Visit[]): TableData[] => {
        const visitorMap = new Map<string, TableData>();

        visits.forEach((visit) => {
            visit.visitors.forEach((visitor) => {
                if (visitor.status === STATUS.SIGNED_IN.id || visitor.status === STATUS.SIGNED_OUT.id) {
                    const fullName = formatFullName(visitor.firstName, visitor.middleName, visitor.lastName);
                    let visitDate = "";
                    if (visitor.status === STATUS.SIGNED_IN.id) {
                        visitDate = dayjs(visitor.signIn).format(DATE_FORMAT);
                    } else {
                        visitDate = dayjs(visitor.signOut).format(DATE_FORMAT);
                    }

                    const hostName = formatFullName(visit.host.firstName, null, visit.host.lastName);
                    const key = `${visitor.id}`;

                    if (!visitorMap.has(fullName)) {
                        visitorMap.set(fullName, {
                            fullName,
                            totalCount: 0,
                            chartData: [],
                            tableData: []
                        });
                    }

                    const visitorData = visitorMap.get(fullName);
                    if (visitorData) {
                        visitorData.totalCount += 1;
                        const chartEntry = visitorData.chartData.find(entry => entry.date === visitDate);
                        if (chartEntry) {
                            chartEntry.count += 1;
                        } else {
                            visitorData.chartData.push({ date: visitDate, count: 1 });
                        }
                        if (!visitorData.tableData.some(entry => entry.key === key)) {
                            visitorData.tableData.push({ key, date: visitDate, hostName, visitId: visit.id });
                        }
                    }
                }
            });
        });

        // Filter out visitors with totalCount less than 2 and sort by totalCount in descending order, then by fullName in ascending order
        return Array.from(visitorMap.values())
            .filter(visitorData => visitorData.totalCount > 1)
            .sort((a, b) => b.totalCount - a.totalCount || a.fullName.localeCompare(b.fullName));
    };

    const tableData = getRepeatVisitorsData(filteredVisits);

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        },
        {
            dataIndex: 'totalCount',
            label: 'Visit Count',
            filter: { type: 'input' },
            sorter: (a, b) => a.totalCount - b.totalCount,
        },
    ];

    const visitorModalColumns: OpTableRawColumnType[] = [
        {
            dataIndex: 'date',
            label: 'Date',
        },
        {
            dataIndex: 'hostName',
            label: 'Host Name',
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: tableData,
        label: `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`,
        columns: columns,
        rowActions: {
            onEditClick: handleRowClick,
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        loading: fetchVisitsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: 'fullName',
    };

    const handleVisitClick = (visit: any) => {
        dispatch(describeVisit({ orgId, visitId: visit.visitId }));
        setIsVisitorsDrawerOpen(true);
    };

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <DateRangeLocationFilter2
                onDateRangeLocationFilter={handleDateRangeLocationFilter}
                initialStartDate={startDate}
                initialEndDate={endDate}
            />
            <OpTable {...opTableProps} />
            <Modal
                title={selectedVisitor ? selectedVisitor.fullName : "No Visitor Selected"}
                open={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    setSelectedVisitor(null);
                }}
                footer={null}
                width={800}
                centered
            >
                <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <div style={{ position: 'relative' }}>
                        <OpCollapse defaultActiveKey={['1']} items={[
                            {
                                key: '1',
                                label: 'Visitor Trend Chart',
                                children: (
                                    <VisitorsTrendChart visitor={selectedVisitor} startDate={startDate} endDate={endDate} />
                                )
                            }
                        ]} />
                        {selectedVisitor && (
                            <OpTable
                                dataSource={selectedVisitor ? selectedVisitor.tableData : []}
                                columns={visitorModalColumns}
                                pagination={false}
                                allowGlobalSearch={false}
                                allowExport={false}
                                allowShowHideColumns={false}
                                style={{ marginTop: '10px' }}
                                rowKey="key"
                                rowActions={{
                                    onEditClick: handleVisitClick,
                                }}
                            />
                        )}
                    </div>
                </div>
            </Modal>

            <VisitorsDrawer open={isVisitorsDrawerOpen} onClose={() => setIsVisitorsDrawerOpen(false)} />

        </OpSpace>
    );
}

export default RepeatVisitorsTable;
