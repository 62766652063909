// Displays a UI component for a table's header section

import { ReactNode } from 'react';

import './TableHeader.scss';

interface ITableHeaderProps {
  batchActions?: ReactNode;
  tableLabel?: ReactNode;
  globalSearch?: ReactNode;
  addButton?: ReactNode;
  csvExportButton?: ReactNode;
  showHideColumnsButton?: ReactNode;
}

export const TableHeader = ({
  batchActions,
  tableLabel = '',
  globalSearch,
  addButton,
  csvExportButton,
  showHideColumnsButton,
}: ITableHeaderProps) => {
  /*
  This checks if all the props are false, undefined, or null. 
  If none of these props are provided, the component will return null, which means it will not render anything.
  */
  if (
    !(
      tableLabel ||
      batchActions ||
      globalSearch ||
      addButton ||
      csvExportButton ||
      showHideColumnsButton
    )
  ) {
    return null;
  }

  return (
    <div className="table-header">
      {batchActions && <div>{batchActions}</div>} {/* Allows conditional rendering based on whether batchActions is available. */}
      <div className="table-header__label">{tableLabel}</div> {/** Always show this div so space between works correctly */}
      <div className="table-header__table-actions">
        {globalSearch}
        {addButton}
        {csvExportButton}
        {showHideColumnsButton}
      </div>
    </div>
  );
};
