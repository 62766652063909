import { ComponentProps } from 'react';
import Skeleton from 'antd/es/skeleton';
import clsx from 'clsx';

import './OpSkeleton.scss';

type OpSkeletonCheckboxType = Omit<
  ComponentProps<typeof Skeleton.Avatar>,
  'shape'
> & {
  skeletonAvatarProps?: ComponentProps<typeof Skeleton.Avatar>;
  skeletonProps?: ComponentProps<typeof Skeleton>;
};

const OpSkeletonCheckbox = ({
  className,
  skeletonAvatarProps,
  skeletonProps,
}: OpSkeletonCheckboxType) => (
  <div className={clsx('op-skeleton-checkbox', className)}>
    <Skeleton.Avatar
      shape="square"
      style={{ width: 18, height: 18, lineHeight: '18px' }}
      {...skeletonAvatarProps}
    />
    <OpSkeleton
      active
      title={{ width: 80 }}
      paragraph={false}
      {...skeletonProps}
    />
  </div>
);
export const OpSkeleton = ({
  className,
  ...skeletonProps
}: ComponentProps<typeof Skeleton>) => (
  <Skeleton className={clsx('op-skeleton', className)} {...skeletonProps} />
);

OpSkeleton.Button = Skeleton.Button;
OpSkeleton.Avatar = Skeleton.Avatar;
OpSkeleton.Input = Skeleton.Input;
OpSkeleton.Image = Skeleton.Image;
OpSkeleton.Node = Skeleton.Node;
OpSkeleton.Checkbox = OpSkeletonCheckbox;
