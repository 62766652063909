import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'components/customAntd/DLS/OpSkeleton/OpSkeleton';
import { OpPhoneInput } from 'components/customAntd/DLS/OpPhoneInput/OpPhoneInput';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

interface IOpFormPhoneInputProps
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      'name' | 'label' | 'rules' | 'validateTrigger'
    >,
    Omit<ComponentProps<typeof OpPhoneInput>, 'name'> {
  formItemClassName?: string;
  testId?: string;
  isLoading?: boolean;
}

export const OpFormPhoneInput = ({
  name,
  label,
  rules,
  validateTrigger,
  formItemClassName,
  isLoading,

  // Wrapped element props
  disabled,

  ...inputProps
}: IOpFormPhoneInputProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);
  return (
    <OpForm.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={validateTrigger}
      className={clsx('op-form-phone-input', formItemClassName)}
    >
      {isDataLoading || isLoading ? (
        /** A placeholder in the shape of the component
         * so we can immediately render the structure of the form while
         * data is loading */
        <OpSkeleton.Input active block />
      ) : (
        /** The actual component that is rendered when we have the data */
        <OpPhoneInput disabled={isReadOnly || disabled} {...inputProps} />
      )}
    </OpForm.Item>
  );
};
