import { ComponentProps } from 'react';
import Tooltip from 'antd/es/tooltip';

export const OpTooltip = ({
  children,
  className = '',
  ...tooltipProps
}: ComponentProps<typeof Tooltip>) => {
  return (
    <Tooltip className={`op-tooltip ${className}`.trim()} {...tooltipProps}>
      {children}
    </Tooltip>
  );
};
