import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { describeVisit } from 'store/slices/visitsSlice';
import { RootState, AppDispatch } from 'store/store';
import STATUS from 'constants/status';
import dayjs from 'dayjs';
import { formatFullName, hasPermission } from 'utils/utils';
import { Visit } from 'types/visitTypes';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';

interface SignOutProps {
    isSignOutModalOpen: boolean;
    setIsSignOutModalOpen: (open: boolean) => void;
    openVisitorsDrawer: () => void; 
}

const SignOut: React.FC<SignOutProps> = ({ isSignOutModalOpen, setIsSignOutModalOpen, openVisitorsDrawer }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const visits = useSelector((state: RootState) => state.visits.visits);
    const { globalLocation } = useSelector((state: RootState) => state.locations);
    const [todaySignedInVisits, setTodaySignedInVisits] = useState<Visit[]>([]);
    const [selectedVisitorName, setSelectedVisitorName] = useState<string>('');

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    useEffect(() => {
        const filteredVisits = visits.data.filter(visit =>
            visit.site?.id === globalLocation?.id &&
            (hasAllvisitorsWrite || globalUserId === visit.host.userId) &&
            visit.visitors.some(visitor =>
                visitor.status === STATUS.SIGNED_IN.id &&
                dayjs(visitor.signIn).isSame(dayjs(), 'day')
            )
        );
        setTodaySignedInVisits(filteredVisits);
    }, [visits, globalLocation?.id, globalUserId, hasAllvisitorsWrite]);

    const resetSelection = () => {
        setSelectedVisitorName('');
    };

    const handleVisitorChange = (value: string, option: any) => {
        setSelectedVisitorName(option.label);
        dispatch(describeVisit({ orgId, visitId: Number(value.split('-')[0]) }));
        setIsSignOutModalOpen(false);
        openVisitorsDrawer(); // Call the function to open the drawer
        resetSelection();
    };

    return (
        <Modal
            title="Visitor to Sign Out"
            open={isSignOutModalOpen}
            onCancel={() => {
                setIsSignOutModalOpen(false);
                resetSelection();
            }}
            footer={null}
            centered
        >
            <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '10px' }}>
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select visitor to sign out"
                    onChange={handleVisitorChange}
                    value={selectedVisitorName}
                    filterOption={(input, option) =>
                        option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={todaySignedInVisits.flatMap(visit =>
                        visit.visitors
                            .filter(visitor => visitor.status === STATUS.SIGNED_IN.id)
                            .map((visitor, index) => ({
                                value: `${visit.id}-${visitor.id}`,
                                label: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
                                key: `${visit.id}-${visitor.id}`
                            }))
                    )}
                />
            </OpSpace>
        </Modal>
    );
};

export default SignOut;
