import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { AppDispatch, RootState } from "store/store";
import { notification, Spin } from "antd";
import { clearSelectedAgreement } from "store/slices/agreementsSlice";
import AgreementContent from "./tabs/AgreementContent";
import { hasPermission } from "utils/utils";
import { OpDrawer } from "components/customAntd/DLS/OpDrawer/OpDrawer";
import { OpTabs } from "components/customAntd/DLS/OpTabs/OpTabs";
import { MultiFormButtons } from "components/customAntd/MultiFormButtons";
import { LoadingOutlined } from "@ant-design/icons";
import { useConfirmModal } from "utils/customHooks/useConfirmModal";
import { DRAWER_WIDTH } from "constants/ui";

interface AgreementsDrawerProps {
    open: boolean;
    onClose: () => void;
}

const AgreementsDrawer: React.FC<AgreementsDrawerProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedAgreement, describeAgreementLoading } = useSelector((state: RootState) => state.agreements);

    const [agreementForm] = OpForm.useForm();
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAgreementsWrite = hasPermission(tokenScopeList, orgId, 'o', 'agreements:w');
    const confirmModal = useConfirmModal();
    const [activeKey, setActiveKey] = useState<string>('agreement');

    useEffect(() => {
        if (open) {
            agreementForm.resetFields();
            setActiveKey('agreement');
        }
    }, [open, agreementForm]);

    const handleSubmitAllForms = async () => {
        try {
            const agreementValues = await agreementForm.validateFields();

            if (agreementValues) {
                if (agreementForm.isFieldsTouched()) {
                    await agreementForm.submit();
                }
                onClose(); // Close the drawer after successful submit
            }
        } catch (error) {
            notification.error({
                message: 'Save Error',
                description: 'Failed to save agreement.',
                placement: 'bottomRight',
            });
        }
    };

    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (agreementForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    agreementForm.resetFields();
                    dispatch(clearSelectedAgreement());
                    onClose();
                },
            });
        } else {
            dispatch(clearSelectedAgreement());
            onClose();
        }
    };

    const items = useMemo(() => [
        {
            key: 'agreement',
            label: 'Agreement',
            children: <AgreementContent form={agreementForm} tab="agreement" />,
        },
        ...((!selectedAgreement || selectedAgreement.type === 'text') ? [{
            key: 'content',
            label: 'Content',
            children: <AgreementContent form={agreementForm} tab="content" />,
        }] : [])
    ], [agreementForm, selectedAgreement]);

    const renderTabContent = useCallback(() => {
        return items.map((item) => (
            <div
                key={item.key}
                style={{ display: item.key === activeKey ? 'block' : 'none' }}
            >
                {item.children}
            </div>
        ));
    }, [activeKey, items]);

    return (
        <OpDrawer
            title={describeAgreementLoading ? undefined : (selectedAgreement ? `Edit Agreement: "${selectedAgreement.name}"` : 'Add New Agreement')}
            width={DRAWER_WIDTH}
            open={open}
            onClose={finalOnClose}
            footer={
                !describeAgreementLoading && hasAgreementsWrite && (
                    <MultiFormButtons
                        forms={[agreementForm]}
                        submitButtonLabel="Save"
                        onSubmitClick={handleSubmitAllForms}
                        isSubmitButtonLoading={describeAgreementLoading}
                    />
                )
            }
        >
            {describeAgreementLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                >
                    {items.map(item => (
                        <OpTabs.TabPane tab={item.label} key={item.key} />
                    ))}
                </OpTabs>
            )}
            {renderTabContent()}
        </OpDrawer>
    );
};

export default AgreementsDrawer;
