import { ComponentProps, ReactNode, useContext } from 'react';
import clsx from 'clsx';
import { OpCheckbox } from 'components/customAntd/DLS/OpCheckbox/OpCheckbox';
import { OpSkeleton } from 'components/customAntd/DLS/OpSkeleton/OpSkeleton';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

import './OpFormCheckbox.scss';

interface IOpFormCheckbox
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      'name' | 'label' | 'tooltip'
    >,
    Omit<ComponentProps<typeof OpCheckbox>, 'name' | 'tooltip'> {
  formItemClassName?: string;
  isLoading?: boolean;
  checkboxTooltip?: ReactNode;
}

export const OpFormCheckbox = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  children,
  checkboxTooltip,

  disabled,
  ...elementProps
}: IOpFormCheckbox) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx('op-form-checkbox', formItemClassName)}
      name={name}
      tooltip={tooltip}
      label={label}
      valuePropName="checked"
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Avatar active size="small" shape="square" />
      ) : (
        <OpCheckbox
          disabled={isReadOnly || disabled}
          tooltip={checkboxTooltip}
          {...elementProps}
        >
          {children}
        </OpCheckbox>
      )}
    </OpForm.Item>
  );
};
