import { ComponentProps } from 'react';
import clsx from 'clsx';
import { OpButton } from '../OpButton/OpButton';

import './OpTextButton.scss';

export const OpTextButton = ({
  className,
  children,
  ...buttonProps
}: Omit<ComponentProps<typeof OpButton>, 'type'>) => {
  return (
    <OpButton
      type="link"
      className={clsx('op-text-button', className)}
      {...buttonProps}
    >
      {children}
    </OpButton>
  );
};
