import { useState, useEffect } from 'react';

/**
 * Custom hook to manage a debounced state.
 * @param initialValue The initial value of the state.
 * @param delay The debounce delay in milliseconds (default is 300ms).
 */
export function useDebouncedState<T>(initialValue: T, delay: number = 300): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, setValue];
}
