import { ComponentProps, useContext } from 'react';
import clsx from 'clsx';
import { OpSkeleton } from 'components/customAntd/DLS/OpSkeleton/OpSkeleton';
import { OpTimezonePicker } from 'components/customAntd/DLS/OpTimezonePicker/OpTimezonePicker';
import { OpFormContext } from '../../OpFormContext';
import { OpForm } from '../../OpForm';

interface IOpFormTimezonePickerProps
  extends Pick<
      ComponentProps<typeof OpForm.Item>,
      | 'name'
      | 'label'
      | 'rules'
      | 'tooltip'
      | 'validateTrigger'
      | 'validateStatus' // Temp: For SUIR wrapped version
      | 'help' // Temp: For SUIR wrapped version
      | 'required' // Temp: For SUIR wrapped version
    >,
    ComponentProps<typeof OpTimezonePicker> {
  formItemClassName?: string;
  isLoading?: boolean;
}

export const OpFormTimezonePicker = ({
  // Form Item props
  formItemClassName,
  name,
  label,
  tooltip,
  rules,
  validateTrigger,
  required, // TEMP: For old form component validation
  validateStatus, // TEMP: For old form component validation
  help, // TEMP: For old form component validation

  // For skeleton loading state
  isLoading,

  // Wrapped element props
  disabled,

  ...elementProps
}: IOpFormTimezonePickerProps) => {
  /** The Form is wrapped in a provider that passes the loading context
   *  to the component */
  const { isDataLoading, isReadOnly } = useContext(OpFormContext);

  return (
    <OpForm.Item
      className={clsx(formItemClassName)}
      name={name}
      label={label}
      rules={rules}
      tooltip={tooltip}
      validateTrigger={validateTrigger}
      required={required} // TEMP: For old form component validation
      validateStatus={validateStatus} // TEMP: For old form component validation
      help={help} // TEMP: For old form component validation
    >
      {isDataLoading || isLoading ? (
        <OpSkeleton.Input active block />
      ) : (
        <OpTimezonePicker disabled={isReadOnly || disabled} {...elementProps} />
      )}
    </OpForm.Item>
  );
};
