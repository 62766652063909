import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { RedFlag, RedFlagData, RedFlagLog, UpdateRedFlagPayload, CreateRedFlagPayload, CreateRedFlagDataPayload, UpdateFlagDataPayload, RedFlagCount, CreateRedFlagLogPayload } from 'types/redFlagTypes';

interface RedFlagState {
    redFlags: ApiResponse<RedFlag>;
    matchingRedFlags: ApiResponse<RedFlag>;
    selectedRedFlag: RedFlag | null;
    redFlagData: RedFlagData[];
    redFlagLogs: RedFlagLog[];
    redFlagCount: ApiResponse<RedFlagCount>;
    fetchRedFlagsLoading: boolean;
    describeRedFlagLoading: boolean;
    createRedFlagLoading: boolean;
    updateRedFlagLoading: boolean;
    fetchRedFlagDataLoading: boolean;
    createRedFlagDataLoading: boolean;
    updateRedFlagDataLoading: boolean;
    fetchRedFlagLogsLoading: boolean;
    createRedFlagLogLoading: boolean;
    fetchRedFlagCountLoading: boolean;
    error: string | null;
}

const initialState: RedFlagState = {
    redFlags: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    matchingRedFlags: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    selectedRedFlag: null,
    redFlagData: [],
    redFlagLogs: [],
    redFlagCount: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchRedFlagsLoading: false,
    describeRedFlagLoading: false,
    createRedFlagLoading: false,
    updateRedFlagLoading: false,
    fetchRedFlagDataLoading: false,
    createRedFlagDataLoading: false,
    updateRedFlagDataLoading: false,
    fetchRedFlagLogsLoading: false,
    createRedFlagLogLoading: false,
    fetchRedFlagCountLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchRedFlags = createAsyncThunk<ApiResponse<RedFlag>, { orgId: number, metadata?: string, firstName?: string, lastName?: string, middleName?: string, dateOfBirth?: string, identification?: string, address?: string, city?: string, postalCode?: string, isMatchingRedFlags?: boolean }, { rejectValue: string }>(
    'redFlags/fetchRedFlags',
    async ({ orgId, metadata, firstName, lastName, middleName, dateOfBirth, identification, address, city, postalCode, isMatchingRedFlags }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<RedFlag>>(`${apiUrl}/orgs/${orgId}/redflag`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    metadata,
                    firstName,
                    lastName,
                    middleName,
                    dateOfBirth,
                    identification,
                    address,
                    city,
                    postalCode
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch red flags');
        }
    }
);

export const describeRedFlag = createAsyncThunk<ApiResponse<RedFlag>, { orgId: number; redFlagId: number }, { rejectValue: string }>(
    'redFlags/describeRedFlag',
    async ({ orgId, redFlagId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<RedFlag>>(`${apiUrl}/orgs/${orgId}/redflag/${redFlagId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch red flag details');
        }
    }
);

export const createRedFlag = createAsyncThunk<void, { orgId: number; redFlag: Partial<CreateRedFlagPayload> }, { rejectValue: string; dispatch: any }>(
    'redFlags/createRedFlag',
    async ({ orgId, redFlag }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/redflag`, redFlag, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchRedFlags({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create red flag');
        }
    }
);

export const updateRedFlag = createAsyncThunk<void, { orgId: number; redFlagId: number; redFlag: Partial<UpdateRedFlagPayload> }, { rejectValue: string; dispatch: any }>(
    'redFlags/updateRedFlag',
    async ({ orgId, redFlagId, redFlag }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/redflag/${redFlagId}`, redFlag, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchRedFlags({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update red flag');
        }
    }
);

export const fetchRedFlagData = createAsyncThunk<ApiResponse<RedFlagData>, { orgId: number; redFlagId: number }, { rejectValue: string }>(
    'redFlags/fetchRedFlagData',
    async ({ orgId, redFlagId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<RedFlagData>>(`${apiUrl}/orgs/${orgId}/redflag/${redFlagId}/redFlagData`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch red flag data');
        }
    }
);

export const createRedFlagData = createAsyncThunk<void, { orgId: number; redFlagId: number; redFlagData: Partial<CreateRedFlagDataPayload> }, { rejectValue: string }>(
    'redFlags/createRedFlagData',
    async ({ orgId, redFlagId, redFlagData }, { rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/redflag/${redFlagId}/redFlagData`, redFlagData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create red flag data');
        }
    }
);

export const updateRedFlagData = createAsyncThunk<void, { orgId: number; redFlagId: number; dataId: number; redFlagData: Partial<UpdateFlagDataPayload> }, { rejectValue: string }>(
    'redFlags/updateRedFlagData',
    async ({ orgId, redFlagId, dataId, redFlagData }, { rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/redflag/${redFlagId}/redFlagData/${dataId}`, redFlagData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update red flag data');
        }
    }
);

export const fetchRedFlagLogs = createAsyncThunk<ApiResponse<RedFlagLog>, { orgId: number; redFlagId: number }, { rejectValue: string }>(
    'redFlags/fetchRedFlagLogs',
    async ({ orgId, redFlagId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<RedFlagLog>>(`${apiUrl}/orgs/${orgId}/redflag/${redFlagId}/redFlagLog`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch red flag logs');
        }
    }
);

export const createRedFlagLog = createAsyncThunk<void, { orgId: number; redFlagId: number; redFlagLog: CreateRedFlagLogPayload }, { rejectValue: string; dispatch: any }>(
    'redFlags/createRedFlagLog',
    async ({ orgId, redFlagId, redFlagLog }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/redflag/${redFlagId}/redFlagLog`, redFlagLog, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchRedFlagLogs({ orgId, redFlagId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create red flag log');
        }
    }
);

export const fetchRedFlagCount = createAsyncThunk<ApiResponse<RedFlagCount>, { orgId: number }, { rejectValue: string }>(
    'redFlags/fetchRedFlagCount',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<any>>(`${apiUrl}/orgs/${orgId}/redflagCount`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update appearance config');
        }
    }
);

const redFlagSlice = createSlice({
    name: 'redFlags',
    initialState,
    reducers: {
        clearRedFlags(state) {
            state.redFlags = initialState.redFlags;
        },
        clearSelectedRedFlag(state) {
            state.selectedRedFlag = initialState.selectedRedFlag;
            state.redFlagData = initialState.redFlagData;
            state.redFlagLogs = initialState.redFlagLogs;
        },
        clearRedFlagCount(state) {
            state.redFlagCount = initialState.redFlagCount;
        },
        clearError(state) {
            state.error = null;
        },
        clearRedFlagState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRedFlags.pending, (state) => {
                state.fetchRedFlagsLoading = true;
                state.error = null;
            })
            .addCase(fetchRedFlags.fulfilled, (state, action) => {
                state.fetchRedFlagsLoading = false;
                if (action.meta.arg.isMatchingRedFlags) {
                    state.matchingRedFlags = action.payload;
                } else {
                    state.redFlags = action.payload;
                }
            })
            .addCase(fetchRedFlags.rejected, (state, action) => {
                state.fetchRedFlagsLoading = false;
                state.error = action.payload || 'Failed to fetch red flags';
            })
            .addCase(describeRedFlag.pending, (state) => {
                state.describeRedFlagLoading = true;
                state.error = null;
            })
            .addCase(describeRedFlag.fulfilled, (state, action) => {
                state.describeRedFlagLoading = false;
                state.selectedRedFlag = action.payload.data[0];
            })
            .addCase(describeRedFlag.rejected, (state, action) => {
                state.describeRedFlagLoading = false;
                state.error = action.payload || 'Failed to fetch red flag details';
            })
            .addCase(createRedFlag.pending, (state) => {
                state.createRedFlagLoading = true;
                state.error = null;
            })
            .addCase(createRedFlag.fulfilled, (state) => {
                state.createRedFlagLoading = false;
            })
            .addCase(createRedFlag.rejected, (state, action) => {
                state.createRedFlagLoading = false;
                state.error = action.payload || 'Failed to create red flag';
            })
            .addCase(updateRedFlag.pending, (state) => {
                state.updateRedFlagLoading = true;
                state.error = null;
            })
            .addCase(updateRedFlag.fulfilled, (state) => {
                state.updateRedFlagLoading = false;
            })
            .addCase(updateRedFlag.rejected, (state, action) => {
                state.updateRedFlagLoading = false;
                state.error = action.payload || 'Failed to update red flag';
            })
            .addCase(fetchRedFlagData.pending, (state) => {
                state.fetchRedFlagDataLoading = true;
                state.error = null;
            })
            .addCase(fetchRedFlagData.fulfilled, (state, action) => {
                state.fetchRedFlagDataLoading = false;
                state.redFlagData = action.payload.data;
            })
            .addCase(fetchRedFlagData.rejected, (state, action) => {
                state.fetchRedFlagDataLoading = false;
                state.error = action.payload || 'Failed to fetch red flag data';
            })
            .addCase(createRedFlagData.pending, (state) => {
                state.createRedFlagDataLoading = true;
                state.error = null;
            })
            .addCase(createRedFlagData.fulfilled, (state) => {
                state.createRedFlagDataLoading = false;
            })
            .addCase(createRedFlagData.rejected, (state, action) => {
                state.createRedFlagDataLoading = false;
                state.error = action.payload || 'Failed to create red flag data';
            })
            .addCase(updateRedFlagData.pending, (state) => {
                state.updateRedFlagDataLoading = true;
                state.error = null;
            })
            .addCase(updateRedFlagData.fulfilled, (state) => {
                state.updateRedFlagDataLoading = false;
            })
            .addCase(updateRedFlagData.rejected, (state, action) => {
                state.updateRedFlagDataLoading = false;
                state.error = action.payload || 'Failed to update red flag data';
            })
            .addCase(fetchRedFlagLogs.pending, (state) => {
                state.fetchRedFlagLogsLoading = true;
                state.error = null;
            })
            .addCase(fetchRedFlagLogs.fulfilled, (state, action) => {
                state.fetchRedFlagLogsLoading = false;
                state.redFlagLogs = action.payload.data;
            })
            .addCase(fetchRedFlagLogs.rejected, (state, action) => {
                state.fetchRedFlagLogsLoading = false;
                state.error = action.payload || 'Failed to fetch red flag logs';
            })
            .addCase(createRedFlagLog.pending, (state) => {
                state.createRedFlagLogLoading = true;
                state.error = null;
            })
            .addCase(createRedFlagLog.fulfilled, (state) => {
                state.createRedFlagLogLoading = false;
            })
            .addCase(createRedFlagLog.rejected, (state, action) => {
                state.createRedFlagLogLoading = false;
                state.error = action.payload || 'Failed to create red flag log';
            })
            .addCase(fetchRedFlagCount.pending, (state) => {
                state.fetchRedFlagCountLoading = true;
                state.error = null;
            })
            .addCase(fetchRedFlagCount.fulfilled, (state, action) => {
                state.fetchRedFlagCountLoading = false;
                state.redFlagCount = action.payload;
            })
            .addCase(fetchRedFlagCount.rejected, (state, action) => {
                state.fetchRedFlagCountLoading = false;
                state.error = action.payload || 'Failed to fetch red flag count';
            });
    },

});

export const { clearRedFlags, clearSelectedRedFlag, clearError, clearRedFlagState } = redFlagSlice.actions;
export default redFlagSlice.reducer;
