import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { AppDispatch, RootState } from 'store/store';
import { clearSelectedRole } from 'store/slices/rolesSlice';
import { notification, Spin } from 'antd';
import RoleContent from './tabs/RoleContent';
import UserContent from './tabs/UserContent';
import ScopeContent from './tabs/ScopeContent';
import { hasPermission } from 'utils/utils';
import { OpDrawer } from 'components/customAntd/DLS/OpDrawer/OpDrawer';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { MultiFormButtons } from 'components/customAntd/MultiFormButtons';
import { LoadingOutlined } from '@ant-design/icons';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { DRAWER_WIDTH } from 'constants/ui';

interface RolesDrawerProps {
    open: boolean;
    onClose: () => void;
}

const RolesDrawer: React.FC<RolesDrawerProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedRole, describeRoleLoading } = useSelector((state: RootState) => state.roles);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const [roleForm] = OpForm.useForm();
    const [userForm] = OpForm.useForm();
    const [scopeForm] = OpForm.useForm();

    const hasRoleWrite = hasPermission(tokenScopeList, orgId, 'o', 'role:w');
    const confirmModal = useConfirmModal();
    const [activeKey, setActiveKey] = useState<string>('role');

    useEffect(() => {
        if (open) {
            roleForm.resetFields();
            userForm.resetFields();
            scopeForm.resetFields();
            setActiveKey('role');
        }
    }, [open, roleForm, userForm, scopeForm]);

    const handleSubmitAllForms = async () => {
        try {
            const roleValues = await roleForm.validateFields();
            const userValues = await userForm.validateFields();
            const scopeValues = await scopeForm.validateFields();
            if (roleValues && userValues && scopeValues) {
                if (roleForm.isFieldsTouched()) {
                    await roleForm.submit();
                }
                if (userForm.isFieldsTouched()) {
                    await userForm.submit();
                }
                if (scopeForm.isFieldsTouched()) {
                    await scopeForm.submit();
                }
                onClose(); // Close the drawer after successful submit
            }
        } catch (error) {
            notification.error({
                message: 'Save Error',
                description: 'Failed to save role.',
                placement: 'bottomRight',
            });
        }
    };

    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (roleForm.isFieldsTouched() || userForm.isFieldsTouched() || scopeForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    roleForm.resetFields();
                    userForm.resetFields();
                    scopeForm.resetFields();
                    dispatch(clearSelectedRole());
                    onClose();
                },
            });
        } else {
            dispatch(clearSelectedRole());
            onClose();
        }
    };

    const items = useMemo(() => [
        {
            key: 'role',
            label: 'Role',
            children: <RoleContent form={roleForm} />,
        },
        ...(selectedRole ? [{
            key: 'user',
            label: 'Users',
            children: <UserContent form={userForm} />,
        }] : []),
        ...(selectedRole ? [{
            key: 'scope',
            label: 'Scope',
            children: <ScopeContent form={scopeForm} />,
        }] : [])
    ], [selectedRole, roleForm, userForm, scopeForm]);

    const renderTabContent = useCallback(() => {
        return items.map((item) => (
            <div
                key={item.key}
                style={{ display: item.key === activeKey ? 'block' : 'none' }}
            >
                {item.children}
            </div>
        ));
    }, [activeKey, items]);

    return (
        <OpDrawer
            title={describeRoleLoading ? '' : (selectedRole ? `Edit Role: ${selectedRole.name}` : 'Add New Role')}
            width={DRAWER_WIDTH}
            open={open}
            onClose={finalOnClose}
            footer={
                !describeRoleLoading && hasRoleWrite && (
                    <MultiFormButtons
                        forms={[roleForm, userForm, scopeForm]}
                        submitButtonLabel="Save"
                        onSubmitClick={handleSubmitAllForms}
                        isSubmitButtonLoading={describeRoleLoading}
                    />
                )
            }
        >
            {describeRoleLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                >
                    {items.map(item => (
                        <OpTabs.TabPane tab={item.label} key={item.key} />
                    ))}
                </OpTabs>
            )}
            {renderTabContent()}
        </OpDrawer>
    );
}

export default RolesDrawer;
