import React, { useState, useEffect } from 'react';
import { List, notification } from 'antd';
import { getStatusNameById, getStatusColor, profileIcon } from 'utils/visitorsHelper';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { MinusCircleOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { AppDispatch, RootState } from 'store/store';
import warningIcon from '../../warning.svg';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { formatFullName, hasPermission } from 'utils/utils';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import EditVisitorModal from '../EditVisitorModal';
import { addVisitorsToVisit, removeVisitorFromVisit, updateVisitor, viewVisitorPhoto } from 'store/slices/visitorsSlice';
import { Visitor } from 'types/visitTypes';
import AddVisitorModal from 'components/layout/pageContent/ActionFloatButtons/invite/AddVisitorModal';
import STATUS from 'constants/status';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import VisitorProfileDrawer from './VisitorProfileDrawer';

interface VisitorsContentProps {
    form: any;
    onClose: () => void;
}

const VisitorsContent: React.FC<VisitorsContentProps> = ({ form, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedVisit = useSelector((state: RootState) => state.visits.selectedVisit);
    const redFlags = useSelector((state: RootState) => state.redFlags.redFlags);
    const [openVisitorDrawer, setOpenVisitorDrawer] = useState(false);
    const [selectedVisitor, setSelectedVisitor] = useState<Visitor | null>(null);

    const [isEditVisitorModalVisible, setIsEditModalVisible] = useState(false);
    const [isAddVisitorModalVisible, setIsAddVisitorModalVisible] = useState(false);

    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    useEffect(() => {
        if (selectedVisitor) {
            dispatch(viewVisitorPhoto({ orgId, visitId: selectedVisit?.id!, visitorId: selectedVisitor.id! }));
        }
    }, [selectedVisitor, orgId, selectedVisit?.id, dispatch]);

    const showDrawer = (visitor: Visitor) => {
        setSelectedVisitor(visitor);
        setOpenVisitorDrawer(true);
        form.resetFields();
    };

    const addVisitor = (visitor: Visitor) => {
        // Add status field to the visitor object
        const visitorWithStatus = { ...visitor, status: STATUS.PENDING.id };
        const visitors = [visitorWithStatus];
        dispatch(addVisitorsToVisit({ orgId, visitId: selectedVisit?.id!, visitors }));
        setIsAddVisitorModalVisible(false);
        notification.success({
            message: 'Success',
            description: 'Visitor added successfully',
            placement: 'bottomRight',
        });
    };

    const confirmModal = useConfirmModal();

    const deleteVisitor = (visitorId: number) => {
        if (selectedVisit?.visitors.length && selectedVisit?.visitors.length > 1) {
            confirmModal({
                title: 'Delete Visitor',
                content: 'Are you sure you want to delete this visitor?',
                okText: ('Yes'),
                cancelText: ('No'),
                onOk: () => {
                    dispatch(removeVisitorFromVisit({ orgId, visitId: selectedVisit?.id!, visitorId }));
                    notification.success({
                        message: 'Success',
                        description: 'Visitor deleted successfully.',
                        placement: 'bottomRight',
                    });
                },
            });
        } else {
            notification.error({
                message: 'Error',
                description: 'There must be at least one visitor in a visit.',
                placement: 'bottomRight',
            });
        }
    }

    const editVisitor = async (updatedVisitor: Visitor) => {
        if (selectedVisitor && selectedVisit) {
            const payload = {
                firstName: updatedVisitor.firstName || undefined,
                middleName: updatedVisitor.middleName === null ? undefined : (updatedVisitor.middleName || ''),
                lastName: updatedVisitor.lastName || undefined,
                email: updatedVisitor.email === null ? undefined : (updatedVisitor.email || ''),
                mobilePhone: updatedVisitor.mobilePhone === null ? undefined : (updatedVisitor.mobilePhone || ''),
                signIn: updatedVisitor.signIn ? dayjs(updatedVisitor.signIn).format(DATE_TIME_FORMAT) : undefined,
                signOut: updatedVisitor.signOut ? dayjs(updatedVisitor.signOut).format(DATE_TIME_FORMAT) : undefined,
            };


            // Validation for date order
            if (payload.signOut && payload.signIn && dayjs(payload.signOut).isBefore(dayjs(payload.signIn))) {
                notification.error({
                    message: 'Validation Error',
                    description: 'End date cannot be before start date.',
                    placement: 'bottomRight',
                });
                setIsEditModalVisible(false);
                return;
            }

            try {
                await dispatch(updateVisitor({
                    orgId: orgId,
                    visitId: selectedVisit.id,
                    visitorId: selectedVisitor.id!,
                    visitorPayload: payload
                }));
                setIsEditModalVisible(false);
                setSelectedVisitor(updatedVisitor);
                notification.success({
                    message: 'Success',
                    description: 'Profile updated successfully',
                    placement: 'bottomRight',
                });
            } catch (error) {
                console.error('Failed to update visitor:', error);
            }

        }
    };

    const isVisitorRedFlagged = (visitor: Visitor) => {
        return redFlags.data.some(redFlag =>
            redFlag?.firstName?.toLowerCase() === visitor.firstName?.toLowerCase() &&
            redFlag?.middleName?.toLowerCase() === visitor.middleName?.toLowerCase() &&
            redFlag?.lastName?.toLowerCase() === visitor.lastName?.toLowerCase()
        );
    };

    return (
        <>
            <List
                dataSource={selectedVisit?.visitors}
                renderItem={(visitor, index) => {
                    const actions = (selectedVisit?.visitStatus.id === 1 && hasInviteWrite && (hasAllvisitorsWrite || globalUserId === selectedVisit?.host.userId))
                        ? [
                            <EyeOutlined onClick={() => showDrawer(visitor)} key={`a-${visitor.id}`} />,
                            <MinusCircleOutlined onClick={() => deleteVisitor(visitor.id!)} key={`d-${visitor.id}`} />
                        ]
                        : [
                            <EyeOutlined onClick={() => showDrawer(visitor)} key={`a-${visitor.id}`} />
                        ];
                    return (
                        <List.Item
                            key={visitor.id}
                            actions={actions}
                            onClick={() => showDrawer(visitor)}
                            style={{ cursor: 'pointer' }}
                        >
                            <List.Item.Meta
                                avatar={
                                    <div style={{ display: 'flex', alignItems: 'center', height: '48px' }}> 
                                        {isVisitorRedFlagged(visitor) ? (
                                            <img src={warningIcon} alt="Warning" style={{ width: '36px', height: '36px', margin: 'auto' }} />
                                        ) : (
                                            <div style={{ margin: 'auto' }}> 
                                                {profileIcon({ visitor, size: 36, color: getStatusColor(getStatusNameById(visitor.status!, selectedVisit?.scheduleStart)) })}
                                            </div>
                                        )}
                                    </div>
                                }
                                title={<span key={`a-${visitor.id}`}>{formatFullName(visitor.firstName, visitor.middleName, visitor.lastName)}</span>}
                                description={
                                    <span>
                                        <span style={{ color: getStatusColor(getStatusNameById(visitor.status!, selectedVisit?.scheduleStart!)) }}>
                                            {getStatusNameById(visitor.status!, selectedVisit?.scheduleStart!)}
                                        </span>
                                        {` | Email: ${visitor.email || 'N/A'} | Mobile: ${visitor.mobilePhone || 'N/A'}`}
                                    </span>
                                }
                            />
                        </List.Item>
                    );
                }}
            />
            {(selectedVisit?.visitStatus.id === 1 && hasInviteWrite && (hasAllvisitorsWrite || globalUserId === selectedVisit?.host.userId)) && (
                <OpButton type="dashed" onClick={() => setIsAddVisitorModalVisible(true)} block icon={<PlusOutlined />}>
                    Add Visitor
                </OpButton>
            )}
            <VisitorProfileDrawer open={openVisitorDrawer} onClose={() => setOpenVisitorDrawer(false)} visitor={selectedVisitor} form={form} />

            <AddVisitorModal
                open={isAddVisitorModalVisible}
                onCreate={addVisitor}
                onCancel={() => setIsAddVisitorModalVisible(false)}
            />

            <EditVisitorModal
                open={isEditVisitorModalVisible}
                onOk={editVisitor}
                onCancel={() => setIsEditModalVisible(false)}
                initialData={selectedVisitor}
            />

        </>
    )
};

export default VisitorsContent;
