import { ComponentProps } from 'react';
import Card from 'antd/es/card';
import clsx from 'clsx';

interface IOpCardProps extends ComponentProps<typeof Card> {
  testId?: string;
}

export const OpCard = ({
  className,
  testId = 'op-col',
  ...cardProps
}: IOpCardProps) => {
  return (
    <Card
      className={clsx('op-card', className)}
      data-testid={testId}
      {...cardProps}
    />
  );
};
