import { OpPage } from 'components/customAntd/OpPage/OpPage';
import React from 'react';
import { Row, Col, Empty } from 'antd';

const NotFound: React.FC = () => {
    return (
        <OpPage>
            <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                    <Empty description={
                        <div>
                            <h1>Not Available</h1>
                            <p>Content you requested is not available.</p>
                        </div>
                    } />
                </Col>
            </Row>
        </OpPage>
    );
};

export default NotFound;
