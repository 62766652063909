import { ComponentProps } from 'react';
import Form from 'antd/es/form';
import clsx from 'clsx';

import './OpFormItem.scss';

interface IOpFormItemProps extends ComponentProps<typeof Form.Item> {
  testId?: string;
}

export const OpFormItem = ({
  children,
  className,
  testId = 'op-form-item',
  ...rest
}: IOpFormItemProps) => {
  /**
   * I intentionally want to spread all props for now
   * I don't know as of now exactly the props we want to
   * use and we don't have Typescript to make
   * this simple
   */
  return (
    <Form.Item
      className={clsx('op-form-item', className)}
      data-testid={testId}
      {...rest}
    >
      {children}
    </Form.Item>
  );
};
