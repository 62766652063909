import React, { useCallback, useMemo } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateVisitorWorkflow, updateVisitorWorkflowConfig } from 'store/slices/visitorWorkflowsSlice';
import { List, notification } from 'antd';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import locationIcon from '../../location.svg';
import { hasPermission } from 'utils/utils';
import { OpSwitch } from 'components/customAntd/DLS/OpSwitch/OpSwitch';

interface WorkflowContentProps {
    form: any;
}

const confirmReturnItemsData = [
    { name: 'confirmReturnItems', label: 'Confirm Return Items', valuePropName: 'checked' },
];

const notificationData = [
    { name: 'notifyHost', label: 'Notify Host', valuePropName: 'checked' },
];

const WorkflowContent: React.FC<WorkflowContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisitorWorkflow, visitorWorkflowLocations, visitorWorkflowConfig } = useSelector((state: RootState) => state.visitorWorkflows);


    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasSignoutconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r');
    const hasSignoutconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:w');
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode); // Accessing isDarkMode from Redux

    const initialValues = useMemo(() => {
        if (selectedVisitorWorkflow) {
            return {
                name: selectedVisitorWorkflow.name,
                workflowType: "Attendant Sign Out Workflow",
                confirmReturnItems: visitorWorkflowConfig?.confirmReturnItems,
                notifyHost: visitorWorkflowConfig?.notifyHost,
            };
        }
        return {};
    }, [selectedVisitorWorkflow, visitorWorkflowConfig]);

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        const visitorWorkflowConfigParams = {
            confirmReturnItems: values.confirmReturnItems,
            notifyHost: values.notifyHost,
        };

        console.log("values", values);
        console.log("initialValues", initialValues);
        console.log("touchedValues", touchedValues);

        if (selectedVisitorWorkflow) {
            dispatch(updateVisitorWorkflow({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, visitorWorkflow: { name: touchedValues.name } }));
            dispatch(updateVisitorWorkflowConfig({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, visitorWorkflowConfigParams }));
        }

        notification.success({
            message: 'Success',
            description: 'Workflows updated successfully',
            placement: 'bottomRight',
        });
    }, [dispatch, orgId, selectedVisitorWorkflow]);


    const locationIconStyle = {
        marginRight: '8px',
        width: '16px',
        height: '16px',
        filter: isDarkMode ? 'invert(1)' : 'none',
    };

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasSignoutconfWrite && hasSignoutconfRead}
        >
            <OpSpace
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}
            >
                <OpRow gutter={16}>
                    <OpCol span={12}>
                        <OpForm.Input label="Name" name="name" rules={[{ required: true, message: 'Please enter the name' }]} />
                    </OpCol>
                    <OpCol span={12}>
                        <OpForm.Input disabled label="Workflow Type" name="workflowType" />
                    </OpCol>
                </OpRow>

                <List
                    header={<div>CHECKLIST</div>}
                    dataSource={confirmReturnItemsData}
                    bordered
                    renderItem={item => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Item name={item.name} valuePropName={item.valuePropName} style={{ marginBottom: 0 }}>
                                    <OpSwitch disabled={!hasSignoutconfWrite && hasSignoutconfRead} />
                                </OpForm.Item>
                                <span style={{ marginLeft: 8 }}>{item.label}</span>
                            </div>
                        </List.Item>
                    )} />

                <List
                    header={<div>NOTIFICATION</div>}
                    dataSource={notificationData}
                    bordered
                    renderItem={item => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Item name={item.name} valuePropName={item.valuePropName} style={{ marginBottom: 0 }}>
                                    <OpSwitch disabled={!hasSignoutconfWrite && hasSignoutconfRead} />
                                </OpForm.Item>
                                <span style={{ marginLeft: 8 }}>{item.label}</span>
                            </div>
                        </List.Item>
                    )} />

                <List
                    header={<div>LOCATIONS</div>}
                    bordered
                    dataSource={visitorWorkflowLocations.data.filter(location => location.status === 1)}
                    renderItem={location => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <img src={locationIcon} alt="location icon" style={locationIconStyle} />
                                <span>{location.name}</span>
                            </div>
                        </List.Item>
                    )}
                />
            </OpSpace>
        </OpForm>
    );
};

export default WorkflowContent;
