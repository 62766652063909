import dayjs from 'dayjs';
import { Location, LocationWorkflow } from 'types/locationTypes';

export const parseLocationResponse = (location: any): Location => ({
    id: Number(location.id),
    name: location.name,
    description: location.description,
    address: location.address,
    address2: location.address2,
    city: location.city,
    region: location.region,
    postalcode: location.postalcode,
    country: location.country,
    phone: location.phone,
    language: location.language,
    createdAt: location.createdAt ? dayjs(location.createdAt).toISOString() : null,
    updatedAt: location.updatedAt ? dayjs(location.updatedAt).toISOString() : null,
    status: Number(location.status),
});

export const parseLocationWorkflowResponse = (response: any): LocationWorkflow => ({
    site: response.site,
    SignInWorkflowId: Number(response.SignInWorkflowId),
    SignOutWorkflowId: Number(response.SignOutWorkflowId),
    KioskInWorkflowId: Number(response.KioskInWorkflowId),
    KioskOutWorkflowId: Number(response.KioskOutWorkflowId),
});