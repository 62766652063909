import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Role, CreateRolePayload, UpdateRolePayload } from 'types/roleTypes';
import { User } from 'types/userTypes';

interface RolesState {
	roles: ApiResponse<Role>;
	selectedRole: Role | null;
	usersInRole: ApiResponse<User> | null;
	fetchRolesLoading: boolean;
	describeRoleLoading: boolean;
	createRoleLoading: boolean;
	updateRoleLoading: boolean;
	deleteRoleLoading: boolean;
	fetchUsersInRoleLoading: boolean;
	addUserToRoleLoading: boolean;
	removeUserFromRoleLoading: boolean;
	bulkUpdateRoleUsersLoading: boolean;
	bulkUpdateRoleScopesLoading: boolean;
	error: string | null;
}

const initialRolesState: RolesState = {
	roles: {
		data: [],
		meta: null,
		totalCount: 0,
		filteredCount: 0,
	},
	selectedRole: null,
	usersInRole: {
		data: [],
		meta: null,
		totalCount: 0,
		filteredCount: 0,
	},
	fetchRolesLoading: false,
	describeRoleLoading: false,
	createRoleLoading: false,
	updateRoleLoading: false,
	deleteRoleLoading: false,
	fetchUsersInRoleLoading: false,
	addUserToRoleLoading: false,
	removeUserFromRoleLoading: false,
	bulkUpdateRoleUsersLoading: false,
	bulkUpdateRoleScopesLoading: false,
	error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
	console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchRoles = createAsyncThunk<ApiResponse<Role>, { orgId: number }, { rejectValue: string }>(
	'roles/fetchRoles',
	async ({ orgId }, { rejectWithValue }) => {
		try {
			const response = await axios.get<ApiResponse<Role>>(`${apiUrl}/orgs/${orgId}/roles`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch roles');
		}
	}
);

export const describeRole = createAsyncThunk<ApiResponse<Role>, { orgId: number; roleId: number }, { rejectValue: string }>(
	'roles/describeRole',
	async ({ orgId, roleId }, { rejectWithValue }) => {
		try {
			const response = await axios.get<ApiResponse<Role>>(`${apiUrl}/orgs/${orgId}/roles/${roleId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch role details');
		}
	}
);

export const createRole = createAsyncThunk<void, { orgId: number; role: Partial<CreateRolePayload> }, { rejectValue: string; dispatch: any }>(
	'roles/createRole',
	async ({ orgId, role }, { dispatch, rejectWithValue }) => {
		try {
			await axios.post(`${apiUrl}/orgs/${orgId}/roles`, role, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			dispatch(fetchRoles({ orgId }));
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to create role');
		}
	}
);

export const updateRole = createAsyncThunk<void, { orgId: number; roleId: number; role: Partial<UpdateRolePayload> }, { rejectValue: string; dispatch: any }>(
	'roles/updateRole',
	async ({ orgId, roleId, role }, { dispatch, rejectWithValue }) => {
		try {
			await axios.patch(`${apiUrl}/orgs/${orgId}/roles/${roleId}`, role, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			dispatch(fetchRoles({ orgId }));
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to update role');
		}
	}
);

export const deleteRole = createAsyncThunk<void, { orgId: number; roleId: number }, { rejectValue: string; dispatch: any }>(
	'roles/deleteRole',
	async ({ orgId, roleId }, { dispatch, rejectWithValue }) => {
		try {
			await axios.delete(`${apiUrl}/orgs/${orgId}/roles/${roleId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			dispatch(fetchRoles({ orgId }));
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to delete role');
		}
	}
);

export const fetchUsersInRole = createAsyncThunk<ApiResponse<User>, { orgId: number; roleId: number }, { rejectValue: string }>(
	'roles/fetchUsersInRole',
	async ({ orgId, roleId }, { rejectWithValue }) => {
		try {
			const response = await axios.get<ApiResponse<User>>(`${apiUrl}/orgs/${orgId}/roles/${roleId}/users`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch users in role');
		}
	}
);

export const addUserToRole = createAsyncThunk<void, { orgId: number; roleId: number; userId: number }, { rejectValue: string; dispatch: any }>(
	'roles/addUserToRole',
	async ({ orgId, roleId, userId }, { dispatch, rejectWithValue }) => {
		try {
			await axios.put(`${apiUrl}/orgs/${orgId}/roles/${roleId}/users/${userId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			dispatch(fetchUsersInRole({ orgId, roleId }));
			dispatch(fetchRoles({ orgId }));
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to add user to role');
		}
	}
);

export const removeUserFromRole = createAsyncThunk<void, { orgId: number; roleId: number; userId: number }, { rejectValue: string; dispatch: any }>(
	'roles/removeUserFromRole',
	async ({ orgId, roleId, userId }, { dispatch, rejectWithValue }) => {
		try {
			await axios.delete(`${apiUrl}/orgs/${orgId}/roles/${roleId}/users/${userId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			dispatch(fetchUsersInRole({ orgId, roleId }));
			dispatch(fetchRoles({ orgId }));
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to remove user from role');
		}
	}
);

export const bulkUpdateRoleUsers = createAsyncThunk<void, { orgId: number; roleId: number; add: number[]; remove: number[] }, { rejectValue: string; dispatch: any }>(
	'roles/bulkUpdateRoleUsers',
	async ({ orgId, roleId, add, remove }, { dispatch, rejectWithValue }) => {
		try {
			const payload: any = {};
			if (add && add.length > 0) payload.add = add;
			if (remove && remove.length > 0) payload.remove = remove;

			await axios.patch(`${apiUrl}/orgs/${orgId}/roles/${roleId}/userIds`, payload, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			dispatch(fetchUsersInRole({ orgId, roleId }));
			dispatch(fetchRoles({ orgId }));
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to update role users');
		}
	}
);

export const bulkUpdateRoleScopes = createAsyncThunk<void, { orgId: number; roleId: number; add: number[]; remove: number[] }, { rejectValue: string; dispatch: any }>(
	'roles/bulkUpdateRoleScopes',
	async ({ orgId, roleId, add, remove }, { dispatch, rejectWithValue }) => {
		try {
			await axios.patch(`${apiUrl}/orgs/${orgId}/roles/${roleId}/scopeIds`, { add, remove }, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				},
			});
			dispatch(describeRole({ orgId, roleId }));
			dispatch(fetchRoles({ orgId }));
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			return rejectWithValue(axiosError.response?.data.message || 'Failed to update role scopes');
		}
	}
);

const rolesSlice = createSlice({
	name: 'roles',
	initialState: initialRolesState,
	reducers: {
		clearRoles(state) {
			state.roles = initialRolesState.roles;
		},
		clearSelectedRole(state) {
			state.selectedRole = initialRolesState.selectedRole;
			state.usersInRole = initialRolesState.usersInRole;
		},
		clearUsersInRole(state) {
			state.usersInRole = initialRolesState.usersInRole;
		},
		clearRolesState() {
			return initialRolesState;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRoles.pending, (state) => {
				state.fetchRolesLoading = true;
				state.error = null;
			})
			.addCase(fetchRoles.fulfilled, (state, action) => {
				state.fetchRolesLoading = false;
				state.roles = action.payload;
			})
			.addCase(fetchRoles.rejected, (state, action) => {
				state.fetchRolesLoading = false;
				state.error = action.payload || 'Failed to fetch roles';
			})
			.addCase(describeRole.pending, (state) => {
				state.describeRoleLoading = true;
				state.error = null;
			})
			.addCase(describeRole.fulfilled, (state, action) => {
				state.describeRoleLoading = false;
				state.selectedRole = action.payload.data[0];
			})
			.addCase(describeRole.rejected, (state, action) => {
				state.describeRoleLoading = false;
				state.error = action.payload || 'Failed to fetch role details';
			})
			.addCase(createRole.pending, (state) => {
				state.createRoleLoading = true;
				state.error = null;
			})
			.addCase(createRole.fulfilled, (state) => {
				state.createRoleLoading = false;
			})
			.addCase(createRole.rejected, (state, action) => {
				state.createRoleLoading = false;
				state.error = action.payload || 'Failed to create role';
			})
			.addCase(updateRole.pending, (state) => {
				state.updateRoleLoading = true;
				state.error = null;
			})
			.addCase(updateRole.fulfilled, (state) => {
				state.updateRoleLoading = false;
			})
			.addCase(updateRole.rejected, (state, action) => {
				state.updateRoleLoading = false;
				state.error = action.payload || 'Failed to update role';
			})
			.addCase(deleteRole.pending, (state) => {
				state.deleteRoleLoading = true;
				state.error = null;
			})
			.addCase(deleteRole.fulfilled, (state) => {
				state.deleteRoleLoading = false;
			})
			.addCase(deleteRole.rejected, (state, action) => {
				state.deleteRoleLoading = false;
				state.error = action.payload || 'Failed to delete role';
			})
			.addCase(fetchUsersInRole.pending, (state) => {
				state.fetchUsersInRoleLoading = true;
				state.error = null;
			})
			.addCase(fetchUsersInRole.fulfilled, (state, action) => {
				state.fetchUsersInRoleLoading = false;
				state.usersInRole = action.payload;
			})
			.addCase(fetchUsersInRole.rejected, (state, action) => {
				state.fetchUsersInRoleLoading = false;
				state.error = action.payload || 'Failed to fetch users in role';
			})
			.addCase(addUserToRole.pending, (state) => {
				state.addUserToRoleLoading = true;
				state.error = null;
			})
			.addCase(addUserToRole.fulfilled, (state) => {
				state.addUserToRoleLoading = false;
			})
			.addCase(addUserToRole.rejected, (state, action) => {
				state.addUserToRoleLoading = false;
				state.error = action.payload || 'Failed to add user to role';
			})
			.addCase(removeUserFromRole.pending, (state) => {
				state.removeUserFromRoleLoading = true;
				state.error = null;
			})
			.addCase(removeUserFromRole.fulfilled, (state) => {
				state.removeUserFromRoleLoading = false;
			})
			.addCase(removeUserFromRole.rejected, (state, action) => {
				state.removeUserFromRoleLoading = false;
				state.error = action.payload || 'Failed to remove user from role';
			})
			.addCase(bulkUpdateRoleUsers.pending, (state) => {
				state.bulkUpdateRoleUsersLoading = true;
				state.error = null;
			})
			.addCase(bulkUpdateRoleUsers.fulfilled, (state) => {
				state.bulkUpdateRoleUsersLoading = false;
			})
			.addCase(bulkUpdateRoleUsers.rejected, (state, action) => {
				state.bulkUpdateRoleUsersLoading = false;
				state.error = action.payload || 'Failed to update role users';
			})
			.addCase(bulkUpdateRoleScopes.pending, (state) => {
				state.bulkUpdateRoleScopesLoading = true;
				state.error = null;
			})
			.addCase(bulkUpdateRoleScopes.fulfilled, (state) => {
				state.bulkUpdateRoleScopesLoading = false;
			})
			.addCase(bulkUpdateRoleScopes.rejected, (state, action) => {
				state.bulkUpdateRoleScopesLoading = false;
				state.error = action.payload || 'Failed to update role scopes';
			});
	},
});

export const { clearRoles, clearSelectedRole, clearUsersInRole, clearRolesState } = rolesSlice.actions;
export default rolesSlice.reducer;
