export const replaceEmptyWithNull: (value: any) => any = (value: any) => {
  if (['', undefined, null].includes(value)) {
    return null;
  }

  if (Array.isArray(value)) {
    return value.map((item: any) => {
      // Do nothing for empty items in arrays but look at nested arrays and objects
      if (typeof item === 'object' && item !== null) {
        return replaceEmptyWithNull(item);
      }

      return item;
    });
  }

  if (typeof value === 'object') {
    for (const key in value) {
      // Make sure we aren't affecting any inherited properties
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        // Intentionally replacing empty values with null so we do want to mutate
        // eslint-disable-next-line no-param-reassign
        value[key] = replaceEmptyWithNull(value[key]);
      }
    }
  }

  return value;
};
