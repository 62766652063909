import React, { useEffect, useState, useCallback } from 'react';
import { Form, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'store/store';
import { clearAuthState, login } from 'store/slices/authSlice';
import { setGlobalOrgId } from 'store/slices/globalOrgSlice';
import { describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { describeUser } from 'store/slices/usersSlice';
import { LoginPayload } from 'types/authTypes';
import LoginForm from './LoginForm';
import './login.scss';

const LogIn: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { auth, loginLoading, error } = useSelector((state: RootState) => state.auth);

    const [autoRedirect, setAutoRedirect] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const identityId = localStorage.getItem('identityId');
            const createdAt = localStorage.getItem('createdAt');
            const expiresAt = localStorage.getItem('expiresAt');
            const tokenScopeList = localStorage.getItem('tokenScopeList');

            if (identityId && createdAt && expiresAt && tokenScopeList) {
                const storedAuthData = {
                    token,
                    identityId,
                    createdAt,
                    expiresAt,
                    tokenScopeList: JSON.parse(tokenScopeList),
                };
                dispatch({
                    type: 'auth/setAuthData',
                    payload: {
                        data: [storedAuthData],
                        meta: null,
                        totalCount: 1,
                        filteredCount: 1,
                    },
                });
            }
        }
    }, [dispatch]);

    const handleOrganizationSelection = useCallback(async (orgId: number, userId: number) => {
        dispatch(setGlobalOrgId(orgId));
        dispatch(describeUser({ orgId, userId, global: true }));

        const locationsResult = await dispatch(fetchLocations({ orgId, status: 1 }));
        if (fetchLocations.fulfilled.match(locationsResult) && locationsResult.payload.data.length !== 0) {
            const firstLocation = locationsResult.payload.data[0];
            if (firstLocation.id) {
                await dispatch(describeLocation({ orgId, locationId: firstLocation.id, global: true }));
                await dispatch(describeLocationWorkflow({ orgId, locationId: firstLocation.id, global: true }));
            }
        }
        navigate(`/dashboard`, { replace: true });
    }, [dispatch, navigate]);

    useEffect(() => {
        if (auth.data.length === 1 && auth.data[0].tokenScopeList.length === 1) {
            const singleOrg = auth.data[0].tokenScopeList[0].org;
            const singleUser = auth.data[0].tokenScopeList[0].user;
            setAutoRedirect(true);
            if (singleOrg.id != null && singleUser.id != null) {
                handleOrganizationSelection(singleOrg.id, singleUser.id);
            }
        }
    }, [auth, handleOrganizationSelection]);

    const onFinish = async (values: LoginPayload) => {
        await dispatch(login(values));
    };

    const handleBack = () => {
        dispatch(clearAuthState());
    };

    if (autoRedirect) {
        return null;
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper__left">
                <div className="auth-wrapper__left-content">
                    <div className="auth-wrapper__left-logo">
                        <img src="/images/invisit_full.png" alt="Logo" />
                    </div>
                    <div className="login">
                        {auth.data.length === 0 ? (
                            <LoginForm onFinish={onFinish} error={error} loading={loginLoading} />
                        ) : (
                            <>
                                {auth.data[0].tokenScopeList.map((item: any) => (
                                    <Form.Item key={item.org.id}>
                                        <Button
                                            type="primary"
                                            className="button"
                                            onClick={() => handleOrganizationSelection(item.org.id, item.user.id)}
                                        >
                                            {item.org.name}
                                        </Button>
                                    </Form.Item>
                                ))}
                                <Button onClick={handleBack} className="button">Back</Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="auth-wrapper__right">
                <div className="auth-wrapper__right">
                    {/* Marketing text or other content */}
                </div>
            </div>
        </div>
    );
};

export default LogIn;
