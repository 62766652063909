import React, { useState, useEffect } from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpDrawer } from 'components/customAntd/DLS/OpDrawer/OpDrawer';
import { useDispatch, useSelector } from 'react-redux';

import VisitorsContent from './tabs/visitorsTab/VisitorsContent';
import DetailsContent from './tabs/DetailsContent';

import { AppDispatch, RootState } from 'store/store';

import STATUS from 'constants/status';

import VisitorsModal from './VisitorsModal';
import { DRAWER_WIDTH } from 'constants/ui';
import NotesContent from './tabs/NotesContent';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import EditVisitModal from './EditVisitModal';
import { updateVisit } from 'store/slices/visitsSlice';
import { Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import { hasPermission } from 'utils/utils';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';

interface VisitorsDrawerProps {
	open: boolean;
	onClose: () => void;
}

const VisitorsDrawer: React.FC<VisitorsDrawerProps> = ({ open, onClose }) => {
	const dispatch: AppDispatch = useDispatch();
	const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
	const confirmModal = useConfirmModal();

	const { selectedVisit, describeVisitLoading, updateVisitLoading } = useSelector((state: RootState) => state.visits);
	const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

	const [activeKey, setActiveKey] = useState<string>('visitors');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newStatus, setNewStatus] = useState<number>(0);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [visitorForm] = OpForm.useForm();
	const [detailsForm] = OpForm.useForm();

	const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

	const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');
	const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
	const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
	const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');
	const hasDenyWrite = hasPermission(tokenScopeList, orgId, 'o', 'deny:w');

	const hasVisitorlogRead = hasPermission(tokenScopeList, orgId, 'o', 'visitorlog:r');

	useEffect(() => {
		if (open) {
			setActiveKey('visitors');
			// Reset forms whenever the drawer opens to ensure they start fresh
			visitorForm.resetFields();
			detailsForm.resetFields();
		}
	}, [open, detailsForm, visitorForm]);

	const items = [
		{
			key: 'visitors',
			label: 'Visitors',
			children: <VisitorsContent form={visitorForm} onClose={onClose} />,
		},
		{
			key: 'details',
			label: 'Details',
			children: <DetailsContent form={detailsForm} />,
		},
		...(hasVisitorlogRead ? [{
			key: 'notes',
			label: 'Notes',
			children: <NotesContent />,
		}] : []),
	];

	const getFooterButtons = () => {
		const hasPendingVisitors = selectedVisit?.visitors.some(visitor => visitor.status === STATUS.PENDING.id);
		const hasSignedInVisitors = selectedVisit?.visitors.some(visitor => visitor.status === STATUS.SIGNED_IN.id);
		if (!describeVisitLoading && !updateVisitLoading) {
			return (
				<>
					{(hasAllvisitorsWrite || (globalUserId === selectedVisit?.host.userId)) && (
						<>
							{hasPendingVisitors && (
								<>
									{hasSigninWrite && (
										<OpButton
											type="primary"
											ghost
											style={{ width: "100%", marginBottom: "10px" }}
											onClick={() => {
												setIsModalOpen(true);
												setNewStatus(Number(STATUS.SIGNED_IN.id));
											}}
										>
											Select Visitors to Sign In
										</OpButton>
									)}
									{hasDenyWrite && (
										<OpButton
											type="primary"
											danger
											ghost
											style={{ width: "100%", marginBottom: "10px" }}
											onClick={() => {
												setIsModalOpen(true);
												setNewStatus(Number(STATUS.DENIED_ENTRY.id));
											}}
										>
											Deny Entry
										</OpButton>
									)}
								</>
							)}
							{hasSignedInVisitors && hasSignoutWrite && (
								<OpButton
									type="primary"
									ghost
									style={{ width: "100%" }}
									onClick={() => {
										setIsModalOpen(true);
										setNewStatus(Number(STATUS.SIGNED_OUT.id));
									}}
								>
									Select Visitors to Sign Out
								</OpButton>
							)}
						</>
					)}
				</>
			);
		}
	};

	const editVisit = async (updatedVisit: any) => {
		let startDate = updatedVisit.date ? dayjs(updatedVisit.date).hour(dayjs(updatedVisit.startTime).hour()).minute(dayjs(updatedVisit.startTime).minute()) : null;
		let endDate = updatedVisit.date ? dayjs(updatedVisit.date).hour(dayjs(updatedVisit.endTime).hour()).minute(dayjs(updatedVisit.endTime).minute()) : null;
		// Adjust endDate to the next day if it's before startDate
		if (endDate && startDate && endDate.isSameOrBefore(startDate)) {
			endDate = endDate.add(1, 'day');
		}

		if (startDate && endDate && endDate.isSameOrBefore(startDate)) {
			notification.error({
				message: 'Error',
				description: 'End time must be after the start time.',
				placement: 'bottomRight',
			});
			return;
		}

		if (selectedVisit) {
			const payload = {
				company: updatedVisit.company,
				siteId: Number(updatedVisit.siteId),
				visitorType: Number(updatedVisit.visitorTypeId),
				hostId: Number(updatedVisit.hostId),
				purpose: updatedVisit.purpose,
				signIn: updatedVisit.signIn?.format('YYYY-MM-DD HH:mm:ss'),
				signOut: updatedVisit.signOut?.format('YYYY-MM-DD HH:mm:ss'),
				scheduleIn: startDate?.format('YYYY-MM-DD HH:mm:ss'),
				scheduleOut: endDate?.format('YYYY-MM-DD HH:mm:ss'),
			};

			try {
				setIsModalVisible(false);
				await dispatch(updateVisit({
					orgId,
					visitId: selectedVisit.id,
					visitPayload: payload
				}));
				notification.success({
					message: 'Success',
					description: 'Visit updated successfully',
					placement: 'bottomRight',
				});
			} catch (error) {
				console.error('Failed to update visit:', error);
				notification.error({
					message: 'Error',
					description: 'Failed to update visit',
					placement: 'bottomRight',
				});
			}
		}
	};

	const onDeleteClick = () => {
		confirmModal({
			title: 'Delete Confirmation',
			content: 'Are you sure you want to delete this visit? This can not be undone.',
			okText: 'Yes',
			cancelText: 'No',
			onOk: async () => {
				onClose();
				await dispatch(updateVisit({
					orgId,
					visitId: selectedVisit?.id!,
					visitPayload: {
						status: 0
					}
				}));
				notification.success({
					message: 'Success',
					description: 'Visit deleted successfully',
					placement: 'bottomRight',
				});
			},
		});
	};

	return (
		<>
			<OpDrawer
				title='Visitors'
				width={DRAWER_WIDTH}
				open={open}
				onClose={onClose}
				footer={getFooterButtons()}
				extra={
					<OpSpace>
						{((selectedVisit?.visitStatus.id === 1 && hasInviteWrite) ||
							(selectedVisit?.visitStatus.id === 2 && hasSigninWrite) ||
							(selectedVisit?.visitStatus.id === 3 && hasSignoutWrite)) && (hasAllvisitorsWrite || (globalUserId === selectedVisit.host.userId)) && (
								<>
									{selectedVisit?.visitStatus.id === 1 && selectedVisit.visitors.every(visitor => visitor.status === 1) && (
										<OpButton type="primary" danger ghost onClick={onDeleteClick}>
											Delete
										</OpButton>
									)}
									<OpButton type="primary" ghost onClick={() => setIsModalVisible(true)}>
										Edit
									</OpButton>
								</>
							)}
					</OpSpace>
				}
			>
				{describeVisitLoading || updateVisitLoading ? (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
					</div>
				) : (
					<OpTabs
						activeKey={activeKey}
						onChange={(newActiveKey: string) => setActiveKey(newActiveKey)}
						items={items}
					/>
				)}
				<VisitorsModal
					open={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					newStatus={newStatus}
				/>
			</OpDrawer >
			<EditVisitModal
				open={isModalVisible}
				onOk={editVisit}
				onCancel={() => setIsModalVisible(false)}
				initialData={selectedVisit}
			/>
		</>
	);
};

export default VisitorsDrawer;
