import { ComponentProps } from 'react';
import Drawer from 'antd/es/drawer';

import './OpDrawer.scss';

interface IOpDrawerProps extends ComponentProps<typeof Drawer> {}

export const OpDrawer = ({
  children,
  placement = 'right',
  className = '',
  maskClassName = '',
  onClose,
  ...drawerProps
}: IOpDrawerProps) => {
  const classes = `op-drawer ${className}`.trim();
  const maskClasses = `op-drawer-mask ${maskClassName}`.trim();

  return (
    <Drawer
      placement={placement}
      className={classes}
      maskClassName={maskClasses}
      onClose={onClose}
      {...drawerProps}
    >
      {children}
    </Drawer>
  );
};
