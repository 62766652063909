import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Agreement, AgreementContent, CreateAgreementPayload, UpdateAgreementPayload, UpdateAgreementContentPayload } from 'types/agreementTypes';

interface AgreementsState {
    agreements: ApiResponse<Agreement>;
    selectedAgreement: Agreement | null;
    selectedDocumentContent: AgreementContent | null;
    fetchAgreementsLoading: boolean;
    describeAgreementLoading: boolean;
    createAgreementLoading: boolean;
    updateAgreementLoading: boolean;
    deleteAgreementLoading: boolean;
    describeAgreementContentLoading: boolean;
    updateAgreementContentLoading: boolean;
    error: string | null;
}

const initialState: AgreementsState = {
    agreements: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    selectedAgreement: null,
    selectedDocumentContent: null,
    fetchAgreementsLoading: false,
    describeAgreementLoading: false,
    createAgreementLoading: false,
    updateAgreementLoading: false,
    deleteAgreementLoading: false,
    describeAgreementContentLoading: false,
    updateAgreementContentLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchAgreements = createAsyncThunk<ApiResponse<Agreement>, { orgId: number; status?: number; }, { rejectValue: string }>(
    'agreements/fetchAgreements',
    async ({ orgId, status }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Agreement>>(`${apiUrl}/orgs/${orgId}/termsAgreements`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    status
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch agreements');
        }
    }
);

export const describeAgreement = createAsyncThunk<ApiResponse<Agreement>, { orgId: number; agreementId: number }, { rejectValue: string }>(
    'agreements/describeAgreement',
    async ({ orgId, agreementId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Agreement>>(`${apiUrl}/orgs/${orgId}/termsAgreements/${agreementId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch agreement details');
        }
    }
);

export const createAgreement = createAsyncThunk<ApiResponse<Agreement>, { orgId: number; agreement: Partial<CreateAgreementPayload> }, { rejectValue: string; dispatch: any }>(
    'agreements/createAgreement',
    async ({ orgId, agreement }, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(`${apiUrl}/orgs/${orgId}/termsAgreements`, agreement, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchAgreements({ orgId }));
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create agreement');
        }
    }
);

export const updateAgreement = createAsyncThunk<void, { orgId: number; agreementId: number; agreement: Partial<UpdateAgreementPayload> }, { rejectValue: string; dispatch: any }>(
    'agreements/updateAgreement',
    async ({ orgId, agreementId, agreement }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/termsAgreements/${agreementId}`, agreement, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchAgreements({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update agreement');
        }
    }
);

export const deleteAgreement = createAsyncThunk<void, { orgId: number; agreementId: number }, { rejectValue: string; dispatch: any }>(
    'agreements/deleteAgreement',
    async ({ orgId, agreementId }, { dispatch, rejectWithValue }) => {
        try {
            await axios.delete(`${apiUrl}/orgs/${orgId}/termsAgreements/${agreementId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchAgreements({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to delete agreement');
        }
    }
);

export const describeAgreementContent = createAsyncThunk<ApiResponse<AgreementContent>, { orgId: number; agreementId: number }, { rejectValue: string }>(
    'agreements/describeAgreementContent',
    async ({ orgId, agreementId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<AgreementContent>>(`${apiUrl}/orgs/${orgId}/termsAgreements/${agreementId}/documentContent`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to get agreement content');
        }
    }
);

export const updateAgreementContent = createAsyncThunk<void, { orgId: number; agreementId: number; content: Partial<UpdateAgreementContentPayload> }, { rejectValue: string; dispatch: any }>(
    'agreements/updateAgreementContent',
    async ({ orgId, agreementId, content }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/termsAgreements/${agreementId}/documentContent`, content, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeAgreementContent({ orgId, agreementId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update agreement content');
        }
    }
);

const agreementsSlice = createSlice({
    name: 'agreements',
    initialState,
    reducers: {
        clearAgreements(state) {
            state.agreements = initialState.agreements;
        },
        clearSelectedAgreement(state) {
            state.selectedAgreement = initialState.selectedAgreement;
            state.selectedDocumentContent = initialState.selectedDocumentContent;
        },
        clearAgreementsState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgreements.pending, (state) => {
                state.fetchAgreementsLoading = true;
                state.error = null;
            })
            .addCase(fetchAgreements.fulfilled, (state, action) => {
                state.fetchAgreementsLoading = false;
                state.agreements = action.payload;
            })
            .addCase(fetchAgreements.rejected, (state, action) => {
                state.fetchAgreementsLoading = false;
                state.error = action.payload || 'Failed to fetch agreements';
            })
            .addCase(describeAgreement.pending, (state) => {
                state.describeAgreementLoading = true;
                state.error = null;
            })
            .addCase(describeAgreement.fulfilled, (state, action) => {
                state.describeAgreementLoading = false;
                state.selectedAgreement = action.payload.data[0];
            })
            .addCase(describeAgreement.rejected, (state, action) => {
                state.describeAgreementLoading = false;
                state.error = action.payload || 'Failed to fetch agreement details';
            })
            .addCase(createAgreement.pending, (state) => {
                state.createAgreementLoading = true;
                state.error = null;
            })
            .addCase(createAgreement.fulfilled, (state) => {
                state.createAgreementLoading = false;
            })
            .addCase(createAgreement.rejected, (state, action) => {
                state.createAgreementLoading = false;
                state.error = action.payload || 'Failed to create agreement';
            })
            .addCase(updateAgreement.pending, (state) => {
                state.updateAgreementLoading = true;
                state.error = null;
            })
            .addCase(updateAgreement.fulfilled, (state) => {
                state.updateAgreementLoading = false;
            })
            .addCase(updateAgreement.rejected, (state, action) => {
                state.updateAgreementLoading = false;
                state.error = action.payload || 'Failed to update agreement';
            })
            .addCase(deleteAgreement.pending, (state) => {
                state.deleteAgreementLoading = true;
                state.error = null;
            })
            .addCase(deleteAgreement.fulfilled, (state) => {
                state.deleteAgreementLoading = false;
            })
            .addCase(deleteAgreement.rejected, (state, action) => {
                state.deleteAgreementLoading = false;
                state.error = action.payload || 'Failed to delete agreement';
            })
            .addCase(describeAgreementContent.pending, (state) => {
                state.describeAgreementContentLoading = true;
                state.error = null;
            })
            .addCase(describeAgreementContent.fulfilled, (state, action) => {
                state.describeAgreementContentLoading = false;
                state.selectedDocumentContent = action.payload.data[0];
            })
            .addCase(describeAgreementContent.rejected, (state, action) => {
                state.describeAgreementContentLoading = false;
                state.error = action.payload || 'Failed to get agreement content';
            })
            .addCase(updateAgreementContent.pending, (state) => {
                state.updateAgreementContentLoading = true;
                state.error = null;
            })
            .addCase(updateAgreementContent.fulfilled, (state) => {
                state.updateAgreementContentLoading = false;
            })
            .addCase(updateAgreementContent.rejected, (state, action) => {
                state.updateAgreementContentLoading = false;
                state.error = action.payload || 'Failed to update agreement content';
            });
    },
});

export const { clearAgreements, clearSelectedAgreement, clearAgreementsState } = agreementsSlice.actions;
export default agreementsSlice.reducer;
