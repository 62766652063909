import React, { useCallback } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { notification } from 'antd';
import { updateUser } from 'store/slices/usersSlice';

interface InfoContentProps {
    form: any;
}

const InfoContent: React.FC<InfoContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { globalUser } = useSelector((state: RootState) => state.users);

    const initialValues = {
        identity: {
            email: globalUser?.identity?.email,
            firstName: globalUser?.identity?.firstName,
            middleName: globalUser?.identity?.middleName,
            lastName: globalUser?.identity?.lastName,
        }
    };

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        if (globalUser?.id) {
            const identity = Object.fromEntries(
                Object.entries(touchedValues.identity).map(([key, value]) => [key, value === null ? '' : value])
            );

            const userPayload = {
                identity
            };

            console.log("userPayload", userPayload);
            dispatch(updateUser({ orgId, userId: globalUser.id, user: userPayload, global: true }));
            notification.success({
                message: 'Success',
                description: 'User updated successfully',
                placement: 'bottomRight',
            });
        }
    }, [dispatch, orgId, globalUser?.id]);

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
        >
            <OpForm.Input
                label="Email"
                name={['identity', 'email']}
                rules={[{ required: true, message: 'Email is required', type: 'email' }]}
                disabled
            />
            <OpForm.Input
                label="First Name"
                name={['identity', 'firstName']}
                rules={[{ required: true, message: 'First name is required' }]}
            />
            <OpForm.Input
                label="Middle Name"
                name={['identity', 'middleName']}
            />
            <OpForm.Input
                label="Last Name"
                name={['identity', 'lastName']}
            />
        </OpForm>
    );
};

export default InfoContent;
