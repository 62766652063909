import { ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/es/select';
import get from 'lodash/get';
import { IUseOpQueryOptions, useOpQuery } from 'utils/customHooks/useOpQuery';
import { useDebouncedState } from 'utils/customHooks/useDebouncedState';
import { ensurePayloadAndQuery } from 'utils/ensurePayloadAndQuery';
import { OpSelect } from '../OpSelect/OpSelect';
// import { Api } from '../../../types/apiTypes';
// import { Utils } from '../../../types/utilsTypes';

type Option = Pick<DefaultOptionType, 'label' | 'value'>;

// type CustomOptionRenderType = ({
//   label,
//   item,
//   value,
// }: {
//   label: ReactNode;
//   item: any;
//   value: string | number;
// }) => ReactNode;

// type BaseOpSelectProps = Omit<ComponentProps<typeof OpSelect>,
//   'showSearch' | 'filterOption' | 'onSearch' | 'notFoundContent' | 'options' | 'optionRender'>;

// interface IOpDataFetchSelectProps<T extends string | number | symbol> extends BaseOpSelectProps  {
//   queryOptions: IUseOpQueryOptions<T>;
//   pathToData?: string;
//   pathToLabel?: string | string[];
//   pathToValue?: string;
//   optionRender?: CustomOptionRenderType;
//   testId?: string;
//   gtm?: string;
// }

const getLabelWithFallback = (
  item: Record<string, unknown>,
  pathToLabel: string[],
): string | null => {
  let label = null;

  pathToLabel.find((path) => {
    label = get(item, path);
    return Boolean(label);
  });
  return label;
};

export const OpDataFetchSelect = ({
  className = '',
  testId = 'op-data-fetch-select',
  gtm,
  queryOptions,
  pathToData = 'json.data',
  pathToLabel = 'name',
  pathToValue = 'id',
  optionRender,
  onChange,
  value: rawValue,

  // Props passed through to OpSelect
  ...opSelectProps
}: any) => {
  const [debouncedQuery, setQuery] = useDebouncedState('');

  const { parametersArray, withQuery } = ensurePayloadAndQuery(
    queryOptions.apiEndpointName,
    queryOptions.parameters || {},
  );

  if (withQuery) {
    // Find the index of the queries (last object)
    const queriesIndex = parametersArray.findIndex(
      (param: any) => typeof param === 'object',
    );

    if (queriesIndex === -1) {
      // ensurePayloadAndQuery guarantees the presence of a queries object, so throw since something is way off...
      throw new Error(
        `Critical: ensurePayloadAndQuery did not ensure a query object for API endpoint ${queryOptions.apiEndpointName}`,
      );
    }

    parametersArray[queriesIndex] = {
      ...(parametersArray[queriesIndex] as Record<string, any>),
      ...(debouncedQuery && { q: debouncedQuery }),
    };
  }

  const finalQueryOptions = {
    ...queryOptions,
    parameters: parametersArray,
    getAll: true, // Use getAll for now to make sure we always have the options for the values
  } as IUseOpQueryOptions<any>;

  const { isFetching } = useOpQuery({
    ...finalQueryOptions,
  });

  const optionData: any[] = [];

  let options: Option[] = [];
  let optionChildren: ReactNode[] = [];

  if (Array.isArray(optionData)) {
    if (optionRender) {
      optionChildren = optionData.map((item) =>
        optionRender({
          label: Array.isArray(pathToLabel)
            ? getLabelWithFallback(item, pathToLabel)
            : get(item, pathToLabel),
          value: String(get(item, pathToValue)), // Must make string as we expect string | string[] in handleChange below
          item,
        }),
      );
    } else {
      options = optionData.map((item) => ({
        label: Array.isArray(pathToLabel)
          ? getLabelWithFallback(item, pathToLabel)
          : get(item, pathToLabel),
        value: String(get(item, pathToValue)), // Must make string as we expect string | string[] in handleChange below
      }));
    }
  } else {
    console.error('Data response needs to be an array for OpDataFetchSelect');
  }

  const handleSearch = (newValue: string) => {
    setQuery(newValue);
  };

  const handleChange = (
    value: string | string[],
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    // Update the value if an onChange was passed
    onChange?.(value, option);

    // Reset the query so we see all options again
    setQuery('');
  };

  /** Manipulating the value so that we make sure we don't have issues in non-ts
   * files. Doing this as Select will display the value if there is no option value
   * that matches (e.g. a number value passed will not match any of the coerced
   * option values [see code above]) */
  const finalValue = !rawValue
    ? rawValue
    : Array.isArray(rawValue)
      ? rawValue.map((v) => String(v))
      : String(rawValue);

  return (
    <OpSelect
      className={`${className}`.trim()}
      testId={testId}
      gtm={gtm}
      showSearch
      filterOption={false} // Needed so options menu doesn't close on change
      onSearch={handleSearch}
      loading={isFetching === true && !debouncedQuery.length}
      notFoundContent={isFetching ? <LoadingOutlined /> : <div>No results</div>}
      options={options.length > 0 ? options : undefined}
      onChange={handleChange}
      value={finalValue}
      {...opSelectProps}
    >
      {optionChildren}
    </OpSelect>
  );
};
