import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { VisitorType } from 'types/VisitorTypeTypes';

interface VisitorTypesState {
    visitorTypes: ApiResponse<VisitorType>;
    fetchVisitorTypesLoading: boolean;
    error: string | null;
}

const initialState: VisitorTypesState = {
    visitorTypes: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchVisitorTypesLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchVisitorTypes = createAsyncThunk<ApiResponse<VisitorType>, { orgId: number }, { rejectValue: string }>(
    'visitorTypes/fetchVisitorTypes',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorType>>(`${apiUrl}/orgs/${orgId}/visitorTypes`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visitor types');
        }
    }
);

const visitorTypesSlice = createSlice({
    name: 'visitorTypes',
    initialState,
    reducers: {
        clearVisitorTypesState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVisitorTypes.pending, (state) => {
                state.fetchVisitorTypesLoading = true;
                state.error = null;
            })
            .addCase(fetchVisitorTypes.fulfilled, (state, action) => {
                state.visitorTypes = action.payload;
                state.fetchVisitorTypesLoading = false;
            })
            .addCase(fetchVisitorTypes.rejected, (state, action) => {
                state.fetchVisitorTypesLoading = false;
                state.error = action.payload || 'Failed to fetch visitor types';
            });
    },
});

export const { clearVisitorTypesState } = visitorTypesSlice.actions;
export default visitorTypesSlice.reducer;
