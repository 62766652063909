import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout } from "antd";
import {
    PushpinOutlined,
    LineChartOutlined,
    UserOutlined,
    WarningOutlined,
    TeamOutlined,
    PieChartOutlined,
    SlidersOutlined,
    TabletOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import { THEME } from '../../../op-themes';
import { OpMenu } from '../../../components/customAntd/DLS/OpMenu/OpMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { hasPermission } from 'utils/utils';
import FeedbackModal from './FeedbackModal';
import MobileAppModal from './MobileAppModal';

const { Sider } = Layout;

type MenuItem = {
    key: React.Key;
    icon?: React.ReactNode;
    children?: MenuItem[];
    label: React.ReactNode;
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    };
}

const SideMenu: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const currentTheme = isDarkMode ? THEME.dark : THEME.light;
    const collapsed = useSelector((state: RootState) => state.ui.collapsed);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [mobileAppModalOpen, setMobileAppModalOpen] = useState(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const hasLocationRead = hasPermission(tokenScopeList, orgId, 'o', 'location:r');
    const hasUsersRead = hasPermission(tokenScopeList, orgId, 'o', 'user:r');
    const hasRolesRead = hasPermission(tokenScopeList, orgId, 'o', 'role:r');
    const hasKiosksRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasRedFlagConfRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasInvitationRead = hasPermission(tokenScopeList, orgId, 'o', 'invitation:r');
    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSignoutconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r');
    const hasAgreementsRead = hasPermission(tokenScopeList, orgId, 'o', 'agreements:r');
    const hasRptVisitorRead = hasPermission(tokenScopeList, orgId, 'o', 'rptvisitor:r');
    const hasRptTrendRead = hasPermission(tokenScopeList, orgId, 'o', 'rpttrend:r');
    const hasRptRedFlagRead = hasPermission(tokenScopeList, orgId, 'o', 'rptredflag:r');
    const hasRptHostRead = hasPermission(tokenScopeList, orgId, 'o', 'rpthost:r');
    const hasRptActivityRead = hasPermission(tokenScopeList, orgId, 'o', 'rptactivity:r');

    const showLocations = hasLocationRead;
    const showUsers = hasUsersRead;
    const showRoles = hasRolesRead;
    const showKiosks = hasKiosksRead;
    const showRedFlag = hasRedFlagConfRead;
    const showInvitationOptions = hasInvitationRead;
    const showWorkflow = hasSigninconfRead || hasSignoutconfRead;
    const showAgreements = hasAgreementsRead;
    const showRptVisitor = hasRptVisitorRead;
    const showRptTrend = hasRptTrendRead;
    const showRptRedFlag = hasRptRedFlagRead;
    const showRptHost = hasRptHostRead;
    const showRptActivity = hasRptActivityRead;

    useEffect(() => {
        const pathSnippets = location.pathname.split('/').filter(i => i);
        const openKey = `/${pathSnippets[0]}`;
        setOpenKeys([openKey]);
    }, [location.pathname]);

    const handleOpenChange = (keys: string[]) => {
        setOpenKeys(keys);
    };

    const items: MenuItem[] = [
        getItem("Dashboard", "/dashboard", <PieChartOutlined style={{ fontSize: '125%' }} />),
        getItem("Visitors", "/visitors", <TeamOutlined style={{ fontSize: '125%' }} />),
        ...(showLocations ? [getItem("Locations", "/locations", <PushpinOutlined style={{ fontSize: '125%' }} />)] : []),
        ...(showUsers || showRoles ? [
            getItem("Users", "/users", <UserOutlined style={{ fontSize: '125%' }} />, [
                ...(showUsers ? [getItem("Users", "/users/users")] : []),
                ...(showRoles ? [getItem("Roles", "/users/roles")] : []),
            ])
        ] : []),
        ...(showKiosks ? [getItem("Kiosks", "/kiosks", <TabletOutlined style={{ fontSize: '125%' }} />)] : []),
        ...(showRedFlag ? [getItem("Red Flag", "/redFlag", <WarningOutlined style={{ fontSize: '125%' }} />)] : []),
        ...(showInvitationOptions || showWorkflow || showAgreements ? [
            getItem("Configuration", "/configuration", <SlidersOutlined style={{ fontSize: '125%' }} />, [
                ...(showInvitationOptions ? [getItem("Invitation Options", "/configuration/invitationOptions")] : []),
                ...(showWorkflow ? [getItem("Workflow", "/configuration/workflow")] : []),
                ...(showAgreements ? [getItem("Agreements", "/configuration/agreements")] : []),
            ])
        ] : []),
        ...(showRptVisitor || showRptActivity ? [
            getItem("Reports", "/reports", <LineChartOutlined style={{ fontSize: '125%' }} />, [
                ...(showRptVisitor ? [getItem("Visitor Report", "/reports/visitorReport")] : []),
                ...(showRptTrend ? [getItem("Repeat Visitors", "/reports/repeatVisitors")] : []),
                ...(showRptRedFlag ? [getItem("Red Flag Report", "/reports/redFlagReport")] : []),
                ...(showRptHost ? [getItem("Host Report", "/reports/hostReport")] : []),
                ...(showRptActivity ? [getItem("Activity Log", "/reports/activityLog")] : []),
            ])
        ] : []),
        getItem("Support", "/support", <QuestionCircleOutlined style={{ fontSize: '125%' }} />, [
            getItem(
                <span onClick={(e) => e.stopPropagation()}>
                    <a href="https://commaowl.com/?83FJ6X" target="_blank" rel="noopener noreferrer">User Manual</a>
                </span>,
                "usermanual"
            ),
            getItem(
                <span onClick={() => setFeedbackModalOpen(true)}>
                    Send Feedback
                </span>,
                "sendFeedback"
            ),
            getItem(
                <span onClick={() => setMobileAppModalOpen(true)}>
                    Mobile App
                </span>,
                "mobileApp"
            )
        ]),
    ].flat();

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            trigger={null}
            width={280}
            style={{
                background: currentTheme.token.colorBgLayout,
                overflowY: 'auto'
            }}
        >
            {!collapsed && (
                <style>
                    {`
                        .ant-menu-sub .ant-menu-item {
                            padding-left: 59px !important;
                        }
                    `}
                </style>
            )}

            <OpMenu
                mode="inline"
                defaultSelectedKeys={['dashboard']}
                onClick={e => {
                    if (e.key === 'sendFeedback') {
                        setFeedbackModalOpen(true);
                    } else if (e.key === 'mobileApp') {
                        setMobileAppModalOpen(true);
                    } else {
                        navigate(e.key);
                    }
                }}
                style={{ height: '100%', borderRight: 0, background: currentTheme.token.colorBgLayout }}
                openKeys={openKeys}
                onOpenChange={handleOpenChange}
                selectedKeys={[location.pathname]}
                items={items}
            />

            <FeedbackModal
                open={feedbackModalOpen}
                onClose={() => setFeedbackModalOpen(false)}
            />
            <MobileAppModal
                open={mobileAppModalOpen}
                onClose={() => setMobileAppModalOpen(false)} />
        </Sider>
    );
};

export default SideMenu;
