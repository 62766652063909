import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import AgreementsTable from './AgreementsTable';
import AgreementsDrawer from './AgreementsDrawer';
import { AGREEMENTS_TOOLTIP } from 'constants/tooltip';

const Agreements: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <OpPage title="Agreements" tooltip={AGREEMENTS_TOOLTIP} subtitle="">
            <AgreementsTable setDrawerVisible={setDrawerVisible} />
            <AgreementsDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </OpPage>
    );
}

export default Agreements;
