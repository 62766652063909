import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalOrgState {
	globalOrgId: number;
}

const initialGlobalOrgState: GlobalOrgState = {
	globalOrgId: 0,
};

const globalOrgSlice = createSlice({
	name: 'globalOrg',
	initialState: initialGlobalOrgState,
	reducers: {
		setGlobalOrgId(state, action: PayloadAction<number>) {
			state.globalOrgId = action.payload;
		},
		clearGlobalOrgState() {
			return initialGlobalOrgState;
		},
	},
});

export const { setGlobalOrgId, clearGlobalOrgState } = globalOrgSlice.actions;

export default globalOrgSlice.reducer;
