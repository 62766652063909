import { CountryCode, getCountryCallingCode, getCountries } from 'libphonenumber-js';

interface Country {
  name: string;
  short: CountryCode;
  phoneCode: string;
  emoji: string;
}

const countryNames = new Intl.DisplayNames(['en'], { type: 'region' });

const getCountryEmoji = (countryCode: CountryCode): string => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const countries: Country[] = getCountries().map((countryCode) => {
  return {
    name: countryNames.of(countryCode) || countryCode,
    short: countryCode,
    phoneCode: getCountryCallingCode(countryCode),
    emoji: getCountryEmoji(countryCode),
  };
});
