import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import RedFlagsTable from './RedFlagTable';
import RedFlagOverView from './RedFlagOverView';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import RedFlagsDrawer from './RedFlagsDrawer';
import { REDFLAG_TOOLTIP } from 'constants/tooltip';

const RedFlag: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <OpPage title="Red Flag" tooltip={REDFLAG_TOOLTIP} subtitle="">
            <OpSpace
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <RedFlagOverView />
                <RedFlagsTable setDrawerVisible={setDrawerVisible} />
            </OpSpace>

            <RedFlagsDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </OpPage>
    );
}

export default RedFlag;
