import {
    ComponentProps,
    Key,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd/es/table';
import {
    CsvExportButton,
    GlobalSearch,
    ShowHideColumnsButton,
    TableHeader,
    AddButton,
} from '../OpTableCore/components';
import {
    AllowExportType,
    IOpTableColumn,
    OpTableCore,
    OpTableRawColumnType,
    OpTableRecordType,
} from '../OpTableCore/OpTableCore';
import {
    convertRawColumnsToColumns,
    createFinalColumnKey,
    createTableWidth,
} from '../OpTableCore/helpers';
import { TablePagination } from '../OpTableCore/components/TablePagination';
import { RowActions } from '../OpDataFetchTable/components/RowActions';
import {
    BatchActions,
    IBatchActionsMenuItem,
} from '../OpTableCore/components/BatchActions';

export interface ITableState<
    RecordType extends OpTableRecordType = OpTableRecordType,
> {
    pagination: {
        current: number;
        pageSize: number;
    };
    sorter: SorterResult<RecordType> | SorterResult<RecordType>[];
    filters: Record<string, string | (string | number)[] | null>;
    q: string;
    columns: IOpTableColumn<RecordType>[];
}

export interface IOpRowActionFuncParams {
    record: OpTableRecordType;
    numRecords?: number;
}

export interface IOpRowActions {
    onEditClick?(record: unknown): void;
    onDeleteClick?(record: unknown): void;
    deleteModalTitle?: string | ((params: IOpRowActionFuncParams) => string);
    deleteModalContent?: string | ((params: IOpRowActionFuncParams) => string);
}

interface IAllowAddition {
    itemName: string;
    onClick(): void;
}

export interface IOpTableProps
    extends Omit<ComponentProps<typeof OpTableCore>, 'title' | 'columns'> {
    columns: OpTableRawColumnType[];
    testId?: string;
    height?: string | number;
    allowGlobalSearch?: boolean;
    allowExport?: AllowExportType;
    allowAddition?: boolean | IAllowAddition;
    allowShowHideColumns?: boolean;
    gtm?: string;
    rowActions?: IOpRowActions;
    label?: ReactNode;
    batchActionMenuItems?: IBatchActionsMenuItem[];
    onVisibleColumnsChange?: (visibleColumns: Set<string>) => void;
    writeAccess?: boolean
}

export const OpTable = ({
    dataSource = [],
    columns: rawColumns,
    rowKey = 'id',
    testId,
    height,
    rowActions,
    label: tableLabel,
    allowGlobalSearch = true,
    allowAddition = false,
    allowExport = true,
    allowShowHideColumns = true,
    gtm,
    pagination: tablePagination = {},
    batchActionMenuItems,
    onVisibleColumnsChange,
    writeAccess,
    ...tableProps
}: IOpTableProps) => {
    // Ref used to calculate if a row has been clicked, dragged, highlighted, etc
    const rowMouseDownTimestamp = useRef(0);

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

    const resetRowSelections = () => {
        setSelectedRowKeys([]);
    };

    /** Use rawColumns to determine if any column is filterable. If not, we want
     * to hide the filter portion from all column headers for a cleaner UI */
    const hasHeaderFilter = rawColumns.some(({ filter }) => Boolean(filter));

    /**
     * These updated columns should match columns that would be passed to an antd Table.
     * We want to keep the OpTables props as close to Antd's props as possible and use
     * custom props only when absolutely necessary. See how defaultSortOrder and
     * defaultFilteredValue are used below
     * */
    const defaultStateColumns = convertRawColumnsToColumns({
        rawColumns,
        hasHeaderFilter,
        resetRowSelections,
    });

    /** Antd will show UI for all columns with defaultSortOrder, but will only respect the
     * first one. Seems more logical to only show the first one in the UI as well as if
     * there are not 2 default sort orders then you are never allowed to sort by more than
     * one column. */
    const columnWithDefaultSort = defaultStateColumns.find(
        (column) => column.defaultSortOrder,
    );

    /*
    If dataSource is an array of objects like [{ id: 1, name: 'Alice' }, { id: 2, name: 'Bob' }], 
    then typeof dataSource would be something like Array<{id: number, name: string}>, 
    and (typeof dataSource)[0] would simplify to {id: number, name: string}. 
    Hence, ITableState<{id: number, name: string}> would define a table state suited to manage records with an id and name property.
    */
    const defaultState: ITableState<(typeof dataSource)[0]> = {
        // Pagination defaults
        pagination: {
            current: 1,
            pageSize: 100,
        },

        // Add a default sorter if there is one
        sorter: {
            column: columnWithDefaultSort,
            order: columnWithDefaultSort?.defaultSortOrder,
            columnKey: columnWithDefaultSort?.key,
            field: columnWithDefaultSort?.key
                ? [columnWithDefaultSort.key]
                : undefined,
        },

        /** Create filters based on how antd creates them. By default any filterable column
         * will have the column key with a value of null and any default values will be added
         * if there are any. */
        filters: rawColumns.reduce(
            (acc, { key, dataIndex, filter, defaultFilteredValue }) => {
                // Add the filter and defaultFilteredValue if it exists
                if (filter && (key || dataIndex)) {
                    const finalKey = createFinalColumnKey(key, dataIndex);

                    return {
                        ...acc,
                        [finalKey]: defaultFilteredValue || null,
                    };
                }

                return acc;
            },
            {},
        ),

        // Add the columns to state so we can show/hide columns dynamically
        columns: defaultStateColumns,

        // Table global search defaults
        q: '',
    };

    const [state, setState] = useState<ITableState<(typeof dataSource)[0]>>(defaultState);

    // We need to update the table columns if any async operations make a change to rawColumns
    useEffect(() => {
        setState((prevState) => {
            const updatedOpTableColumns = convertRawColumnsToColumns({
                rawColumns,
                hasHeaderFilter,
                resetRowSelections,
                tableState: prevState,
                setTableState: setState,
            });

            return {
                ...prevState,
                columns: updatedOpTableColumns,
            };
        });
    }, [hasHeaderFilter, rawColumns]);

    // Filtering is done separately so not included
    const onChange: TableProps<(typeof dataSource)[0]>['onChange'] = (
        _pagination,
        _filters,
        sorter,
    ) => {
        setState((prevState) => {
            return {
                ...prevState,
                sorter,
            };
        });
    };

    /** Width calculated based on the width of each column (default is 180px).
     * We define the width so that we can constrain the table width to the
     * container width and scroll horizontally */
    const tableWidth = createTableWidth(state.columns);

    /** Use state filters to filter the data source (filter returns back a new
     * array so we don't have to worry about modifying the original dataSource) */
    let filteredDataSource = dataSource.filter((record) => {
        let result = true;

        // For global filters
        if (state.q) {
            const filterableColumns = state.columns.filter(
                (column) => column.onFilter,
            );

            if (filterableColumns.length) {
                result = filterableColumns.some((column) =>
                    column.onFilter!(state.q.toLowerCase(), record),
                );
            }
        }

        // For column specific filters
        const filtersWithValues = Object.entries(state.filters).filter(
            ([_key, value]) => value && value.length,
        );

        if (filtersWithValues.length) {
            result =
                result &&
                // To support multiple column filters a record must match all filters
                filtersWithValues.every(([key, value]) => {
                    const columnOnFilter = state.columns.find(
                        (column) => column.key === key,
                    )?.onFilter;

                    return Array.isArray(value)
                        ? value.some((filter) => columnOnFilter!(filter, record))
                        : columnOnFilter!(value!, record);
                });
        }

        return result;
    });

    // Set the filtered count for pagination
    const filteredCount = filteredDataSource.length;
    // sorter can optionally be an array, so we need to type narrow
    if (!Array.isArray(state.sorter)) {
        const sorterOrder = state.sorter.order;
        const sorterColumnKey = state.sorter.columnKey;

        // Use state sorter to sort the filtered data
        if (sorterOrder && sorterColumnKey) {
            /** To match the default behavior of the internal table sorter,
             * we allow for a custom sort to be passed */
            if (
                state.sorter.column?.sorter &&
                typeof state.sorter.column.sorter === 'function'
            ) {
                filteredDataSource = filteredDataSource.sort(
                    state.sorter.column.sorter,
                );
                if (sorterOrder === 'descend') {
                    filteredDataSource.reverse();
                }
            } else {
                const sorted = filteredDataSource.sort((a, b) => {
                    const key = sorterColumnKey.toString(); // Convert the key to a string
                    // Being safe and checking the value exists
                    const aValue = a[key]
                        ? typeof a[key] === 'string'
                            ? a[key].toLowerCase()
                            : a[key]
                        : null;
                    const bValue = b[key]
                        ? typeof b[key] === 'string'
                            ? b[key].toLowerCase()
                            : b[key]
                        : null;

                    if (sorterOrder === 'ascend') {
                        /**
                         * Safeguard potential null values by pushing them to the end
                         */
                        if (!a) return 1;
                        if (!b) return -1;
                        if (aValue < bValue) {
                            return -1;
                        }

                        if (aValue > bValue) {
                            return 1;
                        }

                        return 0;
                    }

                    // Reverse of above
                    if (!a) return -1;
                    if (!b) return 1;

                    // Descend
                    if (bValue < aValue) {
                        return -1;
                    }

                    if (bValue > aValue) {
                        return 1;
                    }

                    return 0;
                });

                filteredDataSource = sorted;
            }
        }
    }

    // Use state pagination to paginate the filtered sorted data
    const finalDataSource = filteredDataSource.slice(
        (state.pagination.current - 1) * state.pagination.pageSize,
        state.pagination.current * state.pagination.pageSize,
    );

    // Remove hidden columns and massage data to conform to antd 5 table columns
    const finalColumns = state.columns.filter(({ hidden }) => !hidden);

    // Add an action column if rowActions are passed
    /** This is added as the last thing so that it persists and is
     * not part of the show/hide columns logic */
    if (rowActions) {
        let shouldIncludeWidth = true;

        Object.entries(tableProps).forEach(([propName, propValue]) => {
            /**
             * Explicitly passing scroll as undefined or null to turn off scroll thus we dont need to add a width.
             * This width was needed as the scroll prop adds `table-layout: fixed` which we then seemingly needed this for.
             * We should probably take another look at why this is needed at all
             */
            if (propName === 'scroll' && !propValue) {
                shouldIncludeWidth = false;
            }
        });

        finalColumns.push({
            key: 'op-actions',
            render: (_, record) => (
                <RowActions
                    {...{ rowActions, record, numRecords: dataSource.length, writeAccess }}
                />
            ),
            ...(shouldIncludeWidth && {
                width: 32 + 32 * Object.keys(rowActions).length, // 32 px as a base (16px horizontal padding) and 32 px per action button width looks nice for however many buttons are used.
            }),
            fixed: 'right',
        });
    }
    const tableTitle = () => (
        <TableHeader
            tableLabel={tableLabel}
            batchActions={
                batchActionMenuItems && (
                    <BatchActions
                        menuItems={batchActionMenuItems}
                        selectedRowKeys={selectedRowKeys}
                        resetRowSelections={resetRowSelections}
                    />
                )
            }
            globalSearch={
                allowGlobalSearch && (
                    <GlobalSearch
                        onGlobalSearchChange={({ target: { value } }) => {
                            setState((currentState) => ({
                                ...currentState,
                                q: value,
                            }));
                        }}
                    />
                )
            }
            addButton={
                allowAddition && (
                    <AddButton
                        onClick={
                            typeof allowAddition === 'object'
                                ? allowAddition?.onClick
                                : undefined
                        }
                        itemName={
                            typeof allowAddition === 'object'
                                ? allowAddition?.itemName
                                : undefined
                        }
                    />
                )
            }
            csvExportButton={
                allowExport && (
                    <CsvExportButton
                        // Transform data with dataMapCallback
                        data={filteredDataSource.map(
                            typeof allowExport === 'object'
                                ? (record) => {
                                    const mappedRecord = allowExport.dataMapCallback
                                        ? allowExport.dataMapCallback(record)
                                        : record;
                                    console.log('Mapped Record:', mappedRecord); // Log the mapped record
                                    return mappedRecord;
                                }
                                : (record) => record,
                        )}
                        // Filter columns with createColumns
                        columns={state.columns.filter(
                            typeof allowExport === 'object'
                                ? allowExport.columnsFilterCallback || (() => true)
                                : () => true,
                        )}
                        filename={
                            typeof allowExport === 'object' ? allowExport.filename : undefined
                        }
                    />
                )
            }
            showHideColumnsButton={
                allowShowHideColumns && (
                    <ShowHideColumnsButton
                        tableState={state}
                        setTableState={setState}
                        onVisibleColumnsChange={onVisibleColumnsChange} // Pass the callback
                    />
                )
            }
        />
    );

    return (
        <OpTableCore
            data-testid={testId}
            dataSource={finalDataSource}
            onChange={onChange}
            columns={finalColumns}
            rowKey={rowKey}
            scroll={{
                y: height,
                x: tableWidth,
            }}
            gtm={gtm}
            pagination={false} // Explicitly sets the pagination prop of OpTableCore to false.
            {...(tablePagination && {
                // eslint-disable-next-line react/no-unstable-nested-components
                footer: () => (
                    <TablePagination
                        currentPage={state.pagination.current}
                        pageSize={state.pagination.pageSize}
                        filteredCount={filteredCount}
                        totalCount={dataSource.length}
                        setTableState={setState}
                    />
                ),
            })}
            // Allow row selection if batchActionOptions passed
            {...(batchActionMenuItems && {
                rowSelection: {
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys) =>
                        setSelectedRowKeys(newSelectedRowKeys),
                    preserveSelectedRowKeys: true,
                },
            })}
            // Only show table title if there is content to show
            {...((tableLabel ||
                allowGlobalSearch ||
                allowExport ||
                allowShowHideColumns) && {
                title: tableTitle,
            })}
            // Allow row to be clicked to edit
            onRow={(record) => {
                return {
                    onMouseDown: () => {
                        rowMouseDownTimestamp.current = Date.now();
                    },
                    onClick: () => {
                        if (
                            rowActions?.onEditClick &&
                            Date.now() - rowMouseDownTimestamp.current < 150
                        ) {
                            rowActions.onEditClick?.(record);
                        }
                    },
                };
            }}
            {...tableProps}
        />
    );
};
