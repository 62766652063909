import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

export const phoneUtils = {
  getCountryCode: (phoneNumber: string): CountryCode => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    return phoneNumberObj?.country as CountryCode || 'US'; // Default to 'US' if country code not found
  },

  getFormattedE164CountryCode: (countryCode: CountryCode): string => {
    const examplePhoneNumber = parsePhoneNumberFromString(`+${countryCode}1234567890`, countryCode);
    return examplePhoneNumber?.countryCallingCode || '';
  },
};
