import { Key } from 'react';
import get from 'lodash/get';
import { IOpTableColumn, OpTableRawColumnType } from './OpTableCore';
import { ColumnHeader } from './components';
import { ITableState } from '../OpTable/OpTable';

export const createFinalColumnKey = (
  key?: Key,
  dataIndex?: OpTableRawColumnType['dataIndex'],
) => {
  if (key) {
    return key as string;
  }

  if (Array.isArray(dataIndex)) {
    return dataIndex.join('.') as string;
  }

  return dataIndex as string;
};

/** Width calculated based on the width of each column (default is 180px)
 * plus the width of the selection column (if there is one). We define the
 * width so that we can constrain the table width to the container width
 * and scroll horizontally */
export const createTableWidth = (
  columns: IOpTableColumn[],
  extraWidth: number = 0,
) =>
  columns.reduce(
    (acc, { width = 180, hidden }) => (hidden ? acc : acc + width),
    0,
  ) + extraWidth;

export const convertRawColumnsToColumns = ({
  rawColumns,
  hasHeaderFilter,
  tableState,
  setTableState,
  resetRowSelections,
}: {
  rawColumns: OpTableRawColumnType[];
  hasHeaderFilter: boolean;
  tableState?: ITableState;
  setTableState?: (callback: (state: ITableState) => ITableState) => void;
  resetRowSelections?: () => void;
}) => {
  return rawColumns.map(
    ({
      label,
      tooltip,
      dataIndex,
      key,
      filter,
      onFilter,
      hidden,
      defaultFilteredValue,
      ...rest
    }) => {
      const finalKey = createFinalColumnKey(key, dataIndex);

      /** We want to use the latest state of the hidden columns (table state) so
       * that if new data comes in, the hidden columns are not reset to their
       * defaults (passed in rawColumns) */
      const isColumnHidden = tableState
        ? tableState.columns.find(
            ({ key: currColumnKey }) => currColumnKey === finalKey,
          )?.hidden
        : hidden;

      /** title needs to be a function so that setState can be used as it may not
       * be passed or initialized yet */
      const title = () => (
        <ColumnHeader
          label={label}
          tooltip={tooltip}
          hasHeaderFilter={hasHeaderFilter}
          filterKey={finalKey}
          filter={filter}
          setTableState={setTableState}
          resetRowSelections={resetRowSelections}
          defaultFilteredValue={defaultFilteredValue}
        />
      );

      /** If there is no filter, then we shave no need for onFilter. Otherwise we
       * use the passed onFilter or a default that matches any partial string */
      const finalOnFilter = !filter
        ? undefined
        : onFilter ||
          ((value, record) =>
            (get(record, finalKey) || '')
              .toString()
              .toLowerCase()
              .includes(String(value).toLowerCase()));

      return {
        key: finalKey,
        dataIndex,
        hidden: isColumnHidden,
        title,
        label, // Need the label for show/hide columns
        showSorterTooltip: false, // Hides the default tooltip
        onFilter: finalOnFilter,
        /** Explicitly not including defaultFilteredValue as we use custom filtering
         * and our filtered don't trigger anything on the internal table state that
         * will prevent defaultFilteredValue from taking affect */
        ...rest,
      } satisfies IOpTableColumn;
    },
  );
};
