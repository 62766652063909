import { Auth, TokenScope } from 'types/authTypes';

export const parseTokenScope = (response: any): TokenScope => ({
    org: {
        id: Number(response.org.id),
        name: String(response.org.name),
    },
    user: {
        id: Number(response.user.id),
    },
    scope: Array.isArray(response.scope) ? response.scope.map(String) : [],
});

export const parseAuth = (response: any): Auth => ({
    token: response.token,
    identityId: response.identityId,
    createdAt: response.createdAt,
    expiresAt: response.expiresAt,
    tokenScopeList: Array.isArray(response.tokenScopeList) ? response.tokenScopeList.map(parseTokenScope) : [],
});
