import React from 'react';
import { Modal, List } from 'antd';
import { getStatusColor, profileIcon } from 'utils/visitorsHelper';

interface ModalContent {
    name: string;
    firstName?: string;
    lastName?: string;
    dateTime: string;
    statusName: string;
    visitId: number;
}

interface CardInfoModalProps {
    visible: boolean;
    title: string;
    content: ModalContent[];
    onCancel: () => void;
    onItemClick: (visitId: number) => void;
}

const CardInfoModal: React.FC<CardInfoModalProps> = ({ visible, title, content, onCancel, onItemClick }) => {
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={null}
            centered
        >
            <List
                itemLayout="horizontal"
                dataSource={content}
                renderItem={item => {
                    const colorCode = getStatusColor(item.statusName);
                    return (
                        <List.Item
                            onClick={() => onItemClick(item.visitId)}
                            style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
                        >
                            <List.Item.Meta
                                avatar={profileIcon({ firstName: item.firstName, lastName: item.lastName, size: 40, color: colorCode })}
                                title={item.name}
                                description={item.dateTime}
                            />
                        </List.Item>
                    );
                }}
                style={{ overflowY: 'auto', maxHeight: '50vh' }}
            />
        </Modal>
    );
};

export default CardInfoModal;
