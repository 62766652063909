import { ComponentProps } from 'react';
import Alert from 'antd/es/alert';
import clsx from 'clsx';

import './OpBanner.scss';

interface IOpBannerProps
  extends Omit<ComponentProps<typeof Alert>, 'data-testid'> {
  testId?: string;
}

export const OpBanner = ({
  testId = 'op-banner',
  className,
  ...alertProps
}: IOpBannerProps) => {
  return (
    <Alert
      {...alertProps}
      banner
      className={clsx('op-banner', className)}
      data-testid={testId}
    />
  );
};
