import dayjs from 'dayjs';
import { Visit, Site, Host, Visitor, ScheduleBy, VisitStatus, Note, VisitorAgreement } from 'types/visitTypes';
import { parseVisitorTypeResponse } from './visitorTypeParser';
import { parseAgreementResponse } from './agreementParser';

export const parseVisitResponse = (response: any): Visit => ({
    id: Number(response.id),
    visitorType: parseVisitorTypeResponse(response.visitorType),
    company: response.company,
    site: parseSiteResponse(response.site),
    host: parseHostResponse(response.host),
    visitors: Array.isArray(response.visitors) ? response.visitors.map(parseVisitorResponse) : [],
    scheduleBy: parseScheduleByResponse(response.scheduleBy),
    scheduleAllDay: response.scheduleAllDay === '1',
    scheduleStart: response.scheduleStart ? dayjs(response.scheduleStart).toISOString() : null,
    scheduleEnd: response.scheduleEnd ? dayjs(response.scheduleEnd).toISOString() : null,
    signIn: response.signIn ? dayjs(response.signIn).toISOString() : null,
    signOut: response.signOut ? dayjs(response.signOut).toISOString() : null,
    visitStatus: parseVisitStatusResponse(response.visitStatus),
    purpose: response.purpose,
    confirmReturnItems: response.confirmReturnItems === '1',
});

export const parseSiteResponse = (response: any): Site => ({
    id: response.id ? Number(response.id) : null,
    name: response.name,
});

export const parseHostResponse = (response: any): Host => ({
    userId: response.userId ? Number(response.userId) : null,
    identityID: response.identityID !== null ? Number(response.identityID) : null,
    firstName: response.firstName,
    lastName: response.lastName,
    email: response.email,
    mobilePhone: response.mobilePhone,
    department: response.department,
    title: response.title,
});

export const parseVisitorResponse = (response: any): Visitor => ({
    id: response.id ? Number(response.id) : null,
    firstName: response.firstName,
    middleName: response.middleName,
    lastName: response.lastName,
    email: response.email,
    mobilePhone: response.mobilePhone,
    userId: response.userId ? Number(response.userId) : null,
    status: response.status ? Number(response.status) : null,
    signIn: response.signIn ? dayjs(response.signIn).toISOString() : null,
    signOut: response.signOut ? dayjs(response.signOut).toISOString() : null,
});

export const parseVisitorAgreementResponse = (response: any): VisitorAgreement => ({
    agreementDate: response.agreementDate ? dayjs(response.agreementDate).toISOString() : null,
    hasAgreed: response.hasAgreed === '1',
    hasSigned: response.hasSigned === '1',
    document: parseAgreementResponse(response.document),
    visitor: parseVisitorResponse(response.visitor),
});

export const parseScheduleByResponse = (response: any): ScheduleBy => ({
    userId: response.userId ? Number(response.userId) : null,
    identityID: response.identityID !== null ? Number(response.identityID) : null,
    firstName: response.firstName,
    lastName: response.lastName,
    email: response.email,
    mobilePhone: response.mobilePhone,
    department: response.department,
    title: response.title,
});

export const parseVisitStatusResponse = (response: any): VisitStatus => ({
    id: Number(response.id),
    name: response.name,
    languageCode: response.languageCode,
    status: Number(response.status),
});

export const parseNoteResponse = (response: any): Note => ({
    id: Number(response.id),
    timestamp: response.timestamp ? dayjs(response.timestamp).toISOString() : null,
    loggedByUserId: Number(response.loggedByUserId),
    log: response.log,
});
