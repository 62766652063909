import dayjs from 'dayjs';
import { RedFlag, RedFlagData, RedFlagLog, RedFlagCount, loggedByUser } from 'types/redFlagTypes';

export const parseRedFlagResponse = (response: any): RedFlag => ({
    id: Number(response.id),
    level: response.level !== null ? Number(response.level) : null,
    levelName: response.levelName,
    datatype: response.datatype,
    effectiveDate: response.effectiveDate ? dayjs(response.effectiveDate).toISOString() : null,
    expireDate: response.expireDate ? dayjs(response.expireDate).toISOString() : null,
    metadata: response.metadata,
    firstName: response.firstName,
    lastName: response.lastName,
    middleName: response.middleName,
    dateOfBirth: response.dateOfBirth ? dayjs(response.dateOfBirth).toISOString() : null,
    identification: response.identification,
    data: Array.isArray(response.data) ? response.data.map(parseRedFlagDataResponse) : [],
});

export const parseRedFlagDataResponse = (response: any): RedFlagData => ({
    id: Number(response.id),
    data: response.data,
    value: response.value,
});

export const parseRedFlagLogResponse = (response: any): RedFlagLog => {
    return {
        id: Number(response.id),
        timestamp: response.timestamp ? dayjs(response.timestamp).toISOString() : null,
        loggedByUser: parseLoggedByUserResponse(response.loggedByUser),
        log: response.log,
    };
};

export const parseLoggedByUserResponse = (response: any): loggedByUser => {
    return {
        userId: response.userId ? Number(response.userId) : null,
        identityID: response.identityID,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        mobilePhone: response.mobilePhone,
        department: response.department,
        title: response.title,
    };
};

export const parseRedFlagCountResponse = (count: any): RedFlagCount => ({
    level: Number(count.level),
    levelName: count.levelName,
    count: count.count !== null ? Number(count.count) : null,
});
