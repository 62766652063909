import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Dropdown, MenuProps } from 'antd';

// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import DateRangeLocationFilter from 'components/customAntd/DateRangeLocationFilter';

// Import actions and types from Redux slices
import { RootState, AppDispatch } from 'store/store';
import { fetchVisits, describeVisit, clearVisits } from 'store/slices/visitsSlice';
import { clearRedFlags, fetchRedFlags } from 'store/slices/redFlagSlice';

// Import utility functions
import { getVisitDateTime, getStatusNameById, getStatusColor, profileIcon } from 'utils/visitorsHelper';

// Import constants
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';

import warningIcon from './warning.svg';
import { formatFullName, hasPermission } from 'utils/utils';
import { Visit, Visitor } from 'types/visitTypes';
// Extend dayjs with the isBetween plugin
dayjs.extend(isBetween);

interface VisitTableData {
    visitId: number;
    fullName: string;
    status: string;
    dateTime: string;
    visit: Visit;
    visitors: Visitor[];
}

interface VisitorsTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const VisitorsTable: React.FC<VisitorsTableProps> = ({ setDrawerVisible }) => {
    // Redux hooks
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const { visits, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);
    const redFlags = useSelector((state: RootState) => state.redFlags.redFlags);

    const [filteredVisits, setFilteredVisits] = useState<Visit[]>();

    const [selectedLocationId, setSelectedLocationId] = useState<number>(globalLocationId!);
    const [startDate, setStartDate] = useState(dayjs().startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().endOf('day'));

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    // Fetch visits and red flags on organizationId change
    useEffect(() => {
        if (orgId) {
            dispatch(fetchVisits({ orgId }));
            dispatch(fetchRedFlags({ orgId }));
        }
        return () => {
            dispatch(clearVisits());
            dispatch(clearRedFlags());
        };
    }, [dispatch, orgId]);

    // Filter visits based on location and date range
    useEffect(() => {
        if (visits && visits.data) {
            const filteredVisits = visits.data
                .filter((visit) => {
                    if (visit?.site?.id !== selectedLocationId) return false;
                    const visitDateTime = getVisitDateTime(visit, visit.visitStatus.name!, DATE_TIME_FORMAT);
                    if (!visitDateTime) return false;
                    if (!hasAllvisitorsRead && globalUserId !== visit.host.userId) return false;

                    return dayjs(visitDateTime).isBetween(startDate, endDate, null, '[]');
                })
                .sort((a, b) => dayjs(getVisitDateTime(b, b.visitStatus.name!, DATE_TIME_FORMAT)).unix() - dayjs(getVisitDateTime(a, a.visitStatus.name!, DATE_TIME_FORMAT)).unix());
            setFilteredVisits(filteredVisits);
        }
    }, [visits, selectedLocationId, startDate, endDate, globalUserId, hasAllvisitorsRead]);

    const handleDateRangeLocationFilter = (locationId: number, start: dayjs.Dayjs, end: dayjs.Dayjs) => {
        setSelectedLocationId(locationId);
        setStartDate(start);
        setEndDate(end);
        // Fetch visits with the new filters
        dispatch(fetchVisits({ orgId }));
    };

    // Check if any visitor in the visit is red-flagged
    const isRedFlagged = (visit: Visit) => {
        if (!visit.visitors || !redFlags.data) return false;
        return visit.visitors.some(visitor =>
            redFlags.data.some(redFlag =>
                redFlag?.firstName?.toLowerCase() === visitor.firstName?.toLowerCase() &&
                redFlag?.middleName?.toLowerCase() === visitor.middleName?.toLowerCase() &&
                redFlag?.lastName?.toLowerCase() === visitor.lastName?.toLowerCase()
            )
        );
    };

    // Format visits for table display
    const formatVisitsForTable = (visits: Visit[]): VisitTableData[] => {
        return visits
            .map((visit) => {
                const dateTime = getVisitDateTime(visit, getStatusNameById(visit.visitStatus.id, visit.scheduleStart!), DATE_TIME_AM_PM_FORMAT);
                if (!dateTime) return null; // Filter out invalid dates
                return {
                    visitId: visit.id,
                    fullName: formatFullName(visit.visitors[0]?.firstName, visit.visitors[0]?.middleName, visit.visitors[0]?.lastName) +
                        (visit.visitors.length > 1 ? ` (+${visit.visitors.length - 1})` : ''),
                    status: getStatusNameById(visit.visitStatus.id, visit.scheduleStart!),
                    dateTime: dateTime,
                    visit: visit,
                    visitors: visit.visitors
                };
            })
            .filter((visit) => visit !== null) as VisitTableData[]; // Filter out null values
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.fullName.localeCompare(b.fullName), // Alphabetical sorting
            onFilter: (value, record) => {
                const visitRecord = record as VisitTableData; // Type assertion
                const searchValue = value.toString().toLowerCase();
                // Check if the main fullName or any visitor's name matches the search
                const mainNameMatches = visitRecord.fullName.toLowerCase().includes(searchValue);
                const dropdownNameMatches = visitRecord.visitors.some(visitor =>
                    formatFullName(visitor.firstName, visitor.middleName, visitor.lastName).toLowerCase().includes(searchValue)
                );
                return mainNameMatches || dropdownNameMatches;
            },
            render: (text, record) => {
                const visitRecord = record as VisitTableData; // Type assertion
                const handleMenuClick: MenuProps['onClick'] = () => {
                    dispatch(describeVisit({ orgId, visitId: visitRecord.visitId }));
                    setDrawerVisible(true);
                };

                const menuItems: MenuProps['items'] = visitRecord.visitors.map((visitor: Visitor) => {
                    const visitorColorCode = getStatusColor(getStatusNameById(visitor.status!, visitRecord.visit.scheduleStart));
                    return {
                        key: visitor.id?.toString() || '',  // Ensure key is a string and not undefined
                        label: (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {profileIcon({ visitor, size: 28, color: visitorColorCode })}
                                <span>
                                    {formatFullName(visitor.firstName, visitor.middleName, visitor.lastName)}
                                </span>
                            </span>
                        ),
                    };
                });
                const visitColorCode = getStatusColor(getStatusNameById(visitRecord.visit.visitStatus.id, visitRecord.visit.scheduleStart));
                const profileIconComponent = (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {profileIcon({ visitor: visitRecord.visitors[0], size: 28, color: visitColorCode })}
                        <span>{text}</span>
                        {isRedFlagged(visitRecord.visit) && <img src={warningIcon} alt="Warning" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />}
                    </span>
                );

                return visitRecord.visitors.length > 1 ? (
                    <Dropdown
                        menu={{ items: menuItems, onClick: handleMenuClick }}
                        trigger={['hover']}
                    >
                        <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            {profileIconComponent}
                        </span>
                    </Dropdown>
                ) : profileIconComponent;
            }
        },
        {
            dataIndex: 'status',
            label: 'Status',
            filter: {
                type: 'multiSelect',
                options: [
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Signed In', value: 'Signed In' },
                    { label: 'Signed Out', value: 'Signed Out' },
                    { label: 'Denied Entry', value: 'Denied Entry' },
                    { label: 'No Show', value: 'No Show' },
                ],
            },
            defaultFilteredValue: ['Pending', 'Signed In', 'Signed Out', 'Denied Entry', 'No Show'],
            sorter: (a, b) => (a.status || '').localeCompare(b.status || ''), // Alphabetical sorting
            render: (statusName) => {
                const color = getStatusColor(statusName);
                return <div style={{ backgroundColor: color, padding: '5px 10px', borderRadius: '4px', color: 'white' }}>{statusName}</div>;
            }
        },
        {
            dataIndex: 'dateTime',
            label: "Date & Time",
            sorter: (a, b) => dayjs(a.dateTime, DATE_TIME_AM_PM_FORMAT).unix() - dayjs(b.dateTime, DATE_TIME_AM_PM_FORMAT).unix(),
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: filteredVisits ? formatVisitsForTable(filteredVisits) : [],
        label: `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`,
        columns: columns,
        rowActions: {
            onEditClick: (visit: VisitTableData) => {
                dispatch(describeVisit({ orgId, visitId: visit.visitId }));
                setDrawerVisible(true);
            },
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        // allowAddition: {
        //     itemName: 'Visitor',
        //     onClick: () => {
        //         dispatch(clearSelectedVisit());
        //         setDrawerVisible(true);
        //     }
        // },
        loading: fetchVisitsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: 'visitId',
    };

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <DateRangeLocationFilter
                onDateRangeLocationFilter={handleDateRangeLocationFilter}
                initialStartDate={startDate}
                initialEndDate={endDate}
            />
            <OpTable {...opTableProps} />
        </OpSpace>
    );
}

export default VisitorsTable;
