import { ComponentProps, Key, ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Table, { ColumnType } from 'antd/es/table';
import clsx from 'clsx';
import { SelectFilter } from './components/SelectFilter';

import './OpTableCore.scss';

// Defines the type of a single row of data in your table.
export type OpTableRecordType = Record<string, any>;


export interface IOpTableColumn<RecordType extends OpTableRecordType = OpTableRecordType> extends Omit<ColumnType<RecordType>, 'defaultFilteredValue'> {
  width?: number; // TODO should really be string/number/true to be what scroll['x'] allows
  label?: string;
  tooltip?: string | ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  createCsvExportCellValue?(record: RecordType): string;
}

/*
interface Product {
  name: string;
  price: number;
  // ... other product properties
}

const productColumns: IOpTableColumn<Product>[] = [
  { dataIndex: 'name', label: 'Product Name' },
  { dataIndex: 'price', label: 'Price' },
  // ... other columns for product data
];
*/

type InputFilterType = {
  type: 'input';
};

type SelectFilterType = {
  type: 'select' | 'multiSelect';
  options: ComponentProps<typeof SelectFilter>['options'];
};

export type OpTableRawColumnType = Omit<IOpTableColumn, 'title'> & {
  filter?: InputFilterType | SelectFilterType;
  defaultFilteredValue?: string[] | null;
} & (
    | { key: Key }
    | { dataIndex: string | number | readonly (string | number)[] }
  );

type OpTableCorePropsType<RecordType extends OpTableRecordType> =
  ComponentProps<typeof Table<RecordType>> & {
    loading?: boolean;
    testId?: string;
    gtm?: string;
  };

interface IAllowExport {
  // Transform or modify each record of the table data before exporting. 
  // This method allows for customization of how data is handled or formatted at the record level.
  // (OpTableRecordType) => OpTableRecordType
  dataMapCallback?(record: OpTableRecordType): OpTableRecordType;

  // Determines whether a particular column should be included in the export based on some criteria
  columnsFilterCallback?(record: IOpTableColumn): boolean;

  // Filename for the exported file
  filename?: string;
}

export type AllowExportType = boolean | IAllowExport;

export const OpTableCore = <RecordType extends OpTableRecordType>({
  className,
  loading = false,
  testId = 'op-table-core',
  gtm,
  ...tableProps
}: OpTableCorePropsType<RecordType>) => {
  return (
    <Table
      data-testid={testId}
      data-gtm={gtm}
      className={clsx('op-table-core', className)}
      size="middle"
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined />,
      }}
      {...tableProps}
    />
  );
};
