import { ComponentProps } from 'react';
import Image from 'antd/es/image';
import clsx from 'clsx';

interface IOpImageProps extends ComponentProps<typeof Image> {}

export const OpImage = ({
  className,
  preview = false,
  ...imageProps
}: IOpImageProps) => {
  return (
    <Image
      className={clsx('op-image', className)}
      preview={preview}
      {...imageProps}
    />
  );
};
