import { ComponentProps, ReactNode, useEffect, useRef, useState } from 'react';
import { IUseOpQueryOptions, useOpQuery } from 'utils/customHooks/useOpQuery';
import difference from 'lodash/difference';
import debounce from 'lodash/debounce';
import differenceBy from 'lodash/differenceBy';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import uniqBy from 'lodash/uniqBy';
import { ensurePayloadAndQuery } from 'utils/ensurePayloadAndQuery';
// import { OpTag } from '../OpTag/OpTag';
import { OpTransfer } from '../OpTransfer/OpTransfer';
// import { Api } from '../../../types/apiTypes';

import './OpDataFetchTransfer.scss';

interface IDataParams<T> {
  queryOptions: IUseOpQueryOptions<T>;
  createKey?: (item: Record<string, any>) => string;
  createTitle?: (item: Record<string, any>) => string;
  createDescription?: (item: Record<string, any>) => string;
}

interface RecordType {
  key?: string | number;  // Use string or number to match typical Key types
  title?: string;
  description?: string;
}

interface IQueries {
  limit?: number;
  offset?: number;
  sort?: string;
  order?: string;
  q?: string;
  filter?: string;
  options?: string;
}

export interface IOpDataFetchTransferProps<
  T extends any,
  U extends any,
> extends Omit<ComponentProps<typeof OpTransfer>, 'onChange'> {
  availableItemsParams: IDataParams<T>;
  existingItemsParams: IDataParams<U>;
  createLabel: (record: RecordType) => ReactNode;
  existingItemsLabel?: string;
  testId?: string;
  onChange?: (idsToAddAndRemove: { add: number[]; remove: number[] }) => void;
}

const appendQueriesInQueryOptions = <T extends any>(
  queryOptions: IUseOpQueryOptions<T>,
  queriesToAppend: IQueries,
) => {
  const { parametersArray, withQuery } = ensurePayloadAndQuery(
    queryOptions.apiEndpointName,
    queryOptions.parameters || {},
  );
  if (withQuery) {
    // Find the index of the queries (last object)
    const queriesIndex = parametersArray.findIndex(
      (param: any) => typeof param === 'object',
    );

    if (queriesIndex === -1) {
      // ensurePayloadAndQuery guarantees the presence of a queries object, so throw since something is way off...
      throw new Error(
        `Critical: ensurePayloadAndQuery did not ensure a query object for API endpoint ${queryOptions.apiEndpointName}`,
      );
    }

    parametersArray[queriesIndex] = {
      ...(parametersArray[queriesIndex] as IQueries),
      ...queriesToAppend,
    } satisfies IQueries;
  }

  return {
    ...queryOptions,
    parameters: parametersArray,
  } as IUseOpQueryOptions<T>;
};

export const OpDataFetchTransfer = <
  T extends any,
  U extends any,
>({
  className = '',
  testId = 'op-data-fetch-transfer',
  availableItemsParams,
  existingItemsParams,
  createLabel = (record: RecordType) => record.title,
  existingItemsLabel,
  onChange: onChangeProp,

  // Props passed through to OpTransfer
  ...opTransferProps
}: IOpDataFetchTransferProps<T, U>) => {
  const { t } = useTranslation();

  const [availableDataSource, setAvailableDataSource] = useState<RecordType[]>(
    [],
  );
  const [existingDataSource, setExistingDataSource] = useState<RecordType[]>(
    [],
  );
  const [availableTotalItems, setAvailableTotalItems] = useState<number>();
  const [existingTotalItems, setExistingTotalItems] = useState<number>();
  const [availableItemsQuery, setAvailableItemsQuery] = useState<string>();
  const [existingItemsQuery, setExistingItemsQuery] = useState<string>();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const addedItems = useRef<RecordType[]>([]);
  const removedItems = useRef<RecordType[]>([]);

  const { data: availableItemsHeliumResponse } = useOpQuery(
    appendQueriesInQueryOptions<T>(availableItemsParams.queryOptions, {
      limit: 100 + addedItems.current.length, // Makes sure there are always items (if more than 50 in db)
      ...(availableItemsQuery && { q: availableItemsQuery }),
    }),
  );
  const { data: existingItemsHeliumResponse } = useOpQuery(
    appendQueriesInQueryOptions<U>(existingItemsParams.queryOptions, {
      limit: 50 + removedItems.current.length, // Makes sure there are always items (if more than 50 in db)
      ...(existingItemsQuery && { q: existingItemsQuery }),
    }),
  );

  // Cleanup
  useEffect(
    () => () => {
      addedItems.current = [];
      removedItems.current = [];
    },
    [],
  );

  // Set the existingDataSource
  useEffect(() => {
    // Assuming existingItemsHeliumResponse?.json?.data is an array of User (or another appropriate type)
    const existingItemsData = existingItemsHeliumResponse?.data as Record<string, any>[];

    if (Array.isArray(existingItemsData)) {
      setExistingTotalItems(existingItemsHeliumResponse?.totalCount);

      setExistingDataSource(() =>
        uniqBy(
          existingItemsData.map((item) => ({
            key: existingItemsParams.createKey?.(item) || String(item.id),
            title: existingItemsParams.createTitle?.(item) || String(item.name),
            description: existingItemsParams.createDescription?.(item),
          })),
          'key',
        ),
      );

      // Add group users to targetKeys
      setTargetKeys(
        existingItemsData
          .map(
            (item) => existingItemsParams.createKey?.(item) || String(item.id),
          )
          .filter(
            (key) =>
              key &&
              !removedItems.current
                .map(({ key: removedItemKey }) => removedItemKey)
                .includes(key),
          ),
      );
    }
  }, [
    existingItemsHeliumResponse?.data,
    existingItemsHeliumResponse?.totalCount,
    existingItemsParams,
  ]);


  // TODO - persist removed item on available when not in available or existing fetched data
  // Set the availableDataSource
  useEffect(() => {
    // Assuming availableItemsHeliumResponse?.json?.data is an array of a specific type, e.g., User or another appropriate type
    const availableItemsData = availableItemsHeliumResponse?.data as Record<string, any>[];

    if (Array.isArray(availableItemsData)) {
      setAvailableTotalItems(availableItemsHeliumResponse?.totalCount);

      setAvailableDataSource(() =>
        uniqBy(
          availableItemsData.map((item) => ({
            key: availableItemsParams.createKey?.(item) || String(item.id),
            title: availableItemsParams.createTitle?.(item) || String(item.name),
            description: availableItemsParams.createDescription?.(item),
          })),
          'key',
        ),
      );
    }
  }, [
    availableItemsHeliumResponse?.data,
    availableItemsHeliumResponse?.totalCount,
    availableItemsParams,
  ]);


  const onChange: ComponentProps<typeof OpTransfer>['onChange'] = (
    newTargetKeys,
    direction,
    moveKeys,
  ) => {
    // Convert newTargetKeys to string[] if they're not already
    const stringTargetKeys = newTargetKeys.map(key => String(key));
    // Update targetKeys
    setTargetKeys(stringTargetKeys);

    if (direction === 'right') {
      const moveKeysNotInRemoveKeys = difference(
        moveKeys.map(key => String(key)),
        removedItems.current.map(({ key }) => String(key)),
      );

      const newAddedItems = [
        ...addedItems.current,
        ...dataSource.filter(({ key }) =>
          moveKeysNotInRemoveKeys.includes(String(key)),
        ),
      ];
      const newRemovedItems = differenceBy(
        removedItems.current,
        dataSource.filter(({ key }) => key && moveKeys.includes(String(key))),
        'key',
      );

      onChangeProp?.({
        add: newAddedItems.map(({ key }) => Number(key)),
        remove: newRemovedItems.map(({ key }) => Number(key)),
      });

      addedItems.current = newAddedItems;
      removedItems.current = newRemovedItems;
    } else {
      const moveKeysNotInAddKeys = difference(
        moveKeys.map(key => String(key)),
        addedItems.current.map(({ key }) => String(key)),
      );

      const newRemovedItems = [
        ...removedItems.current,
        ...dataSource.filter(({ key }) => moveKeysNotInAddKeys.includes(String(key))),
      ];
      const newAddedItems = differenceBy(
        addedItems.current,
        dataSource.filter(({ key }) => key && moveKeys.includes(String(key))),
        'key',
      );

      onChangeProp?.({
        remove: newRemovedItems.map(({ key }) => Number(key)),
        add: newAddedItems.map(({ key }) => Number(key)),
      });

      removedItems.current = newRemovedItems;
      addedItems.current = newAddedItems;
    }
  };


  const onSearch = debounce((direction, value) => {
    if (direction === 'left') {
      setAvailableItemsQuery(value);
    } else {
      setExistingItemsQuery(value);
    }
  }, 300);

  // DataSource is the combined responses from available and existing item endpoints
  let dataSource = availableDataSource.concat(existingDataSource);

  // If there is an existing items query, add removed items to the end of the dataSource
  if (existingItemsQuery) {
    dataSource.push(...removedItems.current);
  }

  // If there is an available items query, add added items to the end of the dataSource
  if (availableItemsQuery) {
    dataSource.push(...addedItems.current);
  }

  // Make sure dataSource items are unique
  dataSource = uniqBy(dataSource, 'key');

  // Sort the dataSource so removed items are at top of available list
  dataSource = dataSource.sort((a, b) => {
    // Sorted so removed items are at top of available list
    const aKeyIndex = a.key
      ? removedItems.current.map(({ key }) => key).indexOf(a.key)
      : -1;
    const bKeyIndex = b.key
      ? removedItems.current.map(({ key }) => key).indexOf(b.key)
      : -1;

    if (aKeyIndex !== -1 && bKeyIndex === -1) {
      // a's key is in removedItems, but b's key is not
      return -1;
    }
    if (aKeyIndex === -1 && bKeyIndex !== -1) {
      // b's key is in removedItems, but a's key is not
      return 1;
    }
    // Both a's key and b's key are in removedItems or both are not in removedItems
    // Preserve the original order
    return 0;
  });

  return (
    <OpTransfer
      className={clsx('op-data-fetch-transfer', className)}
      dataSource={dataSource}
      showSearch
      testId={testId}
      selectAllLabels={[
        ({
          selectedCount,
          totalCount,
        }: {
          selectedCount: number;
          totalCount: number;
        }) => {
          const count =
            selectedCount > 0
              ? t('{{count}}/{{totalCount}} items selected', {
                count: selectedCount,
                totalCount,
              })
              : t('Showing {{count}} items', { count: totalCount });

          const total =
            availableTotalItems && totalCount !== availableTotalItems
              ? `(${t('{{availableTotalItems}} available', {
                availableTotalItems,
              })})`
              : '';

          return `${count} ${total}`;
        },
        ({
          selectedCount,
          totalCount,
        }: {
          selectedCount: number;
          totalCount: number;
        }) => {
          const count =
            selectedCount > 0
              ? t('{{count}}/{{totalCount}} items selected', {
                count: selectedCount,
                totalCount,
              })
              : t('Showing {{count}} items', { count: totalCount });

          const total = existingTotalItems
            ? `(${t('{{existingTotalItems}} {{existingItemsLabel}}', {
              existingTotalItems:
                existingTotalItems +
                addedItems.current.length -
                removedItems.current.length,
              existingItemsLabel: existingItemsLabel || t('total'),
            })})`
            : '';

          return `${count} ${total}`;
        },
      ]}
      listStyle={{
        width: '100%',
        height: 500,
        overflow: 'hidden', // Keeps overall width constrained to drawer width
      }}
      targetKeys={addedItems.current.map(({ key }) => key!).concat(targetKeys)}
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(inputValue, option) => {
        return option.title!.toLowerCase().includes(inputValue.toLowerCase());
      }}
      // render={(record: RecordType) => {
      //   const { key, title } = record;

      //   // Convert key to string
      //   const recordKey = key ? String(key) : undefined;

      //   return {
      //     label: (
      //       <div className="op-data-fetch-transfer__label-wrapper">
      //         <div>{createLabel(record)}</div>
      //         {key &&
      //           addedItems.current
      //             .map(({ key: addedItemKey }) => addedItemKey)
      //             .includes(key) && (
      //             <OpTag className="op-data-fetch-transfer__tag">added</OpTag>
      //           )}
      //         {key &&
      //           removedItems.current
      //             .map(({ key: removedItemKey }) => removedItemKey)
      //             .includes(key) && (
      //             <OpTag className="op-data-fetch-transfer__tag">removed</OpTag>
      //           )}
      //       </div>
      //     ),
      //     value: title || '--',
      //   };
      // }}
      {...opTransferProps}
    />
  );
};
