import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OpCollapse } from 'components/customAntd/DLS/OpCollapse/OpCollapse';
import dayjs from 'dayjs';
import { Select, DatePicker, Button, notification } from 'antd';
import { describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { RootState, AppDispatch } from 'store/store';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';

interface DateRangeLocationFilterProps {
    onDateRangeLocationFilter: (locationId: number, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => void;
    initialStartDate: dayjs.Dayjs;
    initialEndDate: dayjs.Dayjs;
}

const { RangePicker } = DatePicker;

const DateRangeLocationFilter: React.FC<DateRangeLocationFilterProps> = ({ onDateRangeLocationFilter, initialStartDate, initialEndDate }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { locations, globalLocation, fetchLocationsLoading } = useSelector((state: RootState) => state.locations);

    const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([initialStartDate, initialEndDate]);

    useEffect(() => {
        if (orgId) {
            dispatch(fetchLocations({ orgId, status: 1 }));
        }
    }, [dispatch, orgId]);

    const handleUpdate = () => {
        if (globalLocation?.id && dateRange && dateRange[0] && dateRange[1]) {
            onDateRangeLocationFilter(globalLocation.id, dateRange[0].startOf('day'), dateRange[1].endOf('day'));
            notification.success({
                message: 'Filter Applied',
                description: 'The visitors have been filtered successfully.',
                placement: 'bottomRight'
            });
        }
    };

    const handleLocationChange = async (locationId: number) => {
        await dispatch(describeLocation({ orgId, locationId, global: true }));
        await dispatch(describeLocationWorkflow({ orgId, locationId, global: true }));
    };

    // Disabled 7 days from the selected date
    const disabled7DaysDate: DatePickerProps['disabledDate'] = (current, { from }) => {
        if (from) {
            return Math.abs(current.diff(from, 'days')) >= 7;
        }

        return false;
    };

    const rangePresets: RangePickerProps['presets'] = [
        { label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
        { label: 'Tomorrow', value: [dayjs().add(1, 'day').startOf('day'), dayjs().add(1, 'day').endOf('day')] },
        { label: 'Yesterday', value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')] },
        { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
        { label: 'Next Week', value: [dayjs().add(1, 'week').startOf('week'), dayjs().add(1, 'week').endOf('week')] },
        { label: 'Last Week', value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')] },
    ];

    return (
        <OpCollapse defaultActiveKey={['1']} items={[{
            key: '1',
            label: 'Filter Visitors',
            children: (
                <div style={{ display: 'flex', gap: '16px', alignItems: 'center', width: '100%' }}>
                    <Select
                        value={globalLocation?.id}
                        style={{ width: 200 }}
                        options={locations.data.map(location => ({ value: location.id, label: location.name }))}
                        onChange={handleLocationChange}
                        loading={fetchLocationsLoading}
                        placeholder="Select Location"
                    />
                    <RangePicker
                        style={{ flex: 1 }}
                        value={dateRange as any}
                        onChange={(dates) => setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])}
                        presets={rangePresets}
                        disabledDate={disabled7DaysDate}
                    />
                    <Button type="primary" onClick={handleUpdate}>Update</Button>
                </div>
            )
        }]} />
    );
};

export default DateRangeLocationFilter;
