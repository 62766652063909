import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'store/store';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';
import { Visitor } from 'types/visitTypes';

interface ModalFormProps {
    open: boolean;
    onCreate: (data: Visitor) => void;
    onCancel: () => void;
    initialData?: Visitor | null;
}

const AddVisitorModal: React.FC<ModalFormProps> = ({ open, onCreate, onCancel, initialData }) => {
    const [form] = Form.useForm();
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const invitationConfig = useSelector((state: RootState) => state.visitorInvitation.invitationConfig);

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue(initialData);
        } else {
            form.resetFields();
        }
    }, [initialData, form]);

    useEffect(() => {
        if (invitationConfig.data.length === 0) {
            dispatch(fetchInvitationConfig({ orgId }));
        }
    }, [dispatch, orgId, invitationConfig.data.length]);

    const renderFormItems = () => {
        if (!invitationConfig.data || !invitationConfig.data[0]) return null;

        const fieldsConfig = {
            firstName: invitationConfig.data[0].firstName,
            lastName: invitationConfig.data[0].lastName,
            email: invitationConfig.data[0].email,
            mobilePhone: invitationConfig.data[0].visitPhone,
        };

        return Object.entries(fieldsConfig).map(([key, config]) => {
            if (config?.included) {
                return (
                    <Form.Item
                        key={key}
                        name={key}
                        label={key === 'mobilePhone' ? 'Mobile Phone' : key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                        rules={config.required ? [{ required: true, message: `Please input the ${key === 'mobilePhone' ? 'mobile phone' : key.replace(/([A-Z])/g, ' $1').toLowerCase()}!` }] : []}
                    >
                        <Input />
                    </Form.Item>
                );
            }
            return null;
        });
    };

    return (
        <Modal
            open={open}
            title={initialData ? `Edit Visitor` : 'Add Visitor'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            centered={true}
        >
            <Form form={form} layout="vertical">
                {renderFormItems()}
            </Form>
        </Modal>
    );
};

export default AddVisitorModal;
