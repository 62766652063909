import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Modal, List } from 'antd';

// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';

// Import actions and types from Redux slices
import { RootState, AppDispatch } from 'store/store';
import { fetchVisits, clearVisits, describeVisit } from 'store/slices/visitsSlice';
import { clearRedFlags, fetchRedFlags } from 'store/slices/redFlagSlice';

// Import utility functions
import { getVisitDateTime, getStatusNameById, getStatusColor, profileIcon } from 'utils/visitorsHelper';

// Import constants
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';

import { formatFullName } from 'utils/utils';
import VisitorsDrawer from 'components/pages/visitors/VisitorsDrawer';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';

// Extend dayjs with the isBetween plugin
dayjs.extend(isBetween);

interface HostReportTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

interface HostVisitCount { hostName: string, userId: number, count: number };
interface HostData { hostName: string, dateTime?: string, visitName: string, statusName: string, visitId: number };

const HostReportTable: React.FC<HostReportTableProps> = ({ setDrawerVisible }) => {
    // Redux hooks
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const { visits, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);
    const [selectedLocationId, setSelectedLocationId] = useState<number>(globalLocationId!);
    const [startDate, setStartDate] = useState(dayjs().startOf('week'));
    const [endDate, setEndDate] = useState(dayjs().endOf('week'));

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedHostVisitData, setSelectedHostVisitData] = useState<HostData[]>([]);
    const [hostVisitCounts, setHostVisitCounts] = useState<HostVisitCount[]>([]);
    const [hostDataArray, setHostDataArray] = useState<{ [key: number]: HostData[] }>({});

    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);

    // Fetch visits and red flags on organizationId change
    useEffect(() => {
        if (orgId) {
            dispatch(fetchVisits({ orgId }));
            dispatch(fetchRedFlags({ orgId }));
        }
        return () => {
            dispatch(clearVisits());
            dispatch(clearRedFlags());
        };
    }, [dispatch, orgId]);

    // Filter visits based on location and date range
    useEffect(() => {
        if (visits && visits.data) {
            const filtered = visits.data
                .filter((visit) => {
                    if (visit?.site?.id !== selectedLocationId) return false;
                    if (!visit.host.userId) return false; // Filter out hosts without userId
                    const visitDateTime = getVisitDateTime(visit, visit.visitStatus.name!, DATE_TIME_FORMAT);
                    if (!visitDateTime) return false;

                    return dayjs(visitDateTime).isBetween(startDate, endDate, null, '[]');
                })
                .sort((a, b) => dayjs(getVisitDateTime(b, b.visitStatus.name!, DATE_TIME_FORMAT)).unix() - dayjs(getVisitDateTime(a, a.visitStatus.name!, DATE_TIME_FORMAT)).unix());

            // Aggregate visits by host userId
            const hostVisitMap: { [key: number]: HostVisitCount } = {};
            const hostDataMap: { [key: number]: HostData[] } = {};
            filtered.forEach((visit) => {
                const userId = visit.host.userId!;
                const hostName = formatFullName(visit.host.firstName, null, visit.host.lastName) || visit.host.email || "";
                const dateTime = getVisitDateTime(visit, getStatusNameById(visit.visitStatus.id, visit.scheduleStart!), DATE_TIME_AM_PM_FORMAT);
                const visitName = formatFullName(visit.visitors[0]?.firstName, visit.visitors[0]?.middleName, visit.visitors[0]?.lastName) +
                    (visit.visitors.length > 1 ? ` (+${visit.visitors.length - 1})` : '');
                const statusName = getStatusNameById(visit.visitStatus.id, visit.scheduleStart);
                const visitId = visit.id;

                if (hostVisitMap[userId]) {
                    hostVisitMap[userId].count++;
                } else {
                    hostVisitMap[userId] = { hostName, userId: userId, count: 1 };
                }

                if (hostDataMap[userId]) {
                    hostDataMap[userId].push({ hostName, dateTime, visitName, statusName, visitId });
                } else {
                    hostDataMap[userId] = [{ hostName, dateTime, visitName, statusName, visitId }];
                }
            });

            // Convert to array of objects for the table and sort by hostName
            const hostVisitArray = Object.keys(hostVisitMap)
                .map((userId) => ({
                    userId: Number(userId),
                    hostName: hostVisitMap[Number(userId)].hostName,
                    count: hostVisitMap[Number(userId)].count,
                }))
                .sort((a, b) => a.hostName.localeCompare(b.hostName)); // Sort by hostName

            setHostVisitCounts(hostVisitArray);
            setHostDataArray(hostDataMap);
        }
    }, [visits, selectedLocationId, startDate, endDate]);

    const handleDateRangeLocationFilter = (locationId: number, start: dayjs.Dayjs, end: dayjs.Dayjs) => {
        setSelectedLocationId(locationId);
        setStartDate(start);
        setEndDate(end);
        // Fetch visits with the new filters
        dispatch(fetchVisits({ orgId }));
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'hostName',
            label: 'Host Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.hostName.localeCompare(b.hostName),
        },
        {
            dataIndex: 'count',
            label: 'Count',
            filter: { type: 'input' },
            sorter: (a, b) => a.count - b.count,
        },
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: hostVisitCounts,
        label: `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`,
        columns: columns,
        rowActions: {
            onEditClick: (hostVisit: HostVisitCount) => {
                setSelectedHostVisitData(hostDataArray[hostVisit.userId]);
                setIsModalVisible(true);
            },
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        loading: fetchVisitsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: 'userId',
    };

    const handleClickedVisitor = (visitId: number) => {
        dispatch(describeVisit({ orgId, visitId }));
        setIsModalVisible(false);
        setIsVisitorsDrawerOpen(true);
        setSelectedHostVisitData([]);
    }

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <DateRangeLocationFilter2
                onDateRangeLocationFilter={handleDateRangeLocationFilter}
                initialStartDate={startDate}
                initialEndDate={endDate}
            />
            <OpTable {...opTableProps} />

            <Modal
                title="Host Visits"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered
            >
                <List
                    itemLayout="horizontal"
                    dataSource={selectedHostVisitData}
                    renderItem={item => {
                        const colorCode = getStatusColor(item.statusName);
                        return (
                            <List.Item
                                onClick={() => handleClickedVisitor(item.visitId)}
                                style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
                            >
                                <List.Item.Meta
                                    avatar={profileIcon({ fullName: item.visitName.replace(/\s\(\+\d+\)$/, ''), size: 40, color: colorCode })}
                                    title={item.visitName}
                                    description={`${item.dateTime} - ${item.statusName}`}
                                />
                            </List.Item>
                        );
                    }}
                    style={{ overflowY: 'auto', maxHeight: '50vh' }}
                />
            </Modal>
            <VisitorsDrawer open={isVisitorsDrawerOpen} onClose={() => setIsVisitorsDrawerOpen(false)} />

        </OpSpace>
    );
}

export default HostReportTable;
