import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
// import OpVideoPlayer from 'containers/OpVideoPlayer';
import { useTranslation } from 'react-i18next';
import { OpInfo } from 'components/customAntd/DLS/OpInfo/OpInfo';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { OpTooltip } from 'components/customAntd/DLS/OpTooltip/OpTooltip';
import { createPolygonPointCoordinates } from './createPolygonPointCoordinates';
import { VideoRegion } from './VideoRegion';

import './VideoRegionSelect.scss';

export interface IPolygons {
  detectionWindowPolygons: { polygon?: string }[];
  privacyMaskPolygons: { polygon?: string }[];
}

interface IVideoRegionSelector {
  opvideoDeviceId?: number | null;
  privacyMaskMaxCount?: number | null;
  detectionWindowMaxCount?: number | null;
  onChange?: (e: { target: { value: IPolygons } }) => void;
  value?: IPolygons;
  disabled?: boolean;
}

export const VideoRegionSelect = ({
  onChange,
  opvideoDeviceId,
  privacyMaskMaxCount = 5,
  detectionWindowMaxCount = 5,
  value,
  disabled,
}: IVideoRegionSelector) => {
  const { t } = useTranslation();

  const { detectionWindowPolygons = [], privacyMaskPolygons = [] } =
    value || {};

  const videoWrapperRef = useRef<HTMLDivElement>(null);

  const [, forceUpdate] = useState({});

  const [isFormingPolygon, setIsFormingPolygon] = useState(false);
  const [tempPolygonStartingPosition, setTempPolygonStartingPosition] =
    useState<number[]>();
  const [tempPolygonEndingPosition, setTempPolygonEndingPosition] =
    useState<number[]>();

  const [isShowingPolygonTypeChoice, setIsShowingPolygonTypeChoice] =
    useState(false);
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);

  /** Watch for changes in the dimensions of the video play wrapper div so
   * that we can create polygons accordingly */
  useEffect(() => {
    // Watch the first element (video player wrapper div)
    const observer = new ResizeObserver(([{ contentRect }]) => {
      setVideoHeight(contentRect.height);
      setVideoWidth(contentRect.width);
    });

    if (videoWrapperRef.current) {
      // Start observing the div element for size changes
      observer.observe(videoWrapperRef.current);
    }

    // Clean up the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  const tempPolygonPoints = useMemo(() => {
    if (!tempPolygonStartingPosition || !tempPolygonEndingPosition) {
      return undefined;
    }

    // Top left
    const x0 = Math.min(
      tempPolygonStartingPosition[0],
      tempPolygonEndingPosition[0],
    );
    const y0 = Math.min(
      tempPolygonStartingPosition[1],
      tempPolygonEndingPosition[1],
    );

    // Bottom right
    const x2 = Math.max(
      tempPolygonStartingPosition[0],
      tempPolygonEndingPosition[0],
    );
    const y2 = Math.max(
      tempPolygonStartingPosition[1],
      tempPolygonEndingPosition[1],
    );

    // Bottom left
    const x1 = x0;
    const y1 = y2;

    // Top right
    const x3 = x2;
    const y3 = y0;

    return `${x0},${y0},${x1},${y1},${x2},${y2},${x3},${y3}`;
  }, [tempPolygonStartingPosition, tempPolygonEndingPosition]);

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setIsShowingPolygonTypeChoice(false);
    setIsFormingPolygon(true);
    setTempPolygonEndingPosition(undefined);
    setTempPolygonStartingPosition(
      createPolygonPointCoordinates(e, videoWidth, videoHeight),
    );
  };

  const handleMouseMove = useMemo(() => {
    if (!isFormingPolygon) return () => null;

    // By using throttle we can limit number of times we set the coordinates and hence renders
    const throttled = throttle((e: MouseEvent<HTMLDivElement>) => {
      setTempPolygonEndingPosition(
        createPolygonPointCoordinates(e, videoWidth, videoHeight),
      );
    }, 40);

    return (e: MouseEvent<HTMLDivElement>) => {
      e.persist();
      throttled(e);
    };
  }, [isFormingPolygon, videoWidth, videoHeight]);

  const handleMouseUp = useCallback(() => {
    if (isFormingPolygon) {
      setIsFormingPolygon(false);
      if (tempPolygonPoints) {
        setIsShowingPolygonTypeChoice(true);
      }
    }
  }, [isFormingPolygon, tempPolygonPoints]);

  const handlePolygonTypeSelection = ({
    currentTarget: { id },
  }: MouseEvent<HTMLButtonElement>) => {
    setIsShowingPolygonTypeChoice(false);

    // Reset temp polygon positions (in doing so will also close selection area)
    setTempPolygonStartingPosition(undefined);
    setTempPolygonEndingPosition(undefined);

    if (!onChange) {
      return;
    }

    if (id === 'privacyMask') {
      const newValue: IPolygons = { ...value! };
      newValue.privacyMaskPolygons.push({ polygon: tempPolygonPoints });
      onChange({ target: { value: newValue } });
    } else if (id === 'detectionWindow') {
      const newValue: IPolygons = { ...value! };
      newValue.detectionWindowPolygons.push({ polygon: tempPolygonPoints });
      onChange({ target: { value: newValue } });
    }
  };

  const handleClear = ({
    currentTarget: { id },
  }: MouseEvent<HTMLButtonElement>) => {
    if (!onChange) {
      return;
    }

    if (id === 'privacyMask') {
      const newValue: IPolygons = { ...value! };
      newValue.privacyMaskPolygons = [];
      onChange({ target: { value: newValue } });
      forceUpdate({});
    } else {
      const newValue: IPolygons = { ...value! };
      newValue.detectionWindowPolygons = [];
      onChange({ target: { value: newValue } });
      forceUpdate({});
    }
  };

  /** If mouse is moved outside the component we still want to handle the
   * mouseUp event. This makes it much easier to create a box the extends
   * all the way to the edge. */
  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  return (
    <div className="video-region-select">
      <OpInfo
        className="video-region-select__info"
        message={t(
          'Click and drag on the feed to add a privacy mask or region of interest',
        )}
      />

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className={clsx('video-region-select__video-wrapper', {
          'video-region-select__video-wrapper--read-only': disabled,
        })}
        ref={videoWrapperRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        role="region"
      >
        {/* <OpVideoPlayer
          isLive
          opvideoDeviceId={opvideoDeviceId}
          controls={false}
        /> */}

        {/* These must stay in this order so that overlaps make sense */}
        {detectionWindowPolygons.map(({ polygon: polygonPoints }, index) => (
          <VideoRegion
            key={index}
            polygonPoints={polygonPoints}
            containerWidth={videoWidth}
            containerHeight={videoHeight}
            type="detectionWindow"
          />
        ))}
        {privacyMaskPolygons.map(({ polygon: polygonPoints }, index) => (
          <VideoRegion
            key={index}
            polygonPoints={polygonPoints}
            containerWidth={videoWidth}
            containerHeight={videoHeight}
            type="privacyMask"
          />
        ))}
        {tempPolygonPoints && (
          <VideoRegion
            polygonPoints={tempPolygonPoints}
            containerWidth={videoWidth}
            containerHeight={videoHeight}
          />
        )}
      </div>

      {!isShowingPolygonTypeChoice &&
        (privacyMaskPolygons.length > 0 ||
          detectionWindowPolygons.length > 0) && (
          <div className="video-region-select__type-choice-button-wrapper">
            {privacyMaskPolygons.length > 0 && (
              <OpButton
                block
                type="primary"
                id="privacyMask"
                onClick={handleClear}
              >
                {t('Clear privacy masks')}
              </OpButton>
            )}
            {detectionWindowPolygons.length > 0 && (
              <OpButton
                block
                type="primary"
                id="detectionWindow"
                onClick={handleClear}
              >
                {t('Clear regions of interest')}
              </OpButton>
            )}
          </div>
        )}

      {/* Selection application */}
      <OpModal
        title={t('How should this selection be applied?')}
        open={isShowingPolygonTypeChoice}
        onCancel={handlePolygonTypeSelection}
        footer={[
          <OpButton key="cancel" onClick={handlePolygonTypeSelection}>
            {t('Cancel')}
          </OpButton>,
          <OpTooltip
            key="privacyMask"
            title={
              (privacyMaskPolygons?.length || 0) < (privacyMaskMaxCount || 5)
                ? null
                : t('Only {{privacyMaskMaxCount}} privacy masks are allowed', {
                    privacyMaskMaxCount,
                  })
            }
          >
            <OpButton
              disabled={
                (privacyMaskPolygons?.length || 0) >= (privacyMaskMaxCount || 5)
              }
              id="privacyMask"
              type="primary"
              onClick={handlePolygonTypeSelection}
            >
              {t('Privacy mask')}
            </OpButton>
          </OpTooltip>,
          <OpTooltip
            key="detectionWindow"
            title={
              (detectionWindowPolygons?.length || 0) <
              (detectionWindowMaxCount || 5)
                ? null
                : t(
                    'Only {{detectionWindowMaxCount}} regions of interest are allowed',
                    { detectionWindowMaxCount },
                  )
            }
          >
            <OpButton
              disabled={
                (detectionWindowPolygons?.length || 0) >=
                (detectionWindowMaxCount || 5)
              }
              id="detectionWindow"
              type="primary"
              onClick={handlePolygonTypeSelection}
            >
              {t('Region of interest')}
            </OpButton>
          </OpTooltip>,
        ]}
      />
    </div>
  );
};
