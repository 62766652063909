import theme from 'antd/es/theme';

const defaultThemeProps = {
  algorithm: theme.defaultAlgorithm,

  token: {
    // // Seed tokens
    borderRadius: 12,
    controlHeight: 36,
    fontFamily: 'Graphik',

    // Map tokens
    borderRadiusSM: 12, // Affects elements give size "small"
    controlHeightSM: 32, // Affects elements give size "small"
    controlHeightLG: 48, // Affects elements give size "large"
    fontSizeLG: 14, // Affects elements give size "large"
  },

  // Override at the component level
  components: {
    Checkbox: {
      borderRadiusSM: 6,
    },
  },
};

export const THEME = {
  old: {
    token: {
      colorPrimary: '#006ed7',
      colorPrimaryHover: '#0065c6',
      colorPrimaryActive: '#005cb5',
      colorSuccess: '#13761c', // $color-success-dark
      colorWarning: '#7a5500', // $color-warning-dark
      colorError: '#a80c0c', // $color-error-dark
      borderRadius: 12,
      borderRadiusSM: 12, // Affects elements give size "small"
      boxShadow: 0,
      colorLink: '#006ed7',
      colorLinkHover: '#0065c6',
      colorLinkActive: '#005cb5',
      fontFamily: 'Graphik',
      controlHeight: 36,
      controlHeightSM: 32, // Affects elements give size "small"
      controlHeightLG: 48, // Affects elements give size "large"
      fontSizeLG: 14, // Affects elements give size "large"
    },

    // Override at the component level
    components: {
      Checkbox: {
        borderRadiusSM: 6,
      },
    },
  },
  light: {
    ...defaultThemeProps,
    token: {
      ...defaultThemeProps.token,

      // COLORS
      // Seed tokens
      colorError: '#de1111',
      colorInfo: '#8d9399',
      colorLink: '#006ed7',
      colorPrimary: '#006ed7',
      colorSuccess: '#20c62f',
      colorTextBase: '#121826',
      colorWarning: '#eaa301',

      // Map tokens
      colorBgLayout: '#F2F5F7',
      colorBgMask: 'rgba(18,24,38,0.45)',
      colorBgSpotlight: '#363B47',
      colorBorder: '#C2C7CC',
      colorBorderSecondary: '#EBEEF0',
      colorFill: 'rgba(18,24,38,0.15)',
      colorFillSecondary: 'rgba(18,24,38,0.06)',
      colorFillTertiary: 'rgba(18,24,38,0.04)',
      colorFillQuaternary: 'rgba(18,24,38,0.02)',
      colorInfoActive: '#646b73',
      colorInfoBg: '#f2f6fb',
      colorInfoBgHover: '#E1E5EB',
      colorInfoBorder: '#D5D8DC',
      colorInfoBorderHover: '#a8aeb3',
      colorInfoHover: '#a8aeb3',
      colorInfoText: '#8d9399',
      colorInfoTextActive: '#646b73',
      colorInfoTextHover: '#9ca1a6',
      colorLinkActive: '#005CB5',
      colorLinkHover: '#0065C6',
      colorPrimaryActive: '#005CB5',
      colorPrimaryHover: '#0065C6',
      colorText: 'rgba(18,24,38,1)',
      colorTextSecondary: 'rgba(18,24,38,0.65)',
      colorTextTertiary: 'rgba(18,24,38,0.45)',
      colorTextQuaternary: 'rgba(18,24,38,0.25)',
    },
  },

  dark: {
    ...defaultThemeProps,
    algorithm: theme.darkAlgorithm,
    token: {
      ...defaultThemeProps.token,

      // COLORS
      // Seed tokens
      colorError: '#FF1F1F',
      colorInfo: '#7B8085',
      colorLink: '#1F93FF',
      colorPrimary: '#006ed7', // same as light mode
      colorSuccess: '#20c62f', // same as light mode
      colorWarning: '#eaa301', // same as light mode

      // Map tokens
      colorBgContainer: '#121826',
      colorBgElevated: '#181f32',
      colorBgLayout: '#21283a',
      colorBgSpotlight: '#5C6A77',
      colorBorder: '#5C6A77',
      colorBorderSecondary: '#2D3642',
      colorErrorText: '#FF1F1F',
      colorLinkActive: '#78BEFF',
      colorLinkHover: '#4BA8FF',
      colorPrimaryActive: '#005CB5',
      colorPrimaryHover: '#0065C6',
      colorPrimaryText: '#1F93FF',
      colorWarningText: '#ca8e04',
    },
  },
};
