import React, { useCallback } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateVisitorWorkflowFields } from 'store/slices/visitorWorkflowsSlice';
import { notification } from 'antd';
import { OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { VisitorWorkflowFields } from 'types/visitorWorkflowTypes';
import { hasPermission } from 'utils/utils';
import { OpSwitch } from 'components/customAntd/DLS/OpSwitch/OpSwitch';

interface FieldsContentProps {
    form: any;
}

interface InitialValues {
    [key: string]: {
        included: boolean;
        required: boolean;
    };
}

interface FormValues {
    [key: string]: {
        included: boolean;
        required: boolean;
    };
}

const FieldsContent: React.FC<FieldsContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisitorWorkflow, visitorWorkflowFields, updateVisitorWorkflowFieldsLoading } = useSelector((state: RootState) => state.visitorWorkflows);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSigninconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:w');

    const fields = [
        { label: 'First Name', field: 'firstName' },
        { label: 'Last Name', field: 'lastName' },
        { label: 'Middle Name', field: 'middleName' },
        { label: 'Email', field: 'email' },
        { label: 'Visitor Company', field: 'visitCompany' },
        { label: 'Visit Phone', field: 'visitPhone' },
        { label: 'Host', field: 'host' },
        { label: 'Visit Purpose', field: 'visitPurpose' }
    ];

    const initialValues: InitialValues = fields.reduce((acc, field) => {
        const fieldData = visitorWorkflowFields?.[field.field as keyof VisitorWorkflowFields];
        acc[field.field] = {
            included: field.field === 'firstName' || field.field === 'lastName' ? true : fieldData?.included!,
            required: field.field === 'firstName' || field.field === 'lastName' ? true : fieldData?.required!
        };
        return acc;
    }, {} as InitialValues);

    const handleValuesChange = (changedValues: Partial<FormValues>, allValues: FormValues) => {
        fields.forEach(field => {
            if (changedValues[field.field]?.included !== undefined) {
                const included = allValues[field.field].included;
                form.setFieldsValue({
                    [field.field]: {
                        required: included ? form.getFieldValue([field.field, 'required']) : false
                    }
                });
            }
        });
    };

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<FormValues>) => {
        console.log("touchedValues", touchedValues);
        console.log("values", values);
        console.log("initialValues", initialValues);
        if (selectedVisitorWorkflow) {
            dispatch(updateVisitorWorkflowFields({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, visitorWorkflowFields: values }));
        }
        notification.success({
            message: 'Saved',
            description: 'Your changes have been saved.',
            placement: 'bottomRight',
        });
    }, [dispatch, orgId, selectedVisitorWorkflow]);

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onValuesChange={handleValuesChange}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasSigninconfWrite && hasSigninconfRead}
        >
            <OpTable
                label={<div>INVITATION FIELDS</div>}
                dataSource={fields}
                pagination={false}
                allowGlobalSearch={false}
                allowExport={false}
                allowShowHideColumns={false}
                columns={[
                    {
                        label: 'Field',
                        dataIndex: 'label',
                    },
                    {
                        label: 'ENABLED',
                        dataIndex: 'enabledSwitch',
                        render: (text, record) => (
                            <OpForm.Item name={[record.field, 'included']} valuePropName="checked" style={{ marginBottom: 0 }}>
                                <OpSwitch
                                    loading={updateVisitorWorkflowFieldsLoading}
                                    disabled={record.field === 'firstName' || record.field === 'lastName' || (!hasSigninconfWrite && hasSigninconfRead)}
                                    onChange={(checked) => {
                                        form.setFieldsValue({
                                            [record.field]: {
                                                included: checked,
                                                required: checked ? form.getFieldValue([record.field, 'required']) : false
                                            }
                                        });
                                    }}
                                />
                            </OpForm.Item>
                        )
                    },
                    {
                        label: 'REQUIRED',
                        dataIndex: 'requiredSwitch',
                        render: (text, record) => (
                            <OpForm.Item
                                shouldUpdate={(prevValues: any, currentValues: any) => (prevValues as FormValues)[record.field]?.included !== (currentValues as FormValues)[record.field]?.included}
                                noStyle
                            >
                                {({ getFieldValue }) => (
                                    <OpForm.Item
                                        name={[record.field, 'required']}
                                        valuePropName="checked"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <OpSwitch
                                            loading={updateVisitorWorkflowFieldsLoading}
                                            disabled={!getFieldValue([record.field, 'included']) || record.field === 'firstName' || record.field === 'lastName' || (!hasSigninconfWrite && hasSigninconfRead)}
                                        />
                                    </OpForm.Item>
                                )}
                            </OpForm.Item>
                        )
                    }
                ]}
            />
        </OpForm>
    );
};

export default FieldsContent;
