import { ComponentProps } from 'react';
import Space from 'antd/es/space';
import clsx from 'clsx';

export const OpSpace = ({
  children,
  className,
  ...spaceProps
}: ComponentProps<typeof Space>) => {
  return (
    <Space className={clsx('op-space', className)} {...spaceProps}>
      {children}
    </Space>
  );
};

// Make the sub components available as a property on the wrapper component
OpSpace.Compact = Space.Compact;
