import createParseMiddleware from './createParseMiddleware';
import { parseVisitorWorkflowResponse, parseVisitorWorkflowTypeResponse, parseVisitorWorkflowConfigResponse, parseVisitorWorkflowFieldsResponse } from 'utils/apiParser/visitorWorkflowParser';
import { VisitorWorkflow, VisitorWorkflowType, VisitorWorkflowConfig, VisitorWorkflowFields } from 'types/visitorWorkflowTypes';
import { Location } from 'types/locationTypes';
import { parseLocationResponse } from 'utils/apiParser/locationParser';
import { Agreement } from 'types/agreementTypes';
import { parseAgreementResponse } from 'utils/apiParser/agreementParser';

export const parseFetchVisitorWorkflowTypesMiddleware = createParseMiddleware<VisitorWorkflowType>(parseVisitorWorkflowTypeResponse, 'visitorWorkflows/fetchVisitorWorkflowTypes'); // api is not implemented yet
export const parseFetchVisitorWorkflowsMiddleware = createParseMiddleware<VisitorWorkflow>(parseVisitorWorkflowResponse, 'visitorWorkflows/fetchVisitorWorkflows');
export const parseDescribeVisitorWorkflowMiddleware = createParseMiddleware<VisitorWorkflow>(parseVisitorWorkflowResponse, 'visitorWorkflows/describeVisitorWorkflow');
export const parseDescribeVisitorWorkflowLocationsMiddleware = createParseMiddleware<Location>(parseLocationResponse, 'visitorWorkflows/describeVisitorWorkflowLocations');
export const parseDescribeVisitorWorkflowConfigMiddleware = createParseMiddleware<VisitorWorkflowConfig>(parseVisitorWorkflowConfigResponse, 'visitorWorkflows/describeVisitorWorkflowConfig');
export const parseDescribeVisitorWorkflowFieldsMiddleware = createParseMiddleware<VisitorWorkflowFields>(parseVisitorWorkflowFieldsResponse, 'visitorWorkflows/describeVisitorWorkflowFields');

export const parseFetchVisitorWorkflowAgreementsMiddleware = createParseMiddleware<Agreement>(parseAgreementResponse, 'visitorWorkflows/fetchVisitorWorkflowAgreements');
