import React from 'react';
import { Layout } from 'antd';
import AppRoutes from "./AppRoutes";
import ActionFloatButtons from './ActionFloatButtons/ActionFloatButtons';

const { Content } = Layout;

const PageContent: React.FC = () => {
    return (
        <Content>
            <AppRoutes />
            <ActionFloatButtons />
        </Content>
    );
}

export default PageContent;
