import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { AppDispatch, RootState } from 'store/store';
import { clearSelectedLocation, deleteLocation, describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { TABLE_HEIGHT } from 'constants/ui';
import { Location } from 'types/locationTypes';
import { hasPermission } from 'utils/utils';

interface LocationsTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const LocationsTable: React.FC<LocationsTableProps> = ({ setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { locations, fetchLocationsLoading } = useSelector((state: RootState) => state.locations);

    const [activeLocations, setActiveLocations] = useState<Location[]>([]);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasLocationWrite = hasPermission(tokenScopeList, orgId, 'o', 'location:w');

    // Get locations from database
    useEffect(() => {
        if (orgId) {
            dispatch(fetchLocations({ orgId, status: 1 }));
        }
    }, [dispatch, orgId]);

    // Filter and get list of active locations
    useEffect(() => {
        if (locations && locations.data) {
            const filteredLocations = locations.data.filter(location => location.status === 1);
            setActiveLocations(filteredLocations);
        }
    }, [locations]);

    // Handle deleting a location
    const handleDeleteLocation = async (record: Location) => {
        if (locations.data.filter(location => location.status === 1).length === 1) {
            notification.error({
                message: 'Deletion Error',
                description: 'There must be at least one location.',
                placement: 'bottomRight',
            });
            return;
        }
        try {
            await dispatch(deleteLocation({ orgId, locationId: record.id }));
            notification.success({
                message: 'Success',
                description: 'Location deleted successfully',
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: 'Deletion Error',
                description: 'Failed to delete location',
                placement: 'bottomRight',
            });
        }
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: "LOCATION",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''), // Alphabetical sorting
        },
        {
            dataIndex: 'description',
            label: "DESCRIPTION",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.description || '').localeCompare(b.description || ''), // Alphabetical sorting
        },
        {
            dataIndex: 'address',
            label: "ADDRESS",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.address || '').localeCompare(b.address || ''), // Alphabetical sorting
        },
        {
            dataIndex: 'address2',
            label: "ADDRESS 2",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.address2 || '').localeCompare(b.address2 || ''), // Alphabetical sorting
        },
        {
            dataIndex: 'city',
            label: "CITY",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.city || '').localeCompare(b.city || ''), // Alphabetical sorting
        },
        {
            dataIndex: 'region',
            label: "STATE",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.region || '').localeCompare(b.region || ''), // Alphabetical sorting
        },
        {
            dataIndex: 'postalcode',
            label: "ZIP CODE",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.postalcode || '').localeCompare(b.postalcode || ''), // Alphabetical sorting
        },
        {
            dataIndex: 'country',
            label: "COUNTRY",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.country || '').localeCompare(b.country || ''), // Alphabetical sorting
        },
        {
            dataIndex: 'phone',
            label: "PHONE",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.phone || '').localeCompare(b.phone || ''), // Alphabetical sorting
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: activeLocations,
        columns: columns,
        rowActions: {
            onEditClick: (location: Location) => {
                dispatch(describeLocation({ orgId, locationId: location.id }));
                dispatch(describeLocationWorkflow({ orgId, locationId: location.id }));
                setDrawerVisible(true);
            },
            onDeleteClick: hasLocationWrite ? handleDeleteLocation : undefined,
            deleteModalTitle: ({ record }) => `Confirm Deletion of ${record.name}`,
            deleteModalContent: ({ record }) => `You are about to delete ${record.name}.`,
        },
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasLocationWrite,
        allowAddition: hasLocationWrite ? {
            itemName: 'Location',
            onClick: () => {
                dispatch(clearSelectedLocation());
                setDrawerVisible(true);
            },
        } : false,
        loading: fetchLocationsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
    };

    return <OpTable {...opTableProps} />;
};

export default LocationsTable;
