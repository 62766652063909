import { ComponentProps } from 'react';
import Menu from 'antd/es/menu';
import clsx from 'clsx';

export const OpMenu = ({
  className,
  ...menuProps
}: ComponentProps<typeof Menu>) => {
  return <Menu className={clsx('op-menu', className)} style={{ backgroundColor: 'var(--colorFillSecondary)' }} // Use CSS variable
    {...menuProps} />;
};
