import { ComponentProps } from 'react';
import Divider from 'antd/es/divider';

import './OpDivider.scss';

export const OpDivider = ({
  children,
  className = '',
  ...dividerProps
}: ComponentProps<typeof Divider>) => (
  <Divider className={`op-divider ${className}`.trim()} {...dividerProps}>
    {children}
  </Divider>
);
