import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import VisitorSearchTable from './VisitorSearchTable';
import VisitorsDrawer from '../visitors/VisitorsDrawer';

const VisitorSearch: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <OpPage title="Visitor Search" tooltip="" subtitle="">
            <VisitorSearchTable setDrawerVisible={setDrawerVisible} />
            <VisitorsDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false);
                }}
            />
        </OpPage>
    );
}

export default VisitorSearch;
