import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Auth, ChangePasswordPayload, LoginPayload } from 'types/authTypes';

interface AuthState {
	auth: ApiResponse<Auth>;
	loginLoading: boolean;
	changePasswordLoading: boolean;
	error: string | null;
}

const initialAuthState: AuthState = {
	auth: {
		data: [],
		meta: null,
		totalCount: 0,
		filteredCount: 0,
	},
	loginLoading: false,
	changePasswordLoading: false,
	error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
	console.error('REACT_APP_BACKEND_URL is not set');
}

export const login = createAsyncThunk<ApiResponse<Auth>, LoginPayload, { rejectValue: string }>(
	'auth/login',
	async (credentials, { rejectWithValue }) => {
		try {
			// const response = await axios.post<ApiResponse<Auth>>('/auth/login', credentials);

			const response = await axios.post<ApiResponse<Auth>>(`${apiUrl}/auth/login`, credentials, {
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
			});

			const authData = response.data;

			// Store token and additional auth data in localStorage for persistence
			if (authData.data.length > 0) {
				const firstAuthData = authData.data[0];
				if (firstAuthData.token) localStorage.setItem('authToken', firstAuthData.token);
				if (firstAuthData.identityId) localStorage.setItem('identityId', firstAuthData.identityId);
				if (firstAuthData.createdAt) localStorage.setItem('createdAt', firstAuthData.createdAt);
				if (firstAuthData.expiresAt) localStorage.setItem('expiresAt', firstAuthData.expiresAt);
				if (firstAuthData.tokenScopeList) localStorage.setItem('tokenScopeList', JSON.stringify(firstAuthData.tokenScopeList));
			}

			return authData;
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			console.error('Login error:', axiosError);
			if (axiosError.response) {
				console.error('Login error response:', axiosError.response.data);
				return rejectWithValue(axiosError.response.data.message || 'Login failed');
			} else {
				return rejectWithValue('An unexpected error occurred!');
			}
		}
	}
);

export const fetchTokenIdentity = createAsyncThunk<ApiResponse<Auth>, { token: string }, { rejectValue: string }>(
	'auth/fetchTokenIdentity',
	async ({ token }, { rejectWithValue }) => {
		try {
			const response = await axios.get<ApiResponse<Auth>>(`${apiUrl}/auth/accessTokens/${token}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
				}
			});
			return response.data;
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			if (axiosError.response) {
				return rejectWithValue(axiosError.response.data.message || 'Failed to fetch token identity');
			} else {
				return rejectWithValue('An unexpected error occurred');
			}
		}
	}
);

export const changePassword = createAsyncThunk<void, { orgId: number; userId: number; payload: ChangePasswordPayload }, { rejectValue: string }>(
	'auth/changePassword',
	async ({ orgId, userId, payload }, { rejectWithValue }) => {
		try {
			await axios.patch(`${apiUrl}/orgs/${orgId}/users/${userId}/password`, payload, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('authToken')}`,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			const axiosError = error as AxiosError<ApiErrorResponse>;
			if (axiosError.response) {
				return rejectWithValue(axiosError.response.data.message || 'Change password failed');
			} else {
				return rejectWithValue('An unexpected error occurred');
			}
		}
	}
);

const authSlice = createSlice({
	name: 'auth',
	initialState: initialAuthState,
	reducers: {
		setAuthData(state, action: PayloadAction<ApiResponse<Auth>>) {
			state.auth = action.payload;
		},
		clearAuthState(state) {
			state.auth = initialAuthState.auth;
			localStorage.removeItem('authToken');
			localStorage.removeItem('identityId');
			localStorage.removeItem('createdAt');
			localStorage.removeItem('expiresAt');
			localStorage.removeItem('tokenScopeList');
		},
		clearError(state) {
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.loginLoading = true;
				state.error = null;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.loginLoading = false;
				state.auth = action.payload;
			})
			.addCase(login.rejected, (state, action) => {
				state.loginLoading = false;
				state.error = action.payload || 'Login failed';
			})
			.addCase(fetchTokenIdentity.pending, (state) => {
				state.loginLoading = true;
				state.error = null;
			})
			.addCase(fetchTokenIdentity.fulfilled, (state, action) => {
				state.loginLoading = false;
				state.auth = action.payload;
			})
			.addCase(fetchTokenIdentity.rejected, (state, action) => {
				state.loginLoading = false;
				state.error = action.payload || 'Failed to fetch token identity';
			})
			.addCase(changePassword.pending, (state) => {
				state.changePasswordLoading = true;
				state.error = null;
			})
			.addCase(changePassword.fulfilled, (state) => {
				state.changePasswordLoading = false;
			})
			.addCase(changePassword.rejected, (state, action) => {
				state.changePasswordLoading = false;
				state.error = action.payload || 'Change password failed';
			});
	},
});

export const { setAuthData, clearAuthState, clearError } = authSlice.actions;
export default authSlice.reducer;
