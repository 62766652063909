import { Form, Input, Modal, notification } from 'antd';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { IOpTableProps, OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { TABLE_HEIGHT } from 'constants/ui';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createVisitorWorkflow, deleteVisitorWorkflow, describeVisitorWorkflow, describeVisitorWorkflowConfig, describeVisitorWorkflowFields, describeVisitorWorkflowLocations, fetchVisitorWorkflows } from 'store/slices/visitorWorkflowsSlice';
import { AppDispatch, RootState } from 'store/store';
import { ApiResponse } from 'types/apiTypes';
import { Location } from 'types/locationTypes';
import { CreateVisitorWorkflowPayload, VisitorWorkflow } from 'types/visitorWorkflowTypes';
import { hasPermission } from 'utils/utils';

interface KioskSignInTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const KioskSignInTable: React.FC<KioskSignInTableProps> = ({ setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { visitorWorkflows, fetchVisitorWorkflowsLoading } = useSelector((state: RootState) => state.visitorWorkflows);
    const [createForm] = OpForm.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [kioskSignInWorkflows, setKioskSignInWorkflows] = useState<VisitorWorkflow[]>([]);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasSigninconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:w');

    useEffect(() => {
        if (orgId) {
            dispatch(fetchVisitorWorkflows({ orgId }));
        }
    }, [dispatch, orgId]);

    // Filter and get list of active workflows
    useEffect(() => {
        if (visitorWorkflows && visitorWorkflows.data) {
            const filteredWorkflows = visitorWorkflows.data.filter(visitorWorkflow => visitorWorkflow.workflowTypeId === 3);
            setKioskSignInWorkflows(filteredWorkflows);
        }
    }, [visitorWorkflows]);

    // Handle deleting a Visitor workflow
    // Handle deleting a Visitor workflow
    const handleDeleteVisitorWorkflow = async (record: VisitorWorkflow) => {
        try {
            const response = await dispatch(describeVisitorWorkflowLocations({ orgId, visitorWorkflowId: record.id }));
            const locations = response.payload as ApiResponse<Location>;
            if (locations.data.length > 0) {
                notification.error({
                    message: 'Deletion Error',
                    description: 'This workflow has a location. It cannot be deleted.',
                    placement: 'bottomRight',
                });
            } else {
                await dispatch(deleteVisitorWorkflow({ orgId, visitorWorkflowId: record.id }));
                notification.success({
                    message: 'Success',
                    description: 'Visitor workflow deleted successfully',
                    placement: 'bottomRight',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Deletion Error',
                description: 'Failed to delete visitor workflow',
                placement: 'bottomRight',
            });
        }
    };
    const handleCreateVisitorWorkflow = async (values: any) => {
        try {
            const payload: CreateVisitorWorkflowPayload = {
                name: values.name,
                workflowTypeId: 3
            };
            await dispatch(createVisitorWorkflow({ orgId, visitorWorkflow: payload }));
            notification.success({
                message: 'Success',
                description: 'Visitor workflow created successfully',
                placement: 'bottomRight',
            });
            setIsModalOpen(false);
            createForm.resetFields();
        } catch (error) {
            notification.error({
                message: 'Creation Error',
                description: 'Failed to create visitor workflow',
                placement: 'bottomRight',
            });
        }
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: "Name",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''), // Alphabetical sorting with null check
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: kioskSignInWorkflows,
        columns: columns,
        rowActions: {
            onEditClick: (visitorWorkflow: VisitorWorkflow) => {
                dispatch(describeVisitorWorkflow({ orgId, visitorWorkflowId: visitorWorkflow.id }));
                dispatch(describeVisitorWorkflowLocations({ orgId, visitorWorkflowId: visitorWorkflow.id }));
                dispatch(describeVisitorWorkflowConfig({ orgId, visitorWorkflowId: visitorWorkflow.id }));
                dispatch(describeVisitorWorkflowFields({ orgId, visitorWorkflowId: visitorWorkflow.id }));
                setDrawerVisible(true);
            },
            onDeleteClick: hasSigninconfWrite ? handleDeleteVisitorWorkflow : undefined,
            deleteModalTitle: () => 'Delete Visitor Workflow',
            deleteModalContent: ({ record }) => {
                return `Are you sure you want to delete this visitor workflow (${record.name})?`;
            },
        },
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasSigninconfWrite,
        allowAddition: hasSigninconfWrite ? {
            itemName: 'Kiosk Sign In Workflow',
            onClick: () => {
                setIsModalOpen(true);
            },
        } : false,
        loading: fetchVisitorWorkflowsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
    };

    return (
        <>
            <OpTable {...opTableProps} />
            <Modal
                title="New Kiosk Sign In Workflow"
                open={isModalOpen}
                onOk={createForm.submit}
                onCancel={() => setIsModalOpen(false)}
                okText="Confirm"
                cancelText="Cancel"
                centered
            >
                <Form form={createForm} onFinish={handleCreateVisitorWorkflow}>
                    <Form.Item
                        label="Workflow Name"
                        name="name"
                        rules={[{ required: true, message: 'Please enter the workflow name' }]}
                    >
                        <Input placeholder="Workflow Name" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default KioskSignInTable;
