import React, { useState } from 'react';
import { DRAWER_WIDTH } from 'constants/ui';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import ManualForm from './ManualForm';

interface EnterManuallyComponentProps {
    open: boolean;
    onClose: () => void;
}

const EnterManuallyComponent: React.FC<EnterManuallyComponentProps> = ({ open, onClose }) => {
    const [form] = OpForm.useForm();
    const [formIsLoading, setFormIsLoading] = useState(false);

    const handleFormLoading = (isLoading: boolean) => {
        setFormIsLoading(isLoading);
    };

    return (
        <OpFormDrawer
            title={'Enter Manually'}
            width={DRAWER_WIDTH}
            formIsLoading={formIsLoading}
            open={open}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            form={form}
            formComponent={
                <ManualForm
                    form={form}
                    onClose={onClose}
                    setFormIsLoading={handleFormLoading}
                />
            }
        />
    );
};

export default EnterManuallyComponent;
