import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { ACTIVITYLOG_TOOLTIP } from 'constants/tooltip';
import ActivityLogTable from './ActivityLogTable';
import ActivityLogDrawer from './ActivityLogDrawer';

const ActivityLog: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <OpPage title="Activity Log" tooltip={ACTIVITYLOG_TOOLTIP} subtitle="">
            <ActivityLogTable setDrawerVisible={setDrawerVisible} />
            <ActivityLogDrawer
                open={drawerVisible}
                onClose={() => {
                    setDrawerVisible(false)
                }}
            />
        </OpPage>
    );
}

export default ActivityLog;
