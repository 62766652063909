import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { ITableState } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpInput } from 'components/customAntd/DLS/OpInput/OpInput';
import { SelectFilter } from './SelectFilter';
import { OpTableRawColumnType } from '../OpTableCore';

export interface IColumnHeaderFilterProps
  extends Pick<OpTableRawColumnType, 'filter' | 'defaultFilteredValue'> {
  filterKey: Key;
  setTableState?: (callback: (state: ITableState) => ITableState) => void;
  resetRowSelections?: () => void;
}

export const ColumnHeaderFilter = ({
  filterKey,
  setTableState,
  resetRowSelections = () => { },
  filter,
  defaultFilteredValue,
}: IColumnHeaderFilterProps) => {
  const { t } = useTranslation();

  const createChangeHandler = (filterValue?: string | string[]) => {
    let finalFilterValue: string[] | null;

    // Determines the final format of the filter value (finalFilterValue). 
    // This can either be an array of strings, a single string wrapped as an array, or null if there is no value.
    if (Array.isArray(filterValue)) {
      finalFilterValue = filterValue;
    } else if (typeof filterValue === 'string' && filterValue !== '') {
      finalFilterValue = [filterValue];
    } else {
      finalFilterValue = null;
    }

    // Update the state of the table. 
    // It specifically resets the pagination to the first page and updates the filter values in the state using the filterKey.
    if (setTableState) {
      setTableState((currentTableState) => ({
        ...currentTableState,
        pagination: {
          ...currentTableState.pagination,
          current: 1, // Reset to page 1 on filter
        },
        filters: {
          ...currentTableState.filters,
          [filterKey as string | number]: finalFilterValue
        },
      }));
    }

    // Reset row selections on filter
    resetRowSelections();
  };

  /*
  If the filter type is select or multiSelect, it renders a SelectFilter component. 
  This component allows for either single or multiple selections based on the filter type. 
  It initializes with a default value converted from the defaultFilteredValue prop and triggers createChangeHandler upon changes.
  */
  if (filter?.type === 'select' || filter?.type === 'multiSelect') {
    // As we allow the default type for antd defaultFilteredValue, this transforms to what we want
    const defaultValue =
      defaultFilteredValue === null
        ? undefined
        : defaultFilteredValue?.map(String);

    return (
      <SelectFilter
        allowMultiple={filter.type === 'multiSelect'}
        options={filter?.options || []}
        defaultValue={defaultValue}
        onChange={(value) => {
          createChangeHandler(value);
        }}
      />
    );
  }

  /*
  If the filter type is input, it renders an OpInput for text input. 
  The default value for this is derived from the first element of defaultFilteredValue and is converted to a string.
  */
  if (filter?.type === 'input') {
    // antd defaultFilteredValue is an array
    const defaultValue = defaultFilteredValue?.[0]
      ? String(defaultFilteredValue[0])
      : undefined;

    return (
      <OpInput
        size="small"
        placeholder={t('Filter')}
        allowClear
        data-lpignore // Prevents autofill
        defaultValue={defaultValue}
        onChange={({ target: { value } }) => {
          createChangeHandler(value);
        }}
      />
    );
  }

  /*
  For any other cases where the filter type does not match the expected types, 
  it renders a disabled input box with a placeholder indicating the column cannot be filtered.
  */
  return <OpInput size="small" placeholder={t('Unfilterable')} disabled />;
};
