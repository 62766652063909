import { Middleware } from '@reduxjs/toolkit';
import { ApiResponse } from 'types/apiTypes';

interface PayloadAction<T> {
    payload: T;
    type: string;
}

function createParseMiddleware<T>(parseFunction: (data: any) => T, actionType: string): Middleware {
    return storeAPI => next => (action: any) => {
        if (isFulfilledApiResponseAction(action) && action.type === `${actionType}/fulfilled`) {
            const parsedData = action.payload.data.map(parseFunction);
            action.payload.data = parsedData;
        }
        return next(action);
    };
}

// Type guard to check if the action is a fulfilled API response action
function isFulfilledApiResponseAction(action: any): action is PayloadAction<ApiResponse<any>> {
    return action.type && action.type.endsWith('/fulfilled') && action.payload && action.payload.data;
}

export default createParseMiddleware;
