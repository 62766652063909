import clsx from 'clsx';

import './VideoRegion.scss';

interface IVideoRegion {
  polygonPoints?: string; // x0,y0,x1,y1,x2,y2,x3,y3 (topLeft counterClockwise / value 0 - 9999 [as % of total width/height])
  containerWidth: number;
  containerHeight?: number;
  type?: 'privacyMask' | 'detectionWindow';
}

export const VideoRegion = ({
  polygonPoints,
  containerWidth,
  containerHeight = containerWidth * 0.75,
  type,
}: IVideoRegion) => {
  const classes = clsx('video-region', {
    'video-region__privacy-mask': type === 'privacyMask',
    'video-region__detection-window': type === 'detectionWindow',
  });

  const [x0, y0, y1, x3] = (polygonPoints || '')
    .split(',')
    .map(Number);
  const style = {
    left: (x0 * containerWidth) / 10000,
    top: (y0 * containerHeight) / 10000,
    width: ((x3 - x0) * containerWidth) / 10000,
    height: ((y1 - y0) * containerHeight) / 10000,
  };

  return <div className={classes} style={style} />;
};
