import React, { useState } from 'react';
import { OpCollapse } from 'components/customAntd/DLS/OpCollapse/OpCollapse';
import dayjs from 'dayjs';
import { DatePicker, Button, notification } from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';

interface DateRangeFilterProps {
    onDateRangeFilter: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => void;
    initialStartDate: dayjs.Dayjs;
    initialEndDate: dayjs.Dayjs;
}

const { RangePicker } = DatePicker;

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({ onDateRangeFilter, initialStartDate, initialEndDate }) => {
    const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([initialStartDate, initialEndDate]);

    const handleUpdate = () => {
        if (dateRange && dateRange[0] && dateRange[1]) {
            onDateRangeFilter(dateRange[0].startOf('day'), dateRange[1].endOf('day'));
            notification.success({
                message: 'Filter Applied',
                description: 'The visitors have been filtered successfully.',
                placement: 'bottomRight'
            });
        }
    };

    // Disabled date by month
    const disabledByMonth: DatePickerProps['disabledDate'] = (current, { from }) => {
        if (from) {
            return current.isAfter(from.add(1, 'month'));
        }

        return false;
    };

    const rangePresets: RangePickerProps['presets'] = [
        { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
        { label: 'Next Week', value: [dayjs().add(1, 'week').startOf('week'), dayjs().add(1, 'week').endOf('week')] },
        { label: 'Last Week', value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')] },
        { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
        { label: 'Last Month', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
    ];

    return (
        <OpCollapse defaultActiveKey={['1']} items={[{
            key: '1',
            label: 'Filter Visitors',
            children: (
                <div style={{ display: 'flex', gap: '16px', alignItems: 'center', width: '100%' }}>
                    <RangePicker
                        style={{ flex: 1 }}
                        value={dateRange as any}
                        onChange={(dates) => setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])}
                        presets={rangePresets}
                        disabledDate={disabledByMonth}
                    />
                    <Button type="primary" onClick={handleUpdate}>Update</Button>
                </div>
            )
        }]} />
    );
};

export default DateRangeFilter;
